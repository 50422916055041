import React from 'react';
import './App.css';
import {VisualWrapper} from "../../../../framework.visual";
import {createVisualConnector} from "../../../../framework.visual";
import {
    authorizationService,
    displayService, selectionService,
    userService
} from "../../../../serviceComposition";
import {AppView} from "./appView";
import {DispatchProps, PermissionsVM, StateProps} from "./appModel";
import {createSelector} from "@reduxjs/toolkit";
import {ComponentTypes, ContainerTypes, SelectionTypes} from "../../../../app.model";
import {PERMISSION_ENTITY, PERMISSION_OPERATOR} from "../../../../app.core.api";

class App extends VisualWrapper {
    constructor() {
        super();

        this.id = ComponentTypes.AppWrapper;

        this.view = AppView;

        this.displayOptions = {
            containerId: ContainerTypes.KnowledgeManagement,
            visible: true,
            appearClass: '',
            enterClass: '',
            exitClass: '',
            timeout: 0
        };

        this.mapDispatchToProps = (dispatch: any): DispatchProps => {
            return {
                onResetMenuSelection: () => selectionService.setContext(SelectionTypes.MENU_SELECTION, ''),
            }
        }

        this.mapStateToProps = (state: any, props: any): StateProps => {
            return {
                currentSystemTool: displayService.getSelectedNodeId(ContainerTypes.SystemToolPanel),
                isDocumentVisible: this.isDocumentVisible(state),
                isPocketDocumentVisible: this.isPocketDocumentVisible(state),
                isReportVisible: this.isReportVisible(state),
                permissions: this.getPermissions(state),
                isAuthorized: true,//authorizationService.isAuthorized(),
                isAuthorizing: false// authorizationService.isAuthorizing()
            }
        }
    }

    isDocumentVisible = createSelector(
        [() => displayService.getNodeInfo(ComponentTypes.DocumentPanelWrapper)],
        (nodeInfo) => {
            let result = false;

            if (nodeInfo && nodeInfo.visible) {
                result = true;
            }

            return result;
        }
    );

    isPocketDocumentVisible = createSelector(
        [() => displayService.getNodeInfo(ComponentTypes.PocketDocumentPanelWrapper)],
        (nodeInfo) => {
            let result = false;

            if (nodeInfo && nodeInfo.visible) {
                result = true;
            }

            return result;
        }
    )

    isReportVisible = createSelector(
        [() => displayService.getNodeInfo(ComponentTypes.ReportPanelWrapper)],
        (nodeInfo) => {
            let result = false;

            if (nodeInfo && nodeInfo.visible) {
                result = true;
            }

            return result;
        }
    );

    getPermissions = createSelector(
        [() => userService.getCurrentUserId(), authorizationService.getPermissions],
        (currentUserId, permissionInfoLookup) => {
            let result: PermissionsVM = {
                canSearch: authorizationService.hasPermission(PERMISSION_ENTITY.DOCUMENT, PERMISSION_OPERATOR.GET, currentUserId, currentUserId),
                isAuthorizing: authorizationService.isAuthorizing()
            }
            return result;
        }
    )
}

export const {
    connectedPresenter: AppPresenter
} = createVisualConnector(App);

