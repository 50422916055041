import {UserInfo} from "../../../app.model";
import {RegistrationStatusType} from "../../model/registrationStatusType";
import {HTMLAttributes} from "react";

export type ProfilePanelAppStateProps = {
    accountStatuses: Record<string, AccountStatusVM>;
    currentUser: UserInfoVM;
    departments: Record<string, DepartmentVM>;
    permissions: PermissionsVM;
    roles: Record<string, RoleVM>;
    searchText: string;
    userRequests: UserInfoVM[];
    users: Record<string, UserInfoVM>;
}

export type ProfilePanelAppDispatchProps = {
    onAcceptUserRequest: (id: string, role: string) => void;
    onAddNewUser: (newUser: UserUpdateParams) => void;
    onClearSearch: () => void;
    onDeclineUserRequest: (id: string) => void;
    onRemoveUser: (id: string) => void;
    onSearch: (name: string, value: string) => void;
    onSearchTextChanged: (value: string) => void;
    onUpdateUser: (user: UserUpdateParams) => void;
}

export type ProfilePanelPresenterProps = ProfilePanelAppStateProps & ProfilePanelAppDispatchProps;

export type ProfilePanelPresenterState = {
    currentUserEditProperties: EditPropertyVM[];
    editProfiles: Record<string, boolean>;
    editProperties: EditPropertyVM[];
    isAddNewUser: boolean;
    newUserEditProperties: EditPropertyVM[];
    newUserErrorMessages: Record<string, string>;
    openProfiles: Record<string, boolean>;
    selectedUserId: string;
    showPopup: boolean;
    tmpUsers: Record<string, UserInfoVM>;
}

export type ProfilePanelViewProps = HTMLAttributes<any> & {
    accountStatuses: Record<string, AccountStatusVM>;
    currentUser: UserInfoVM;
    currentUserEditProperties: EditPropertyVM[];
    departments: Record<string, DepartmentVM>;
    editProfiles: Record<string, boolean>;
    editProperties: EditPropertyVM[];
    isAddNewUser: boolean;
    newUserEditProperties: EditPropertyVM[];
    newUserErrorMessages: Record<string, string>;
    onAcceptUserRequest: (id: string) => void;
    onAddNewUser: () => void;
    onClearSearch: () => void;
    onDeclineUserRequest: (id: string) => void;
    onManageEditProfiles: (id: string) => void;
    onManageOpenProfiles: (id: string) => void;
    onRemoveUser: (id: string) => void;
    onSearch: (name: string, value: string) => void;
    onSearchTextChanged: (value: string) => void;
    onTmpUsersChanged: (id: string, name: string, value: string) => void;
    onToggleIsAddNewUser: () => void;
    onToggleShowPopup: () => void;
    onUpdateUser: (id: string) => void;
    openProfiles: Record<string, boolean>;
    permissions: PermissionsVM;
    roles: Record<string, RoleVM>;
    searchText: string;
    showPopup: boolean;
    selectedUserId: string;
    tmpUsers: Record<string, UserInfoVM>;
    userRequests: UserInfoVM[];
    users: Record<string, UserInfoVM>;
}

export type ProfileInfoViewProps = HTMLAttributes<any> & {
    accountStatuses: Record<string, AccountStatusVM>;
    departments: Record<string, DepartmentVM>;
    dirty: boolean;
    editProperties: EditPropertyVM[];
    onManageEditProfiles: (id: string) => void;
    onManageOpenProfiles: (id: string) => void;
    onRemoveUser: (id: string) => void;
    onTmpUsersChanged: (id: string, name: string, value: string) => void;
    onToggleShowPopup: () => void;
    onUpdateUser: (id: string) => void;
    permissions: PermissionsVM;
    roles: Record<string, RoleVM>;
    selected: boolean;
    showPopup: boolean;
    tmpUser: UserInfoVM;
    user: UserInfoVM;
    userLookup: Record<string, UserInfoVM>
}

export type NewUserProfileInfoViewProps = HTMLAttributes<any> & {
    departments: Record<string, DepartmentVM>;
    newUserEditProperties: EditPropertyVM[];
    newUserErrorMessages: Record<string, string>;
    onAddNewUser: () => void;
    onCancelNewUser: () => void;
    onTmpNewUserChanged: (name: string, value: string) => void;
    roles: Record<string, RoleVM>;
    tmpNewUser: UserInfoVM;
}

export type UserRequestInfoViewProps = HTMLAttributes<any> & {
    onAcceptUserRequest: (id: string, role?: string) => void;
    onDeclineUserRequest: (id: string) => void;
    onManageOpenProfiles: (id: string) => void;
    onTmpUsersChanged: (id: string, name: string, value: string) => void;
    roles: Record<string, RoleVM>;
    selected: boolean;
    tmpUserRequest: UserInfoVM;
    userRequest: UserInfoVM;
}

export type PermissionsVM = {
    canModifySelf: boolean;
    canCreate: boolean;
    canDelete: boolean;
    canModify: boolean;
}

export type UserInfoVM = {
    [key: string]: any;
    id?: string;
    first_name?: string;
    last_name?: string;
    department?: string;
    role?: string;
    email_address?: string;
    phone_number?: string;
    dod_id?: string;
    account_status?: RegistrationStatusType;
    approved_by?: string;
    date_approved?: string;
    isUpdating?: boolean;
    registration_reason?: string;
}

export type RoleVM = {
    title: string;
}

export type DepartmentVM = {
    title: string;
}

export type AccountStatusVM = {
    id: string,
    title: string,
}

export type EditPropertyVM = {
    id: string;
    placeholder?: string;
    readonly?: boolean;
}

export type UserUpdateParams = Partial<Record<keyof Omit<UserInfo, 'className'>, string>>;

export const TMP_USER_ID = "-1";
