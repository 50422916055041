import React, {Component} from "react";
import {PocketContentNode, PocketContentNodeType, SelectedPocketViewProps} from "../pocketManagerPanelModel";
import Button from "../../../theme/widgets/button/button";
import {ArrowLeftSVG} from "../../../theme/svgs/arrowLeftSVG";
import {forEach, forEachKVP} from "../../../../framework.core/extras/utils/collectionUtils";
import {ExcerptSVG} from "../../../theme/svgs/excerptSVG";
import {NoteSVG} from "../../../theme/svgs/noteSVG";
import {ReportSVG} from "../../../theme/svgs/reportSVG";
import {DocumentSVG} from "../../../theme/svgs/documentSVG";
import UserInput from "../../../theme/widgets/userInput/userInput";
import {PocketSVG} from "../../../theme/svgs/pocketSVG";
import {LoadingIndicator} from "../../../theme/widgets/loadingIndicator/loadingIndicator";
import {Size} from "../../../theme/widgets/loadingIndicator/loadingIndicatorModel";
import {getDurationFromSeconds} from "../../../../framework.core/extras/utils/timeUtils";
import {TooltipPortal} from "../../../theme/widgets/tooltipPortal/tooltipPortal";

export class SelectedPocketView extends Component<SelectedPocketViewProps> {
    render() {
        const {
            onAddUser,
            onAddExcerptToReport,
            onExcerptSelect,
            onNoteSelect,
            onReportSelect,
            onResourceSelect,
            onReturn,
            onToggleUserInputExpanded,
            selectedPocket,
            userInputExpanded,
            userSuggestionSupplier,
        } = this.props;

        let cn = 'selected-pocket d-flex flex-fill flex-column py-5 px-3 v-gap-3 h-100';

        let pocketContentDivs: JSX.Element[] = [];
        let authorDivs: JSX.Element[] = [];

        let display_title = "";

        let editor_copy = "";
        let edit_duration_copy = "";
        let isOwned_copy: boolean | undefined = false;
        let isShared_copy: boolean | undefined = false;
        let author_copy = "";

        if (selectedPocket) {
            const { title, pocketContent, author, shared_authors, editor, edit_duration, isOwned, isShared } = selectedPocket;

            display_title = title || "";

            editor_copy = editor || "";
            edit_duration_copy = edit_duration || "";
            isOwned_copy = isOwned;
            isShared_copy = isShared;
            author_copy = author || "";

            forEach(pocketContent, (item: PocketContentNode) => {
                const { id:object_id, title, author, upload_date, type, selected, resource_id, source_id, children:resource_children } = item;

                let icon: JSX.Element;

                let onClick: (id: string, second_id?: string) => void;

                let draggable: boolean = false;

                let id: string = object_id;

                let resourceIsOpen: boolean = false;
                if (selected) {
                    resourceIsOpen = true;
                }

                switch (type) {
                    case PocketContentNodeType.RESOURCE:
                        icon = <DocumentSVG className={"small-image-container"}/>;
                        onClick = () => onResourceSelect(source_id || "", object_id || "");
                        if (source_id) {
                            id = source_id;
                        }
                        break;
                    case PocketContentNodeType.REPORT:
                        icon = <ReportSVG className={"small-image-container"}/>;
                        onClick = onReportSelect;
                        break;
                    case PocketContentNodeType.EXCERPT:
                        icon = <ExcerptSVG className={"small-image-container"}/>;
                        onClick = onExcerptSelect;
                        draggable = true;
                        break;
                    case PocketContentNodeType.NOTE:
                        icon = <NoteSVG className={"small-image-container"}/>;
                        onClick = onNoteSelect;
                        break;
                }

                let resourceChildrenDivs: JSX.Element[] = [];

                if (resource_children) {
                    forEachKVP(resource_children, (itemKey: string, itemValue: PocketContentNode) => {
                        const { id:object_id, title, author, upload_date, type, selected, resource_id, source_id, children:excerpt_children } = itemValue;

                        let icon: JSX.Element;

                        let onClick: (id: string) => void;

                        let draggable: boolean = false;

                        let id: string = object_id;

                        let excerptIsOpen: boolean = false;

                        if (selected) {
                            resourceIsOpen = true;
                            excerptIsOpen = true;
                        }

                        switch (type) {
                            case PocketContentNodeType.EXCERPT:
                                icon = <ExcerptSVG className={"small-image-container"}/>;
                                onClick = onExcerptSelect;
                                draggable = true;
                                break;
                            case PocketContentNodeType.NOTE:
                            default:
                                icon = <NoteSVG className={"small-image-container"}/>;
                                onClick = onNoteSelect;
                                break;
                        }

                        let excerptChildrenDivs: JSX.Element[] = [];

                        if (excerpt_children) {
                            forEachKVP(excerpt_children, (itemKey: string, itemValue: PocketContentNode) => {
                                const { id:object_id, title, author, upload_date, type, selected, resource_id, source_id, children } = itemValue;

                                let icon: JSX.Element;

                                let onClick: (id: string) => void;

                                let draggable: boolean = false;

                                let id: string = object_id;

                                if (selected) {
                                    resourceIsOpen = true;
                                    excerptIsOpen = true;
                                }

                                switch (type) {
                                    case PocketContentNodeType.NOTE:
                                        icon = <NoteSVG className={"small-image-container"}/>;
                                        onClick = onNoteSelect;
                                        break;
                                }

                                excerptChildrenDivs.push(
                                    <div
                                        key={id}
                                        className={`pocket-content-item cursor-pointer d-flex flex-column p-3 v-gap-3 ${selected ? 'selected border border-info' : ''}`}
                                        onClick={() => onNoteSelect(id)}
                                        draggable={draggable}
                                        onDragStart={(e) => onAddExcerptToReport(e, id, resource_id || "")}
                                    >
                                        <div className={'d-flex h-gap-3 align-items-center'}>
                                            <NoteSVG className={"small-image-container"}/>
                                            <TooltipPortal portalContent={
                                                <div className={'display-4'}>{title}</div>
                                            }>
                                                <div className={'display-2 title text-break overflow-hidden'}>{title}</div>
                                            </TooltipPortal>

                                        </div>
                                        <div className={'header-2 text-break overflow-hidden text-info author'}>
                                            Upload: {upload_date}
                                        </div>
                                        <div className={'header-2 text-break overflow-hidden text-info author'}>
                                            Author: {author}
                                        </div>
                                    </div>
                                );
                            });
                        }

                        resourceChildrenDivs.push(
                            <div>
                                <div
                                    key={id}
                                    className={`pocket-content-item cursor-pointer d-flex flex-column p-3 v-gap-3 ${excerptIsOpen ? 'selected border border-info' : ''}`}
                                    onClick={() => onClick(id)}
                                    draggable={draggable}
                                    onDragStart={(e) => onAddExcerptToReport(e, id, resource_id || "")}
                                >
                                    <div className={'d-flex h-gap-3 align-items-center'}>
                                        {icon}
                                        <TooltipPortal portalContent={
                                            <div className={'display-4'}>{title}</div>
                                        }>
                                            <div className={'display-2 excerpt-title text-break overflow-hidden'}>{title}</div>
                                        </TooltipPortal>

                                    </div>
                                    <div className={'header-2 text-break overflow-hidden text-info author'}>
                                        Upload: {upload_date}
                                    </div>
                                    <div className={'header-2 text-break overflow-hidden text-info author'}>
                                        Author: {author}
                                    </div>
                                </div>

                                {
                                    excerptChildrenDivs.length > 0 &&
                                    <div className={`pocket-content-item-portal position-absolute p-3 shadow-lg ${excerptIsOpen ? 'd-block border border-info mx-3' : 'd-none'}`}>
                                        {excerptChildrenDivs}
                                    </div>
                                }
                            </div>

                        );
                    });
                }

                pocketContentDivs.push(
                    <div className={''}>
                        <div
                            key={id}
                            className={`pocket-content-item cursor-pointer d-flex flex-column p-3 v-gap-3 ${resourceIsOpen ? 'selected border border-info' : ''}`}
                            onClick={() => onClick(id)}
                            draggable={draggable}
                            onDragStart={(e) => onAddExcerptToReport(e, id, resource_id || "")}
                        >
                            <div className={'d-flex h-gap-3 align-items-center'}>
                                {icon}
                                <TooltipPortal portalContent={
                                    <div className={'display-4'}>{title}</div>
                                }>
                                    <div className={'display-2 title text-break overflow-hidden'}>{title}</div>
                                </TooltipPortal>
                            </div>
                            <div className={'header-2 text-break overflow-hidden text-info author'}>
                                Upload: {upload_date}
                            </div>
                            <TooltipPortal portalContent={
                                <div className={'display-4'}>{author}</div>
                            }>
                                <div className={'header-2 text-break overflow-hidden text-info author'}>
                                    Author: {author}
                                </div>
                            </TooltipPortal>
                        </div>

                        {
                            resourceChildrenDivs.length > 0 &&
                            <div className={`pocket-content-item-portal position-absolute p-3 shadow-lg ${resourceIsOpen ? 'd-block border border-info' : 'd-none'}`}>
                                {resourceChildrenDivs}
                            </div>
                        }
                    </div>
                );
            });

            if (author) {
                let name = author.split(' ');
                let editorClass = '';
                if (editor && editor === author) {
                    editorClass = 'editor';
                }

                let initials = name[0]?.charAt(0);
                if (name[1]) {
                    initials += name[1].charAt(0);
                }

                authorDivs.push(
                    <TooltipPortal portalContent={
                        <div className={`header-2 text-nowrap`}>
                            { author }
                        </div>
                    }>
                        <div className={`author-icon self rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center ${editorClass}`}>
                            {initials}
                        </div>
                    </TooltipPortal>
                );
            }

            if (shared_authors) {
                forEachKVP(shared_authors, (itemKey: string, itemValue: string) => {
                    if (itemValue === 'Unknown') {
                        authorDivs.push(
                            <div className={'d-flex align-items-center'}>
                                <LoadingIndicator className={Size.nano}/>
                            </div>
                        )
                    } else {
                        let name = itemValue.split(' ');
                        let editorClass = '';
                        if (editor && editor === itemValue) {
                            editorClass = 'editor';
                        }

                        let initials = name[0]?.charAt(0);
                        if (name[1]) {
                            initials += name[1].charAt(0);
                        }

                        if (authorDivs.length < 5) {
                            authorDivs.push(
                                <TooltipPortal portalContent={
                                                   <div className={`header-2 text-nowrap`}>
                                                       { itemValue }
                                                   </div>
                                               }>
                                    <div className={`author-icon rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center ${editorClass}`}>
                                        { initials }
                                    </div>
                                </TooltipPortal>
                            );
                        } else {
                            authorDivs.push(
                                <div className={"d-flex align-items-center justify-content-start h-gap-2 text-nowrap"}>
                                    <div className={`author-icon rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center ${editorClass}`}>
                                        { initials }
                                    </div>
                                    <div className={`header-2 d-flex align-items-center justify-content-center`}>
                                        { itemValue }
                                    </div>
                                </div>
                            );
                        }
                    }

                });
            }
        }

        return (
            <div className={cn}>
                <div className={'return d-flex align-items-center h-gap-3'} onClick={onReturn}>
                    <Button className={'bg-accent fill-primary'}>
                        <ArrowLeftSVG className={'nano-image-container'}/>
                    </Button>
                    <div>Back to My Library</div>
                </div>
                <div className={'header d-flex h-gap-3 py-2 px-3 align-items-center'}>
                    <div className={'title d-flex flex-row h-gap-3 py-2 px-3 align-items-center'}>
                        <PocketSVG className={'tiny-image-container fill-info'}/>
                        <div className={'text-info display-5'}>{display_title}</div>
                        {
                            isOwned_copy ?
                                <div className={'text-info display-4 py-1 px-2 collaboration-info'}>Owned</div>
                                :
                                isShared_copy ?
                                    <div className={'d-flex h-gap-2 py-1 px-2 collaboration-info align-items-center'}>
                                        <div className={'rounded-circle icon available'}/>
                                        <div className={'text-info display-4 overflow-hidden'}>From {author_copy}</div>
                                    </div>
                                    : <div/>

                        }
                        {
                            editor_copy &&
                            <div className={'d-flex h-gap-2 py-1 px-2 collaboration-info align-items-center'}>
                                <div className={'rounded-circle icon in-use'}/>
                                <div className={'text-info display-4 overflow-hidden'}>In Use by {editor_copy}</div>
                                <div className={'orange d-flex flex-row h-gap-1 align-items-center'}>
                                    <div className={'font-weight-semi-bold'}>Time remaining: </div>
                                    <div>{getDurationFromSeconds(edit_duration_copy)}</div>
                                </div>
                            </div>

                        }
                    </div>
                    <div className={'d-flex h-gap-3 align-items-center'}>
                        {
                            authorDivs.length < 5
                                ? authorDivs
                                : <div className={'d-flex h-gap-3 align-items-center'}>
                                    {authorDivs.slice(0, 5)}
                                    <div className={'header-2 text-info'}>
                                        <TooltipPortal
                                            portalContent={
                                                <div className={"author-tooltip d-flex flex-column v-gap-2 align-items-start"}>
                                                    {authorDivs.slice(5, authorDivs.length)}
                                                </div>
                                            }>
                                            <div className={'display-2 excerpt-title text-break overflow-hidden'}>+{authorDivs.length - 5}</div>
                                        </TooltipPortal>
                                    </div>
                                </div>
                        }
                        <UserInput
                            name={'shared_author_ids'}
                            onExpand={onToggleUserInputExpanded}
                            expanded={userInputExpanded}
                            suggestionSupplier={userSuggestionSupplier}
                            onSubmitUser={onAddUser}
                        />
                    </div>
                </div>
                <div className={'pocket-content-grid overflow-auto'}>
                    {pocketContentDivs}
                </div>

            </div>
        );
    }
}
