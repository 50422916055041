export enum AnimationTypes {
    SlideLeftIn='slideLeftIn',
    SlideRightOut='slideRightOut',
    SlideRightIn='slideRightIn',
    SlideLeftOut='slideLeftOut',
    SlideDownIn='slideDownIn',
    SlideUpOut='slideUpOut',
    SlideUpIn='slideUpIn',
    SlideDownOut='slideDownOut',
    ShrinkHorizontal='shrinkHorizontal',
    GrowHorizontal='growHorizontal',
    FadeIn='fadeIn',
    FadeOut='fadeOut'
}
