import {VisualWrapper} from "../../../framework.visual";
import {createVisualConnector} from "../../../framework.visual";
import {createSelector} from "@reduxjs/toolkit";
import {
    authenticationService,
    authorizationService, documentService,
    referenceService,
    userService
} from "../../../serviceComposition";
import {
    SettingsPanelAppDispatchProps, SettingsPanelAppStateProps, UserUpdateParams,
    AccountStatusVM, DepartmentVM, RoleVM, UserInfoVM
} from "./settingsPanelModel";
import {forEach, forEachKVP} from "../../../framework.core/extras/utils/collectionUtils";
import {
    AnimationTypes,
    ComponentTypes,
    ContainerTypes,
    ReferenceType,
    UserInfo
} from "../../../app.model";
import SettingsPanelPresenter from "./presenters/settingsPanelPresenter";
import {RegistrationStatusType} from "../../model/registrationStatusType";
import {PERMISSION_ENTITY, PERMISSION_OPERATOR} from "../../../app.core.api";

class _SettingsPanelWrapper extends VisualWrapper {
    // private readonly accountStatuses: Record<string, AccountStatusVM>;

    constructor() {
        super();

        this.id = ComponentTypes.SettingsPanelWrapper;

        this.view = SettingsPanelPresenter;

        this.displayOptions = {
            containerId: ContainerTypes.SettingsPanel,
            visible: false,
            appearClass: AnimationTypes.FadeIn,
            enterClass: AnimationTypes.FadeIn,
            exitClass: AnimationTypes.FadeOut
        };

        // // this does not belong here
        // this.accountStatuses = {
        //     'Created': {
        //         id: RegistrationStatusType.CREATED,
        //         title: 'Created',
        //     },
        //     'Active': {
        //         id: RegistrationStatusType.ACTIVE,
        //         title: 'Active',
        //     },
        //     'Inactive': {
        //         id: RegistrationStatusType.NONE,
        //         title: 'Inactive',
        //     },
        // }

        this.mapStateToProps = (state: any, props: any): SettingsPanelAppStateProps => {
            return {
                // accountStatuses: this.getAccountStatuses(state),
                currentUser: this.getCurrentUserVM(state),
                departments: this.getDepartmentVMs(state),
                managedUsers: this.getManagedUserVMs(state),
                permissions: this.getPermissions(state),
                roles: this.getRolesVMs(state),
            }
        }

        this.mapDispatchToProps = (dispatch: any): SettingsPanelAppDispatchProps => {
            return {
                onLogout: () => this.onLogout(),
                onUpdateUser: (user: UserUpdateParams) => userService.updateUser(user),
            };
        }
    }

    onLogout() {
        authenticationService.logout();
        documentService.clearDocuments();
    }


    getPermissions = createSelector(
        [(s) => userService.getCurrentUserId(), (s) => authorizationService.getPermissions()],
        (currentUserId, permissionInfoLookup) => {

            return {
                canModifySelf: authorizationService.hasPermission(PERMISSION_ENTITY.USER, PERMISSION_OPERATOR.MODIFY, currentUserId, currentUserId),
                canCreate: authorizationService.hasPermission(PERMISSION_ENTITY.USER, PERMISSION_OPERATOR.POST),
                canDelete: authorizationService.hasPermission(PERMISSION_ENTITY.USER, PERMISSION_OPERATOR.DELETE),
                canModify: authorizationService.hasPermission(PERMISSION_ENTITY.USER, PERMISSION_OPERATOR.MODIFY)
            }
        }
    )

    getManagedUserVMs = createSelector(
        [() => userService.getSearchUsers(), () => userService.getCurrentUser()],
        (items, currentUser) => {
            let itemVMs: Record<string, UserInfoVM> = {};

            const { id:currentUserId} = currentUser || {};

            forEach(items, (item: UserInfo) => {

                const { id, dod_id, first_name, last_name, email_address, phone_number, department,
                    account_status, role, approved_by, date_approved, isUpdating} = item;

                let registration_status: RegistrationStatusType = RegistrationStatusType.NONE;

                switch (account_status) {
                    case RegistrationStatusType.ACTIVE:
                        registration_status = RegistrationStatusType.ACTIVE;
                        break;
                    case RegistrationStatusType.CREATED:
                        registration_status = RegistrationStatusType.CREATED;
                        break;
                    case RegistrationStatusType.REJECTED:
                        registration_status = RegistrationStatusType.REJECTED;
                        break;
                    default:
                        break;
                }

                let itemVM:UserInfoVM = {
                    id,
                    dod_id: dod_id,
                    first_name,
                    last_name,
                    email_address,
                    phone_number,
                    department,
                    account_status: registration_status,
                    role,
                    approved_by: approved_by ? approved_by : "",
                    date_approved: date_approved ? date_approved : "",
                    isUpdating,
                };

                if (currentUserId) {
                    if (id !== currentUserId) {
                        itemVMs[id] = itemVM;
                    }
                } else {
                    itemVMs[id] = itemVM;
                }
            });
            return itemVMs;
        }
    )

    getCurrentUserVM = createSelector(
        [() => userService.getCurrentUser()],
        (currentUser) => {
            const { id="", dod_id='', first_name="", last_name="", email_address="", phone_number="", department="",
                account_status="", role="", approved_by="", date_approved="", isUpdating} = currentUser || {};

            let registration_status: RegistrationStatusType = RegistrationStatusType.NONE;

            switch (account_status) {
                case RegistrationStatusType.ACTIVE:
                    registration_status = RegistrationStatusType.ACTIVE;
                    break;
                case RegistrationStatusType.CREATED:
                    registration_status = RegistrationStatusType.CREATED;
                    break;
                case RegistrationStatusType.REJECTED:
                    registration_status = RegistrationStatusType.REJECTED;
                    break;
                default:
                    break;
            }

            let itemVM: UserInfoVM = {
                id,
                dod_id: dod_id,
                first_name,
                last_name,
                email_address,
                phone_number,
                department,
                account_status: registration_status,
                role,
                approved_by: approved_by ? approved_by : "",
                date_approved: date_approved ? date_approved : "",
                isUpdating,
            };

            return itemVM;
        }
    )

    getRolesVMs = createSelector(
        [() => referenceService.getAllReferences(ReferenceType.ROLE)],
        (roles) => {
            let itemVMs: Record<string, RoleVM> = {};

            forEachKVP(roles, (itemKey: string, itemValue: RoleVM) => {
                itemVMs[itemKey] = {
                    ...itemValue
                };
            })

            return itemVMs;
        }
    )

    getDepartmentVMs = createSelector(
        [() => referenceService.getAllReferences(ReferenceType.DEPARTMENT)],
        (departments) => {
            let itemVMs: Record<string, DepartmentVM> = {};

            forEachKVP(departments, (itemKey: string, itemValue: DepartmentVM) => {
                itemVMs[itemKey] = {
                    ...itemValue
                };
            })

            return itemVMs;
        }
    )

}

export const {
    connectedPresenter: SettingsPanelWrapper
} = createVisualConnector(_SettingsPanelWrapper);
