import React, {Component} from "react";
import {EditPropertyVM, NewUserProfileInfoViewProps} from "../profilePanelModel";
import ComboBox from "../../../theme/widgets/comboBox/comboBox";
import TextEdit from "../../../theme/widgets/textEdit/textEdit";
import Card from "../../../theme/widgets/card/card";
import Button from "../../../theme/widgets/button/button";
import {nameOf} from "../../../../framework.core/extras/utils/typeUtils";
import {UserInfoVM} from "../../loginPanel/loginPanelModel";
import TextArea from "../../../theme/widgets/textEdit/textArea";

export class NewUserProfileInfoView extends Component<NewUserProfileInfoViewProps> {
    render() {
        const {
            departments,
            newUserEditProperties,
            newUserErrorMessages,
            onAddNewUser,
            onCancelNewUser,
            onTmpNewUserChanged,
            roles,
            tmpNewUser,
        } = this.props;

        let disableButton = false;
        if (tmpNewUser) {
            disableButton = !tmpNewUser["dod_id"] || !tmpNewUser["first_name"] || !tmpNewUser["last_name"] || !tmpNewUser["email_address"] || !tmpNewUser["phone_number"];
        }

        let cn = 'new-user position-relative w-100';

        const editDivs = newUserEditProperties.map((editProperty: EditPropertyVM) => {
            const { id, placeholder } = editProperty;

            const originalValue = '';
            const editValue = tmpNewUser ? tmpNewUser[id] : '';

            let renderDiv;
            let cn = 'align-self-center';

            switch (id) {
                case 'department':
                    let departmentId = editValue ? editValue : originalValue;
                    let departmentTitle = '';
                    if ( departments && departments[departmentId]) {
                        departmentTitle = departments[departmentId].title;
                    }
                    else {
                        departmentTitle = departmentId;
                    }

                    renderDiv = (
                        <ComboBox
                            className={cn}
                            onSelect={(value: string) => onTmpNewUserChanged(id, value)}
                            title={departmentTitle || 'Department'}
                            items={departments}
                            light={true}
                        />
                    );
                    break;
                case 'role':
                    let roleId = editValue ? editValue : originalValue;
                    let roleTitle = '';
                    if (roles && roles[roleId]) {
                        roleTitle = roles[roleId].title;
                    }
                    else {
                        roleTitle = roleId;
                    }

                    renderDiv = (
                        <ComboBox
                            className={cn}
                            onSelect={(value: string) => onTmpNewUserChanged(id, value)}
                            title={roleTitle}
                            items={roles}
                            light={true}
                        />
                    );
                    break;
                case 'registration_reason':
                    renderDiv = (
                        <TextArea
                            id={id}
                            value={editValue ? editValue : originalValue}
                            name={id}
                            placeholder={placeholder}
                            onChange={(value: string) => onTmpNewUserChanged(id, value)}
                        />
                    );

                    break;
                case 'first_name':
                case 'last_name':
                case 'email_address':
                case 'phone_number':
                case 'dod_id':
                default:
                    renderDiv = (
                        <TextEdit
                            id={id}
                            className={cn}
                            placeholder={placeholder}
                            name={id}
                            dirty={!!editValue}
                            value={editValue ? editValue : originalValue}
                            edit={true}
                            onChange={(value) => onTmpNewUserChanged(id, value)}
                            autoFocus={id === "first_name"}/>
                    );
                    break;
            }

            return renderDiv;
        });

        return (
            <div className={cn}>
                <div className={'new-user-header d-flex align-items-center justify-content-between p-3'}>
                    <div className={'font-weight-semi-bold header-1'}>NEW USER</div>
                </div>


                <div className={'new-user-body p-3 d-block position-absolute'}>
                    <div className={"new-user-grid w-100 font-weight-semi-bold header-2"}>
                        <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
                            <div className={"text-notification"}>*</div>
                            <div>DoD ID:</div>
                        </div>
                        <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
                            <div className={"text-notification"}>*</div>
                            <div>First Name:</div>
                        </div>
                        <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
                            <div className={"text-notification"}>*</div>
                            <div>Last Name:</div>
                        </div>
                        <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
                            <div className={"text-notification"}>*</div>
                            <div>Email:</div>
                        </div>
                        <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
                            <div>Phone:</div>
                        </div>
                        <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
                            <div className={"text-notification"}>*</div>
                            <div>Department:</div>
                        </div>
                        <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
                            <div className={"text-notification"}>*</div>
                            <div>Reason:</div>
                        </div>
                        {editDivs}
                        <div className={"d-flex align-items-center display-3 text-notification"}>{newUserErrorMessages["first_name"]}</div>
                        <div className={"d-flex align-items-center display-3 text-notification"}>{newUserErrorMessages["last_name"]}</div>
                        <div/>
                        <div/>
                        <div className={"d-flex align-items-center display-3 text-notification"}>{newUserErrorMessages["email_address"]}</div>
                        <div className={"d-flex align-items-center display-3 text-notification"}>{newUserErrorMessages["phone_number"]}</div>
                        <div className={"d-flex align-items-center display-3 text-notification"}>{newUserErrorMessages["dod_id"]}</div>
                        <div/>
                    </div>

                    <div className={"d-flex h-gap-2 align-items-center justify-content-end pt-5 mt-5"}>
                        <Button text={"Cancel"} light={true} orientation={"horizontal"} onClick={onCancelNewUser} selected={false} disabled={false} className={"px-5"}/>
                        <Button text={"Save"} light={true} orientation={"horizontal"} onClick={onAddNewUser} selected={false} disabled={disableButton} className={"px-5"}/>
                    </div>
                </div>
            </div>
            // <Card className={cn}
            //       header={
            //           <div className={'new-user-header d-flex align-items-center justify-content-between p-3'}>
            //               <div className={'font-weight-semi-bold header-1'}>NEW USER</div>
            //           </div>
            //       }
            //       body={
            //           <div className={'new-user-body p-3'}>
            //               <div className={"new-user-grid w-100 font-weight-semi-bold header-2"}>
            //                   <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
            //                       <div className={"text-notification"}>*</div>
            //                       <div>First Name:</div>
            //                   </div>
            //                   <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
            //                       <div className={"text-notification"}>*</div>
            //                       <div>Last Name:</div>
            //                   </div>
            //                   <div className={' align-self-center justify-self-end'}>Department:</div>
            //                   <div className={' align-self-center justify-self-end'}>Role:</div>
            //                   <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
            //                       <div className={"text-notification"}>*</div>
            //                       <div>Email:</div>
            //                   </div>
            //                   <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
            //                       <div className={"text-notification"}>*</div>
            //                       <div>Phone:</div>
            //                   </div>
            //                   <div className={'d-flex align-self-center align-self-center justify-self-end h-gap-1'}>
            //                       <div className={"text-notification"}>*</div>
            //                       <div>DoD ID:</div>
            //                   </div>
            //                   {editDivs}
            //                   <div className={"d-flex align-items-center display-3 text-notification"}>{newUserErrorMessages["first_name"]}</div>
            //                   <div className={"d-flex align-items-center display-3 text-notification"}>{newUserErrorMessages["last_name"]}</div>
            //                   <div/>
            //                   <div/>
            //                   <div className={"d-flex align-items-center display-3 text-notification"}>{newUserErrorMessages["email_address"]}</div>
            //                   <div className={"d-flex align-items-center display-3 text-notification"}>{newUserErrorMessages["phone_number"]}</div>
            //                   <div className={"d-flex align-items-center display-3 text-notification"}>{newUserErrorMessages["dod_id"]}</div>
            //                   <div/>
            //               </div>
            //
            //               <div className={"d-flex h-gap-2 align-items-center justify-content-end"}>
            //                   <Button text={"Cancel"} light={true} orientation={"horizontal"} onClick={onCancelNewUser} selected={false} disabled={false} className={"px-5"}/>
            //                   <Button text={"Save"} light={true} orientation={"horizontal"} onClick={onAddNewUser} selected={false} disabled={disableButton} className={"px-5"}/>
            //               </div>
            //           </div>
            //       }
            //       selected={true}
            //       disabled={true}
            // />
        );
    }
}
