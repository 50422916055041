import React from 'react';
import {SVGModel} from '../../../framework.visual';

export const NoteSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="M 9.9686,31.499415 C 9.35578,31.003179 9.24514,30.624827 9.14751,28.691595 L 9.03469,26.457289 H 6.47005 c -3.14033,0 -4.78068,-0.71107 -5.80901,-2.518124 C 0.01986,22.812427 0.00503,22.573406 3.0999562e-4,13.287059 -0.00469,2.687512 0.03091,2.4650867 1.99573,0.9658825 L 3.03236,0.1748988 15.24243,0.0832578 c 8.10739,-0.06085 12.57094,0.014374 13.28406,0.2238713 1.30822,0.3843287 2.75033,1.9588163 3.1664,3.4570537 0.40946,1.4744562 0.40946,17.4825372 0,18.9569942 -0.4194,1.510226 -1.85719,3.070906 -3.16333,3.433683 -0.59238,0.164537 -2.96553,0.299893 -5.27366,0.300791 l -4.1966,0.0016 -3.48288,2.630039 c -1.91559,1.446521 -3.80164,2.710041 -4.19122,2.80782 -0.47218,0.118511 -0.94447,-0.01342 -1.4166,-0.395733 z m 5.09629,-5.042126 3.12035,-2.349264 h 4.5748 c 4.74932,0 6.00704,-0.261507 6.41883,-1.334617 0.2381,-0.620492 0.2381,-18.4409655 0,-19.0614569 C 28.69593,2.4534537 27.92706,2.3773337 15.69818,2.3773337 H 4.06151 L 3.20313,3.2357186 2.34475,4.0941035 v 9.1096115 9.109611 l 0.70801,0.823935 c 0.69921,0.81369 0.75581,0.825138 4.5517,0.920686 l 3.84368,0.09675 v 2.325928 c 0,1.279259 0.11169,2.325927 0.2482,2.325927 0.13652,0 1.65236,-1.057169 3.36855,-2.349264 z"/>
    </svg>
);


