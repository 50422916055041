import {Converter} from "../../common/converters/converter";

export class GetTemplateArrayRequestConverter extends Converter<any, any>{
    convert(uiRequestData?: any): any {
        let keyword = 'NULL';

        if (uiRequestData) {
            keyword = uiRequestData;
        }

        return `/search?search_keyword=${keyword}`;
    }
}
