import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const AddUserSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        {/*<path d="m 8.753955,25.965169 c 0,-1.39274 0.0105,-1.69892 0.0662,-1.92361 0.42413,-1.71201 2.52055,-3.22038 5.87973,-4.23046 1.17977,-0.35474 2.62846,-0.65003 3.96161,-0.8075 0.79847,-0.0943 2.5525,-0.0944 3.39047,-2.5e-4 1.39814,0.15717 3.26036,0.55756 4.48367,0.96403 3.19686,1.06222 5.05233,2.5061 5.40006,4.20218 0.0521,0.25411 0.0643,0.61225 0.0643,1.88289 v 1.5691 H 20.376955 8.753925 Z m -4.3561,-7.7679 v -2.1777 l -2.18822,-0.0105 -2.18823,-0.0105 -0.0107,-1.45542 -0.0107,-1.45543 h 2.19892 2.19891 v -2.17805 -2.1780397 h 1.44525 1.44525 v 2.1780397 2.17805 h 2.17804 2.17805 v 1.4656 1.4656 h -2.17805 -2.17804 v 2.17805 2.17805 h -1.44525 -1.44525 z m 15.55167,-2.21814 c -1.93515,-0.15916 -3.56268,-1.15462 -4.55973,-2.78889 -0.55137,-0.90377 -0.81822,-1.88817 -0.82084,-3.02813 -0.002,-0.9377897 0.1648,-1.6668397 0.57464,-2.5093897 0.77751,-1.5984 2.15262,-2.69895 3.9207,-3.13789 0.62153,-0.1543 1.57521,-0.18092 2.26245,-0.0632 2.41114,0.41316 4.27194,2.22605 4.75781,4.63531 0.11395,0.56503 0.10285,1.7312297 -0.0218,2.2908097 -0.24677,1.10781 -0.73829,2.0266 -1.51063,2.82384 -0.52184,0.53865 -0.97491,0.87235 -1.61032,1.18603 -0.9722,0.47994 -1.96422,0.67603 -2.99227,0.59147 z"/>*/}
        <path d="m 17.930669,29.321403 c -3.96966,-0.0516 -8.0011494,-0.46281 -10.5431394,-1.07526 l -0.52964,-0.1276 0.0887,-0.14672 c 0.19393,-0.32093 0.50173,-0.70216 0.84087,-1.04147 1.09015,-1.09071 2.5510394,-1.83088 5.5543194,-2.81411 0.93759,-0.30696 1.40042,-0.56252 1.87202,-1.03367 0.14935,-0.14922 0.32429,-0.34978 0.38874,-0.44569 0.33611,-0.50017 0.6005,-1.23692 0.75156,-2.09435 l 0.0154,-0.0876 -0.35956,-0.1351 c -1.8707,-0.70289 -3.48249,-2.04223 -4.48064,-3.72324 -1.06125,-1.78727 -1.48495,-3.86506 -1.21741,-5.97012 0.22596,-1.7778802 0.91675,-3.3879602 2.03097,-4.7337102 0.28931,-0.34943 0.95793,-1.00396 1.29205,-1.26482 0.81698,-0.63785 1.82021,-1.17756 2.74437,-1.47638 2.83553,-0.91685 5.91629,-0.52207 8.27491,1.0604 2.19148,1.47032 3.58725,3.79285 3.91704,6.5179102 0.068,0.56216 0.0685,1.68466 9.9e-4,2.19061 -0.26356,1.97424 -1.1425,3.76856 -2.56733,5.24114 -0.87833,0.90778 -2.14218,1.76358 -3.23033,2.18739 l -0.24078,0.0938 0.006,0.13739 c 0.003,0.0756 0.0383,0.27704 0.0783,0.44773 0.33181,1.41718 0.94915,2.24908 2.05753,2.77263 0.14646,0.0692 0.62957,0.24602 1.07359,0.39297 0.87265,0.28883 1.82172,0.63706 2.38191,0.87398 0.48113,0.20348 1.31003,0.62522 1.66398,0.84663 0.87172,0.54528 1.60568,1.24297 2.0684,1.96617 0.0792,0.12385 0.14066,0.2286 0.13648,0.23278 -0.024,0.024 -0.80946,0.20328 -1.40682,0.32111 -2.0403,0.40244 -4.5888,0.66954 -7.84658,0.8224 -0.6127,0.0287 -3.79976,0.0875 -4.14988,0.0766 -0.0935,-0.003 -0.39303,-0.008 -0.66568,-0.0118 z m -13.9650894,-7.8415 v -2.05369 h -1.98288 -1.98287000955 v -0.66568 -0.66567 H 1.9826996 h 1.98288 v -2.06786 -2.06785 h 0.66568 0.66568 v 2.06785 2.06786 h 1.99756 1.99755 l -0.008,0.65859 -0.008,0.6586 -1.98996,0.007 -1.98995,0.007 v 2.0535 2.0535 h -0.66568 -0.66568 z"/>
    </svg>
);


