import {createSelector} from "@reduxjs/toolkit";
import {VisualWrapper} from "../../../framework.visual";
import {createVisualConnector} from "../../../framework.visual";
import {forEach, forEachKVP} from "../../../framework.core/extras/utils/collectionUtils";
import {
    displayService,
    documentService,
    referenceService,
    repoService,
    tagService, userService
} from "../../../serviceComposition";
import {
    ComponentTypes, ContainerTypes, DocumentInfo,
    ReferenceInfo,
    ReferenceType,
    SearchBannerMenuItem,
    SearchParamInfo, SearchResultInfo, SortPropertyInfo,
    TagInfo
} from "../../../app.model";
import {MenuItemVM} from "../../../framework.visual";
import {SearchBannerAppDispatchProps, SearchBannerAppStateProps, SearchParamItemVM} from "./searchBannerModel";
import SearchBannerPresenter from "./presenter/searchBannerPresenter";
import {SortPropertyInfoVM} from "../searchResultsPanel/searchResultsModel";

class _SearchBannerWrapper extends VisualWrapper {
    constructor() {
        super();

        this.id = ComponentTypes.SearchBannerWrapper;

        this.view = SearchBannerPresenter;

        this.mapStateToProps = (state: any): SearchBannerAppStateProps => {

            return {
                tools: this._getToolVMs(state),
                searchText: documentService.getSearchText(),
                searchParamsBasic: this._getSearchParamsBasicVMs(state),
                searchParamsAdvanced: this._getSearchParamsAdvancedVMs(state),
                selectedSort: this._getSelectedSort(state),
                sortTypes: this._getSortVMs(state),
            }
        }

        this.mapDispatchToProps = (dispatch: any): SearchBannerAppDispatchProps => {
            return {
                onSearch: () => this._onSearch(),
                onSearchParamChanged: (id: string, value: string | string[] ) => this._onSearchParamsChanged(id, value),
                onSearchTextChanged: (value: string) => documentService.setSearchText(value),
                onToolSelected: (id: string) => this._onToolSelected(id),
                onClearSearch: () => documentService.clearSearchResults(),
                onSortSelected: (id: string) => this._onSortSelected(id),
            };
        }
    }

    _onSearch() {
        documentService.setShowSearchResults(true);
        documentService.fetchSearchResults();
    }

    _getTools = () => {
        return repoService.getAll<SearchBannerMenuItem>(SearchBannerMenuItem.class);
    }

    _onToolSelected(nextId: string) {
        let currentId = displayService.getSelectedNodeId(ContainerTypes.SearchBannerTools)

        if (currentId === nextId) {
            displayService.popNode(ContainerTypes.SearchBannerTools);
        } else {
            displayService.pushNode(nextId);
        }
    }

    _onSearchParamsChanged(id: string, value: string | string[]) {
        documentService.setSearchParam(id, value);
    }


    _getToolVMs = createSelector(
        [() => displayService.getSelectedNodeId(ContainerTypes.SearchBannerTools), () => this._getTools()], // if this changes, will re-evaluate the combiner and trigger a re-render
        (selectedId, items) => {
            let itemVMs: Record<string, MenuItemVM> = {};

            // build the hash map used to redux data
            forEach(items, (item: SearchBannerMenuItem) => {
                itemVMs[item.id] = {
                    id: item.id,
                    title: item.title,
                    graphic: item.graphic,
                    context: item.context,
                    selected: selectedId === item.id
                };
            })

            return Object.values(itemVMs);
        }
    );

    _createItemVM(
        itemVMs: Record<string, SearchParamItemVM>,
        item: SearchParamInfo,
        referenceTypes: Record<ReferenceType, Record<string, ReferenceInfo>>,
        publicTags: Record<string, TagInfo>,
        searchResults?: Record<string, SearchResultInfo>,
    ) {
        const { id, type, value, title, dirty, options, optionsId} = item;

        let itemVM:SearchParamItemVM = {
            id,
            type,
            value,
            title,
            dirty,
            options,
        };

        if (id === 'tags') {
            if (searchResults && Object.values(searchResults).length > 0) {
                let searchResultsTags: Record<string, string> = {};
                let searchTags: Record<string, TagInfo> = {};

                forEachKVP(searchResults, (itemKey: string, itemValue: SearchResultInfo) => {
                    if (itemValue instanceof DocumentInfo) {
                        const { public_tag, private_tag } = itemValue;
                        const currentUserId = userService.getCurrentUserId();

                        forEachKVP(public_tag, (itemKey: string, itemValue: string) => {
                            searchResultsTags[itemKey] = itemValue;
                        });
                        // if (private_tag[currentUserId]) {
                        //     forEachKVP(private_tag[currentUserId], (itemKey: string, itemValue: string) => {
                        //         searchResultsTags[itemKey] = itemValue;
                        //     });
                        // }

                        forEachKVP(publicTags, (itemKey: string, itemValue: TagInfo) => {
                            if (searchResultsTags[itemValue.id]) {
                                searchTags[itemKey] = itemValue;
                            }
                        });
                    }
                });

                itemVM.options = {
                    ...searchTags,
                }
            } else {
                itemVM.options = {
                    ...publicTags,
                }
            }
        } else if (optionsId && referenceTypes) {
            let index;
            switch (optionsId) {
                case 'DEPARTMENT':
                    index = ReferenceType.DEPARTMENT;
                    break;
                case 'PURPOSE':
                    index = ReferenceType.PURPOSE;
                    break;
                case 'ROLE':
                    index = ReferenceType.ROLE;
                    break;
                case 'STATUS':
                default:
                    index = ReferenceType.STATUS;
                    break;
            }

            if (searchResults && Object.values(searchResults).length > 0) {

                let searchResultsReferences: Record<string, string> = {};
                let searchReferences: Record<string, ReferenceInfo> = {};

                switch (index) {
                    case ReferenceType.DEPARTMENT:
                        forEachKVP(searchResults, (itemKey: string, itemValue: SearchResultInfo) => {
                            if (itemValue instanceof DocumentInfo) {
                                const { department } = itemValue;

                                searchResultsReferences[department] = department;
                            }
                        });
                        if (referenceTypes[index]) {
                            forEachKVP(referenceTypes[index], (itemKey: string, itemValue: ReferenceInfo) => {
                                if (searchResultsReferences[itemKey]) {
                                    searchReferences[itemKey] = itemValue;
                                }
                            });
                        }

                        itemVM.options = {
                            ...searchReferences,
                        };
                        break;
                    case ReferenceType.PURPOSE:
                        forEachKVP(searchResults, (itemKey: string, itemValue: SearchResultInfo) => {
                            if (itemValue instanceof DocumentInfo) {
                                const { purpose } = itemValue;

                                searchResultsReferences[purpose] = purpose;
                            }
                        });
                        if (referenceTypes[index]) {
                            forEachKVP(referenceTypes[index], (itemKey: string, itemValue: ReferenceInfo) => {
                                if (searchResultsReferences[itemKey]) {
                                    searchReferences[itemKey] = itemValue;
                                }
                            });
                        }

                        itemVM.options = {
                            ...searchReferences,
                        };
                        break;
                    default:
                        itemVM.options = {
                            ...referenceTypes[index],
                        };
                        break;
                }

            } else {
                itemVM.options = {
                    ...referenceTypes[index],
                };
            }
        }

        itemVMs[id] = itemVM;
    }

    _getSearchParamsBasicVMs = createSelector(
        [
            (s) => documentService.getSearchParams(),
            (s) => referenceService.getAllReferencesGroupedByType(),
            (s) => tagService.getAllPublicTags(),
            (s) => documentService.getSearchResults()
        ],
        (items, referenceTypes, publicTags, searchResults) => {
            let itemVMs: Record<string, SearchParamItemVM> = {};

            forEach(items, (item: SearchParamInfo) => {
                if (item.visible && !item.advanced) {
                    this._createItemVM(itemVMs, item, referenceTypes, publicTags, searchResults)
                }
            });
            return Object.values(itemVMs);
        }
    );

    _getSearchParamsAdvancedVMs = createSelector(
        [documentService.getSearchParams, referenceService.getAllReferencesGroupedByType, tagService.getAllPublicTags],
        (items, referenceTypes, publicTags) => {
            let itemVMs: Record<string, SearchParamItemVM> = {};

            forEach(items, (item: SearchParamInfo) => {
                if (item.visible && item.advanced) {
                    this._createItemVM(itemVMs, item, referenceTypes, publicTags)
                }
            });
            return Object.values(itemVMs);
        }
    );

    _onSortSelected(id: string) {
        documentService.setSortParam(id);
        documentService.fetchSearchResults();
    }

    _getSelectedSort = createSelector(
        [documentService.getSortTypes, documentService.getActiveSortId],
        (items, selectedId) => {
            let result = null;

            forEach(items, (item: SortPropertyInfo) => {
                if (item.id === selectedId) {
                    result = item;
                    return true;
                }
            })

            return result;
        }
    );

    _getSortVMs = createSelector(
        [documentService.getSortTypes, documentService.getActiveSortId],
        (items, selectedId) => {
            let itemVMs: Record<string, SortPropertyInfoVM> = {};

            forEach(items, (item: SortPropertyInfo) => {
                const { id, title, value} = item;

                itemVMs[item.id] = {
                    id,
                    title,
                    value,
                    selected: id === selectedId
                };
            });

            return Object.values(itemVMs);
        }
    );
}

export const {
    connectedPresenter: SearchBannerWrapper
} = createVisualConnector(_SearchBannerWrapper);
