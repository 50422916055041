import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const CheckMarkSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 11.556828,27.878955 c -0.44417,-0.14708 -0.26339,0.0249 -5.9006599,-5.6124 -4.37137,-4.37137 -5.38823996,-5.40554 -5.46294996,-5.55589 -0.1932099964795,-0.38882 -0.24184,-0.77594 -0.14445,-1.14984 0.11432,-0.43891 0.16069,-0.49527 1.54833996,-1.88194 1.1669,-1.16607 1.3369,-1.32273 1.54429,-1.42309 0.47203,-0.22843 0.97548,-0.22782 1.44043,0.002 0.21253,0.10494 0.54434,0.4259 3.82463,3.69955 l 3.5919199,3.58464 7.59815,-7.5914 c 7.02299,-7.0167602 7.61551,-7.5998502 7.82759,-7.7030502 0.4535,-0.22066 0.98386,-0.22148 1.42426,-0.002 0.18364,0.0914 0.42051,0.3107 1.53357,1.41959 1.15897,1.15464 1.33054,1.33964 1.4348,1.54714 0.24022,0.47807 0.24432,0.97433 0.012,1.45109 -0.097,0.19912 -0.97432,1.08708 -9.48342,9.5987402 l -9.37651,9.37932 -0.2824,0.13337 c -0.26046,0.123 -0.30981,0.13406 -0.63539,0.14237 -0.22209,0.006 -0.40537,-0.008 -0.4942,-0.0378 z"/>
    </svg>
);


