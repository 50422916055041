import React, {Component} from "react";
import {UserRequestInfoViewProps} from "../profilePanelModel";
import Card from "../../../theme/widgets/card/card";
import {LoadingIndicator} from "../../../theme/widgets/loadingIndicator/loadingIndicator";
import {Size} from "../../../theme/widgets/loadingIndicator/loadingIndicatorModel";
import ComboBox from "../../../theme/widgets/comboBox/comboBox";
import Button from "../../../theme/widgets/button/button";

export class UserRequestInfoView extends Component<UserRequestInfoViewProps> {
    render() {
        const {
            onAcceptUserRequest,
            onDeclineUserRequest,
            onManageOpenProfiles,
            onTmpUsersChanged,
            roles,
            selected,
            tmpUserRequest,
            userRequest,
        } = this.props;

        const {
            email_address,
            first_name,
            id,
            isUpdating,
            last_name,
            phone_number,
            registration_reason,
            role
        } = userRequest;

        let cn = 'user-request position-relative w-100';
        if (selected) {
            cn += ' selected';
        }

        let display_name = first_name;
        let initials = first_name?.charAt(0);
        if (last_name) {
            display_name += ` ${last_name}`;
            initials += last_name.charAt(0);
        }

        const originalValue = role || '';
        const editValue = tmpUserRequest ? tmpUserRequest["role"] || '' : '';

        let roleId = editValue ? editValue : originalValue;

        let roleTitle = '';
        if (roleId) {
            if (roles && roles[roleId]) {
                roleTitle = roles[roleId].title;
            }
            else {
                roleTitle = roleId;
            }
        }

        // let durationTitle = userRequest?.duration || '';

        return (
            <div className={cn}>
                <div
                    onClick={() => onManageOpenProfiles(id || "")}
                    className={'user-request-header p-3 d-flex align-items-center justify-content-between cursor-pointer w-100'}>
                    <div className={'d-flex h-gap-3 align-items-center'}>
                        <div className={'icon rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
                            {initials}
                        </div>
                        <div className={'header-1 font-weight-semi-bold'}>Request: {display_name}</div>
                    </div>
                    {
                        isUpdating &&
                        <LoadingIndicator size={Size.small} className={"loading position-absolute"}/>
                    }
                </div>

                <div className={`user-request-body p-3 position-absolute ${selected ? 'd-block' : 'd-none'}`}>
                    {/*<div className={'request-info-grid'}>*/}
                    {/*    <div className={'header-2 text-right'}>Role:</div>*/}
                    {/*    <div className={'header-2 text-right text-wrap'}>Reason for Authentication:</div>*/}
                    {/*    <div className={'d-flex justify-content-between'}>*/}
                    {/*        <ComboBox title={roleTitle} disable={true}/>*/}
                    {/*        <div className={'header-2 text-accent'}>for</div>*/}
                    {/*        <ComboBox title={durationTitle} disable={true}/>*/}
                    {/*    </div>*/}
                    {/*    <div className={'header-1 font-italic font-weight-light text-info'}>{userRequest?.comment}</div>*/}
                    {/*</div>*/}

                    <div className={'user-request-grid header-2'}>
                        {/*<div className={'header-1 font-weight-semi-bold align-self-center justify-self-end'}>Email:</div>*/}
                        {/*<div className={'header-1 font-weight-semi-bold align-self-center justify-self-end'}>Phone:</div>*/}
                        <div className={'align-self-center justify-self-end'}>Role:</div>
                        <div className={'align-self-center justify-self-end text-wrap text-right'}>Reason for Authentication:</div>
                        {/*<div className={'header-1 font-weight-semi-bold align-self-center'}>{email_address}</div>*/}
                        {/*<div className={'header-1 font-weight-semi-bold align-self-center'}>{phone_number}</div>*/}
                        <ComboBox
                            className={"align-self-center"}
                            onSelect={(value: string) => onTmpUsersChanged(id || "", "role", value)}
                            title={roleTitle}
                            items={roles}
                            disable={isUpdating}
                            light={true}
                        />
                        <div className={'header-1 font-weight-semi-bold align-self-center text-wrap font-italic text-info'}>{registration_reason}</div>
                    </div>

                    <div className={"d-flex flex-fill justify-content-between"}>
                        {/*<div className={"d-flex info-button justify-content-start"}>*/}
                        {/*    <div className={'d-flex h-gap-3 align-items-center'}>*/}
                        {/*        <InfoSVG className={'small-image-container'}/>*/}
                        {/*        <div className={'header-2'}>More Info</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={'d-flex flex-fill h-gap-2 justify-content-end'}>
                            <Button
                                text={"Decline"}
                                highlight={true}
                                orientation={"horizontal"}
                                onClick={() => onDeclineUserRequest(id || "")}
                                selected={false}
                                disabled={isUpdating}
                                className={"px-5"}/>
                            <Button
                                text={"Accept"}
                                orientation={"horizontal"}
                                onClick={() => onAcceptUserRequest(id || "")}
                                selected={false}
                                disabled={isUpdating}
                                className={"px-5"}/>
                        </div>

                    </div>
                </div>
            </div>
            // <Card className={cn}
            //       header={
            //           <div
            //               onClick={() => onManageOpenProfiles(id || "")}
            //               className={'user-request-header p-3 d-flex align-items-center justify-content-between'}>
            //               <div className={'d-flex h-gap-3 align-items-center'}>
            //                   <div className={'icon rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
            //                       {initials}
            //                   </div>
            //                   <div className={'header-1 font-weight-semi-bold'}>Request: {display_name}</div>
            //               </div>
            //               {
            //                   isUpdating &&
            //                   <LoadingIndicator size={Size.small} className={"loading position-absolute"}/>
            //               }
            //           </div>
            //       }
            //       body={
            //           <div className={'user-request-body p-3'}>
            //               {/*<div className={'request-info-grid'}>*/}
            //               {/*    <div className={'header-2 text-right'}>Role:</div>*/}
            //               {/*    <div className={'header-2 text-right text-wrap'}>Reason for Authentication:</div>*/}
            //               {/*    <div className={'d-flex justify-content-between'}>*/}
            //               {/*        <ComboBox title={roleTitle} disable={true}/>*/}
            //               {/*        <div className={'header-2 text-accent'}>for</div>*/}
            //               {/*        <ComboBox title={durationTitle} disable={true}/>*/}
            //               {/*    </div>*/}
            //               {/*    <div className={'header-1 font-italic font-weight-light text-info'}>{userRequest?.comment}</div>*/}
            //               {/*</div>*/}
            //
            //               <div className={'user-request-grid header-2'}>
            //                   {/*<div className={'header-1 font-weight-semi-bold align-self-center justify-self-end'}>Email:</div>*/}
            //                   {/*<div className={'header-1 font-weight-semi-bold align-self-center justify-self-end'}>Phone:</div>*/}
            //                   <div className={'align-self-center justify-self-end'}>Role:</div>
            //                   <div className={'align-self-center justify-self-end text-wrap text-right'}>Reason for Authentication:</div>
            //                   {/*<div className={'header-1 font-weight-semi-bold align-self-center'}>{email_address}</div>*/}
            //                   {/*<div className={'header-1 font-weight-semi-bold align-self-center'}>{phone_number}</div>*/}
            //                   <ComboBox
            //                       className={"align-self-center"}
            //                       onSelect={(value: string) => onTmpUsersChanged(id || "", "role", value)}
            //                       title={roleTitle}
            //                       items={roles}
            //                       disable={isUpdating}
            //                       light={true}
            //                   />
            //                   <div className={'header-1 font-weight-semi-bold align-self-center text-wrap font-italic text-info'}>{registration_reason}</div>
            //               </div>
            //
            //               <div className={"d-flex flex-fill justify-content-between"}>
            //                   {/*<div className={"d-flex info-button justify-content-start"}>*/}
            //                   {/*    <div className={'d-flex h-gap-3 align-items-center'}>*/}
            //                   {/*        <InfoSVG className={'small-image-container'}/>*/}
            //                   {/*        <div className={'header-2'}>More Info</div>*/}
            //                   {/*    </div>*/}
            //                   {/*</div>*/}
            //                   <div className={'d-flex flex-fill h-gap-2 justify-content-end'}>
            //                       <Button
            //                           text={"Decline"}
            //                           highlight={true}
            //                           orientation={"horizontal"}
            //                           onClick={() => onDeclineUserRequest(id || "")}
            //                           selected={false}
            //                           disabled={isUpdating}
            //                           className={"px-5"}/>
            //                       <Button
            //                           text={"Accept"}
            //                           orientation={"horizontal"}
            //                           onClick={() => onAcceptUserRequest(id || "")}
            //                           selected={false}
            //                           disabled={isUpdating}
            //                           className={"px-5"}/>
            //                   </div>
            //
            //               </div>
            //           </div>
            //       }
            //       selected={selected}
            // />
        );
    }
}
