import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const AddNewSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="M 15.088657,31.966641 C 12.881232,31.847287 10.704598,31.256637 8.6983235,30.232562 5.6858885,28.694904 3.2077475,26.189934 1.6883755,23.14672 0.55490048,20.876434 0.00824448,18.55604 0.00788448,16.013519 c -2.22e-4,-1.490832 0.131567,-2.590261 0.472181,-3.939406 C 1.9171685,6.381835 6.3570945,1.935599 12.073224,0.46447096 c 0.626258,-0.161179 1.617956,-0.337098 2.342764,-0.415589 0.677944,-0.07342 2.625273,-0.06191 3.314729,0.01958 1.975739,0.23354 3.584126,0.706615 5.355196,1.57513104 1.628006,0.798356 2.893111,1.701731 4.188178,2.990649 1.608296,1.600661 2.78044,3.366008 3.585461,5.400017 0.538591,1.360829 0.845598,2.559629 1.049483,4.098023 0.09302,0.701926 0.112311,2.49586 0.03549,3.30131 -0.257191,2.696599 -1.117528,5.151945 -2.579584,7.36196 -1.376045,2.080004 -3.237426,3.84514 -5.288717,5.015254 -2.333213,1.330931 -4.703456,2.033548 -7.287221,2.160172 -0.805966,0.0395 -0.89376,0.03927 -1.70035,-0.0043 z m 1.919364,-1.902246 c 3.892974,-0.2658 7.593092,-2.214978 10.041978,-5.28999 1.666904,-2.093092 2.707471,-4.660709 2.990128,-7.378184 0.06723,-0.646393 0.07588,-2.08111 0.01622,-2.690665 C 29.720829,11.277971 28.262287,8.23603 25.808808,5.846869 24.676928,4.744662 23.472973,3.912937 22.039556,3.242959 20.579765,2.560659 19.307741,2.191891 17.677508,1.978377 17.063534,1.897967 15.569339,1.867173 14.917686,1.921507 12.297107,2.139992 9.8992105,3.012551 7.7900095,4.515162 6.2860575,5.58659 4.8755025,7.106706 3.9393645,8.664888 c -0.887378,1.477023 -1.508043,3.113013 -1.818275,4.792729 -0.272966,1.477944 -0.292579,3.169312 -0.05477,4.723387 0.316886,2.070873 1.182237,4.224015 2.373034,5.904519 2.885208,4.071726 7.6065965,6.317673 12.5686835,5.978877 z M 14.733636,20.834296 V 17.209369 H 11.127397 7.5211575 v -1.19585 -1.195853 h 3.6062395 3.606239 v -3.64361 -3.643611 h 1.233223 1.233223 v 3.643611 3.64361 h 3.643609 3.643612 v 1.195853 1.19585 h -3.643612 -3.643609 v 3.624927 3.624925 h -1.233223 -1.233223 z"/>
    </svg>
);


