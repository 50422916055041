import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const TextFormatColorSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
          <path d="M -2.9253226e-8,31.197133 C -2.9253226e-8,30.95659 0.04175263,30.794138 0.10810699,30.776507 0.16756589,30.760707 0.41080663,30.702097 0.64864207,30.646263 2.0236743,30.323453 2.6248799,29.695777 3.7843882,27.372458 4.6454727,25.647094 5.322649,24.080709 11.794872,8.8433155 l 3.581717,-8.4323473 h 0.44942 0.449421 L 22.102513,13.68651 c 3.204896,7.301548 6.084253,13.790358 6.398571,14.419577 0.855683,1.712955 1.425215,2.242279 2.720222,2.528178 0.81864,0.180731 0.778371,0.151169 0.778371,0.57141 v 0.383357 h -5.881022 -5.881021 v -0.385267 c 0,-0.368618 0.01215,-0.387031 0.281078,-0.426136 2.659056,-0.386639 2.799346,-0.459343 2.893675,-1.499618 0.08351,-0.920954 -0.239766,-1.89908 -1.873256,-5.667854 l -0.890524,-2.05461 -6.106646,0.0222 -6.1066472,0.0222 -0.9346354,2.378354 c -1.4790553,3.763733 -1.664144,4.420631 -1.4968393,5.312443 0.074734,0.398364 0.5129141,0.899524 0.9582528,1.095982 0.3038507,0.134041 0.5921713,0.194939 1.8378193,0.388173 0.2685239,0.04166 0.2810782,0.06081 0.2810782,0.428869 v 0.385264 H 4.5404947 -2.9253226e-8 Z M 19.942507,19.589153 C 19.721916,18.9729 14.648241,6.9445507 14.608164,6.9428296 14.545552,6.9401399 9.3391161,19.480765 9.3398927,19.632396 c 4.325e-4,0.08531 1.1241293,0.108107 5.3286723,0.108107 5.037379,0 5.325162,-0.0083 5.273942,-0.15135 z"/>
    </svg>
);


