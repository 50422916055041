import React, {Component} from 'react';
import {
    CardCollectionRendererProps,
    CardCollectionRendererState,
    DocumentInfoVM,
    ObjectType
} from "../../searchResultsModel";
import {forEachKVP} from "../../../../../framework.core/extras/utils/collectionUtils";
import Tag from "../../../../theme/widgets/tag/tag";
import {ReportInfoSVG} from "../../../../theme/svgs/reportInfoSVG";
import {PocketInfoSVG} from "../../../../theme/svgs/pocketInfoSVG";
import {DocumentInfoSVG} from "../../../../theme/svgs/documentInfoSVG";
import Card from "../../../../theme/widgets/card/card";
import {TooltipPortal} from "../../../../theme/widgets/tooltipPortal/tooltipPortal";
import CheckBox from "../../../../theme/widgets/checkBox/checkBox";
import {EllipsisSVG} from "../../../../theme/svgs/ellipsisSVG";
import {LoadingIndicator} from "../../../../theme/widgets/loadingIndicator/loadingIndicator";
import ScrollBar from "../../../../theme/widgets/scrollBar/scrollBar";

class CardCollectionView extends Component<CardCollectionRendererProps, CardCollectionRendererState> {
    // private resizeObserver: ResizeObserver;
    // private readonly characterWidth: number;
    // private tagCharactersAllowed: number;
    // private tagCharactersDisplayed: number;
    // private nextTagWidth: number;
    //
    // private sampleId: string;

    constructor(props: any, context: any) {
        super(props, context);

        this.state = {
            renderTrigger: 0,
        }

        // this.characterWidth = 8.15;//pixels
        // this.tagCharactersAllowed = 0;
        // this.tagCharactersDisplayed = 0;
        // this.nextTagWidth = 0;
        //
        // this.sampleId = "";
        //
        // this.resizeObserver = new ResizeObserver(entries => {
        //     for (let entry of entries) {
        //         if (entry.contentRect) {
        //             const { renderTrigger } = this.state;
        //
        //             const width = entry.contentRect.width - 29;
        //
        //             this.tagCharactersAllowed = width / this.characterWidth;
        //
        //             if ((this.tagCharactersDisplayed > this.tagCharactersAllowed) || (this.tagCharactersDisplayed + this.nextTagWidth < this.tagCharactersAllowed)) {
        //                 this.setState({
        //                     ...this.state,
        //                     renderTrigger: renderTrigger + 1,
        //                 })
        //             }
        //         }
        //     }
        // })
    }

    componentDidMount() {
        // let element = document.getElementById(this.sampleId);
        // if (element) {
        //     this.resizeObserver.observe(element);
        // }
    }

    componentDidUpdate(prevProps: Readonly<CardCollectionRendererProps>, prevState: Readonly<CardCollectionRendererState>, snapshot?: any) {
        // let element = document.getElementById(this.sampleId);
        // if (element) {
        //     this.resizeObserver.observe(element);
        // }
    }

    componentWillUnmount() {
        // this.resizeObserver.disconnect();
    }

    render() {
        const { className, searchResults, onDocumentSelected, userLookup, ...rest } = this.props;

        let cn = "cards pr-4";
        if (className) {
            cn += ` ${className}`;
        }

        let itemDivs: any[] = [];
        if (searchResults) {
            itemDivs = searchResults.map((item: DocumentInfoVM) => {
                const {id, author, title, upload_date, private_tag=[], public_tag=[], selected, status, isUpdating=true, publication_date, object_type, editor, isOwned, isShared } = item;

                // this.sampleId = id;

                let cn = 'position-relative result-item';
                if (selected) {
                    cn += ' selected'
                }

                let author_text = author;
                if (object_type !== ObjectType.DocumentInfo) {
                    if (userLookup) {
                        const author_user = userLookup[author || ""];

                        if (author_user) {
                            author_text = author_user.first_name + " " + author_user.last_name;
                        }
                    }
                }
                //
                // let hoverTagDivs: any[] = [];
                // let displayPublicTagDivs: any[] = [];
                // let displayPrivateTagDivs: any[] = [];
                //
                // let length = 0;
                // let totalLength = 0;
                //
                // let nextTagRecorded = false;
                //
                // this.tagCharactersDisplayed = 0;
                // this.nextTagWidth = 0;

                // if (public_tag) {
                //     forEachKVP(public_tag, (tag: string) => {
                //         if (tag.length > 0) {
                //             this.tagCharactersDisplayed += (tag.length + (46 / this.characterWidth));
                //
                //             if (this.tagCharactersDisplayed < this.tagCharactersAllowed) {
                //                 displayPublicTagDivs?.push(<Icon name={tag} text={tag} isEdit={false} isGlobal={true} key={tag + "_short"}
                //                 />);
                //                 length++;
                //             } else if (!nextTagRecorded) {
                //                 this.nextTagWidth = tag.length;
                //                 nextTagRecorded = true;
                //             }
                //
                //             totalLength++;
                //
                //             hoverTagDivs?.push(<Icon name={tag} text={tag} isEdit={false} isGlobal={true} key={tag}/>)
                //         }
                //     })
                // }
                //
                // if (private_tag) {
                //     forEachKVP(private_tag, (tag: string) => {
                //         if (tag.length > 0) {
                //             this.tagCharactersDisplayed += (tag.length + (46 / this.characterWidth));
                //
                //             if (this.tagCharactersDisplayed < this.tagCharactersAllowed) {
                //                 displayPrivateTagDivs?.push(<Icon name={tag} text={tag} isEdit={false} key={tag + "_short"}
                //                 />);
                //                 length++;
                //             } else if (!nextTagRecorded) {
                //                 this.nextTagWidth = tag.length;
                //                 nextTagRecorded = true;
                //             }
                //
                //             totalLength++;
                //
                //             hoverTagDivs?.push(<Icon name={tag} text={tag} isEdit={false} key={tag}/>)
                //         }
                //     })
                // }

                let graphic_node;

                switch (object_type) {
                    case ObjectType.ReportInfo:
                        graphic_node = <ReportInfoSVG className={"xxlarge-image-container"} fill={selected ? "#ffffff" : "#f0f0f0"} stroke={selected ? "#48a8ff" : "#bababa"}/>
                        break;
                    case ObjectType.PocketInfo:
                        graphic_node = <PocketInfoSVG className={"xxlarge-image-container"} fill={selected ? "#ffffff" : "#f0f0f0"} stroke={selected ? "#48a8ff" : "#bababa"}/>
                        break;
                    case ObjectType.DocumentInfo:
                    default:
                        graphic_node = <DocumentInfoSVG className={"xxlarge-image-container"} fill={selected ? "#ffffff" : "#f0f0f0"} stroke={selected ? "#48a8ff" : "#bababa"}/>
                        break;
                }

                return (
                    <div key={id} className={cn}>
                        <Card className={'position-absolute h-100 w-100'} selected={selected} onClick={() => onDocumentSelected(id, object_type)}
                              header={
                                  <div className={"d-flex py-3 pr-3"}>
                                      {graphic_node}
                                      <div className={'d-flex flex-column w-100 v-gap-2'}>
                                          <div className={'d-flex align-items-center'}>
                                              <div className={'d-flex h-gap-3 align-items-center w-100 overflow-hidden justify-content-between'}>

                                                  <div className={"overflow-hidden"}>
                                                      <TooltipPortal portalContent={
                                                          <div>{title}</div>
                                                      }>
                                                          <div className={"font-weight-semi-bold display-3 overflow-hidden title text-break"}>{title}</div>
                                                      </TooltipPortal>
                                                  </div>

                                                  <div className={"font-weight-semi-bold display-3 title"}>{publication_date !== "No Publication Date" ? publication_date : upload_date}</div>
                                              </div>
                                              {/*<UserInput selected={selected} disabled={true}/>*/}
                                          </div>
                                          <div className={'d-flex h-gap-2 align-items-center'}>
                                              {
                                                  isOwned ?
                                                      <div className={'text-info display-4 py-1 px-2 collaboration-info'}>Owned</div>
                                                      : isShared ?
                                                          <div className={'d-flex h-gap-2 py-1 px-2 collaboration-info align-items-center'}>
                                                              <div className={'rounded-circle icon available'}/>
                                                              <div className={'text-info display-4 overflow-hidden'}>From {author_text}</div>
                                                          </div>
                                                      : <div/>

                                              }
                                              {
                                                  editor &&
                                                  <div className={'d-flex h-gap-2 py-1 px-2 collaboration-info align-items-center'}>
                                                      <div className={'rounded-circle icon in-use'}/>
                                                      <div className={'text-info display-4'}>In Use by {editor}</div>
                                                  </div>

                                              }
                                          </div>
                                          <TooltipPortal className={'flex-fill'} portalContent={
                                              <div>
                                                  {
                                                      author &&
                                                      <div>{author_text}</div>
                                                  }
                                              </div>
                                          }>
                                              <div className={'flex-fill d-flex'}>
                                                  <div className={"font-weight-bold overflow-hidden text-break text header-4"}>{author_text}</div>
                                              </div>
                                          </TooltipPortal>
                                          {/*<TooltipPortal portalContent={*/}
                                          {/*    <div className={'d-flex justify-content-start align-items-center overflow-hidden'}>*/}
                                          {/*        <div className={'d-inline-flex flex-wrap align-items-center'}>*/}
                                          {/*            {hoverTagDivs}*/}
                                          {/*        </div>*/}
                                          {/*    </div>*/}

                                          {/*}>*/}
                                          {/*    <div id={id} className={'d-flex justify-content-start align-items-center h-gap-2'}>*/}
                                          {/*        {*/}
                                          {/*            Object.keys(public_tag).length > 0 &&*/}
                                          {/*            <div className={'d-flex align-items-center h-gap-2'}>*/}
                                          {/*                {displayPublicTagDivs}*/}
                                          {/*            </div>*/}
                                          {/*        }*/}
                                          {/*        {*/}
                                          {/*            Object.keys(private_tag).length > 0 &&*/}
                                          {/*            <div className={'d-flex align-items-center h-gap-2'}>*/}
                                          {/*                {displayPrivateTagDivs}*/}
                                          {/*            </div>*/}
                                          {/*        }*/}
                                          {/*        {*/}
                                          {/*            (length < totalLength) &&*/}
                                          {/*            <EllipsisSVG className={"ml-3 small-image-container"}/>*/}
                                          {/*        }*/}
                                          {/*    </div>*/}
                                          {/*</TooltipPortal>*/}
                                      </div>
                                  </div>
                              }/>
                        {
                            isUpdating &&
                            <div className={"loader position-absolute"}>
                                <LoadingIndicator/>
                            </div>
                        }
                    </div>
                )
            });
        }

        return (
            <ScrollBar className={"p-0"} renderTrackHorizontal={false}>
                <ul className={cn} {...rest}>
                    {itemDivs}
                </ul>
            </ScrollBar>
        );
    }
}

export default CardCollectionView;
