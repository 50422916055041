import React, {Component, Fragment} from 'react';
import '../profilePanel.css';
import ProfileInfoView from "./profileInfoView";
import {NewUserProfileInfoView} from "./newUserProfileInfoView";
import {EditPropertyVM, ProfilePanelViewProps, TMP_USER_ID, UserInfoVM} from "../profilePanelModel";
import {UserRequestInfoView} from "./userRequestInfoView";
import ComboBox from "../../../theme/widgets/comboBox/comboBox";
import TextEdit from "../../../theme/widgets/textEdit/textEdit";
import ScrollBar from "../../../theme/widgets/scrollBar/scrollBar";
import Button from "../../../theme/widgets/button/button";
import SearchBox from "../../../theme/widgets/searchBox/searchBox";
import {forEach} from "../../../../framework.core/extras/utils/collectionUtils";
import {LoadingIndicator} from "../../../theme/widgets/loadingIndicator/loadingIndicator";
import {Size} from "../../../theme/widgets/loadingIndicator/loadingIndicatorModel";
import Card from "../../../theme/widgets/card/card";
import {AddUserSVG} from "../../../theme/svgs/addUserSVG";

// const CARD_BODY_HEIGHT = 32.22;
// const CARD_HEADER_HEIGHT = 7.6;
// const CARD_HEIGHT = CARD_BODY_HEIGHT + CARD_HEADER_HEIGHT;
// const USER_REQUEST_CARD_HEIGHT = 11.2 + CARD_HEADER_HEIGHT;
// const BASE_HEIGHT = 6;

class ProfilePanelView extends Component<ProfilePanelViewProps> {
    render() {
        const {
            accountStatuses,
            currentUser,
            currentUserEditProperties,
            departments,
            editProfiles,
            editProperties,
            isAddNewUser,
            newUserEditProperties,
            newUserErrorMessages,
            openProfiles,
            onAcceptUserRequest,
            onAddNewUser,
            onClearSearch,
            onDeclineUserRequest,
            onManageEditProfiles,
            onManageOpenProfiles,
            onRemoveUser,
            onSearch,
            onSearchTextChanged,
            onTmpUsersChanged,
            onToggleIsAddNewUser,
            onToggleShowPopup,
            onUpdateUser,
            permissions,
            roles,
            searchText,
            selectedUserId,
            showPopup,
            tmpUsers,
            userRequests,
            users,
        } = this.props;

        let cn = "d-flex w-100 h-100 align-items-center justify-content-center";

        const isCurrentUserDirty = editProfiles[currentUser.id || ""];

        const editDivs = currentUserEditProperties.map((editProperty: EditPropertyVM) => {
            const { id, placeholder, readonly } = editProperty;

            const originalValue = currentUser ? currentUser[id] || "" : "";
            const editValue = currentUser.id ? tmpUsers[currentUser.id] ? tmpUsers[currentUser.id][id] : "" : "";//no I'm not sorry for this

            let renderDiv;
            let cn = 'align-self-center';

            switch (id) {
                case 'department':
                    let departmentId = editValue ? editValue : originalValue;
                    let departmentTitle = '';

                    if (departments && departments[departmentId]) {
                        departmentTitle = departments[departmentId].title;
                    }
                    else {
                        departmentTitle = departmentId;
                    }

                    renderDiv = (
                        <ComboBox
                            key={id}
                            className={cn}
                            disable={!isCurrentUserDirty}
                            onSelect={(value: string) => onTmpUsersChanged(currentUser.id || "", id, value)}
                            title={departmentTitle}
                            items={departments}
                            light={true}
                        />
                    );
                    break;
                case 'role':
                    let roleId = editValue ? editValue : originalValue;
                    let roleTitle = '';
                    if (roles && roles[roleId]) {
                        roleTitle = roles[roleId].title;
                    } else {
                        roleTitle = roleId;
                    }
                    renderDiv =(
                        <TextEdit
                            key={id}
                            className={cn}
                            disable={!readonly ? !isCurrentUserDirty : true}
                            placeholder={placeholder}
                            name={id}
                            dirty={!!editValue}
                            value={roleTitle || "N/A"}
                            edit={!readonly ? isCurrentUserDirty : false}
                            onSubmit={(name: string, value: string) => onTmpUsersChanged(currentUser.id || "", name, value)}
                        />
                    )
                    break;
                case 'first_name':
                case 'last_name':
                case 'email_address':
                case 'phone_number':
                case 'account_status':
                default:
                    renderDiv = (
                        <TextEdit
                            key={id}
                            className={cn}
                            disable={!readonly ? !isCurrentUserDirty : true}
                            placeholder={placeholder}
                            name={id}
                            dirty={!!editValue}
                            value={editValue ? editValue : originalValue}
                            edit={!readonly ? isCurrentUserDirty : false}
                            onSubmit={(name: string, value: string) => onTmpUsersChanged(currentUser.id || "", name, value)}
                        />
                    );
            }
            return renderDiv;
        });

        let profileInfoViews: JSX.Element[] = [];

        // let profileInfoCount = 0;
        // let profileInfoHeight = BASE_HEIGHT;//rem
        // let profileInfoOpenCount = 0;

        if (isAddNewUser) {
            // profileInfoCount++;
            // profileInfoHeight += CARD_HEIGHT;
            // profileInfoOpenCount++;

            profileInfoViews.push(
                <NewUserProfileInfoView
                    departments={departments}
                    newUserEditProperties={newUserEditProperties}
                    newUserErrorMessages={newUserErrorMessages}
                    onAddNewUser={onAddNewUser}
                    onCancelNewUser={onToggleIsAddNewUser}
                    onTmpNewUserChanged={(name: string, value: string) => onTmpUsersChanged(TMP_USER_ID, name, value)}
                    roles={roles}
                    tmpNewUser={tmpUsers[TMP_USER_ID]}
                />
            )
        }

        // if (currentUser) {
        //     const { id, first_name, last_name, isUpdating } = currentUser;
        //     let display_name = first_name;
        //     let initials = first_name?.charAt(0);
        //     if (last_name) {
        //         display_name += ` ${last_name}`;
        //         initials += last_name.charAt(0);
        //     }
        //
        //     const componentOpen = openProfiles[id || ""];
        //
        //     profileInfoCount++;
        //
        //     if (componentOpen) {
        //         profileInfoOpenCount++;
        //         if (profileInfoCount === 1) {
        //             profileInfoHeight += CARD_HEIGHT;
        //         } else {
        //             profileInfoHeight += (CARD_HEIGHT / 2);
        //         }
        //     } else {
        //         if (profileInfoCount === 1) {
        //             profileInfoHeight += CARD_HEADER_HEIGHT;
        //         } else {
        //             profileInfoHeight += (CARD_HEADER_HEIGHT / 2);
        //         }
        //     }
        //
        //     profileInfoViews.push(
        //         <Card className={'profile-info d-flex flex-column'}
        //               header={
        //                   <div
        //                       onClick={() => onManageOpenProfiles(id || "")}
        //                       className={'profile-info-header p-3 d-flex align-items-center justify-content-between'}>
        //                       <div className={'d-flex h-gap-3 align-items-center'}>
        //                           <div className={'icon rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
        //                               {initials}
        //                           </div>
        //                           <div className={`header-1 ${componentOpen ? 'font-weight-bold' : 'font-weight-semi-bold'}`}>{display_name}</div>
        //                       </div>
        //                       {
        //                           isUpdating &&
        //                           <LoadingIndicator size={Size.small} className={"loading position-absolute"}/>
        //                       }
        //                   </div>
        //               }
        //               body={
        //                   <div className={'profile-info-body p-3'}>
        //                       <div className={'current-user-grid w-100 header-2 font-weight-semi-bold align-items-center'}>
        //                           <div className={'align-self-center justify-self-end '}>First Name:</div>
        //                           <div className={'align-self-center justify-self-end'}>Last Name:</div>
        //                           <div className={'align-self-center justify-self-end'}>Department:</div>
        //                           <div className={'align-self-center justify-self-end'}>Role:</div>
        //                           <div className={'align-self-center justify-self-end'}>Email:</div>
        //                           <div className={'align-self-center justify-self-end'}>Phone:</div>
        //                           <div className={'align-self-center justify-self-end'}>Account Status:</div>
        //                           {editDivs}
        //                       </div>
        //                       {
        //                           !isCurrentUserDirty &&
        //                           <div className={"d-flex justify-content-end h-gap-2"}>
        //                               <Button text={"Edit"} orientation={"horizontal"} onClick={() => onManageEditProfiles(id || "")} selected={false} disabled={isUpdating} className={"px-5"}/>
        //                           </div>
        //                       }
        //                       {
        //                           isCurrentUserDirty &&
        //                           <div className={"d-flex h-gap-2 justify-content-end"}>
        //                               {
        //                                   permissions.canDelete &&
        //                                   <Button text={"Remove User"} highlight={true} onClick={onToggleShowPopup} selected={false} disabled={isUpdating} className={"px-5"}/>
        //                               }
        //                               <Button text={"Cancel"} highlight={true} onClick={() => onManageEditProfiles(id || "")} selected={false} disabled={isUpdating} className={"px-5"}/>
        //                               <Button text={"Save"} onClick={() => onUpdateUser(id || "")} selected={false} disabled={isUpdating} className={"px-5"}/>
        //                           </div>
        //                       }
        //                   </div>
        //               }
        //               selected={componentOpen}
        //         />
        //     );
        // }

        forEach(users, (user: UserInfoVM) => {
            const { id } = user;

            if (id) {
                // const componentOpen = openProfiles[id];
                const componentOpen = id === selectedUserId;
                const componentEdit = editProfiles[id];
                const tmpUserProp: UserInfoVM = tmpUsers[id];

                // profileInfoCount++;

                // if (componentOpen) {
                //     profileInfoOpenCount++;
                //     if (profileInfoCount === 1) {
                //         profileInfoHeight += CARD_HEIGHT;
                //     } else {
                //         profileInfoHeight += (CARD_HEIGHT / 2);
                //     }
                // } else {
                //     if (profileInfoCount === 1) {
                //         profileInfoHeight += CARD_HEADER_HEIGHT;
                //     } else {
                //         profileInfoHeight += (CARD_HEADER_HEIGHT / 2);
                //     }
                // }

                profileInfoViews.push(
                    <ProfileInfoView
                        accountStatuses={accountStatuses}
                        departments={departments}
                        dirty={componentEdit}
                        editProperties={editProperties}
                        key={id}
                        onManageEditProfiles={onManageEditProfiles}
                        onManageOpenProfiles={onManageOpenProfiles}
                        onRemoveUser={onRemoveUser}
                        onTmpUsersChanged={onTmpUsersChanged}
                        onToggleShowPopup={onToggleShowPopup}
                        onUpdateUser={onUpdateUser}
                        permissions={permissions}
                        roles={roles}
                        selected={componentOpen}
                        showPopup={showPopup}
                        tmpUser={tmpUserProp}
                        user={user}
                        userLookup={users}
                    />
                );
            }
        });

        // if (profileInfoOpenCount > 0) {
        //     profileInfoHeight += CARD_HEIGHT / 3;
        // }

        let userRequestViews;

        // let userRequestCount = 0;
        // let userRequestHeight = BASE_HEIGHT;//rem
        // let userRequestOpenCount = 0;

        if (userRequests) {
            userRequestViews = userRequests.map((userRequest: UserInfoVM) => {
                const { id } = userRequest;

                if (id) {
                    // let componentOpen = openProfiles[id];
                    const componentOpen = id === selectedUserId;
                    const tmpUserProp: UserInfoVM = tmpUsers[id];

                    // userRequestCount++;

                    // if (componentOpen) {
                    //     userRequestOpenCount++;
                    //     if (userRequestCount <= 1) {
                    //         userRequestHeight += USER_REQUEST_CARD_HEIGHT;
                    //     } else {
                    //         userRequestHeight += (USER_REQUEST_CARD_HEIGHT / 2);
                    //     }
                    // } else {
                    //     if (userRequestCount <= 1) {
                    //         userRequestHeight += CARD_HEADER_HEIGHT;
                    //     } else {
                    //         userRequestHeight += (CARD_HEADER_HEIGHT / 2);
                    //     }
                    // }

                    return (
                        <UserRequestInfoView
                            key={id}
                            onAcceptUserRequest={onAcceptUserRequest}
                            onDeclineUserRequest={onDeclineUserRequest}
                            onManageOpenProfiles={onManageOpenProfiles}
                            onTmpUsersChanged={onTmpUsersChanged}
                            roles={roles}
                            selected={componentOpen}
                            tmpUserRequest={tmpUserProp}
                            userRequest={userRequest}
                        />
                    );
                }
            });

            // if (userRequestOpenCount > 0) {
            //     userRequestHeight += USER_REQUEST_CARD_HEIGHT / 3;
            // }
        }

        return (
            <div className={cn}>
                <div className={'profile-panel system-tool-panel flex-fill h-100 d-flex flex-column'}>
                    <ScrollBar renderTrackHorizontal={false}>
                        {
                            permissions.canModify &&
                            <Fragment>
                                <div className={"header d-flex align-items-center mr-4"}>
                                    <div className={'py-3 px-5 header-1 font-weight-bold'}>
                                        User Requests
                                    </div>
                                </div>

                                <div className={'user-request-container py-5 mr-4'}>
                                    {userRequestViews}
                                </div>

                                <div className={"search-bar p-1 d-flex position-sticky align-items-center justify-content-between mr-4"}>
                                    <div className={'d-flex align-items-center h-gap-2'}>
                                        <SearchBox
                                            placeholder={"Search for User"}
                                            light={true}
                                            onSearch={onSearch}
                                            text={searchText}
                                            onTextChange={onSearchTextChanged}
                                        />
                                        <Button className={'combo-box-button'} onClick={onClearSearch}>
                                            <div className={"header-3 flex-fill"}>Clear</div>
                                        </Button>
                                    </div>

                                    {
                                        permissions.canCreate &&
                                        <Button orientation={"horizontal"} onClick={onToggleIsAddNewUser} selected={false} disabled={false} className={"combo-box-button"}>
                                            <AddUserSVG className={'tiny-image-container'}/>
                                            <div className={"header-3 flex-fill"}>Add User</div>
                                        </Button>
                                    }
                                </div>

                                <div className={'profile-info-container py-5 mr-4'}>
                                    {profileInfoViews}
                                </div>
                            </Fragment>
                        }
                    </ScrollBar>

                </div>
            </div>
        );
    }
}

export default ProfilePanelView;
