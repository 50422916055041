import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const PersonalSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 0.01794,29.7421 c 0,-2.46291 0.003,-2.497 0.23453,-3.13263 0.7911,-2.16766 3.74721,-4.14732 8.08537,-5.41464 1.8634,-0.54436 3.61744,-0.88633 5.73876,-1.11884 0.87677,-0.0961 2.98043,-0.0962 3.90629,-2e-4 2.38502,0.24734 5.09434,0.84911 7.10672,1.57848 3.67932,1.33355 5.96047,3.05037 6.66708,5.01771 0.21441,0.597 0.22537,0.74928 0.22537,3.1314 V 32 H 16 0.01794 Z M 15.25346,15.97526 C 11.42963,15.6061 8.38381,12.53911 8.02948,8.7010897 c -0.18035,-1.95352 0.37574,-3.89403 1.57332,-5.49023 0.7664,-1.0215 1.70756,-1.80126 2.87067,-2.37836995 3.06039,-1.51852 6.72438,-0.92257 9.13055,1.48506995 0.93161,0.93218 1.5778,1.96218 1.97104,3.14174 0.94733,2.84159 0.27336,5.8807103 -1.78038,8.0281703 -0.50524,0.52829 -0.88346,0.84617 -1.45216,1.22048 -1.46798,0.9662 -3.35096,1.43511 -5.08906,1.26731 z"/>
    </svg>
);


