import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const CardsSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="M 3.8553742,23.706331 3.8442953,21.161364 1.9221477,21.150183 0,21.139003 v -7.69515 -7.6951505 l 1.9399375,0.02848 c 1.0669655,0.01566 7.4068313,0.02848 14.0885905,0.02848 h 12.148653 v 2.555705 2.5557055 H 30.088591 32 v 7.66711 7.667114 H 17.933227 3.866453 Z M 30.754362,18.584183 v -6.421477 h -1.28859 -1.288591 v 4.488591 4.488592 H 17.288591 6.4 v 1.932886 1.932884 H 18.577181 30.754362 Z M 26.888591,13.472775 V 7.0512975 H 14.711409 2.5342282 v 6.3928415 c 0,3.516062 0.012886,6.405726 0.028636,6.421476 0.015749,0.01575 5.4954807,0.02863 12.1771818,0.02863 h 12.148545 z"/>
    </svg>
);


