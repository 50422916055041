import {createSelector} from "@reduxjs/toolkit";
import {VisualWrapper} from "../../../framework.visual";
import {createVisualConnector} from "../../../framework.visual";
import {forEach, forEachKVP} from "../../../framework.core/extras/utils/collectionUtils";
import {
    ComponentTypes, ContainerTypes,
    DocumentInfo, ExcerptMapper,
    MetadataType, NoteInfo,
    PocketMapper,
    ReferenceInfo,
    ReferenceType, ResourceMapper,
    SearchResultsMenuItem, SelectionTypes,
    SortPropertyInfo, TagInfo, UserInfo
} from "../../../app.model";
import {
    authenticationService,
    authorizationService,
    displayService,
    documentService, pocketService,
    referenceService, reportService,
    repoService,
    selectionService,
    userService
} from "../../../serviceComposition";
import {MenuItemVM} from "../../../framework.visual";
import {
    DocumentInfoVM,
    ObjectType, PocketVM,
    ReferenceInfoVM, SearchResultsPanelAppDispatchProps,
    SearchResultsPanelAppStateProps,
    SortPropertyInfoVM, TagInfoVM
} from "./searchResultsModel";
import SearchResultsPanelPresenter from "./presenters/searchResultsPanelPresenter";
import {PERMISSION_ENTITY, PERMISSION_OPERATOR, PocketParamType, ResourceParamType} from "../../../app.core.api";
import {SearchResultInfo} from "../../../app.model";
import React from "react";
import {
    PocketContentNode,
    PocketContentNodeType,
    PocketUpdateParams, UserItemVM
} from "../pocketManagerPanel/pocketManagerPanelModel";
import {Nullable} from "../../../framework.core/extras/utils/typeUtils";
import {RegistrationStatusType} from "../../model/registrationStatusType";

class _SearchResultsPanelWrapper extends VisualWrapper {
    constructor() {
        super();

        this.id = ComponentTypes.SearchResultsPanelWrapper;

        this.metadataId = MetadataType.DOCUMENTS_GET_ARRAY;

        this.view = SearchResultsPanelPresenter;

        this.mapStateToProps = (state: any, props: any): SearchResultsPanelAppStateProps => {
            return {
                searchResults: this.getSearchResultVMs(state),
                resultViews: this.getResultViewVMs(state),
                selectedResultView: this.getSelectedResultViewId(state),
                sortTypes: this.getSortVMs(state),
                selectedSort: this.getSelectedSort(state),
                userLookup: userService.getActiveUsers(),
                selectedDocument: this.getSelectedDocumentVM(state),
                permissions: this.getPermissions(state),
                pockets: this.getPockets(state),
                selectedPocket: this._getSelectedPocket(state),
                userSuggestionSupplier: (text: string) => this._getUserSuggestions(text),
            };
        }

        this.mapDispatchToProps = (dispatch: any): SearchResultsPanelAppDispatchProps => {
            return {
                onDocumentSelected: (id: string, object_type: ObjectType) => this.onDocumentSelected(id, object_type),
                onResultViewSelected: (id: string) => this.onResultViewSelected(id),
                onSortSelected: (id: string) => this.onSortSelected(id),
                onDelete: (id: string, object_type: ObjectType) => this._onDelete(id, object_type),
                onDownload: (id: string, object_type: ObjectType) => this._onDownload(id, object_type),
                onAddToPocket: (id: string, object_type: ObjectType, pocketId: string) => this._onAddToPocket(id, object_type, pocketId),
                onAddExcerptToReport: (event: React.DragEvent<HTMLDivElement>, id: string, resource_id: string) => this._onAddExcerptToReport(event, id, resource_id),
                onExcerptSelect: (excerpt_id: string) => this._onExcerptSelect(excerpt_id),
                onNoteSelect: (note_id: string) => this._onNoteSelect(note_id),
                onPocketSelect: (pocket_id: string) => this._onPocketSelect(pocket_id),
                onReportSelect: (report_id: string) => this._onReportSelected(report_id),
                onResourceSelect: (document_id: string, resource_id: string) => this._onDocumentSelected(document_id, resource_id),
                onReturn: () => this._onReturn(),
                onUpdatePocket: (edits: PocketUpdateParams) => this._onUpdatePocket(edits),
            };
        }

        //select card view by default
        this.onResultViewSelected('cardViewId');
    }

    private _onAddExcerptToReport(event: React.DragEvent<HTMLDivElement>, excerpt_id: string, resource_id: string) {
        const excerpt = pocketService.getExcerpt(excerpt_id);

        const resource = pocketService.getResource(resource_id);

        if (excerpt) {
            const { text } = excerpt;

            event.dataTransfer.setData("text/plain", "\"" + text + "\"");
            event.dataTransfer.setData("text/excerpt/excerpt_id", excerpt_id);

            if (resource) {
                const { source_author, title, source_publication_date} = resource;

                let resource_information = "";

                let authors = JSON.parse(source_author);

                if (Array.isArray(authors)) {
                    let index = 0;
                    forEach(JSON.parse(source_author), (author: string) => {
                        if (index < source_author.length - 2) {
                            resource_information += author + ", ";
                        } else {
                            resource_information += author + ". ";
                        }

                        index++;
                    });
                } else {
                    resource_information = authors;
                }

                resource_information += title + ". " + source_publication_date.toLocaleString().split("T")[0]

                event.dataTransfer.setData("text/excerpt", resource_information);
            } else {
                event.dataTransfer.setData("text/excerpt", "resource information");
            }
        }
    }

    private _onReportSelected(id: string) {
        const selectedId = selectionService.getContext(SelectionTypes.REPORT_SELECTION);

        if (selectedId === id) {
            selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');
            displayService.popNode(ContainerTypes.DocumentPreviewPanel);
            selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, '');
        } else {
            selectionService.setContext(SelectionTypes.REPORT_SELECTION, id);
            selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
            // selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, '');
            // selectionService.setContext(SelectionTypes.EXCERPT_SELECTION, '');
            selectionService.setContext(SelectionTypes.NOTE_SELECTION, '');
            displayService.pushNode(ComponentTypes.ReportPanelWrapper);
            reportService.fetchReport(id)
        }
    }

    private _onDocumentSelected(id: string, resource_id: string) {
        const selectedId = selectionService.getContext(SelectionTypes.DOCUMENT_SELECTION);

        if (selectedId === id) {
            selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
            selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, '');
            displayService.popNode(ContainerTypes.DocumentPreviewPanel);
            selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "");
        } else {
            selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, id);
            selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, resource_id);
            selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');
            selectionService.setContext(SelectionTypes.EXCERPT_SELECTION, '');
            selectionService.setContext(SelectionTypes.NOTE_SELECTION, '');
            displayService.pushNode(ComponentTypes.DocumentPanelWrapper);
            documentService.fetchDocument(id);
        }
    }

    _onDelete(id: string, object_type: ObjectType) {
        switch (object_type) {
            case ObjectType.PocketInfo:
                if (id === selectionService.getContext(SelectionTypes.POCKET_SELECTION)) {
                    selectionService.setContext(SelectionTypes.POCKET_SELECTION, "");
                }

                pocketService.removePocket(id);
                break;
            case ObjectType.ReportInfo:
                if (id === selectionService.getContext(SelectionTypes.REPORT_SELECTION)) {
                    selectionService.setContext(SelectionTypes.REPORT_SELECTION, "");
                }

                reportService.removeReport(id)
                break;
            case ObjectType.DocumentInfo:
            default:
                if (id === selectionService.getContext(SelectionTypes.DOCUMENT_SELECTION)) {
                    selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, "");
                    displayService.popNode(ContainerTypes.DocumentPreviewPanel);
                    selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "");
                }

                documentService.removeDocument(id);
                break;
        }
    }

    _onDownload(id: string, object_type: ObjectType) {
        const token = authenticationService.getToken();

        switch (object_type) {
            case ObjectType.PocketInfo:

                break;
            case ObjectType.ReportInfo:

                const report = reportService.getReport(id);

                if (report) {
                    const { preview_url } = report;

                    let xhr = new XMLHttpRequest();

                    xhr.open( "GET", preview_url || "");

                    xhr.setRequestHeader("Authorization", `bearer ${token}` );

                    xhr.responseType = "blob";
                    xhr.onload = function () {
                        //Create a Blob from the PDF Stream
                        const file = new Blob([xhr.response], { type: xhr.response.type });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        const pdfWindow = window.open();
                        if (pdfWindow) {
                            pdfWindow.location.href = fileURL;
                        }
                    };

                    xhr.send();
                }
                break;
            case ObjectType.DocumentInfo:
            default:
                const documentInfo = documentService.getDocument(id);

                if (documentInfo) {
                    const { preview_url } = documentInfo;

                    let xhr = new XMLHttpRequest();

                    xhr.open( "GET", preview_url || "", true);

                    xhr.setRequestHeader("Authorization", `bearer ${token}` );

                    xhr.responseType = "blob";
                    xhr.onload = function () {
                        //Create a Blob from the PDF Stream
                        const file = new Blob([xhr.response], { type: xhr.response.type });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        const pdfWindow = window.open();
                        if (pdfWindow) {
                            pdfWindow.location.href = fileURL;
                        }
                    };

                    xhr.send();
                }
                break;
        }
    }

    _onAddToPocket(id: string, object_type: ObjectType, pocketId: string) {

        const resourceParams: ResourceParamType = {
            source_id: id
        }

        if (object_type !== ObjectType.PocketInfo) {
            if (pocketId !== "") {
                const pocketParams: PocketParamType = {
                    id: pocketId
                }

                pocketService.addResourceToPocket(resourceParams, pocketParams);
            } else {
                pocketService.addOrUpdatePocket({title: "New Pocket"})
                    .then(pocketMapper => {
                        if (pocketMapper) {
                            const pocketParams: PocketParamType = {
                                id: pocketMapper.id
                            }

                            pocketService.addResourceToPocket(resourceParams, pocketParams);
                        }
                    })
            }
        }
    }

    getDepartmentVMs = createSelector(
        [() => referenceService.getAllReferences(ReferenceType.DEPARTMENT)],
        (departments) => {
            let itemVMs: Record<string, ReferenceInfoVM> = {};

            forEachKVP(departments, (itemKey: string, itemValue: ReferenceInfo) => {
                itemVMs[itemKey] = {
                    ...itemValue
                };
            })

            return itemVMs;
        }
    )

    getStatusVMs = createSelector(
        [() => referenceService.getAllReferences(ReferenceType.STATUS)],
        (statuses) => {
            let itemVMs: Record<string, ReferenceInfoVM> = {};

            forEachKVP(statuses, (itemKey: string, itemValue: ReferenceInfo) => {
                itemVMs[itemKey] = {
                    ...itemValue
                };
            })

            return itemVMs;
        }
    )

    getPurposeVMs = createSelector(
        [() => referenceService.getAllReferences(ReferenceType.PURPOSE)],
        (purposes) => {
            let itemVMs: Record<string, ReferenceInfoVM> = {};

            forEachKVP(purposes, (itemKey: string, itemValue: ReferenceInfo) => {
                itemVMs[itemKey] = {
                    ...itemValue
                };
            })

            return itemVMs;
        }
    )

    private _getSelectedDocumentId = selectionService.makeGetContext(SelectionTypes.DOCUMENT_SELECTION);
    private _getSelectedPocketId = selectionService.makeGetContext(SelectionTypes.POCKET_SELECTION);
    private _getSelectedReportId = selectionService.makeGetContext(SelectionTypes.REPORT_SELECTION);
    private _getSelectedExcerptId = selectionService.makeGetContext(SelectionTypes.EXCERPT_SELECTION);
    private _getSelectedNoteId = selectionService.makeGetContext(SelectionTypes.NOTE_SELECTION);
    private _getSelectedResourceId = selectionService.makeGetContext(SelectionTypes.RESOURCE_SELECTION);

    getSelectedId = createSelector(
        [
            (s) => this._getSelectedDocumentId(s),
            (s) => this._getSelectedPocketId(s),
            (s) => this._getSelectedReportId(s)
        ],
        (selectedDocumentId, selectedPocketId, selectedReportId) => {

            if (selectedDocumentId && selectedDocumentId !== "") {
                return selectedDocumentId;
            } else if (selectedPocketId && selectedPocketId !== "") {
                return selectedPocketId;
            } else if (selectedReportId && selectedReportId !== "") {
                return selectedReportId;
            }
        }
    )

    getSearchResultVMs = createSelector(
        [
            (s) => documentService.getSearchResults(),
            (s) => this.getSelectedId(s),
            (s) => this.getDepartmentVMs(s),
            (s) => this.getStatusVMs(s),
            (s) => this.getPurposeVMs(s)
        ],
        (searchResults, selectedId, departments, statuses, purposes) => {
            let itemVMs: Record<string, DocumentInfoVM> = {};

            let departmentVMs: Record<string, ReferenceInfoVM> = {};

            forEachKVP(departments, (itemKey: string, itemValue: ReferenceInfoVM) => {
                departmentVMs[itemKey] = {
                    ...itemValue
                };
            })

            let statusVMs: Record<string, ReferenceInfoVM> = {};

            forEachKVP(statuses, (itemKey: string, itemValue: ReferenceInfoVM) => {
                statusVMs[itemKey] = {
                    ...itemValue
                };
            })

            let purposeVMs: Record<string, ReferenceInfoVM> = {};

            forEachKVP(purposes, (itemKey: string, itemValue: ReferenceInfoVM) => {
                purposeVMs[itemKey] = {
                    ...itemValue
                };
            });

            let length = 0;
            forEach(searchResults, () => {
                length++;
                return;
            });

            if (length === 0) {
                selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, "");
                displayService.popNode(ContainerTypes.DocumentPreviewPanel);
                selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "");
            }

            forEach(searchResults, (item: SearchResultInfo) => {

                if (item instanceof DocumentInfo) {
                    const {
                        id,
                        author,
                        shared_user_ids,
                        department,
                        file_name,
                        file_size,
                        file_page_count,
                        primary_sme_email,
                        primary_sme_name,
                        primary_sme_phone,
                        private_tag,
                        project,
                        public_tag,
                        publication_date,
                        purpose,
                        secondary_sme_email,
                        secondary_sme_name,
                        secondary_sme_phone,
                        status,
                        scope,
                        title,
                        upload_date,
                        uploadedBy_id,
                        isUpdating,
                    } = item;

                    let formattedPrivateTags: Record<string, TagInfoVM> = {};
                    const current_user_id = userService.getCurrentUserId()
                    if (private_tag) {
                        // const current_user_id = userService.getCurrentUserId()
                        if (private_tag[current_user_id]) {
                            forEachKVP(private_tag[current_user_id], (itemKey: string, itemValue: TagInfo) => {
                                const {
                                    id,
                                    title,
                                } = itemValue;

                                let itemVM: TagInfoVM = {
                                    id,
                                    title,
                                    isGlobal: false,
                                    isRecommended: false,
                                }

                                formattedPublicTags[itemKey] = itemVM;
                            });
                        }
                    }

                    let formattedPublicTags: Record<string,TagInfoVM> = {};
                    if (public_tag) {
                        forEachKVP(public_tag, (itemKey: string, itemValue: TagInfo) => {
                            const {
                                id,
                                title,
                            } = itemValue;

                            let itemVM: TagInfoVM = {
                                id,
                                title,
                                isGlobal: true,
                                isRecommended: false,
                            }

                            formattedPublicTags[itemKey] = itemVM;
                        });
                    }

                    itemVMs[item.id] = {
                        id: id,
                        author: author,
                        shared_user_ids: shared_user_ids,
                        department: departmentVMs[department] ? departmentVMs[department].title : department,
                        file_name: title ? file_name : undefined,
                        file_size: file_size,
                        page_count: file_page_count,
                        primary_sme_email: primary_sme_email ? primary_sme_email : "N/A",
                        primary_sme_name: primary_sme_name ? primary_sme_name : "N/A",
                        primary_sme_phone: primary_sme_phone ? primary_sme_email : "N/A",
                        private_tag: formattedPrivateTags,
                        project: project,
                        public_tag: formattedPublicTags,
                        publication_date: publication_date ? new Date(publication_date).toLocaleString().split(',')[0] : 'No Publication Date',
                        purpose: purposeVMs[purpose] ? purposeVMs[purpose].title : purpose,
                        secondary_sme_email: secondary_sme_email ? secondary_sme_email : "N/A",
                        secondary_sme_name: secondary_sme_name ? secondary_sme_name : "N/A",
                        secondary_sme_phone: secondary_sme_phone ? secondary_sme_phone : "N/A",
                        status: statusVMs[status] ? statusVMs[status].title : status,
                        scope: scope,
                        title: title ? title : file_name,
                        upload_date: upload_date ? new Date(upload_date).toLocaleString().split(',')[0] : 'No Upload Date',
                        uploadedBy_id: uploadedBy_id,
                        selected: id === selectedId,
                        isUpdating: isUpdating,
                        object_type: ObjectType.DocumentInfo,
                        // editor: ,
                        isOwned: uploadedBy_id ? uploadedBy_id === current_user_id : false,
                        isShared: (uploadedBy_id ? uploadedBy_id !== current_user_id : true) && (shared_user_ids.includes(current_user_id)),
                    };

                } else if (item instanceof PocketMapper) {
                    const {
                        id,
                        title,
                        shared_author_ids,
                        isUpdating,
                        author_id,
                        upload_date,
                        uploadedBy_id,
                        scope,
                        private_tag,
                        public_tag
                    } = item.pocket;

                    let formattedPrivateTags: Record<string, TagInfoVM> = {};
                    const current_user_id = userService.getCurrentUserId()
                    if (private_tag) {
                        // const current_user_id = userService.getCurrentUserId()
                        if (private_tag[current_user_id]) {
                            forEachKVP(private_tag[current_user_id], (itemKey: string, itemValue: TagInfo) => {
                                const {
                                    id,
                                    title,
                                } = itemValue;

                                let itemVM: TagInfoVM = {
                                    id,
                                    title,
                                    isGlobal: false,
                                    isRecommended: false,
                                }

                                formattedPublicTags[itemKey] = itemVM;
                            });
                        }
                    }

                    let formattedPublicTags: Record<string,TagInfoVM> = {};
                    if (public_tag) {
                        forEachKVP(public_tag, (itemKey: string, itemValue: TagInfo) => {
                            const {
                                id,
                                title,
                            } = itemValue;

                            let itemVM: TagInfoVM = {
                                id,
                                title,
                                isGlobal: true,
                                isRecommended: false,
                            }

                            formattedPublicTags[itemKey] = itemVM;
                        });
                    }

                    itemVMs[item.id] = {
                        id: id,
                        author: author_id,
                        private_tag: formattedPrivateTags,
                        public_tag: formattedPublicTags,
                        scope: scope,
                        title: title,
                        upload_date: upload_date ? new Date(upload_date).toLocaleString().split(',')[0] : 'No Upload Date',
                        uploadedBy_id: uploadedBy_id,
                        selected: id === selectedId,
                        isUpdating: isUpdating,
                        object_type: ObjectType.PocketInfo,
                        isOwned: author_id === current_user_id,
                        isShared: (uploadedBy_id ? uploadedBy_id !== current_user_id : true) && (shared_author_ids.includes(current_user_id)),
                    };

                } else {
                    const {
                        id,
                        title,
                        shared_author_ids,
                        scope,
                        private_tag,
                        public_tag,
                        publication_date,
                        upload_date,
                        uploadedBy_id,
                        isUpdating,
                        author_id,
                    } = item;

                    let formattedPrivateTags: Record<string, TagInfoVM> = {};
                    const current_user_id = userService.getCurrentUserId();
                    if (private_tag) {
                        // const current_user_id = userService.getCurrentUserId();
                        if (private_tag[current_user_id]) {
                            forEachKVP(private_tag[current_user_id], (itemKey: string, itemValue: TagInfo) => {
                                const {
                                    id,
                                    title,
                                } = itemValue;

                                let itemVM: TagInfoVM = {
                                    id,
                                    title,
                                    isGlobal: false,
                                    isRecommended: false,
                                }

                                formattedPublicTags[itemKey] = itemVM;
                            });
                        }
                    }

                    let formattedPublicTags: Record<string,TagInfoVM> = {};
                    if (public_tag) {
                        forEachKVP(public_tag, (itemKey: string, itemValue: TagInfo) => {
                            const {
                                id,
                                title,
                            } = itemValue;

                            let itemVM: TagInfoVM = {
                                id,
                                title,
                                isGlobal: true,
                                isRecommended: false,
                            }

                            formattedPublicTags[itemKey] = itemVM;
                        });
                    }

                    itemVMs[item.id] = {
                        id: id,
                        author: author_id,
                        private_tag: formattedPrivateTags,
                        public_tag: formattedPublicTags,
                        publication_date: publication_date ? new Date(publication_date).toLocaleString().split(',')[0] : 'No Publication Date',
                        scope: scope,
                        title: title,
                        upload_date: upload_date ? new Date(upload_date).toLocaleString().split(',')[0] : 'No Upload Date',
                        uploadedBy_id: author_id,
                        selected: id === selectedId,
                        isUpdating: isUpdating,
                        object_type: ObjectType.ReportInfo,
                        isOwned: author_id === current_user_id,
                        isShared: (uploadedBy_id ? uploadedBy_id !== current_user_id : true) && (shared_author_ids.includes(current_user_id)),
                    };
                }

            })
            return Object.values(itemVMs);
        }
    );

    getSelectedDocumentVM = createSelector(
        [
            (s) => this._getSelectedDocumentId(s),
            (s) => this._getSelectedPocketId(s),
            (s) => this._getSelectedReportId(s)
        ],
        (selectedDocumentId, selectedPocketId, selectedReportId) => {
            let result: DocumentInfoVM | undefined = undefined;

            if (selectedDocumentId && selectedDocumentId !== "") {
                const document = documentService.getDocument(selectedDocumentId);

                if (document) {
                    const { id, title, file_name, author, uploadedBy_id, scope } = document;

                    result = {
                        id,
                        title: title ? title : file_name,
                        author,
                        object_type: ObjectType.DocumentInfo,
                        uploadedBy_id,
                        scope,
                    }
                }
            } else if (selectedPocketId && selectedPocketId !== "") {
                const pocket = pocketService.getPocketInfo(selectedPocketId);

                if (pocket) {
                    const { id, title, author_id, uploadedBy_id, scope } = pocket;

                    result = {
                        id,
                        title,
                        author: author_id,
                        object_type: ObjectType.PocketInfo,
                        uploadedBy_id,
                        scope
                    }
                }
            } else if (selectedReportId && selectedReportId !== "") {
                const report = reportService.getReport(selectedReportId);

                if (report) {
                    const { id, title, author_id, uploadedBy_id, scope } = report;

                    result = {
                        id,
                        title: title,
                        author: author_id,
                        object_type: ObjectType.ReportInfo,
                        uploadedBy_id,
                        scope
                    }
                }

            }

            return result
        }
    )

    onDocumentSelected(id: string, object_type: ObjectType) {
        switch (object_type) {
            case ObjectType.DocumentInfo:
                documentService.fetchDocument(id);
                selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, id);
                selectionService.setContext(SelectionTypes.POCKET_SELECTION, '');
                selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');
                displayService.pushNode(ComponentTypes.DocumentPanelWrapper);
                break;
            case ObjectType.PocketInfo:
                pocketService.fetchPocket(id);
                selectionService.setContext(SelectionTypes.POCKET_SELECTION, id);
                selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
                selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');
                displayService.popNode(ContainerTypes.DocumentPreviewPanel);
                selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "");
                break;
            case ObjectType.ReportInfo:
                reportService.fetchReport(id);
                selectionService.setContext(SelectionTypes.REPORT_SELECTION, id);
                selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
                selectionService.setContext(SelectionTypes.POCKET_SELECTION, '');
                displayService.pushNode(ComponentTypes.DocumentPanelWrapper);
                break;
        }
    }

    getResultViews = () => {
        return repoService.getAll<SearchResultsMenuItem>(SearchResultsMenuItem.class);
    }

    getSelectedResultViewId = selectionService.makeGetContext("search-panel-result-view");

    getResultViewVMs = createSelector(
        [(s) => this.getResultViews(), (s) => this.getSelectedResultViewId(s)],
        (items, selectedId) => {
            let itemVMs: Record<string, MenuItemVM> = {};

            forEach(items, (item: SearchResultsMenuItem) => {
                const { id, graphic, context, title} = item;

                itemVMs[id] = {
                    id,
                    graphic,
                    context,
                    title,
                    selected: id === selectedId,
                };
            });

            return itemVMs;
        }
    )

    onResultViewSelected(id: string) {
        selectionService.setContext("search-panel-result-view", id);
    }

    getSortVMs = createSelector(
        [documentService.getSortTypes, documentService.getActiveSortId],
        (items, selectedId) => {
            let itemVMs: Record<string, SortPropertyInfoVM> = {};

            forEach(items, (item: SortPropertyInfo) => {
                const { id, title, value} = item;

                itemVMs[item.id] = {
                    id,
                    title,
                    value,
                    selected: id === selectedId
                };
            });

            return Object.values(itemVMs);
        }
    );

    getSelectedSort = createSelector(
        [documentService.getSortTypes, documentService.getActiveSortId],
        (items, selectedId) => {
            let result = null;

            forEach(items, (item: SortPropertyInfo) => {
                if (item.id === selectedId) {
                    result = item;
                    return true;
                }
            })

            return result;
        }
    )

    onSortSelected(id: string) {
        documentService.setSortParam(id);
        documentService.fetchSearchResults();
    }

    getPermissions = createSelector(
        [(s) => this.getSelectedDocumentVM(s), (s) => userService.getCurrentUserId(), (s) => authorizationService.getPermissions],
        (documentInfoVM, currentUserId, permissionInfoLookup) => {

            let uploadedBy = documentInfoVM?.uploadedBy_id || null;

            return {
                canDelete: authorizationService.hasPermission(PERMISSION_ENTITY.DOCUMENT, PERMISSION_OPERATOR.DELETE, currentUserId, uploadedBy),
                canDownload: authorizationService.hasPermission(PERMISSION_ENTITY.DOCUMENT, PERMISSION_OPERATOR.DOWNLOAD, currentUserId, uploadedBy),
                canModify: authorizationService.hasPermission(PERMISSION_ENTITY.DOCUMENT, PERMISSION_OPERATOR.MODIFY, currentUserId, uploadedBy)
            }
        }
    )

    getPockets = createSelector(
        [() => pocketService.getPocketMappers()],
        (pocketMappers) => {
            let itemVMs: Record<string, PocketVM> = {};

            forEachKVP(pocketMappers, (itemKey: string, itemValue: PocketMapper) => {
                itemVMs[itemKey] = {
                    ["id"]: itemValue.pocket.id,
                    ["title"]: itemValue.pocket.title,
                };
            })

            return itemVMs;
        }
    );

    private _getSelectedPocket = createSelector(
        [
            (s) => this._getSelectedPocketId(s),
            (s) => this._getSelectedResourceId(s),
            (s) => this._getSelectedExcerptId(s),
            (s) => this._getSelectedNoteId(s),
            (s) => this._getSelectedReportId(s),
            (s) => pocketService.getPocketMappers(),
            (s) => userService.getActiveUsers(),
            (s) => userService.getCurrentUserId(),
        ],
        (
            selectedPocketId,
            selectedResourceId,
            selectedExcerptId,
            selectedNoteId,
            selectedReportId,
            pocketMappers,
            users,
            currentUserId
        ) => {
            let itemVM: Nullable<PocketVM> = null;

            const pocketMapper = pocketMappers[selectedPocketId];

            if (pocketMapper) {
                const pocket = pocketMapper.pocket;

                const {
                    id: pocket_id,
                    title,
                    upload_date,
                    uploadedBy_id,
                    isUpdating,
                    author_id,
                    shared_author_ids,
                    editor_id,
                    edit_duration
                } = pocket;

                let author = 'Unknown';

                const user = userService.getUser(author_id);
                if (user) {
                    const { first_name, last_name } = user;
                    author = first_name + ' ' + last_name;
                }

                let shared_authors: Record<string, string> = {};

                forEach(shared_author_ids, (shared_author_id: string) => {
                    let author = 'Unknown';
                    const user = users[shared_author_id];
                    if (user) {
                        const { first_name, last_name } = user;

                        author = first_name + " " + last_name;
                    } else {
                        userService.fetchUser(shared_author_id); //selector will automatically recalculate
                        //this may cause issues if user does not exist, so it will loop ad infinitum
                    }
                    shared_authors[shared_author_id] = author;
                });

                let editor = '';
                const editor_user = userService.getUser(editor_id);
                if (editor_user) {
                    const { first_name, last_name } = editor_user;

                    editor = first_name + " " + last_name;
                }

                let readonly = editor_id !== '' && editor_id !== currentUserId;

                let pocketContent: Record<string, PocketContentNode> = {};

                forEach(pocketMapper.notes, (note: NoteInfo) => {
                    if (note) {
                        const { id:note_id, author_id, text } = note;

                        let author_name = 'Unknown';

                        const author = userService.getUser(author_id);
                        if (author) {
                            author_name = author.first_name + " " + author.last_name;
                        }

                        let noteVM: PocketContentNode = {
                            id: note_id,
                            title: text,
                            author: author_name,
                            type: PocketContentNodeType.NOTE,
                            selected: note_id === selectedNoteId
                        };

                        pocketContent[note_id] = noteVM;
                    }
                });

                forEach(pocketMapper.pocket.report_ids, (report_id: string) => {
                    const report = reportService.getReport(report_id);

                    if (report) {
                        const { id:report_id, title, author_id, upload_date } = report;

                        let author_name = 'Unknown';

                        const author = userService.getUser(author_id);
                        if (author) {
                            author_name = author.first_name + " " + author.last_name;
                        }

                        let reportVM: PocketContentNode = {
                            id: report_id,
                            title,
                            author: author_name,
                            type: PocketContentNodeType.REPORT,
                            selected: report_id === selectedReportId,
                            upload_date: upload_date ? new Date(upload_date).toLocaleString().split(',')[0] : 'No Upload Date',
                        }

                        pocketContent[report_id] = reportVM;
                    }
                });

                forEach(pocketMapper.resourceMappers, (resourceMapper: ResourceMapper) => {
                    const resource = resourceMapper.resource;
                    if (resource) {
                        const { id: resource_id, title, source_author, source_id } = resource;

                        let resourceChildren: Record<string, PocketContentNode> = {};

                        forEach(resourceMapper.notes, (note: NoteInfo) => {
                            if (note) {
                                const { id:note_id, author_id, text } = note;

                                let author_name = 'Unknown';

                                const author = userService.getUser(author_id);
                                if (author) {
                                    author_name = author.first_name + " " + author.last_name;
                                }

                                let noteVM: PocketContentNode = {
                                    id: note_id,
                                    title: text,
                                    author: author_name,
                                    type: PocketContentNodeType.NOTE,
                                    selected: note_id === selectedNoteId
                                };

                                resourceChildren[note_id] = noteVM;
                            }
                        });

                        forEach(resourceMapper.excerptMappers, (excerptMapper: ExcerptMapper) => {
                            const excerpt = excerptMapper.excerpt;

                            if (excerpt) {
                                const { id: excerpt_id, text, authorId } = excerpt;

                                let author_name = 'Unknown';

                                const author = userService.getUser(authorId);
                                if (author) {
                                    author_name = author.first_name + " " + author.last_name;
                                }

                                let excerptChildren: Record<string, PocketContentNode> = {};

                                forEach(excerptMapper.notes, (note: NoteInfo) => {
                                    if (note) {
                                        const { id: note_id, author_id, text } = note;

                                        let author_name = 'Unknown';

                                        const author = userService.getUser(author_id);
                                        if (author) {
                                            author_name = author.first_name + " " + author.last_name;
                                        }

                                        let noteVM: PocketContentNode = {
                                            id: note_id,
                                            title: text,
                                            author: author_name,
                                            type: PocketContentNodeType.NOTE,
                                            selected: note_id === selectedNoteId
                                        };

                                        excerptChildren[note_id] = noteVM;
                                    }
                                });

                                let excerptVM: PocketContentNode = {
                                    id: excerpt_id,
                                    title: text,
                                    author: author_name,
                                    type: PocketContentNodeType.EXCERPT,
                                    selected: excerpt_id === selectedExcerptId,
                                    resource_id,
                                    children: excerptChildren,
                                }

                                resourceChildren[excerpt_id] = excerptVM;
                            }
                        });

                        let resourceVM: PocketContentNode = {
                            id: resource_id,
                            title,
                            author: source_author,
                            type: PocketContentNodeType.RESOURCE,
                            selected: resource_id === selectedResourceId,
                            source_id,
                            children: resourceChildren,
                        }

                        pocketContent[resource_id] = resourceVM;
                    }
                });

                itemVM = {
                    id: pocket_id,
                    title,
                    upload_date,
                    isUpdating,
                    author,
                    pocketContent,
                    shared_authors,
                    readonly,
                    editor,
                    edit_duration,
                    isOwned: currentUserId === author_id,
                    isShared: (uploadedBy_id ? uploadedBy_id !== currentUserId : true) && (shared_author_ids.includes(currentUserId)),
                }
            }

            return itemVM;
        }
    );

    private _getUserSuggestions(text: string) {
        return new Promise<UserItemVM[]>((resolve, reject) => {
            userService.searchUsers(text)
                .then(result => {
                    const userVMs: UserItemVM[] = [];

                    forEach(result, (user: UserInfo) => {
                        const { account_status } = user;

                        if (account_status === RegistrationStatusType.ACTIVE) {
                            userVMs.push({
                                id: user.id,
                                title: user.first_name + " " + user.last_name,
                            });
                        }
                    });

                    resolve(userVMs);
                })
                .catch(error => {
                    console.log(error);
                    resolve([]);
                });
        });
    }

    private _onUpdatePocket(edits: PocketUpdateParams) {
        const params: PocketParamType = {
            id: edits.id
        }

        if (edits.title) {
            params.title = edits.title
        }

        if (edits.shared_author_ids) {
            params.shared_author_ids = edits.shared_author_ids;
            params.scope = "Group";
        }

        void pocketService.addOrUpdatePocket(params)
    }

    private _onReturn() {
        selectionService.setContext(SelectionTypes.POCKET_SELECTION, '');
        selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, '');
        selectionService.setContext(SelectionTypes.NOTE_SELECTION, '');
        selectionService.setContext(SelectionTypes.EXCERPT_SELECTION, '');
    }

    private _onExcerptSelect(id: string) {
        const selectedId = selectionService.getContext(SelectionTypes.EXCERPT_SELECTION);

        selectionService.setContext(SelectionTypes.EXCERPT_SELECTION, selectedId === id ? '' : id);
        selectionService.setContext(SelectionTypes.NOTE_SELECTION, '');
        selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
        // selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, '');
        // selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');
        // displayService.popNode(ContainerTypes.DocumentPreviewPanel);
        selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, '');
    }

    private _onNoteSelect(id: string) {
        const selectedId = selectionService.getContext(SelectionTypes.NOTE_SELECTION);

        selectionService.setContext(SelectionTypes.NOTE_SELECTION, selectedId === id ? '' : id);
        selectionService.setContext(SelectionTypes.EXCERPT_SELECTION, '');
        selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
        // selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, '');
        // selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');

        // displayService.popNode(ContainerTypes.DocumentPreviewPanel);
        selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, '');
    }

    private _onPocketSelect(id: string) {
        selectionService.setContext(SelectionTypes.POCKET_SELECTION, id);
        pocketService.fetchPocket(id);
    }
}

export const {
    connectedPresenter: SearchResultsPanelWrapper
} = createVisualConnector(_SearchResultsPanelWrapper);
