import {EntityProvider} from "../../common/providers/entityProvider";
import {GetTemplateArrayRequestConverter} from "../converters/getTemplateArrayRequestConverter";
import {GetTemplateArrayResponseConverter} from "../converters/getTemplateArrayResponseConverter";
import {GetTemplateResponseConverter} from "../converters/getTemplateResponseConverter";
import {TemplateInfo} from "../../../app.model";
import {Nullable} from "../../../framework.core/extras/utils/typeUtils";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export class TemplateProvider extends EntityProvider<TemplateInfo> {
    baseUrl: string = `${serverUrl}/pockets/reports/templates`;
    public static class: string = 'TemplateProvider';

    private getTemplateResponseConverter!: GetTemplateResponseConverter;

    private getTemplateArrayRequestConverter!: GetTemplateArrayRequestConverter;
    private getTemplateArrayResponseConverter!: GetTemplateArrayResponseConverter;

    constructor() {
        super();
        super.appendClassName(TemplateProvider.class);
    }

    start() {
        super.start();

        this.getTemplateResponseConverter = this.addConverter(GetTemplateResponseConverter);

        this.getTemplateArrayRequestConverter = this.addConverter(GetTemplateArrayRequestConverter);
        this.getTemplateArrayResponseConverter = this.addConverter(GetTemplateArrayResponseConverter);
        this.getTemplateArrayResponseConverter.singleConverter = this.getTemplateResponseConverter;
    }

    getAll(uiRequestData?: any): Promise<TemplateInfo[]> {
        return new Promise((resolve, reject) => {
            super.sendGetAll(
                () => this.getTemplateArrayRequestConverter.convert(uiRequestData),
                (responseData, reject) => this.getTemplateArrayResponseConverter.convert(responseData, reject))
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    getSingle(id: string): Promise<Nullable<TemplateInfo>> {
        return new Promise((resolve, reject) => {
            super.sendGetSingle(id,
                (responseData, errorHandler) => this.getTemplateResponseConverter.convert(responseData, errorHandler))
                .then(data => {
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                })
        });
    }
}


