import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const TextListBulletSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 7.2819164,26.877304 v -1.78482 H 19.640958 32 v 1.78482 1.784821 H 19.640958 7.2819164 Z M 0.89177316,28.325226 C 0.3216159,28.190612 -0.00240316,27.677365 1.3421368e-5,26.912673 0.00237073,26.167548 0.3200237,25.701803 0.92239058,25.560288 1.8387802,25.344999 2.501635,25.926148 2.4984648,26.942089 c -0.00245,0.785385 -0.2763745,1.200376 -0.9077693,1.375258 -0.3019412,0.08363 -0.3746063,0.08445 -0.69892234,0.0079 z M 7.2819164,15.831621 V 14.0468 H 19.640958 32 v 1.784821 1.784821 H 19.640958 7.2819164 Z m -6.39014324,1.38057 C 0.3216159,17.077578 -0.00240316,16.56433 1.3421368e-5,15.799638 0.00237073,15.054513 0.3200237,14.588768 0.92239058,14.447253 1.8387802,14.231964 2.501635,14.813113 2.4984648,15.829054 c -0.00245,0.785385 -0.2763745,1.200377 -0.9077693,1.375259 -0.3019412,0.08363 -0.3746063,0.08445 -0.69892234,0.0079 z m 0,-10.2374625 C 0.3216159,6.8401146 -0.00240316,6.326867 1.3421368e-5,5.5621757 0.00237073,4.8170501 0.3200237,4.3513055 0.92239058,4.2097901 1.8387802,3.9945016 2.501635,4.57565 2.4984648,5.5915916 c -0.00245,0.7853851 -0.2763745,1.200376 -0.9077693,1.375258 -0.3019412,0.08363 -0.3746063,0.08445 -0.69892234,0.0079 z M 7.2819164,5.1226962 V 3.3378755 H 19.640958 32 V 5.1226962 6.9075166 H 19.640958 7.2819164 Z"/>
    </svg>
);


