import {VisualWrapper} from "../../../framework.visual";
import {createVisualConnector} from "../../../framework.visual";
import {
    DocumentUpdateParams,
    DocumentVM,
    NoteVM,
    PocketUpdateParams,
    PocketVM,
    SystemMenuAppDispatchProps,
    SystemMenuAppStateProps, TemplateVM
} from "./systemMenuModel";
import {
    ComponentTypes, ContainerTypes,
    SelectionTypes,
    TemplateInfo, UserInfo
} from "../../../app.model";
import SystemMenuPresenter from "./presenters/systemMenuPresenter";
import {
    authenticationService,
    authorizationService, displayService, documentService,
    pocketService,
    reportService,
    selectionService, templateService,
    userService
} from "../../../serviceComposition";
import {createSelector} from "@reduxjs/toolkit";
import {forEach, forEachKVP} from "../../../framework.core/extras/utils/collectionUtils";
import {
    DocumentParamType,
    ExcerptParamType,
    NoteParamType,
    PERMISSION_ENTITY,
    PERMISSION_OPERATOR, PocketParamType, ReportParamType,
    ResourceParamType
} from "../../../app.core.api";
import {Nullable} from "../../../framework.core/extras/utils/typeUtils";
import {UserItemVM} from "../pocketManagerPanel/pocketManagerPanelModel";
import {RegistrationStatusType} from "../../model/registrationStatusType";
import {deserialize} from "../reportPanel/views/slate/slateUtils";

class _SystemMenuWrapper extends VisualWrapper {
    constructor() {
        super();

        this.id = ComponentTypes.SystemMenuWrapper;

        this.view = SystemMenuPresenter;

        this.mapStateToProps = (state: any, props: any): SystemMenuAppStateProps => {
            return {
                currentUser: authenticationService.getUserProfile(),
                permissions: this._getPermissions(state),
                pockets: this._getPockets(state),
                selectedDocument: this._getSelectedDocument(state),
                selectedExcerptId: this._getSelectedExcerptId(state),
                selectedMenuItemId: this._getSelectedMenuItemId(state),
                selectedNoteId: this._getSelectedNoteId(state),
                selectedPocket: this._getSelectedPocket(state),
                selectedReportId: this._getSelectedReportId(state),
                selectedResourceId: this._getSelectedResourceId(state),
                selectedTemplateId: this._getSelectedTemplateId(state),
                templates: this._getTemplates(state),
                userSuggestionSupplier: (text: string) => this._getUserSuggestions(text),
            }
        }

        this.mapDispatchToProps = (dispatch: any): SystemMenuAppDispatchProps => {
            return {
                onAddNote: (noteVM: NoteVM) => this._onSaveNote(noteVM),
                onAddToPocket: (id: string, pocket_id: string) => this._onAddToPocket(id, pocket_id),
                onCreatePocket: (title: string) => pocketService.addOrUpdatePocket({title}),
                onCreateReport: (pocket_id: string, title: string) => this._onCreateReport(pocket_id, title),
                onDeleteDocument: (id: string) => this._onDeleteDocument(id),
                onDeleteExcerpt: (excerpt_id: string, pocket_id: string) => this._onRemoveExcerpt(excerpt_id, pocket_id),
                onDeleteNoteFromExcerpt: (note_id: string, pocket_id: string) => this._onRemoveNoteFromExcerpt(note_id, pocket_id),
                onDeleteNoteFromPocket: (note_id: string, pocket_id: string) => this._onRemoveNoteFromPocket(note_id, pocket_id),
                onDeleteNoteFromResource: (note_id: string, pocket_id: string) => this._onRemoveNoteFromResource(note_id, pocket_id),
                onDeletePocket: (pocket_id: string) => this._onRemovePocket(pocket_id),
                onDeleteReport: (report_id: string, pocket_id: string) => this._onRemoveReport(report_id, pocket_id),
                onDeleteResource: (resource_id: string, pocket_id: string) => this._onRemoveResource(resource_id, pocket_id),
                onDownloadPocket: (pocket_id: string) => this._onDownloadPocket(pocket_id),
                onDownloadDocument: (resource_id: string, original?: boolean) => this._onDownloadDocument(resource_id, original),
                onHoverMenuItem: (id: string) => this._onHoverMenuItem(id),
                onSelectMenuItem: (id: string) => this._setSelectedMenuItem(id),
                onTemplateSelect: (template_id: string) => this._onTemplateSelect(template_id),
                onUpdatePocket: (edits: PocketUpdateParams) => this._onUpdatePocket(edits),
                onUpdateDocument: (edits: DocumentUpdateParams) => this._onUpdateDocument(edits),
            };
        }
    }

    private _getSelectedDocumentId = selectionService.makeGetContext(SelectionTypes.DOCUMENT_SELECTION);
    private _getSelectedExcerptId = selectionService.makeGetContext(SelectionTypes.EXCERPT_SELECTION);
    private _getSelectedMenuItemId = selectionService.makeGetContext(SelectionTypes.MENU_SELECTION);
    private _getSelectedNoteId = selectionService.makeGetContext(SelectionTypes.NOTE_SELECTION);
    private _getSelectedPocketId = selectionService.makeGetContext(SelectionTypes.POCKET_SELECTION);
    private _getSelectedReportId = selectionService.makeGetContext(SelectionTypes.REPORT_SELECTION);
    private _getSelectedResourceId = selectionService.makeGetContext(SelectionTypes.RESOURCE_SELECTION);
    private _getSelectedTemplateId = selectionService.makeGetContext(SelectionTypes.TEMPLATE_SELECTION);

    private _getPermissions = createSelector(
        [
            (s) => this._getSelectedPocketId(s),
            (s) => this._getSelectedDocumentId(s),
            (s) => userService.getCurrentUserId()
        ],
        (selectedPocketId, selectedDocumentId, currentUserId) => {

            let entityOwnerId: Nullable<string> = null;

            const pocket = pocketService.getPocketInfo(selectedPocketId);
            const document = documentService.getDocument(selectedDocumentId);
            if (pocket) {
                const { author_id, shared_author_ids } = pocket;

                if (currentUserId === author_id) {
                    entityOwnerId = author_id;
                } else {
                    forEach(shared_author_ids, (shared_author_id: string) => {
                        if (shared_author_id === currentUserId) {
                            entityOwnerId = author_id;//if pocket has been shared with the current user, they also count as the entity owner
                        }
                    });
                }
            } else if (document) {
                entityOwnerId = document.uploadedBy_id;
            }

            return {
                canDelete: authorizationService.hasPermission(PERMISSION_ENTITY.DOCUMENT, PERMISSION_OPERATOR.DELETE, currentUserId, entityOwnerId),
                canDownload: authorizationService.hasPermission(PERMISSION_ENTITY.DOCUMENT, PERMISSION_OPERATOR.DOWNLOAD, currentUserId, entityOwnerId),
                canModify: authorizationService.hasPermission(PERMISSION_ENTITY.DOCUMENT, PERMISSION_OPERATOR.MODIFY, currentUserId, entityOwnerId)
            }
        }
    );

    private _getSelectedDocument = createSelector(
        [
            (s) => this._getSelectedDocumentId(s),
            (s) => userService.getActiveUsers(),
            (s) => userService.getCurrentUserId(),
        ],
        (selectedDocumentId, users, currentUserId) => {
            let itemVM: Nullable<DocumentVM> = null;

            const document = documentService.getDocument(selectedDocumentId);

            if (document) {
                const {
                    id,
                    title,
                    isUpdating,
                    shared_user_ids,
                } = document;

                let shared_authors: Record<string, string> = {};

                forEach(shared_user_ids, (shared_author_id: string) => {
                    let author = 'unknown';
                    const user = users[shared_author_id];
                    if (user) {
                        const { first_name, last_name } = user;

                        author = first_name + " " + last_name;
                    } else {
                        userService.fetchUser(shared_author_id); //selector will automatically recalculate
                        //this may cause issues if user does not exist, so it will loop ad infinitum
                    }
                    shared_authors[shared_author_id] = author;
                });

                itemVM = {
                    id,
                    title,
                    isUpdating,
                    shared_authors,
                }
            }

            return itemVM;
        }
    )

    private _getSelectedPocket = createSelector(
        [
            (s) => this._getSelectedPocketId(s),
            (s) => pocketService.getPocketMappers(),
            (s) => userService.getActiveUsers(),
            (s) => userService.getCurrentUserId(),
        ],
        (
            selectedPocketId,
            pocketMappers,
            users,
            currentUserId
        ) => {
            let itemVM: Nullable<PocketVM> = null;

            const pocketMapper = pocketMappers[selectedPocketId];

            if (pocketMapper) {
                const pocket = pocketMapper.pocket;

                const {
                    id,
                    title,
                    isUpdating,
                    author_id,
                    shared_author_ids,
                    editor_id,
                } = pocket;

                let author = '';

                const user = userService.getUser(author_id);
                if (user) {
                    const { first_name, last_name } = user;
                    author = first_name + ' ' + last_name;
                }

                let shared_authors: Record<string, string> = {};

                forEach(shared_author_ids, (shared_author_id: string) => {
                    let author = 'unknown';
                    const user = users[shared_author_id];
                    if (user) {
                        const { first_name, last_name } = user;

                        author = first_name + " " + last_name;
                    } else {
                        userService.fetchUser(shared_author_id); //selector will automatically recalculate
                        //this may cause issues if user does not exist, so it will loop ad infinitum
                    }
                    shared_authors[shared_author_id] = author;
                });

                let readonly = editor_id !== '' && editor_id !== currentUserId;

                itemVM = {
                    id,
                    title,
                    isUpdating,
                    author,
                    shared_authors,
                    readonly,
                }
            }

            return itemVM;
        }
    );

    private _getTemplates = createSelector(
        [
            (s) => templateService.getTemplates(),
            (s) => this._getSelectedTemplateId(s),
        ],
        (templates, templateId) => {
            let itemVMs: Record<string, TemplateVM> = {};

            forEach(templates, (template: TemplateInfo) => {
                const {
                    id,
                    title,
                    html
                } = template;

                const itemVM: TemplateVM = {
                    id,
                    title,
                    html,
                    selected: id === templateId,
                }

                itemVMs[id] = itemVM;
            });

            return itemVMs;
        }
    );

    private _onSaveNote(note: NoteVM) {
        const { id, excerpt_id, resource_id, pocket_id, text, content } = note;

        if (excerpt_id) {
            const excerptParams: ExcerptParamType = {
                id: excerpt_id
            };

            let noteParams: NoteParamType;

            if (id !== "null") {
                noteParams = {
                    id,
                    text,
                    content
                }
            } else {
                noteParams = {
                    text,
                    content
                }
            }

            const resourceParams: ResourceParamType = {
                id: resource_id,
            }

            const pocketParams: PocketParamType = {
                id: pocket_id
            }

            pocketService.addNoteAndExcerptToPocket(noteParams, excerptParams, resourceParams, pocketParams);
        } else  if (resource_id) {
            let noteParams: NoteParamType;

            if (id !== "null") {
                noteParams = {
                    id,
                    text,
                    content
                }
            } else {
                noteParams = {
                    text,
                    content
                }
            }

            const resourceParams: ResourceParamType = {
                id: resource_id,
            }

            const pocketParams: PocketParamType = {
                id: pocket_id
            }

            pocketService.addNoteToResource(noteParams, resourceParams, pocketParams)
        } else {
            let noteParams: NoteParamType;

            if (id && id !== "null") {
                noteParams = {
                    id,
                    text,
                    content
                }
            } else {
                noteParams = {
                    text,
                    content
                }
            }

            const pocketParams: PocketParamType = {
                id: pocket_id
            }

            pocketService.addNoteToPocket(noteParams, pocketParams)
        }
    }

    private _onCreateReport(id: string, title: string) {
        const params: ReportParamType = {
            title: title ? title : "New Report",
        }

        let content = [{children: [{ text: "" },],}];

        const template_id = selectionService.getContext(SelectionTypes.TEMPLATE_SELECTION);
        const template = templateService.getTemplate(template_id);
        if (template) {
            const { html } = template;

            const report_html = new DOMParser().parseFromString(html, 'text/html');

            content = [{children: deserialize(report_html.body)}];
        }

        params.content = content;

        reportService.createReport(params)
            .then(report => {
                if (report) {
                    let report_ids: string[] = [];

                    const pocket = pocketService.getPocketInfo(id);
                    if (pocket) {
                        forEach(pocket.report_ids, (report_id: string) => {
                            report_ids.push(report_id);
                        });
                    }

                    report_ids.push(report.id);

                    const pocketParams: PocketParamType = {
                        id,
                        report_ids,
                    }

                    void pocketService.addOrUpdatePocket(pocketParams);
                }
            })
    }

    private _onRemoveExcerpt(id: string, pocket_id: string) {
        void pocketService.removeExcerptFromResource(id, pocket_id);
    }

    private _onRemoveNoteFromExcerpt(id: string, pocket_id: string) {
        void pocketService.removeNoteFromExcerpt(id, pocket_id);
    }

    private _onRemoveNoteFromResource(id: string, pocket_id: string) {
        void pocketService.removeNoteFromResource(id, pocket_id);
    }

    private _onRemoveNoteFromPocket(id: string, pocket_id: string) {
        void pocketService.removeNoteFromPocket(id, pocket_id);
    }

    private _onRemoveResource(id: string, pocket_id: string) {
        void pocketService.removeResourceFromPocket(id, pocket_id);
    }

    private _onRemoveReport(id: string, pocket_id: string) {
        reportService.removeReport(id)
            .then(report_id => {
                const pocket = pocketService.getPocketInfo(pocket_id);

                if (pocket) {
                    let newReportIds: string[] = [];
                    forEach(pocket.report_ids, (report_id: string) => {
                        if (report_id !== id) {
                            newReportIds.push(report_id);
                        }
                    });

                    const params: PocketParamType = {
                        id: pocket_id,
                        report_ids: newReportIds,
                    }

                    pocketService.addOrUpdatePocket(params);
                }
            })
    }

    private _onRemovePocket(pocket_id: string) {
        const pocket = pocketService.getPocketInfo(pocket_id);

        if (pocket) {
            forEach(pocket.report_ids, (report_id: string) => {
                reportService.removeReport(report_id);
            })

            pocketService.removePocket(pocket_id);
        }

    }

    private _onDownloadDocument(document_id: string, original?: boolean) {
        const token = authenticationService.getToken();
        const document = documentService.getDocument(document_id);

        if (document) {
            const { preview_url, original_url } = document;

            let url = original ? original_url : preview_url;

            if (url) {
                let xhr = new XMLHttpRequest();

                xhr.open( "GET", url);

                xhr.setRequestHeader("Authorization", `bearer ${token}` );

                xhr.responseType = "blob";
                xhr.onload = function () {
                    //Create a Blob from the PDF Stream
                    const file = new Blob([xhr.response], { type: xhr.response.type });
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    const pdfWindow = window.open();
                    if (pdfWindow) {
                        pdfWindow.location.href = fileURL;
                    }
                };

                xhr.send();
            }
        }
    }

    private _onDownloadPocket(id: string) {
        //TODO implement download pocket
    }

    private _setSelectedMenuItem(id: string) {
        if (id !== selectionService.getContext(SelectionTypes.MENU_SELECTION)) {
            selectionService.setContext(SelectionTypes.MENU_SELECTION, id)
        }
    }

    private _onHoverMenuItem(id: string) {
        if (selectionService.getContext(SelectionTypes.MENU_SELECTION) !== id) {
            selectionService.setContext(SelectionTypes.MENU_SELECTION, "")
        }
    }

    private _onTemplateSelect(id: string) {
        if (id === selectionService.getContext(SelectionTypes.TEMPLATE_SELECTION)) {
            selectionService.setContext(SelectionTypes.TEMPLATE_SELECTION, '');
        } else {
            selectionService.setContext(SelectionTypes.TEMPLATE_SELECTION, id);
        }
    }

    private _onUpdatePocket(edits: PocketUpdateParams) {
        const params: PocketParamType = {
            id: edits.id
        }

        if (edits.title) {
            params.title = edits.title
        }

        if (edits.shared_author_ids) {
            params.shared_author_ids = edits.shared_author_ids;
            params.scope = "Group";
        }

        void pocketService.addOrUpdatePocket(params)
    }

    private _getPockets = createSelector(
        [() => pocketService.getPocketInfos()],
        (items) => {
            let itemVMs: Record<string, PocketVM> = {};

            forEachKVP(items, (itemKey: string, itemValue: PocketVM) => {
                itemVMs[itemKey] = {
                    ...itemValue
                };
            })

            return itemVMs;
        }
    );

    private _getUserSuggestions(text: string) {
        return new Promise<UserItemVM[]>((resolve, reject) => {
            userService.searchUsers(text)
                .then(result => {
                    const userVMs: UserItemVM[] = [];

                    forEach(result, (user: UserInfo) => {
                        const { account_status } = user;

                        if (account_status === RegistrationStatusType.ACTIVE) {
                            userVMs.push({
                                id: user.id,
                                title: user.first_name + " " + user.last_name,
                            });
                        }
                    });

                    resolve(userVMs);
                })
                .catch(error => {
                    console.log(error);
                    resolve([]);
                });
        });
    }

    _onAddToPocket(id: string, pocketId: string) {
        const resourceParams: ResourceParamType = {
            source_id: id
        }

        if (pocketId !== "") {
            const pocketParams: PocketParamType = {
                id: pocketId
            }

            pocketService.addResourceToPocket(resourceParams, pocketParams);
        } else {
            pocketService.addOrUpdatePocket({title: "New Pocket"})
                .then(pocketMapper => {
                    if (pocketMapper) {
                        const pocketParams: PocketParamType = {
                            id: pocketMapper.id
                        }

                        pocketService.addResourceToPocket(resourceParams, pocketParams);
                    }
                })
        }
    }

    private _onDeleteDocument(id: string) {
        if (id === selectionService.getContext(SelectionTypes.DOCUMENT_SELECTION)) {
            selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, "");
            displayService.popNode(ContainerTypes.DocumentPreviewPanel);
            selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "");
        }

        documentService.removeDocument(id)
    }

    private _onUpdateDocument(edits: DocumentUpdateParams) {
        const params: DocumentParamType = {};

        forEachKVP(edits, (itemKey: keyof DocumentParamType, itemValue: any) => {
            params[itemKey] = itemValue;
        });

        documentService.updateDocument(params);
    }
}

export const {
    connectedPresenter: SystemMenuWrapper
} = createVisualConnector(_SystemMenuWrapper);
