import {RoleInfo, UserInfo} from "../../../app.model";
import {GetUserArrayRequestConverter} from "../converters/users/getUserArrayRequestConverter";
import {GetUserArrayResponseConverter} from "../converters/users/getUserArrayResponseConverter";
import {GetUserResponseConverter} from "../converters/users/getUserResponseConverter";
import {CreateUserRequestConverter} from "../converters/users/createUserRequestConverter";
import {UserStatusResponseConverter} from "../converters/users/userStatusResponseConverter";
import {UpdateUserRequestConverter} from "../converters/users/updateUserRequestConverter";
import {forEach} from "../../../framework.core/extras/utils/collectionUtils";
import {Nullable, promiseFulfilled} from "../../../framework.core/extras/utils/typeUtils";
import {EntityProvider} from "../../common/providers/entityProvider";
import {IUserProvider} from "../../../app.core.api";
import {IEntityProvider} from "../../../framework.core.api";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { RegistrationStatusType } from "../../../app.visual/model/registrationStatusType";




const serverUrl = process.env.REACT_APP_SERVER_URL;

export class UserProvider extends EntityProvider<UserInfo> implements IUserProvider{
    public static class: string = 'UserProvider';
    baseUrl: string = `${serverUrl}/users`;

    private createUserRequestConverter!: CreateUserRequestConverter;
    private userStatusResponseConverter!: UserStatusResponseConverter;

    private updateUserRequestConverter!: UpdateUserRequestConverter;

    private getUserResponseConverter!: GetUserResponseConverter;

    private getUserArrayResponseConverter!: GetUserArrayResponseConverter;
    private getUserArrayRequestConverter!: GetUserArrayRequestConverter;

    constructor() {
        super();
        super.appendClassName(UserProvider.class);
    }

    start() {
        super.start();

        this.createUserRequestConverter = this.addConverter(CreateUserRequestConverter);
        this.userStatusResponseConverter = this.addConverter(UserStatusResponseConverter);

        this.updateUserRequestConverter = this.addConverter(UpdateUserRequestConverter);

        this.getUserResponseConverter = this.addConverter(GetUserResponseConverter);

        this.getUserArrayResponseConverter = this.addConverter(GetUserArrayResponseConverter);
        this.getUserArrayResponseConverter.singleConverter = this.getUserResponseConverter;

        this.getUserArrayRequestConverter = this.addConverter(GetUserArrayRequestConverter);

    }

    private roleProvider: Nullable<IEntityProvider<RoleInfo>> = null;

    setRoleProvider(provider: IEntityProvider<RoleInfo>) {
        this.roleProvider = provider;
    }

    getAll(uiRequestData?: any): Promise<UserInfo[]> {
        return new Promise((resolve, reject) => {
            super.sendGetAll(
                () => this.getUserArrayRequestConverter.convert(uiRequestData),
                (responseData, reject) => this.getUserArrayResponseConverter.convert(responseData, reject))
                .then(users => {

                    let promises: Promise<Nullable<UserInfo>>[] = [];

                    forEach(users, (user: UserInfo) => {
                        const {id} = user;

                        let nextUser: UserInfo = Object.assign(new UserInfo(id), user);

                        let promise = this.getRole(id, nextUser);

                        promises.push(promise);
                    });

                    //TODO it would be improve performance to resolve users before roles have been fetched and then update them as the role requests resolve
                    Promise.allSettled(promises)
                        .then((results => {
                            let users: UserInfo[] = [];
                            forEach(results, (result: PromiseSettledResult<UserInfo>) => {
                                if (promiseFulfilled(result)) {
                                    users.push(result.value);
                                }
                            })

                            resolve(users);
                        }))
                        .catch(error => {
                            reject(error);
                        })
                })
                .catch(error => {
                    reject(error);
                })
        });
    }

    private getRole(id: string, nextUser: UserInfo): Promise<Nullable<UserInfo>> {
        return new Promise((resolve, reject) => {
            const { account_status } = nextUser;

            //only fetch role if user has role (i.e. is an active user)
            if (account_status === RegistrationStatusType.ACTIVE) {
                this.roleProvider?.getSingle(id)
                    .then(roleInfo => {
                        // merge the role info with the user
                        if (roleInfo) {
                            nextUser.role = roleInfo.id;
                        }
                        resolve(nextUser);
                    })
                    .catch((error: any) => {
                        resolve(nextUser);
                        console.log(error);
                    });
            } else {
                resolve(nextUser);
            }
        });
    }

    getSingle(id: string): Promise<Nullable<UserInfo>> {
        return new Promise((resolve, reject) => {
            super.sendGetSingle(id,
                (responseData, reject) => this.getUserResponseConverter.convert(responseData[0], reject))
                .then(user => {
                    if (user != null) {
                        resolve(user);
                        // console.log('user ---------->', user)
                        // console.log('user obj inside GET user accessor is -------->', user)

                    }
                    else {
                        reject(user);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }


    create(uiRequestData: { user: UserInfo }, onUpdated?: (user: UserInfo) => void): Promise<Nullable<UserInfo>> {
        return new Promise((resolve, reject) => {
                super.sendPost(
                    () => this.createUserRequestConverter.convert(uiRequestData),
                    (responseData, errorHandler) => this.userStatusResponseConverter.convert(responseData, errorHandler))
                    .then(data => {
                        const {id} = data;
                        const inputStyle = { height:"200px", color: "#2b2d32", backgroundColor: "#eaeaea" }
                        const inputStyleTwo = { height:"200px", color: "#2b2d32", backgroundColor: "#eaeaea" }
                        console.log('data is ---------->',data)
                       // console.log('http resdata detail is ---------->',data.detail)
                        let methoddetail = data.detail;
                        let methodstatus = data.status;
                        let methodid = data.id;
                        let methodtitle = data.title;
                        console.log(methodstatus)
                        uiRequestData.user.id = id;
                        // let successMsg = () => ()



                        if (onUpdated) {
                            onUpdated(uiRequestData.user);
                        }

                        // if(methodtitle === 'failed' || methodstatus === '409') {
                        //     console.log('err 409');
                        //     toast.error(`Err 409: ${methoddetail}`, {style: inputStyle})
                        //     toast.error(`${<ErrModal>}` )
                        //     toast.error( )
                        //     {renderErr && <ErrModal />}
                        // }
                        // if (methodstatus === '201' || methodstatus === '200') {
                        //     toast.success("You have successfuly requested an account. An admin will process your request and notify you via email.", {toastId: 'successfulregister', style: inputStyleTwo})
                        // }



                        // it's a single fetch to get the new user
                        setTimeout(() => {
                            this.getSingle(id)
                                .then(user => {
                                    resolve(user);
                                    console.log(resolve(user));

                                })
                                .catch(error => {
                                    reject(error);
                                    //console.log(error)
                                });
                        }, 1000)
                    })
                    .catch(error => {
                        reject(error);
                        //console.log(error)
                    })
            }
        )
    }

    update(id: string, uiRequestData: { id: string, modifiedUser: Record<string, any> }, onUpdated?: (user: UserInfo) => void): Promise<Nullable<UserInfo>> {
        return new Promise((resolve, reject) => {
            this.sendPut(id,
                () => this.updateUserRequestConverter.convert(uiRequestData),
                (responseData, errorHandler) => this.getUserResponseConverter.convert(responseData, errorHandler))
                .then(user => {
                    if (user) {
                        resolve(user);
                    } else {
                        reject(`Error Updating User with id ${id}`);
                    }
                })
                .catch(error => {
                    reject(error);
                });
            }
        )
    }

    remove(id: string, onUpdated?: (user: UserInfo) => void): Promise<UserInfo> {
        return new Promise((resolve, reject) => {
            this.getSingle(id)
                .then(user => {
                    if (user != null) {
                        user.isUpdating = true;

                        if (onUpdated) {
                            onUpdated(user);
                        }
                        super.sendDelete(id,
                            (responseData, errorHandler) => this.userStatusResponseConverter.convert(responseData, errorHandler))
                            .then(data => {
                                if (data.id === user.id) {
                                    resolve(user);
                                }
                                else {
                                    reject('Could not delete user');
                                }
                            })
                            .catch(error => {
                                reject(error);
                            })
                    }
                })
                .catch(error => {
                    reject(error);
                });
            }
        )
    }
}
