import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const EllipsisSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 3.3566409,19.773139 c -0.86553,-0.109942 -1.56123,-0.445321 -2.20419,-1.06259 -0.73704997,-0.707594 -1.10945997,-1.542418 -1.14912997,-2.575927 -0.0295,-0.768559 0.13744,-1.446441 0.511,-2.075 0.49174997,-0.827442 1.30523997,-1.465379 2.21309997,-1.735518 0.63682,-0.189491 1.62798,-0.177785 2.23604,0.02641 1.50961,0.506948 2.5237,1.814375 2.64407,3.408893 0.0723,0.957687 -0.29968,2.032082 -0.95646,2.762577 -0.24624,0.273873 -0.69509,0.633828 -0.99796,0.800305 -0.36717,0.201822 -1.01964,0.404167 -1.46249,0.453547 -0.42077,0.04692 -0.44395,0.04685 -0.83398,-0.0027 z m 12.2131601,8.02e-4 c -0.40233,-0.04662 -0.82544,-0.168369 -1.20784,-0.347569 -0.41233,-0.193222 -0.63639,-0.347186 -0.97173,-0.667721 -0.76878,-0.734836 -1.14139,-1.54097 -1.19174,-2.578296 -0.0276,-0.569137 0.0212,-0.923027 0.19965,-1.44574 0.31973,-0.93678 1.03093,-1.737649 1.93059,-2.174017 0.61111,-0.296409 0.8744,-0.351675 1.67138,-0.350839 0.7584,7.94e-4 0.92306,0.03061 1.49039,0.269882 1.60079,0.675127 2.56162,2.427722 2.26463,4.130779 -0.29924,1.71597 -1.63715,2.968982 -3.38142,3.166842 -0.39229,0.0445 -0.39116,0.0445 -0.80391,-0.0033 z m 12.19122,0 c -0.40234,-0.04662 -0.82545,-0.168369 -1.20785,-0.347569 -0.40546,-0.190007 -0.61959,-0.337264 -0.96706,-0.665044 -0.7672,-0.723737 -1.14592,-1.540735 -1.19641,-2.580975 -0.0276,-0.569136 0.0213,-0.923027 0.19965,-1.445739 0.31973,-0.936781 1.03093,-1.73765 1.9306,-2.174018 0.61111,-0.296408 0.87439,-0.351674 1.67137,-0.350839 0.7584,7.95e-4 0.92306,0.03061 1.49039,0.269882 1.60079,0.675127 2.56162,2.427722 2.26463,4.130779 -0.29924,1.71597 -1.63715,2.968983 -3.38142,3.166842 -0.39229,0.0445 -0.39115,0.0445 -0.8039,-0.0033 z"/>
    </svg>
);


