import {HTMLAttributes} from "react";
import {Nullable} from "../../../framework.core/extras/utils/typeUtils";
import {DocumentInfo, NoteInfo, PocketInfo} from "../../../app.model";
import {SuggestionItemVM} from "../../theme/widgets/autoComplete/autoCompleteModel";

export type SystemMenuAppStateProps = HTMLAttributes<any> & {
    currentUser: UserVM;
    permissions: PermissionsVM;
    pockets: Record<string, PocketVM>;
    selectedDocument: Nullable<DocumentVM>;
    selectedExcerptId: Nullable<string>;
    selectedMenuItemId: Nullable<string>;
    selectedNoteId: Nullable<string>;
    selectedPocket: Nullable<PocketVM>;
    selectedReportId: Nullable<string>;
    selectedResourceId: Nullable<string>;
    selectedTemplateId: Nullable<string>;
    templates: Record<string, TemplateVM>;
    userSuggestionSupplier: (text:string) => Promise<UserItemVM[]>;
}

export type SystemMenuAppDispatchProps = {
    onAddNote: (noteVM: NoteVM) => void;
    onAddToPocket: (id: string, pocketId: string) => void;
    onCreatePocket: (title: string) => void;
    onCreateReport: (pocket_id: string, title: string) => void;
    onDeleteDocument: (id: string) => void;
    onDeleteExcerpt: (excerpt_id: string, pocket_id: string) => void;
    onDeleteNoteFromExcerpt: (note_id: string, pocket_id: string) => void;
    onDeleteNoteFromPocket: (note_id: string, pocket_id: string) => void;
    onDeleteNoteFromResource: (note_id: string, pocket_id: string) => void;
    onDeletePocket: (pocket_id: string) => void;
    onDeleteReport: (report_id: string, pocket_id: string) => void;
    onDeleteResource: (resource_id: string, pocket_id: string) => void;
    onDownloadPocket: (pocket_id: string) => void;
    onDownloadDocument: (document_id: string, original?: boolean) => void;
    onHoverMenuItem: (id: string) => void;
    onSelectMenuItem: (id: string) => void;
    onTemplateSelect: (template_id: string) => void;
    onUpdatePocket: (edits: PocketUpdateParams) => void;
    onUpdateDocument: (document: DocumentUpdateParams) => void;
}

export type SystemMenuPresenterProps = SystemMenuAppStateProps & SystemMenuAppDispatchProps;

export type SystemMenuPresenterState = {
    showAddToPocket: boolean;
    showCreateReportPopup: boolean;
    showNotePopup: boolean;
    showSharePopup: boolean;
    showTitlePopup: boolean;
    tmpDocument: DocumentUpdateParams;
    tmpNote: NoteVM;
    tmpPocket: PocketUpdateParams;
    tmpReportTitle: string;
    tmpSharedAuthors: Record<string, string>;
    userInputExpanded: boolean;
}

export type SystemMenuViewProps = HTMLAttributes<any> & {
    currentUser: UserVM;
    onAddNote: () => void;
    onAddToPocket: (id: string, pocketId: string) => void;
    onCreatePocket: () => void;
    onCreateReport: () => void;
    onDelete: () => void;
    onDownload: (original?: boolean) => void;
    onHoverMenuItem: (id: string) => void;
    onPublish: (scope?: Scope) => void;
    onSelectMenuItem: (id: string) => void;
    onTemplateSelect: (template_id: string) => void;
    onTmpDocumentChanged: (name: string, value: string) => void;
    onTmpNoteChanged: (name: string, value: string) => void;
    onTmpPocketChanged: (name: string, value: string) => void;
    onTmpReportNameChanged: (name: string, value: string) => void;
    onToggleShowAddToPocket: () => void;
    onToggleShowCreateReportPopup: () => void;
    onToggleShowNotePopup: () => void;
    onToggleShowSharePopup: () => void;
    onToggleShowTitlePopup: () => void;
    onToggleUserInputExpanded: () => void;
    permissions: PermissionsVM;
    pockets: Record<string, PocketVM>;
    selectedDocument: Nullable<DocumentVM>;
    selectedExcerptId: Nullable<string>;
    selectedMenuItemId: Nullable<string>;
    selectedNoteId: Nullable<string>;
    selectedPocket: Nullable<PocketVM>;
    selectedReportId: Nullable<string>;
    selectedTemplateId: Nullable<string>;
    showAddToPocket: boolean;
    showCreateReportPopup: boolean;
    showNotePopup: boolean;
    showSharePopup: boolean;
    showTitlePopup: boolean;
    templates: Record<string, TemplateVM>;
    tmpNote: NoteVM;
    tmpPocket: PocketVM;
    tmpReportTitle: string;
    tmpSharedAuthors: Record<string, string>;
    userInputExpanded: boolean;
    userSuggestionSupplier: (text:string) => Promise<UserItemVM[]>;
}

export type UserVM = {
    id: string;
    firstName: string;
    lastName: string;
}

export type PermissionsVM = {
    canDelete: boolean,
    canDownload: boolean,
    canModify: boolean
}

export type TemplateVM = {
    id?: string;
    title?: string;
    html?: string;
    selected?: boolean;
}

export type NoteVM = {
    id?: string;
    text?: string;
    content?: string;
    excerpt_id?: string;
    resource_id?: string;
    pocket_id?: string;
    selected?: boolean;
}

export type PocketVM = {
    id?: string;
    title?: string;
    isUpdating?: boolean;
    author?: string;
    shared_authors?: Record<string, string>;
    readonly?: boolean;
    scope?: Scope;
}

export type DocumentVM = {
    id?: string;
    title?: string;
    isUpdating?: boolean;
    shared_authors?: Record<string, string>;
    scope?: Scope;
}

export enum Scope {
    PRIVATE='Private',
    PUBLIC='Public',
    GROUP='Group',
    DRAFT='Draft',
}

export type PocketUpdateParams = Partial<Record<keyof Omit<PocketInfo, 'className'>, any>>;

export type DocumentUpdateParams = Partial<Record<keyof Omit<DocumentInfo, 'className'>, any>>;

export type UserItemVM = SuggestionItemVM;
