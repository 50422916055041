export enum ComponentTypes {
    AppWrapper='AppWrapper',
    DocumentPanelWrapper='DocumentPanelWrapper',
    PocketDocumentPanelWrapper='PocketDocumentPanelWrapper',
    KnowledgeManagementWrapper='KnowledgeManagementWrapper',
    LandingPanelWrapper='LandingPanelWrapper',
    LibraryWrapper='LibraryWrapper',
    LoginPanelWrapper='LoginPanelWrapper',
    LoginWrapper='LoginWrapper',
    PocketManagerPanelWrapper='PocketManagerPanelWrapper',
    PocketsPanelWrapper='PocketsPanelWrapper',
    ProfilePanelWrapper='ProfilePanelWrapper',
    ReportPanelWrapper='ReportPanelWrapper',
    SearchBannerWrapper='SearchBannerWrapper',
    SearchGraphsPanelWrapper='SearchGraphsPanelWrapper',
    SearchResultsPanelWrapper='SearchResultsPanelWrapper',
    SearchWrapper='SearchWrapper',
    SettingsPanelWrapper='SettingsPanelWrapper',
    StatsPanelWrapper='StatsPanelWrapper',
    SystemBannerWrapper='SystemBannerWrapper',
    SystemToolbarWrapper='SystemToolbarWrapper',
    SystemMenuWrapper='SystemMenuWrapper',
    TagsPanelWrapper='TagsPanelWrapper',
    UploadPanelWrapper='UploadPanelWrapper',
}
