import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const AcceptSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 11.685021,27.899338 c -0.0943,-0.018 -0.27201,-0.0797 -0.395,-0.13726 -0.20948,-0.098 -0.56466,-0.4456 -5.6217003,-5.50208 -5.82198999,-5.82134 -5.51523999,-5.49508 -5.63145999,-5.98969 -0.0948,-0.40336 -0.003,-0.89531 0.2303,-1.22824 0.16778,-0.23991 2.57874999,-2.64768 2.74740999,-2.74376 0.53885,-0.30698 1.13456,-0.30728 1.67756,-8.5e-4 0.10121,0.0571 1.42887,1.36169 3.72264,3.6579 1.9609303,1.96301 3.5761503,3.56911 3.5893803,3.56911 0.0132,0 3.42449,-3.40096 7.58057,-7.55768 4.15608,-4.1567301 7.62996,-7.6051701 7.71973,-7.6632101 0.50247,-0.32485 1.21295,-0.31449 1.72592,0.0252 0.0944,0.0625 0.73822,0.68249 1.43058,1.37764 1.32327,1.3286 1.36386,1.37796 1.48998,1.81182 0.11483,0.39499 0.0234,0.92431 -0.22337,1.29333 -0.0555,0.083 -4.30967,4.3575901 -9.45366,9.4990301 -10.05358,10.04862 -9.44792,9.46431 -9.92737,9.57735 -0.2407,0.0568 -0.4086,0.0596 -0.66151,0.0114 z"/>
    </svg>
);


