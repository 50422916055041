import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const RemoveSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 14.600561,31.950983 c -3.13023,-0.28759 -6.1872498,-1.51832 -8.6351898,-3.47646 -0.69131,-0.55298 -1.8829,-1.74572 -2.42502,-2.42735 -2.30969,-2.90407 -3.53225004,-6.38779 -3.53403004,-10.07033 -0.002,-4.31144 1.78535004,-8.5140398 4.89344004,-11.5054798 3.56624,-3.43239 8.5310898,-5.02058996 13.4467698,-4.30146996 2.77866,0.4065 5.4873,1.58573996 7.71086,3.35702996 0.68065,0.54221 1.87162,1.73589 2.42272,2.42823 1.87596,2.35675 2.98177,4.9592098 3.42294,8.0556798 0.1212,0.85064 0.12074,3.11971 -7.9e-4,3.98067 -0.43537,3.08414 -1.54483,5.696 -3.42187,8.05567 -0.54754,0.68833 -1.73845,1.88092 -2.423,2.42642 -2.90976,2.31869 -6.46992,3.55329 -10.16764,3.52596 -0.45486,-0.003 -1.035,-0.0252 -1.28919,-0.0486 z m -5.7162598,-7.54425 c 0.0535,-0.0264 1.6789298,-1.62735 3.6120298,-3.5576 l 3.51472,-3.50954 3.53937,3.53605 c 2.53485,2.53247 3.5808,3.54685 3.68533,3.57408 0.20746,0.0541 0.57542,0.0369 0.71135,-0.0332 0.44337,-0.22856 0.64526,-0.68394 0.50696,-1.14348 -0.0666,-0.22125 -0.32762,-0.49444 -3.58803,-3.75508 l -3.51623,-3.51648 3.53605,-3.53938 c 2.53247,-2.5348398 3.54685,-3.5807898 3.57408,-3.6853198 0.0579,-0.22224 0.0351,-0.57683 -0.0472,-0.73448 -0.15959,-0.30569 -0.54602,-0.55472 -0.86079,-0.55472 -0.082,0 -0.23046,0.0336 -0.32994,0.0747 -0.12661,0.0523 -1.23522,1.12847 -3.69576,3.5877398 l -3.51488,3.51307 -3.51489,-3.51307 C 10.035931,8.6907532 8.9273312,7.6145432 8.8007112,7.5622832 c -0.0995,-0.0411 -0.24795,-0.0747 -0.32994,-0.0747 -0.31477,0 -0.7012,0.24903 -0.86079,0.55472 -0.0823,0.15765 -0.10508,0.51224 -0.0472,0.73448 0.0272,0.10453 1.04156,1.15043 3.5739098,3.6851598 l 3.53589,3.53921 -3.53413,3.53921 c -3.4824298,3.48745 -3.5348698,3.5428 -3.5846498,3.78415 -0.0508,0.24655 -0.0217,0.49518 0.0804,0.68603 0.0837,0.15633 0.38517,0.40211 0.53333,0.43476 0.0731,0.0161 0.15479,0.0371 0.18154,0.0466 0.0724,0.0258 0.42362,-0.0301 0.53514,-0.0852 z"/>
    </svg>
);


