import {RepoItem} from "../../framework.core/services";

export class TemplateInfo extends RepoItem {

  public static class: string = 'TemplateInfo';

  title: string =  '';
  html: string = '';

  constructor(id: string)
  {
    super(id);

    this.appendClassName(TemplateInfo.class);
  }
}
