import React from 'react';
import {SVGModel} from '../../../framework.visual';

export const RecommendedSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 11.821214,31.947507 c -0.69282,-0.16786 -1.1934,-0.70658 -1.30127,-1.40042 -0.12931,-0.83177 0.41508,-1.66683 1.22399,-1.87753 0.1807,-0.0471 0.73091,-0.0541 4.23855,-0.0541 2.83122,0 4.08654,0.0117 4.21787,0.0394 0.75602,0.15921 1.29843,0.84112 1.29843,1.63237 0,0.85478 -0.56225,1.51922 -1.42382,1.68261 -0.10154,0.0193 -1.80848,0.0317 -4.1118,0.03 -3.24626,-0.002 -3.97399,-0.0116 -4.14195,-0.0523 z m 0.16848,-5.69694 c -0.64656,-0.087 -1.1436,-0.48457 -1.4002,-1.11992 -0.0816,-0.20195 -0.0818,-0.20644 -0.0922,-2.16566 l -0.0105,-1.96328 -0.27181,-0.1587 c -0.3785103,-0.22099 -0.9954303,-0.65994 -1.4068303,-1.00097 -0.41519,-0.34418 -1.24195,-1.18321 -1.57671,-1.60012 -1.11655,-1.39049 -1.96701,-3.25983 -2.27946,-5.01035 -0.48164,-2.6984 0.022,-5.4613601 1.42146,-7.7978001 1.81939,-3.03758 5.0425803,-5.07428997 8.5409703,-5.39698997 0.59241,-0.0546 1.68028,-0.0474 2.26201,0.015 0.79577,0.0854 1.2908,0.18235 2.0112,0.394 4.0752,1.19724997 7.15311,4.64662997 7.88264,8.83399997 0.12295,0.70572 0.15498,1.0905401 0.15566,1.8700601 0.001,1.55397 -0.22891,2.79264 -0.77722,4.18118 -0.58846,1.49024 -1.4074,2.72653 -2.5724,3.88336 -0.67779,0.67304 -1.19755,1.07478 -2.22761,1.72184 l -0.14995,0.0942 -7.5e-4,1.93131 -7.4e-4,1.9313 -0.0823,0.23311 c -0.0956,0.27071 -0.23573,0.48436 -0.45114,0.68767 -0.1925,0.18169 -0.33937,0.2687 -0.62946,0.37292 l -0.22935,0.0824 -3.95183,0.005 c -2.1735,0.003 -4.04709,-0.008 -4.16353,-0.0235 z"/>
    </svg>
);


