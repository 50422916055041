import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const DownloadSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 4.2832198,31.951816 c -1.76767,-0.19443 -3.29165002,-1.36094 -3.95230002,-3.02522 -0.32287,-0.81338 -0.31493,-0.70417 -0.33466,-4.60105 -0.0119,-2.35014 -0.001,-3.60762 0.0313,-3.78515 0.17095,-0.92473 0.99834002,-1.50109 1.89054002,-1.31694 0.15041,0.031 0.34774,0.0942 0.43853,0.14026 0.24902,0.12644 0.55535,0.45569 0.69044,0.74208 l 0.12059,0.25565 0.0255,3.55578 c 0.0168,2.33917 0.0425,3.60394 0.0752,3.69654 0.17357,0.49193 0.52948,0.87627 0.96091,1.03769 l 0.26666,0.0998 H 15.99644 27.49695 l 0.26866,-0.10052 c 0.36697,-0.1373 0.75198,-0.52231 0.88928,-0.88928 0.0996,-0.26613 0.10053,-0.29994 0.10088,-3.57868 2.3e-4,-2.05201 0.0186,-3.4224 0.0483,-3.60572 0.0605,-0.37316 0.22517,-0.70022 0.47396,-0.94167 0.24044,-0.23334 0.44489,-0.3393 0.79353,-0.41125 0.89401,-0.18451 1.74184,0.4108 1.89214,1.32859 0.0614,0.37489 0.0588,6.47693 -0.003,7.07922 -0.23831,2.32222 -1.98137,4.0694 -4.31108,4.32126 -0.58506,0.0632 -22.7907602,0.062 -23.3663902,-0.001 z M 15.55481,22.342536 c -0.41224,-0.13524 -0.58926,-0.30148 -4.70016,-4.41405 -4.5845202,-4.58638 -4.3943302,-4.37231 -4.4449402,-5.00282 -0.0379,-0.47227 0.0891,-0.8545 0.39746,-1.19578 0.51621,-0.57138 1.31632,-0.70776 1.97475,-0.33661 0.1112,0.0627 1.3610502,1.27635 2.8917402,2.80803 l 2.69589,2.69763 0.0232,-7.8447803 0.0232,-7.84478 0.15302,-0.3108 c 0.17536,-0.35621 0.42317,-0.59511 0.79984,-0.77111 0.36384,-0.17 0.89114,-0.17 1.25498,0 0.37666,0.176 0.62447,0.4149 0.79984,0.77111 l 0.15301,0.3108 0.0232,7.84478 0.0232,7.8447803 2.69589,-2.69753 c 1.48274,-1.48365 2.78129,-2.748 2.88568,-2.80967 0.10439,-0.0617 0.32689,-0.13739 0.49446,-0.16826 1.3763,-0.2536 2.37703,1.22922 1.63872,2.42815 -0.0686,0.11138 -1.97888,2.0568 -4.24509,4.32317 -3.38459,3.38482 -4.16604,4.14369 -4.37603,4.24961 -0.20842,0.10512 -0.32863,0.13146 -0.65073,0.1426 -0.2173,0.008 -0.44738,-0.004 -0.51129,-0.0245 z"/>
    </svg>
);


