import {VisualWrapper} from "../../../framework.visual";
import {createVisualConnector} from "../../../framework.visual";
import {
    AnimationTypes,
    CitationType,
    ComponentTypes,
    ContainerTypes,
    ReportInfo,
    SelectionTypes
} from "../../../app.model";
import {
    displayService,
    documentService,
    pocketService,
    reportService,
    selectionService, templateService, userService
} from "../../../serviceComposition";
import ReportPanelPresenter from "./presenters/reportPanelPresenter";
import {
    ReportInfoVM,
    ReportPanelAppDispatchProps,
    ReportPanelAppStateProps,
    ReportUpdateParams
} from "./reportPanelModel";
import {ReportParamType} from "../../../app.core.api";
import {createSelector} from "@reduxjs/toolkit";
import {deserialize} from "./views/slate/slateUtils";

class _ReportPanelWrapper extends VisualWrapper {
    constructor() {
        super();

        this.id = ComponentTypes.ReportPanelWrapper;

        this.view = ReportPanelPresenter;

        this.displayOptions = {
            containerId: ContainerTypes.DocumentPreviewPanel,
            visible: false,
            appearClass: AnimationTypes.FadeIn,
            enterClass: AnimationTypes.FadeIn,
            exitClass: AnimationTypes.FadeOut,
        };

        this.mapStateToProps = (state: any): ReportPanelAppStateProps => {
            return {
                report: this._getReport(state),
                citations: {},
                excerpts: {},// this._getExcerpts(state),
            };
        }

        this.mapDispatchToProps = (): ReportPanelAppDispatchProps => {
            return {
                onSaveReport: (edits: any, unlock?: boolean) => this._onSaveReport(edits, unlock),
                onClose: () => this._onClose(),
                onLockReport: (id: string) => this._onLockReport(id),
            };
        }
    }

    _getCitations() {
        return {
            [CitationType.MLA]: {title: 'MLA'},
            [CitationType.APA]: {title: 'APA'},
            [CitationType.CHICAGO]: {title: 'Chicago'},
            [CitationType.Harvard]: {title: 'Harvard'},
        }
    }

    _onClose() {
        displayService.popNode(ContainerTypes.DocumentPreviewPanel);
        selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');
        selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "");
    }

    _onSaveReport(edits: ReportUpdateParams, unlock?: boolean) {
        const params: ReportParamType = {
            ...edits
        }

        const { scope, id } = edits;

        let report_id = id ? id : selectionService.getContext(SelectionTypes.REPORT_SELECTION);

        if (report_id) {
            if (unlock) {
                pocketService.unlockPocket(report_id);
            } else {
                this._onLockReport(report_id);
            }
        }

        //if scope is set to private or public, report needs to be published
        if (scope && scope !== "Draft") {
            reportService.publishReport(params)
                .then(document => {
                    if (document) {
                        const { id } = document;

                        selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, id);//select new document
                        selectionService.setContext(SelectionTypes.REPORT_SELECTION, "");//clear report selection
                        selectionService.setContext(SelectionTypes.MENU_SELECTION, 'Document_Panel_Info');//open menu tab
                        selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "EXPANDED");//expand "more info" section on document preview
                        displayService.pushNode(ComponentTypes.DocumentPanelWrapper);//navigate to upload panel and document preview panel
                        displayService.pushNode(ComponentTypes.UploadPanelWrapper);

                        //fetch document until pdf is generated and nlp is complete
                        documentService.fetchDocument(id);

                        const updatedDocument = documentService.getDocument(id);

                        if (!updatedDocument) {
                            setTimeout(() => {
                                documentService.fetchDocument(id);

                                const updatedDocument = documentService.getDocument(id);

                                if (!updatedDocument) {
                                    setTimeout(() => {
                                        documentService.fetchDocument(id);

                                        const updatedDocument = documentService.getDocument(id);

                                        if (!updatedDocument) {
                                            setTimeout(() => {
                                                documentService.fetchDocument(id);
                                            }, 5000);
                                        }
                                    }, 3000);
                                }
                            }, 1000);
                        }
                    }
                })
        } else {
            reportService.updateReport(params);
        }
    }

    _getSelectedReportId = selectionService.makeGetContext(SelectionTypes.REPORT_SELECTION);
    _getSelectedTemplateId = selectionService.makeGetContext(SelectionTypes.TEMPLATE_SELECTION);

    _getReport = createSelector(
        [
            (s) => this._getSelectedReportId(s),
            (s) => this._getSelectedTemplateId(s),
            (s) => reportService.getReports(),
            (s) => userService.getCurrentUserId(),
        ],
        (reportId, templatedId, reports: Record<string, ReportInfo>, currentUserId) => {
            let report = reports[reportId];

            let itemVM: ReportInfoVM = {};

            if (report) {
                const {
                    id,
                    author_id,
                    title,
                    publication_date,
                    citation,
                    content,
                    resource_ids,
                    isUpdating,
                    scope,
                    editor_id,
                    edit_duration,
                    html,
                } = report;

                let editor = '';
                const editor_user = userService.getUser(editor_id);
                if (editor_user) {
                    const { first_name, last_name } = editor_user;

                    editor = first_name + " " + last_name;
                }

                let readonly = editor_id !== '' && editor_id !== currentUserId;

                itemVM = {
                    id,
                    author_id,
                    title,
                    publication_date,
                    content,
                    scope,
                    isUpdating,
                    readonly,
                    edit_duration,
                    editor,
                }
            }

            return itemVM;
        }
    );

    private _onLockReport(id: string) {
        pocketService.lockPocket(id);
    }
}

export const {
    connectedPresenter: ReportPanelWrapper
} = createVisualConnector(_ReportPanelWrapper)
