import {Editor, Transforms} from "slate";
import React, {useRef, useState} from "react";
import {ReactEditor, useFocused, useSelected, useSlateStatic} from "slate-react";
import imageExtensions from 'image-extensions';
import isUrl from 'is-url';
import Button from "../../../../theme/widgets/button/button";

export const withImages = (editor: Editor) => {
    const { insertData, isVoid } = editor

    editor.isVoid = (element: any) => {//TODO find correct type
        return element.type === 'image' ? true : isVoid(element)
    }

    editor.insertData = data => {
        const text = data.getData('text/plain')
        const { files } = data

        if (files && files.length > 0) {
            for (const file of files) {
                const reader = new FileReader()
                const [mime] = file.type.split('/')

                if (mime === 'image') {
                    reader.addEventListener('load', () => {
                        const url = reader.result
                        insertImage(editor, url)
                    })

                    reader.readAsDataURL(file)
                }
            }
        } else if (isImageUrl(text)) {
            insertImage(editor, text)
        } else {
            insertData(data)
        }
    }

    return editor
}

const insertImage = (editor: Editor, url: any) => {//TODO find type for url
    const text = { text: '' }
    const image: any = { type: 'image', url, children: [text] }//TODO get type for image 'ImageElement'
    Transforms.insertNodes(editor, image)
}

const Element = (props: any) => {//TODO find type for props
    const { attributes, children, element } = props

    switch (element.type) {
        case 'image':
            return <Image {...props} />
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Image = ({ attributes, children, element }: any) => {
    const editor = useSlateStatic()
    const path = ReactEditor.findPath(editor, element)

    const selected = useSelected()
    const focused = useFocused()
    return (
        <div {...attributes}>
            {children}
            <div
                contentEditable={false}
                className={'position-relative'}
            >
                <img
                    src={element.url}
                    className={'d-block'}
                    style={{maxWidth: '100%', maxHeight: '20em', boxShadow: `${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'}`}}
                />
                <Button
                    onClick={() => Transforms.removeNodes(editor, { at: path })}
                >
                    DELETE IMAGE
                </Button>
            </div>
        </div>
    )
}

export const InsertImageButton = () => {
    const editor = useSlateStatic()
    return (
        <Button
            onMouseDown={event => {
                event.preventDefault()
                const url = window.prompt('Enter the URL of the image:')
                if (url && !isImageUrl(url)) {
                    alert('URL is not an image')
                    return
                }
                insertImage(editor, url)
            }}
        >
            IMAGE
        </Button>
    )
}

// export const InsertImageButton = ({ icon }: any) => {
//     const editor = useSlateStatic();
//     const uploadedImageURL = (url: string) => {
//         insertImage(editor, url);
//     };
//     const uploadButtom = () => <IconButton component="span">{icon}
//     </IconButton>;
//
//     return (
//         <ImageCropper
//             uploadTextIcon={uploadButtom}
//             uploadedImageURL={uploadedImageURL}
//         />
//     );
// };
//
const isImageUrl = (url: string) => {
    if (!url) return false
    if (!isUrl(url)) return false
    const ext = new URL(url).pathname.split('.').pop()
    return imageExtensions.includes(ext || "")
}

// interface Props {
//     uploadTextIcon: any;
//     uploadedImageURL: (image: string) => void;
// }
// export const ImageCropper = (props: Props) => {
//     const { uploadTextIcon, uploadedImageURL } = props;
//     // Opens the Cropper dialog
//     const [openDialog, setOpenDialog] = useState(false);
//     const [selectedFile, setSelectedFile] = useState<any>(null);
//     // Using this to show the cropped image quickly to the user.
//     const [croppedFilePath, setCroppedFilePath] = useState<any>(null);
//     const [croppedFile, setCroppedFile] = useState<any>(null);
//
//     // Mutation to upload the image to the server
//     const [uploadImageMutation] = useUploadImageMutation();
//     const cropper = useRef(null);
//
//     // Image input ref. It will be used to reset the file input to blank.
//     const fileInputRef = useRef<any>(null);
//
//     // Will close the cropper dialog
//     const handleClose = () => {
//         // Reset the image input to blank incase user selects the same file.
//         // If users selects the same file then onChange will not fire.
//         if (fileInputRef && fileInputRef.current) {
//             fileInputRef.current.value = "";
//         }
//         setOpenDialog(false);
//     };
//
//     const confirmCroppedImage = async () => {
//         // Upload cropped image to the server.
//         const { data } = await uploadImageMutation({
//             variables: { input: { picture: croppedFile } },
//         });
//
//         // Getting the file name of the uploaded image on the server.
//         // Create a full URL to store that in slate.
//         if (data && data.uploadImage && data.uploadImage.filename) {
//             const url = `${IMAGE_SERVER_URL}${encodeURI(data.uploadImage.filename)}`;
//             uploadedImageURL(url);
//         }
//         handleClose();
//     };
//
//     // This method will run when any image is selected.
//     const fileSelectHandler = async (event: any) => {
//         if (event.target.files && event.target.files.length > 0) {
//             const file = event.target.files[0];
//             const newFile = Object.assign(file, {
//                 preview: URL.createObjectURL(file),
//             });
//             setSelectedFile(newFile);
//             setOpenDialog(true);
//         }
//     };
//
//     // Will crop any selected image and will set the croppedImage file to be saved to DB.
//     const _crop = () => {
//         const str = (cropper as any).current
//             .getCroppedCanvas()
//             .toDataURL(selectedFile.type, 0.8);
//
//         setCroppedFilePath(str);
//         setCroppedFile(base64StringtoFile(str, selectedFile.name));
//     };
//     return (
//         <>
//             <input
//                 accept="image/*"
//                 style={{ display: "none" }}
//                 id="upload-image"
//                 type="file"
//                 onChange={fileSelectHandler}
//                 ref={fileInputRef}
//             />
//             <label htmlFor="upload-image">{uploadTextIcon()}</label>
//             <Dialog
//                 open={openDialog}
//                 onClose={handleClose}
//                 scroll="paper"
//                 aria-labelledby="scroll-dialog-title"
//                 aria-describedby="scroll-dialog-description"
//             >
//                 <DialogTitle id="scroll-dialog-title">Select image</DialogTitle>
//                 <DialogContent dividers={true}>
//                     {selectedFile && (
//                         <Cropper
//                             ref={cropper}
//                             src={selectedFile?.preview}
//                             style={{ height: 400, width: "100%", marginBottom: 30 }}
//                             guides={true}
//                             zoomOnWheel={false}
//                             crop={_crop}
//                         />
//                     )}
//                     { /* Showing the cropped image here */}
//                     {croppedFilePath && (
//                         <span style={{ display: "flex", flexDirection: "column" }}>
//               <span style={{ fontWeight: "bolder" }}>Cropped Image: </span>
//               <img src={croppedFilePath} style={{ width: "100%" }} />
//             </span>
//                     )}
//                 </DialogContent>
//                 <DialogActions>
//                     <Button
//                         onClick={handleClose}
//                         color="secondary"
//                         style={{ textTransform: "none" }}
//                     >
//                         Cancel
//                     </Button>
//                     <ConfirmationButton
//                         label="Confirm Image"
//                         onSubmit={confirmCroppedImage}
//                     />
//                 </DialogActions>
//             </Dialog>
//         </>
//     );
// };
