import './menu.css';
import React, {Component, MouseEventHandler} from "react";
import {MenuProps} from "./menuModel";
import {forEach} from "../../../../framework.core/extras/utils/collectionUtils";
import Button from "../button/button";
import {bindInstanceMethods} from "../../../../framework.core/extras/utils/typeUtils";

export class Menu extends Component<MenuProps> {
    constructor(props: MenuProps) {
        super(props);

        bindInstanceMethods(this);
    }

    onMouseOver: MouseEventHandler<HTMLDivElement> = (event) => {
        this.props.onHoverMenuItem(event.currentTarget.id);
    };

    render() {
        const { className, menuItems, onSelectMenuItem, light } = this.props;

        let cn = 'menu d-flex p-0 w-100';
        if (className) {
            cn += ` ${className}`;
        }
        if (light) {
            cn += ' light';
        }

        let menuItemDivs: JSX.Element[] = [];
        forEach(menuItems, (menuItem: { id: string; type: string; content: JSX.Element[]; selected: boolean; wide: boolean; }) => {
            const { id, type, content, selected, wide } = menuItem;

            menuItemDivs.push(
                <div id={id + "_" + type} className={'menu-item'} onMouseOver={this.onMouseOver}>
                    <Button selected={selected} className={'pt-2 pb-0 header-1 menu-button'} orientation={'vertical'} onClick={() => onSelectMenuItem(id + "_" + type)}>
                        {type}
                        <div className={'bar mt-2'}/>
                    </Button>

                    <div className={`menu-modal ${selected ? 'd-block' : ''} ${wide ? 'w-100' : ''}`}>
                        <div className={`modal d-flex flex-fill flex-column text-nowrap ${wide ? 'w-100' : ''}`}>
                            {content}
                        </div>
                    </div>
                </div>
            );
        })

        return (
            <div id={'MENU'} className={cn}>
                    {menuItemDivs}
            </div>
        );
    }
}
