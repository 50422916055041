export enum ContainerTypes {
    BUMED='BUMED',
    DocumentPreviewPanel='DocumentPreviewPanel',
    KnowledgeManagement='KnowledgeManagement',
    Main='Main',
    SearchBannerTools='SearchBannerTools',
    SettingsPanel='SettingsPanel',
    SystemToolPanel='SystemToolPanel',
    SystemToolPocketsPanel='SystemToolPocketsPanel',
}
