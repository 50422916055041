import React from 'react';
import {VisualWrapper} from '../../../../../framework.visual';
import {SearchView} from "./searchView";
import {createVisualConnector} from "../../../../../framework.visual";
import {AnimationTypes, ComponentTypes, ContainerTypes} from "../../../../../app.model";

class Search extends VisualWrapper {
    constructor(props: any) {
        super();

        this.id = ComponentTypes.SearchWrapper;

        this.view = SearchView;

        this.displayOptions = {
            containerId: ContainerTypes.SystemToolPanel,
            visible: true,
            appearClass: AnimationTypes.FadeIn,
            enterClass: AnimationTypes.FadeIn,
            exitClass: AnimationTypes.FadeOut
        };
    }
}

export const {
    connectedPresenter: SearchPresenter
} = createVisualConnector(Search);

