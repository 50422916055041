import React, {Component} from 'react';
import './settingsPanel.css';
import '../../../theme/stylesheets/panel.css';
import {AccountStatusVM, SettingsPanelViewProps} from "../settingsPanelModel";
import Card from "../../../theme/widgets/card/card";
import Button from "../../../theme/widgets/button/button";
import {LoadingIndicator} from "../../../theme/widgets/loadingIndicator/loadingIndicator";
import {Size} from "../../../theme/widgets/loadingIndicator/loadingIndicatorModel";
import {EditPropertyVM, UserInfoVM} from "../settingsPanelModel";
import ComboBox from "../../../theme/widgets/comboBox/comboBox";
import TextEdit from "../../../theme/widgets/textEdit/textEdit";
import {SettingsSVG} from "../../../theme/svgs/settingsSVG";
import {LogoutSVG} from "../../../theme/svgs/logoutSVG";
import {RegistrationStatusType} from "../../../model/registrationStatusType";

class SettingsPanelView extends Component<SettingsPanelViewProps> {
    _onShowHelpThree() {
        //window.location.href = `${process.env.REACT_APP_USERGUIDE_URL}/UserGuide/Content/Project%20Description/Overview.htm`
        // window.location.href = `/UserGuide/Content/Project%20Description/Overview.htm`
        window.open(`/UserGuide/Content/Project%20Description/Overview.htm`, "_blank")
    }

    render() {
        const {
            currentUser,
            className,
            departments,
            edit,
            dirty,
            editProperties,
            onLogout,
            onToggleEdit,
            onTmpUserChanged,
            onUpdateUser,
            permissions,
            roles,
            tmpUser,
            userLookup,
        } = this.props;

        const {id, isUpdating, first_name, last_name, role} = currentUser;

        let display_name = first_name;
        let initials = first_name?.charAt(0);
        if (last_name) {
            display_name += ` ${last_name}`;
            initials += last_name.charAt(0);
        }

        let roleTitle = role ? roles[role].title : '';

        let cn = 'settings-panel position-absolute overflow-auto h-100 d-flex p-3 v-gap-2 flex-column align-items-center justify-content-start';
        if (className) {
            cn += ` ${className}`;
        }


        const editDivs = editProperties.map((editProperty: EditPropertyVM) => {
            const {id, placeholder, readonly} = editProperty;

            const originalValue = currentUser[id] || '';
            const editValue = tmpUser ? tmpUser[id] || '' : '';

            let renderDiv;
            let cn = 'align-self-center display-4 font-weight-light';

            switch (id) {
                case 'department':
                    let departmentId = editValue ? editValue : originalValue;
                    let departmentTitle = '';
                    if (departments && departments[departmentId]) {
                        departmentTitle = departments[departmentId].title;
                    } else {
                        departmentTitle = departmentId;
                    }

                    renderDiv = (
                        <ComboBox
                            key={id}
                            className={cn}
                            disable={!edit || isUpdating}
                            onSelect={(value: string) => onTmpUserChanged(currentUser.id || "", id, value)}
                            title={departmentTitle}
                            items={departments}
                        />
                    );
                    break;
                case 'role':
                    let roleId = editValue ? editValue : originalValue;
                    let roleTitle = '';
                    if (roles && roles[roleId]) {
                        roleTitle = roles[roleId].title;
                    } else {
                        roleTitle = roleId;
                    }

                    renderDiv = (
                        <ComboBox
                            key={id}
                            className={cn}
                            disable={!edit || isUpdating}
                            onSelect={(value: string) => onTmpUserChanged(currentUser.id || "", id, value)}
                            title={roleTitle}
                            items={roles}
                        />
                    );
                    break;
                case 'account_status':
                    // this may or may not belong here
                    const accountStatuses: Record<string, AccountStatusVM> = {
                        'Created': {
                            id: RegistrationStatusType.CREATED,
                            title: 'Created',
                        },
                        'Active': {
                            id: RegistrationStatusType.ACTIVE,
                            title: 'Active',
                        },
                        'Inactive': {
                            id: RegistrationStatusType.NONE,
                            title: 'Inactive',
                        },
                    }

                    let accountStatusId = editValue ? editValue : originalValue;
                    let accountStatusTitle = '';
                    if (accountStatuses && accountStatuses[accountStatusId]) {
                        accountStatusTitle = accountStatuses[accountStatusId].title;
                    } else {
                        accountStatusTitle = accountStatusId;
                    }

                    renderDiv = (
                        <ComboBox
                            key={id}
                            className={cn}
                            disable={!edit || isUpdating}
                            onSelect={(value: string) => onTmpUserChanged(currentUser.id || "", id, value)}
                            title={accountStatusTitle}
                            items={accountStatuses}
                        />
                    );
                    break;
                case 'approved_by':
                    let name = originalValue;
                    let approver = userLookup[name];
                    if (approver) {
                        if (approver.first_name) {
                            name = approver.first_name;
                        }
                        if (approver.last_name) {
                            name += ` ${approver.last_name}`;
                        }
                    }
                    renderDiv = (
                        <TextEdit
                            key={id}
                            className={cn + " info"}
                            disable={!readonly ? !edit || isUpdating : true}
                            placeholder={placeholder}
                            name={id}
                            dirty={!!editValue}
                            value={name}
                            edit={!readonly ? edit : false}
                            onSubmit={(name: string, value: string) => onTmpUserChanged(currentUser.id || "", name, value)}/>
                    )
                    break;
                case 'first_name':
                case 'last_name':
                case 'email_address':
                case 'phone_number':
                case 'dod_id':
                default:
                    renderDiv = (
                        <TextEdit
                            key={id}
                            className={cn + " info"}
                            disable={!readonly ? !edit || isUpdating : true}
                            placeholder={placeholder}
                            name={id}
                            dirty={!!editValue}
                            value={editValue ? editValue : originalValue}
                            edit={!readonly ? edit : false}
                            onSubmit={(name: string, value: string) => onTmpUserChanged(currentUser.id || "", name, value)}/>
                    );
                    break;
            }

            return renderDiv;
        });

        // this is a placeholder, obviously
        const themes: Record<string, any> = {
            "placeholder1": {
                title: "Placeholder 1"
            },
            "placeholder2": {
                title: "Placeholder 2"
            }, }

        return (
            <div
                className={cn}>
                <Card className={'w-100 d-flex flex-column v-gap-2'}
                      selected={true}
                    header={
                        <div className={'name w-100 d-flex flex-column v-gap-2 align-items-center'}>
                            <div className={'icon rounded-circle display-1 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
                                {initials}
                            </div>
                            <div className={`namerole d-flex align-items-center justify-content-center p-3`}>
                                <div className={'displayname header-1 font-weight-semi-bold pr-3'}>{display_name}</div>
                                <div className={'role header-1 font-weight-light pr-3'}>{roleTitle}</div>
                            </div>
                        </div>
                    }
                    body={
                        <div className={"d-flex flex-column v-gap-2 align-items-center overflow-hidden"}>
                            {
                                isUpdating &&
                                <LoadingIndicator size={Size.small} className={"loading position-absolute"}/>
                            }
                            {
                                !edit &&
                                <div className={"d-flex justify-content-end h-gap-2"}>
                                    <Button text={"Edit"} orientation={"horizontal"} onClick={() => onToggleEdit()} selected={false} disabled={isUpdating}
                                            className={"px-5 combo-box-button"}/>
                                </div>
                            }
                            {
                                edit &&
                                <div className={"d-flex h-gap-2 justify-content-end"}>
                                    {/*<Button text={"Cancel"} highlight={true} onClick={() => onToggleEdit()} selected={false} disabled={isUpdating} className={"px-5"}/>*/}
                                    <Button text={"Publish"} onClick={() => onUpdateUser()} selected={false} disabled={isUpdating}
                                            className={"px-5 combo-box-button"}/>
                                </div>
                            }

                            <div className={'profile-info-grid w-100 display-3 font-weight-normal align-items-center'}>
                                <div className={'align-self-center justify-self-end fields'}>First Name:</div>
                                <div className={'align-self-center justify-self-end fields'}>Last Name:</div>
                                <div className={'align-self-center justify-self-end fields'}>Department:</div>
                                <div className={'align-self-center justify-self-end fields'}>Role:</div>
                                <div className={'align-self-center justify-self-end fields'}>Email:</div>
                                <div className={'align-self-center justify-self-end fields'}>Phone:</div>
                                <div className={'align-self-center justify-self-end fields'}>Account Status:</div>
                                <div className={'align-self-center justify-self-end fields'}>Approved By:</div>
                                {editDivs}
                            </div>
                        </div>
                    }
                />

                {/*<Card className={'w-100 d-flex flex-column'}*/}
                {/*      header={*/}
                {/*    <div className={'section-header w-100 header-1 font-weight-bold d-flex align-items-center justify-content-start p-3 h-gap-2'}>*/}
                {/*          <SettingsSVG className={'settings-svg tiny-image-container'}></SettingsSVG>*/}
                {/*          <div>Customizations</div>*/}
                {/*    </div>*/}
                {/*}*/}
                {/*      body={*/}
                {/*    <div className={"py-4"}>*/}
                {/*        <div className={"header-1 font-weight-semi-bold"}>Appearance:</div>*/}
                {/*        <div className={"appearance"}>*/}
                {/*            <div className={'customizations-grid w-100 display-3 font-weight-normal align-items-center'}>*/}
                {/*                <div className={'align-self-center justify-self-end fields'}>Interface:</div>*/}
                {/*                <div className={'align-self-center justify-self-end fields'}>Font:</div>*/}
                {/*                <div className={'align-self-center justify-self-end fields'}>Size:</div>*/}
                {/*                {[*/}
                {/*                    <ComboBox*/}
                {/*                    // key={id}*/}
                {/*                    className={"w-100"}*/}
                {/*                    // disable={!edit || isUpdating}*/}
                {/*                    // onSelect={(value: string) => onTmpUserChanged(currentUser.id || "", id, value)}*/}
                {/*                    title={"Searcher"}*/}
                {/*                    items={themes}*/}
                {/*                    />,*/}
                {/*                    <ComboBox*/}
                {/*                        // key={id}*/}
                {/*                        className={"w-100"}*/}
                {/*                        // disable={!edit || isUpdating}*/}
                {/*                        // onSelect={(value: string) => onTmpUserChanged(currentUser.id || "", id, value)}*/}
                {/*                        title={"Searcher"}*/}
                {/*                        items={themes}*/}
                {/*                    />,*/}
                {/*                    <ComboBox*/}
                {/*                        // key={id}*/}
                {/*                        className={"w-100"}*/}
                {/*                        // disable={!edit || isUpdating}*/}
                {/*                        // onSelect={(value: string) => onTmpUserChanged(currentUser.id || "", id, value)}*/}
                {/*                        title={"Searcher"}*/}
                {/*                        items={themes}*/}
                {/*                    />,*/}
                {/*                ]}*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        <div className={"header-1 font-weight-semi-bold"}>Search Results:</div>*/}
                {/*        <div className={"search-results"}>*/}
                {/*            <div className={'customizations-grid w-100 display-3 font-weight-normal align-items-center'}>*/}
                {/*                <div className={'align-self-center justify-self-end fields'}>Graphs:</div>*/}
                {/*                <div className={'align-self-center justify-self-end fields'}>Recent:</div>*/}
                {/*                <div className={'align-self-center justify-self-end fields'}>Advanced:</div>*/}
                {/*                {[*/}
                {/*                    <ComboBox*/}
                {/*                        // key={id}*/}
                {/*                        className={"w-100"}*/}
                {/*                        // disable={!edit || isUpdating}*/}
                {/*                        // onSelect={(value: string) => onTmpUserChanged(currentUser.id || "", id, value)}*/}
                {/*                        title={"Searcher"}*/}
                {/*                        items={themes}*/}
                {/*                    />,*/}
                {/*                    <ComboBox*/}
                {/*                        // key={id}*/}
                {/*                        className={"w-100"}*/}
                {/*                        // disable={!edit || isUpdating}*/}
                {/*                        // onSelect={(value: string) => onTmpUserChanged(currentUser.id || "", id, value)}*/}
                {/*                        title={"Searcher"}*/}
                {/*                        items={themes}*/}
                {/*                    />,*/}
                {/*                    <ComboBox*/}
                {/*                        // key={id}*/}
                {/*                        className={"w-100"}*/}
                {/*                        // disable={!edit || isUpdating}*/}
                {/*                        // onSelect={(value: string) => onTmpUserChanged(currentUser.id || "", id, value)}*/}
                {/*                        title={"Searcher"}*/}
                {/*                        items={themes}*/}
                {/*                    />,*/}
                {/*                ]}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}

                {/*/>*/}

                {/*<div*/}
                {/*    className={'section-header settings-button w-100 header-1 font-weight-bold d-flex align-items-center justify-content-start p-3 h-gap-2'}*/}
                {/*    onClick={this._onShowHelpThree}*/}
                {/*>*/}
                {/*    <div className={'d-flex align-items-center header-2 rounded-circle justify-content-center'}>?</div>*/}
                {/*    <div>Help</div>*/}
                {/*</div>*/}

                {/*<div*/}
                {/*    className={'section-header logout-button w-100 header-1 font-weight-bold d-flex align-items-center justify-content-start p-3 h-gap-2'}*/}
                {/*    onClick={() => onLogout()}*/}
                {/*>*/}
                {/*    <LogoutSVG className={'tiny-image-container'}></LogoutSVG>*/}
                {/*    <div>Logout</div>*/}
                {/*</div>*/}

            </div>
        );
    }
}

export default SettingsPanelView;
