import React from 'react';
import {SVGModel} from '../../../framework.visual';

export const DocumentInfoSVG = ({className, fill, stroke} : SVGModel) => (
    // <svg className={className} viewBox="0 0 32 32">
    //     <path d="m 5.932592,31.95838 c -0.55846,-0.0939 -1.2032,-0.56571 -1.47826,-1.08178 -0.30176,-0.56616 -0.27915,0.63867 -0.27915,-14.87224 0,-15.3707497 -0.0185,-14.2917397 0.25457,-14.8306497 0.15412,-0.30416 0.57003,-0.71829 0.87819,-0.87445 0.57223,-0.28996 0.0646,-0.26866 6.78151,-0.28465 L 18.210672,1.029702e-5 18.562262,0.3533703 c 0.19337,0.19434 2.35664,2.36703 4.80726,4.82818 l 4.45568,4.47484 -5.4e-4,10.2326597 c -4.6e-4,8.5058 -0.011,10.27198 -0.0626,10.46561 -0.21561,0.80974 -0.93462,1.47551 -1.73506,1.6066 -0.32684,0.0535 -19.77553,0.0507 -20.09444,-0.003 z m 20.36067,-0.44408 c 0.52191,-0.21074 0.89741,-0.60682 1.07496,-1.13387 0.0937,-0.27822 0.0938,-0.28927 0.0938,-10.41112 V 9.8367003 l -0.0987,-0.10506 -0.0987,-0.10506 h -3.59133 c -3.08012,0 -3.62937,-0.01 -3.85849,-0.0696 -0.82927,-0.21599 -1.45447,-0.93137 -1.56614,-1.79203 -0.0251,-0.19316 -0.0447,-1.82825 -0.0455,-3.7863 -10e-4,-3.14193 -0.008,-3.45366 -0.0765,-3.52935 -0.072,-0.0796 -0.31366,-0.083 -5.88981,-0.0821 -3.50723,5.5e-4 -5.93535,0.0183 -6.11876,0.0449 -0.63393,0.0917 -1.14252,0.46364 -1.41211,1.03274 l -0.14495,0.30598 -0.0121,14.1173797 c -0.008,9.3079 0.003,14.19051 0.0334,14.33204 0.14032,0.66195 0.64586,1.19198 1.31301,1.37662 0.16698,0.0462 2.21824,0.0564 10.17303,0.0504 l 9.96387,-0.007 z M 22.786522,5.1099203 c -2.28433,-2.28443 -4.16868,-4.1535 -4.18746,-4.1535 -0.0514,0 -0.0399,6.41623 0.0122,6.77657 0.10046,0.69474 0.61667,1.29306 1.26688,1.46837 0.15527,0.0419 1.06984,0.0567 3.63459,0.059 l 3.42712,0.003 z"/>
    // </svg>
    <svg
        className={className}
        viewBox="0 0 32 32"
    >
        <defs
            id="defs973">
            <filter
                id="Path_29727"
                x="0"
                y="0.0020000001"
                width="82.914001"
                height="105.721"
                filterUnits="userSpaceOnUse">
                <feOffset
                    dy="3"
                    id="feOffset936" />
                <feGaussianBlur
                    stdDeviation="3"
                    result="blur"
                    id="feGaussianBlur938" />
                <feFlood
                    floodOpacity="0.188"
                    id="feFlood940" />
                <feComposite
                    operator="in"
                    in2="blur"
                    id="feComposite942"
                    result="result1" />
                <feComposite
                    in="SourceGraphic"
                    in2="result1"
                    id="feComposite944" />
            </filter>
            <filter
                id="Path_29724"
                x="0"
                y="0"
                width="82.914001"
                height="105.721"
                filterUnits="userSpaceOnUse">
                <feOffset
                    dy="3"
                    id="feOffset947" />
                <feGaussianBlur
                    stdDeviation="3"
                    result="blur-2"
                    id="feGaussianBlur949" />
                <feFlood
                    floodOpacity="0.188"
                    id="feFlood951" />
                <feComposite
                    operator="in"
                    in2="blur-2"
                    id="feComposite953"
                    result="result1" />
                <feComposite
                    in="SourceGraphic"
                    in2="result1"
                    id="feComposite955" />
            </filter>
            <clipPath
                id="clip-path">
                <g
                    filter="url(#Path_29727)"
                    id="g959">
                    <path
                        id="Path_29727-2"
                        data-name="Path 29727"
                        d="m 5.979,0 h 32.569 l 26.366,26.5 v 55.259 a 5.97,5.97 0 0 1 -5.979,5.962 H 5.979 A 5.97,5.97 0 0 1 0,81.759 V 5.962 A 5.97,5.97 0 0 1 5.979,0 Z"
                        fill={`${fill ? fill : "#f0f0f0"}`}
                        stroke={`${stroke ? stroke : "#bababa"}`}
                        strokeWidth="1" />
                </g>
            </clipPath>
            <filter
                id="Path_29725"
                x="36.549"
                y="-1.998"
                width="44.365002"
                height="44.365002"
                filterUnits="userSpaceOnUse">
                <feOffset
                    dx="-2"
                    dy="1"
                    id="feOffset962" />
                <feGaussianBlur
                    stdDeviation="3"
                    result="blur-3"
                    id="feGaussianBlur964" />
                <feFlood
                    floodOpacity="0.102"
                    id="feFlood966" />
                <feComposite
                    operator="in"
                    in2="blur-3"
                    id="feComposite968"
                    result="result1" />
                <feComposite
                    in="SourceGraphic"
                    in2="result1"
                    id="feComposite970" />
            </filter>
        </defs>
        <g
            id="Group_54030"
            data-name="Group 54030"
            transform="matrix(0.30268348,0,0,0.30268348,-192.98993,-258.83675)">
            <g
                transform="translate(649,855.14)"
                filter="url(#Path_29724)"
                id="g980">
                <g
                    id="Path_29724-2"
                    data-name="Path 29724"
                    transform="translate(9,6)"
                    fill={`${fill ? fill : "#f0f0f0"}`}>
                    <path
                        d="M 58.935032,87.220711 H 5.9789224 c -3.0210891,0 -5.47892234,-2.450073 -5.47892234,-5.461617 V 5.961607 c 0,-3.0115442 2.45783324,-5.46161099 5.47892234,-5.46161099 H 38.339909 L 64.413956,26.710911 v 55.048183 c 0,3.011544 -2.457833,5.461617 -5.478924,5.461617 z"
                        stroke="none"
                        id="path975" />
                    <path
                        d="M 5.97892,1 C 3.233532,1 1,3.2257614 1,5.9616013 V 81.759094 c 0,2.735848 2.233532,4.961609 4.97892,4.961609 h 52.956104 c 2.745388,0 4.978932,-2.225761 4.978932,-4.961609 V 26.917259 L 38.132027,1 H 5.97892 m 0,-1 h 32.568855 l 26.366181,26.504574 v 55.25452 c 0,3.292504 -2.676861,5.961609 -5.978932,5.961609 H 5.97892 C 2.6768494,87.720703 0,85.051598 0,81.759094 V 5.9616013 C 0,2.6690903 2.6768494,0 5.97892,0 Z"
                        stroke="none"
                        fill={`${stroke ? stroke : "#bababa"}`}
                        id="path977" />
                </g>
            </g>
            <g
                id="Mask_Group_23"
                transform="translate(658,861.139)"
                >
                <g
                    transform="translate(-9,-6)"
                    filter="url(#Path_29725)"
                    id="g988">
                    <g
                        id="Path_29725-2"
                        transform="translate(47.55,6)"
                        fill={`${fill ? fill : "#f0f0f0"}`}>
                        <path
                            d="M 25.158157,25.865055 H 5.8589535 c -2.9549401,0 -5.3589501,-2.403999 -5.3589501,-5.35891 V 1.2070892 Z"
                            stroke="none"
                            id="path983" />
                        <path
                            d="M 1.0000038,2.4141922 V 20.506145 c 0,2.679211 2.1797199,4.85891 4.8589497,4.85891 H 23.95104 L 1.0000038,2.4141922 M 3.8146973e-6,-3.8146973e-6 26.365263,26.365055 H 5.8589535 c -3.2358094,0 -5.8589496853027,-2.623119 -5.8589496853027,-5.85891 z"
                            stroke="none"
                            fill={`${stroke ? stroke : "#bababa"}`}
                            id="path985" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);


