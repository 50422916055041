import {RepoItem} from "../framework.core/services";
import {TagType} from "./tagType";

export class TagInfo extends RepoItem {
    public static class: string = 'TagInfo';

    title: string = "";
    user_id?: string = "";
    type: TagType = TagType.DRAFT;

    constructor(id: string) {
        super(id);
        this.appendClassName(TagInfo.class);
    }
}
