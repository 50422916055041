import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const TextFormatBoldSVGD = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
         <path d="m 1.7450016,31.487953 c 0,-0.570261 0.096491,-0.650516 0.7856512,-0.65345 0.6806506,-0.0029 1.7101663,-0.290028 2.1984041,-0.613127 0.2492659,-0.164958 0.598383,-0.546891 0.7758157,-0.848744 L 5.8274775,28.823811 5.8618633,16.288885 C 5.8867017,7.2344121 5.8602154,3.6262358 5.7664737,3.2939619 5.4175212,2.0570785 4.6047383,1.4993215 2.8087453,1.2642728 L 1.7450016,1.1250567 V 0.56252833 0 L 10.5137,0.04650812 c 7.60945,0.04035909 8.925628,0.07266476 9.955734,0.24436943 5.23643,0.87284075 7.948139,3.15338525 8.151686,6.85556105 0.140131,2.5487545 -0.695278,4.2228534 -2.825681,5.6624514 -1.02283,0.691169 -1.857311,1.084057 -3.005562,1.415071 -0.687831,0.198288 -0.869766,0.29735 -0.898408,0.489173 -0.02959,0.198179 0.05141,0.261833 0.459997,0.361479 2.775957,0.677001 5.007771,1.901985 6.266225,3.439361 1.838028,2.245409 2.158733,5.47072 0.808853,8.134583 -0.968627,1.911492 -3.247022,3.582337 -6.098931,4.472608 -2.565726,0.800932 -2.265136,0.780775 -12.411416,0.83228 L 1.7450016,32 Z M 18.304904,30.309684 c 1.817657,-0.543234 2.79892,-1.52673 3.390117,-3.397828 0.854124,-2.703249 0.515581,-6.582533 -0.737959,-8.456084 -0.560631,-0.83792 -0.919474,-1.180768 -1.770867,-1.691925 -0.905575,-0.543687 -2.385776,-0.879289 -4.242373,-0.96186 l -1.468889,-0.06533 6.9e-4,6.428576 c 4.6e-4,4.20197 0.04316,6.581705 0.123402,6.870682 0.180532,0.650155 0.746905,1.205611 1.428447,1.400911 0.715859,0.205135 2.381939,0.140502 3.277481,-0.127145 z M 17.097411,14.065099 c 1.618226,-0.43426 2.296583,-0.873354 2.901624,-1.878196 0.62061,-1.030695 0.862574,-2.183205 0.877141,-4.177969 0.02472,-3.3848414 -0.820098,-5.4065058 -2.598665,-6.2186625 -0.662406,-0.3024775 -0.83067,-0.3291229 -2.100094,-0.3325614 -1.520471,-0.00412 -1.912709,0.1233393 -2.402965,0.7808423 -0.237681,0.3187649 -0.24259,0.4309148 -0.273818,6.2563341 l -0.0318,5.9317525 1.411792,-0.07276 c 0.78182,-0.04029 1.770995,-0.169152 2.216785,-0.288782 z"/>
    </svg>
);


