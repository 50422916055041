import {createSelector, Selector} from "@reduxjs/toolkit";
import {Plugin} from "../../framework.core/extras/plugin";
import {Nullable} from "../../framework.core/extras/utils/typeUtils";
import {IEntityProvider} from "../../framework.core.api";
import {ITemplateService} from "../../app.core.api";
import {TemplateInfo} from "../../app.model";
import {forEach} from "../../framework.core/extras/utils/collectionUtils";

export class TemplateService extends Plugin implements ITemplateService {
    public static readonly class: string = 'TemplateService';

    private readonly getAllTemplatesSelector: Selector<any, Record<string, TemplateInfo>>;
    private templateProvider: Nullable<IEntityProvider<TemplateInfo>> = null;

    constructor() {
        super();
        this.appendClassName(TemplateService.class);

        this.getAllTemplatesSelector = createSelector(
            [() => super.getAll<TemplateInfo>(TemplateInfo.class)],
            (items) => {
                return items;
            }
        );
    }

    setTemplateProvider(provider: IEntityProvider<TemplateInfo>): void {
        this.templateProvider = provider;
    }

    start() {
        super.start();
    }

    stop() {
        super.stop();
    }

    configure() {
        super.configure();
    }

    getTemplates() {
        return this.getAllTemplatesSelector(super.getRepoState());
    }

    getTemplate(id: string): Nullable<TemplateInfo> {
        return super.getRepoItem<TemplateInfo>(TemplateInfo.class, id);
    }

    fetchTemplates(): Promise<void> {
        return new Promise<void> (() => {
            this.templateProvider?.getAll()
                .then(responseData => {
                    this.removeAllByType(TemplateInfo.class)
                    this.addOrUpdateAllRepoItems(responseData);

                    forEach(responseData, (template: TemplateInfo) => {
                        const { id } = template;
                        this.fetchTemplate(id);
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        });
    };

    fetchTemplate(id: string): Promise<void> {
        return new Promise<void> (() => {
            this.templateProvider?.getSingle(id)
                .then((template) => {
                    if (template) {
                        this.addOrUpdateRepoItem<TemplateInfo>(template);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }
}
