import React, {Component} from 'react';
import '../searchBanner.css';
import {SearchBannerViewProps} from "../searchBannerModel";
import Button from "../../../theme/widgets/button/button";
import ComboBox from "../../../theme/widgets/comboBox/comboBox";
import {ParamType} from "../../../../app.model";
import TextEdit from "../../../theme/widgets/textEdit/textEdit";
import {ArrowDownSVG} from "../../../theme/svgs/arrowDownSVG";
import {ArrowUpSVG} from "../../../theme/svgs/arrowUpSVG";
import SearchBox from "../../../theme/widgets/searchBox/searchBox";
import Portal from "../../../theme/widgets/portal/portal";
import {SortSVG} from "../../../theme/svgs/sortSVG";

class SearchBannerView extends Component<SearchBannerViewProps> {
    render() {
        const {
            className,
            onSearch,
            onSearchTextChanged,
            searchText,
            tools,
            onToolSelected,
            onClearSearch,
            searchParamsBasic,
            searchParamsAdvanced,
            onSearchParamChanged,
            showAdvanced,
            onDateChanged,
            onSetShowAdvanced,
            onSortSelected,
            selectedSort,
            sortTypes,
            ...rest
        } = this.props;

        let basicDivs = searchParamsBasic.map((item) => {
            let result;
            const {id, title='', value, options= null} = item;

            let cbTitle = title;
            let dirty = false;
            if (options && options[value]) {
                cbTitle = options[value].title;
                dirty = true;
            }

            let cbOptions = [
                // {
                //     id: 'all',
                //     title: 'All'
                // },
                ...Object.values(options)
            ]

            result = (
                <ComboBox key={id} className={dirty ? 'dirty rounded-lg' : 'rounded-lg'} light={true}
                          title={cbTitle || ''} items={cbOptions} multiSelect={true} selectedItemIds={value}
                          onSelect={(value: string | string[]) => onSearchParamChanged(id, value)}/>
            )
            return result;
        })

        let advancedDivs = searchParamsAdvanced.map((item) => {
            const {id, type, title='', dirty, value, options= null} = item;

            let cellRenderer = null;

            switch (type) {
                case ParamType.STRING: {
                    cellRenderer = (
                        <TextEdit dirty={dirty} edit={true} placeholder={title || ''} name={id} value={value} onSubmit={onSearchParamChanged}/>
                    )
                    break;
                }
                case ParamType.NUMBER: {
                    cellRenderer = (
                        <TextEdit dirty={dirty} edit={true} value={value} name={id} onSubmit={onSearchParamChanged}/>
                    )
                    break;
                }
                case ParamType.DATE_RANGE: {
                    const { start_date, end_date } = value || {};
                    cellRenderer = (
                        <div className={'d-flex h-gap-4 align-items-end'}>
                            <div className={'d-flex h-gap-2 align-items-center'}>
                                <TextEdit dirty={dirty} edit={true} type={'date'} value={start_date} onChange={(value) => onDateChanged(end_date, id, 'start_date', value)}/>
                            </div>
                            <div className={'d-flex align-items-end'}>
                                <div className={''}>to</div>
                            </div>
                            <div className={'d-flex h-gap-2 align-items-center'}>
                                <TextEdit dirty={dirty} edit={true} type={'date'} value={end_date} onChange={(value) => onDateChanged(start_date, id, 'end_date', value)}/>
                            </div>
                        </div>
                    )
                    break;
                }
                case ParamType.OPTIONS: {
                    let cbTitle = title;
                    let dirty = false;
                    if (options && options[value]) {
                        cbTitle = options[value].title;
                        dirty = true;
                    }

                    let cbOptions = {
                        // 'all': {
                        //     id: 'all',
                        //     title: 'All'
                        // },
                        ...options
                    }

                    cellRenderer = (
                        <ComboBox className={dirty ? 'dirty rounded-lg' : 'rounded-lg'} light={true}
                                  title={cbTitle || ''} items={cbOptions} multiSelect={true}
                                  onSelect={(value: string | string[]) => onSearchParamChanged(id, value)}/>
                    )
                    break;
                }
            }

            let cn = "d-flex justify-content-between p-2 align-items-stretch h-gap-3";

            return (
                <div key={id} className={cn}>
                    <div className={'d-flex align-items-end'}>
                        <div className={'font-weight-semi-bold p-3'} style={{minWidth: '15rem'}}>{title}</div>
                    </div>
                    {cellRenderer}
                </div>
            )
        })

        let cn = 'flex-fill search-banner d-flex flex-wrap align-items-end p-1 h-gap-2';
        if (className) {
            cn += ` ${className}`;
        }

        const minWidth = '35.5rem';
        const maxWidth = '40.4rem';

        let arrowSVG = <ArrowDownSVG className={''}/>;
        if (showAdvanced) {
            arrowSVG = <ArrowUpSVG className={''}/>;
        }

        let sortTitle = 'Sort';
        if (selectedSort) {
            const { title } = selectedSort;
            if (title) {
                sortTitle = title;
            }
        }

        return (
            <div className={cn} {...rest}>
                <SearchBox
                    style={{minWidth: minWidth, maxWidth: maxWidth}}
                    light={true}
                    onSearch={onSearch}
                    text={searchText}
                    onTextChange={onSearchTextChanged}
                />

                {basicDivs}

                <Portal
                    isOpen={showAdvanced}
                    zIndex={9999}
                    enterClass={'growVertical'}
                    exitClass={'shrinkVertical'}
                    timeout={200}
                    autoLayout={false}
                    onShouldClose={() => onSetShowAdvanced(false)}
                    portalContent={
                        ({}) =>
                            <div className={'portal position-absolute'}
                            >
                                <div className={'advanced d-flex flex-column v-gap-5 shadow'}>
                                    <div>
                                        {advancedDivs}
                                    </div>
                                    <div className={'d-flex flex-fill justify-content-end align-items-end'}>
                                        <div className={'d-flex flex-fill justify-content-end align-items-end footer p-4'}>
                                            <Button light={true} onClick={() => onSetShowAdvanced(false)}>Done</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }>
                    <Button className={`combo-box-button ${showAdvanced ? 'selected' : ''}`} orientation={"horizontal"} onClick={() => onSetShowAdvanced(!showAdvanced)}>
                        <div className={"flex-fill"}>More</div>
                        <div className={"d-flex align-items-center tiny-image-container combo-box-arrow pe-none"}>{arrowSVG}</div>
                    </Button>
                </Portal>

                <Button className={'combo-box-button'} onClick={onClearSearch}>
                    <div className={"header-3 flex-fill"}>Clear</div>
                </Button>

                <ComboBox
                    className={'sort rounded-lg'}
                    style={{minWidth:'23.5rem'}}
                    onSelect={onSortSelected}
                    title={sortTitle}
                    graphic={SortSVG}
                    items={sortTypes}
                    light={true}
                />
            </div>
        );
    }
}

export default SearchBannerView;
