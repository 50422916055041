import {Converter} from "../../../common/converters/converter";

export class LockRequestConverter extends Converter<any, any> {

    convert(fromData: any, reject?: any, options?: any): any {
        let serverObject: Record<string, string> = {};

        const { lock, duration } = fromData;

        serverObject["request"] = lock ? "Lock" : "Unlock";
        if (duration) {
            serverObject["retention_time"] = duration;
        }

        return serverObject;
    }
}
