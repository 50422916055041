import React, {Component} from 'react';
import './searchView.css';
import {SearchBannerWrapper} from "../../../../components/searchBanner/searchBannerWrapper";
import {SearchResultsPanelWrapper} from "../../../../components/searchResultsPanel/searchResultsPanelWrapper";
import {SearchGraphsPanelWrapper} from "../../../../components/searchGraphsPanel/searchGraphsPanelWrapper";
import {SearchViewProps} from "./searchModel";
import { SystemMenuWrapper } from '../../../../components/systemMenu/systemMenuWrapper';

export class SearchView extends Component<SearchViewProps> {
    render() {
        const { className, toolsVisible, permissions, ...rest } = this.props;

        let cn = 'd-flex flex-column h-100 search-view';
        if (className) {
            cn += ` ${className}`;
        }

        return (
            <div id={'search-view'} {...rest} className={cn}>
                {
                    permissions.canSearch ?
                        <SearchBannerWrapper />
                        :
                        <div className={"d-flex flex-fill align-items-center justify-content-center"}>
                            {
                                !permissions.isAuthorizing &&
                                <div className={'display-1 text-secondary'}>You do not have search permissions</div>
                            }
                        </div>
                }
                <SystemMenuWrapper/>

                <SearchGraphsPanelWrapper/>

                {
                    permissions.canSearch &&
                    <SearchResultsPanelWrapper/>
                }
            </div>
        );
    }
}

