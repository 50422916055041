import {MenuItemVM} from "../../../framework.visual";
import {MetaDataVM} from "../../../framework.visual";
import {Nullable} from "../../../framework.core/extras/utils/typeUtils";
import {ReferenceType, UserInfo} from "../../../app.model";
import React, {HTMLAttributes} from "react";
import {SuggestionItemVM} from "../../theme/widgets/autoComplete/autoCompleteModel";
import {PocketUpdateParams} from "../pocketManagerPanel/pocketManagerPanelModel";

export type SearchResultsPanelAppStateProps = HTMLAttributes<any> & {
    searchResults: DocumentInfoVM[];
    resultViews: Record<string, MenuItemVM>;
    selectedResultView: string;
    sortTypes: SortPropertyInfoVM[];
    selectedSort: Nullable<SortPropertyInfoVM>;
    userLookup?: Record<string, UserInfo>;
    selectedDocument?: DocumentInfoVM | undefined;
    permissions: PermissionsVM;
    pockets: Record<string, PocketVM>;
    selectedPocket: Nullable<PocketVM>;
    userSuggestionSupplier: (text:string) => Promise<UserItemVM[]>;
}

export type SearchResultsPanelAppDispatchProps = {
    onDocumentSelected: (id: string, object_type: ObjectType) => void;
    onResultViewSelected: (id: string) => void;
    onSortSelected: (id: string) => void;
    onDownload: (id: string, object_type: ObjectType) => void;
    onDelete: (id: string, object_type: ObjectType) => void;
    onAddToPocket: (id: string, object_type: ObjectType, pocketId: string) => void;
    onAddExcerptToReport: (event: React.DragEvent<HTMLDivElement>, id: string, resource_id: string) => void;
    onUpdatePocket: (edits: PocketUpdateParams) => void;
    onExcerptSelect: (excerpt_id: string) => void;
    onNoteSelect: (note_id: string) => void;
    onPocketSelect: (pocket_id: string) => void;
    onReportSelect: (report_id: string) => void;
    onResourceSelect: (document_id: string, resource_id: string) => void;
    onReturn: () => void;
}

export type SearchResultsPanelPresenterProps = SearchResultsPanelAppStateProps & SearchResultsPanelAppDispatchProps & MetaDataVM;

export type SearchResultsPanelPresenterState = {
    pageWidth: PageWidth;
    userInputExpanded: boolean;
}

export type SearchResultsPanelViewProps = HTMLAttributes<any> & {
    searchResults: DocumentInfoVM[];
    resultViews: Record<string, MenuItemVM>;
    selectedResultView: string;
    sortTypes: SortPropertyInfoVM[];
    selectedSort: Nullable<SortPropertyInfoVM>;
    userLookup?: Record<string, UserInfo>;
    selectedDocument?: DocumentInfoVM;
    isLoading?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    pageWidth: PageWidth;
    permissions: PermissionsVM;
    pockets: Record<string, PocketVM>;
    onDocumentSelected: (id: string, object_type: ObjectType) => void;
    onResultViewSelected: (id: string) => void;
    onSortSelected: (id: string) => void;
    onDownload: (id: string, object_type: ObjectType) => void;
    onCopy: (id: string, object_type: ObjectType) => void;
    onEdit: (id: string, object_type: ObjectType) => void;
    onShare: (id: string, object_type: ObjectType) => void;
    onDelete: (id: string, object_type: ObjectType) => void;
    onAddToPocket: (id: string, object_type: ObjectType, pocketId: string) => void;
    selectedPocket: Nullable<PocketVM>;
    onAddExcerptToReport: (event: React.DragEvent<HTMLDivElement>, id: string, resource_id: string) => void;
    onAddUser: (name: string, value: string) => void;
    onExcerptSelect: (excerpt_id: string) => void;
    onNoteSelect: (note_id: string) => void;
    onPocketSelect: (pocket_id: string) => void;
    onReportSelect: (report_id: string) => void;
    onResourceSelect: (document_id: string, resource_id: string) => void;
    onToggleUserInputExpanded: () => void;
    onReturn: () => void;
    userInputExpanded: boolean;
    userSuggestionSupplier: (text:string) => Promise<UserItemVM[]>;
}

export type SearchResultsPanelViewState = {
    showAddToPocket: boolean;
}

export type ResultsRendererProps = {
    className?: string;
    pageWidth: PageWidth;
    searchResults: DocumentInfoVM[];
    onDocumentSelected: (id: Nullable<string>, object_type: ObjectType) => void;
    userLookup?: Record<string, UserInfo>;
}

export type ResultsRendererState = {
    renderTrigger: number;
}

export type CardCollectionRendererProps = ResultsRendererProps & {

}

export type ListCollectionRendererProps = ResultsRendererProps & {

}

export type TableCollectionRendererProps = ResultsRendererProps & {

}

export type CardCollectionRendererState = ResultsRendererState & {

}

export type ListCollectionRendererState = ResultsRendererState & {

}

export type TableCollectionRendererState = ResultsRendererState & {
    columnWidths: string[];
}

export type SelectedPocketViewProps = HTMLAttributes<any> & {
    onAddExcerptToReport: (event: React.DragEvent<HTMLDivElement>, id: string, resource_id: string) => void;
    onAddUser: (name: string, value: string) => void;
    onExcerptSelect: (excerpt_id: string) => void;
    onNoteSelect: (note_id: string) => void;
    onReportSelect: (report_id: string) => void;
    onResourceSelect: (document_id: string, resource_id: string) => void;
    onReturn: () => void;
    onToggleUserInputExpanded: () => void;
    selectedPocket: Nullable<PocketVM>;
    userInputExpanded: boolean;
    userSuggestionSupplier: (text:string) => Promise<UserItemVM[]>;
}

export enum PageWidth {
    FULL='FULL',
    ONE_HALF='ONE_HALF',
    ONE_THIRD='ONE_THIRD',
}

export type ReferenceInfoVM = {
    id: string;
    title: string;
    type: ReferenceType;
    className: string;
}

export type DocumentInfoVM = {
    [key: string]: any;
    id: string;
    author?: string;
    department?: string;
    file_name?: string;
    file_size?: string;
    type?: string;
    page_count?: string;
    primary_sme_email?: string;
    primary_sme_name?: string;
    primary_sme_phone?: string;
    private_tag?: Record<string, TagInfoVM>,
    project?: string,
    public_tag?: Record<string, TagInfoVM>,
    publication_date?: string,
    purpose?: string;
    secondary_sme_email?: string;
    secondary_sme_name?: string;
    secondary_sme_phone?: string;
    status?: string;
    title?: string;
    upload_date?: string;
    uploadedBy_id?: string;
    preview_url?: string;
    original_url?: string
    isUpdating?: boolean;
    isPending?: boolean;
    selected?: boolean;
    scope?: string;
    object_type: ObjectType;
    editor?: string;
    // edit_duration?: string;
    isOwned?: boolean;
    // isShared?: boolean;
}

export enum ObjectType {
    DocumentInfo,
    PocketInfo,
    ReportInfo
}

export type SortPropertyInfoVM = {
    id?: string;
    title?: Nullable<string>;
    value?: Nullable<any>;
    selected?: boolean;
}

export type PermissionsVM = {
    canDelete: boolean,
    canDownload: boolean,
    canModify: boolean
}

export type PocketVM = {
    id?: string;
    title?: string;
    upload_date?: string;
    isUpdating?: boolean;
    author?: string;
    shared_authors?: Record<string, string>;
    pocketContent?: Record<string, PocketContentNode>;
    readonly?: boolean;
    editor?: string;
    edit_duration?: string;
    isOwned?: boolean;
    isShared?: boolean;
}

export type PocketContentNode = {
    id: string;
    title: string;
    author: string;
    type: PocketContentNodeType;
    upload_date?: string;
    selected: boolean;
    resource_id?: string;
    source_id?: string;
    children?: Record<string, PocketContentNode>;
}

export enum PocketContentNodeType {
    RESOURCE='RESOURCE',
    REPORT='REPORT',
    EXCERPT='EXCERPT',
    NOTE='NOTE'
}

export type TagInfoVM = {
    id: string,
    title: string,
    isRecommended: boolean,
    isGlobal: boolean,
}

export type UserItemVM = SuggestionItemVM;
