import React, {Component} from 'react';
import './systemBanner.css';
import Button from "../../theme/widgets/button/button";
import {bindInstanceMethods} from "../../../framework.core/extras/utils/typeUtils";
import {SystemBannerProps, SystemBannerState} from "./systemBannerModel";
import {isDev} from "../../../framework.core/extras/utils/environmentUtils";
import {LogoutSVG} from "../../theme/svgs/logoutSVG";



class SystemBannerView extends Component<SystemBannerProps, SystemBannerState> {


    constructor(props: any, context: any) {
        super(props, context);

        bindInstanceMethods(this);
    }

    _onReturnHome() {
        const { onReturnHome } = this.props;

        if (onReturnHome) {
            onReturnHome();
        }
    }

    _onLogout() {
        const { onLogout } = this.props;

        if (onLogout) {
            onLogout();
        }
    }

    _onShowHelp() {
        const { onShowHelp } = this.props;
        if (onShowHelp) {
            onShowHelp();
        }
    }

     _onShowHelpTwo() {
        if (isDev()) {
            window.open(`/UserGuide/Content/Project%20Description/Overview.htm`);
        } else {
            window.open(`UserGuide/Content/Project%20Description/Overview.htm`);
        }
        //window.location.href = `${process.env.REACT_APP_USERGUIDE_URL}/UserGuide/Content/Project%20Description/Overview.htm`

     }

    render() {
        const {className, onLogout, onShowHelp, userName, role, isLoggedIn, onReturnHome, toggleSettingsPanel, ...rest} = this.props;

        let cn = 'system-banner w-100 d-flex p-3';
        if (className) {
            cn += ` ${className}`;
        }

        return (
            <div className={cn} {...rest}>
                <div className={"d-flex flex-fill flex-basis-0 align-items-center h-gap-3 mr-5"}>
                    <Button className={"btn-transparent"} onClick={this._onReturnHome}>
                        <div className={"bumed-logo"}/>
                    </Button>
                    <div className={"display-1 title font-weight-bold"}>CIC</div>
                    <div className={"header-1 pt-1 font-weight-semi-bold text-nowrap text hidden-second"}>Consolidated Information Center</div>
                    <div className={"header-1 pt-1 text-nowrap text hidden-first"}>|</div>
                    <div className={"header-1 system-banner-text-accent pt-1 text-nowrap hidden-first"}>Knowledge Management</div>


                </div>
                <div className={"flex-fill flex-basis-0"}/>
                <div className={"d-flex flex-fill flex-shrink-0 align-self-stretch justify-content-end"}>
                    <div className={`d-flex align-items-center h-gap-5 mr-3`}>
                        <Button className={"help-button rounded-circle"} onClick={this._onShowHelpTwo} text={"?"}/>
                        <div className={"header-1 font-weight-semi-bold title cursor-pointer"} onClick={toggleSettingsPanel}>{userName}</div>
                        <div className={"header-1 font-weight-light text-info"}>{role}</div>
                        {/*{*/}
                        {/*    isLoggedIn && (*/}
                        {/*    <Button onClick={this._onLogout}>Logout</Button>*/}

                        {/*    )*/}
                        {/*}*/}
                        {
                            isLoggedIn &&
                            <div
                                className={'logout-button header-1 font-weight-bold d-flex align-items-center justify-content-start p-3 h-gap-2'}
                                onClick={() => onLogout()}
                            >
                                <LogoutSVG className={'tiny-image-container'}></LogoutSVG>
                                <div>Logout</div>
                            </div>
                        }


                    </div>
                </div>
            </div>
        );
    }
}

export default SystemBannerView;
