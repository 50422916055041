import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const ProfileSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d={"M 13.389828,31.968485 C 10.231734,31.884858 7.6002671,31.688095 4.999796,31.341134 3.9871823,31.20603 1.9416172,30.820879 1.2312034,30.631563 L 0.897205,30.542557 1.180898,30.103629 c 0.1560308,-0.24141 0.5097833,-0.664531 0.7861157,-0.940269 1.2381086,-1.235439 2.9183658,-2.124777 5.9817418,-3.166055 0.7775387,-0.264294 1.620435,-0.581332 1.8731029,-0.704527 0.9298796,-0.453392 1.6705066,-1.260808 2.0328306,-2.21615 0.144185,-0.380171 0.454713,-1.527521 0.456524,-1.686779 2.78e-4,-0.02357 -0.195141,-0.113027 -0.434242,-0.198802 C 10.682903,20.762688 9.1704251,19.798231 8.1841852,18.83628 4.842904,15.577284 3.9939752,10.401614 6.0886931,6.0606821 7.4880743,3.160712 10.048296,1.1163488 13.285279,0.3141335 c 1.716794,-0.42546883 3.785962,-0.41835736 5.529966,0.0190064 3.971134,0.9958881 7.031536,4.1256288 7.932292,8.112013 0.847559,3.7509521 -0.213146,7.4316861 -2.938001,10.1951061 -0.93384,0.947056 -1.904799,1.644402 -3.097355,2.224532 l -1.024444,0.498349 0.04244,0.243448 c 0.261375,1.499478 0.830376,2.57225 1.722206,3.246982 0.53432,0.404252 0.87465,0.555489 2.510056,1.115436 3.161861,1.082588 4.785533,1.938388 6.047442,3.187465 0.272205,0.269438 0.629467,0.691375 0.793915,0.93764 l 0.298999,0.447752 -0.614022,0.154691 c -3.633475,0.915379 -10.703403,1.441287 -17.098941,1.271931 z"} />
    </svg>
);


