import {forEachKVP} from "../../../framework.core/extras/utils/collectionUtils";
import {IConverter} from "../../../framework.core.api";
import {Nullable} from "../../../framework.core/extras/utils/typeUtils";
import {Converter} from "../../common/converters/converter";
import {TemplateInfo} from "../../../app.model";

export class GetTemplateArrayResponseConverter extends Converter<any,TemplateInfo[]>{

    private _singleConverter!: IConverter<any, Nullable<TemplateInfo>>;

    convert(fromData: any, reject: any): TemplateInfo[] {

        const result: TemplateInfo[] = [];

        if (Array.isArray(fromData)) {
            forEachKVP(fromData, (itemKey:string, itemValue: string[]) => {
                let templateInfo = this._singleConverter.convert(itemValue, reject);

                if (templateInfo != null) {
                    result.push(templateInfo);
                }
            });
        }
        else {
            reject('Error while parsing array of templates. Expected Array. Receive the following: <' + fromData + '>');
        }

        return result;
    }

    get singleConverter(): IConverter<any, Nullable<TemplateInfo>> {
        return this._singleConverter;
    }

    set singleConverter(value: IConverter<any, Nullable<TemplateInfo>>) {
        this._singleConverter = value;
    }
}
