import {Component} from "react";
import {bindInstanceMethods} from "../../../../framework.core/extras/utils/typeUtils";
import ProfilePanelView from "../views/profilePanelView";
import {
    ProfilePanelPresenterProps,
    ProfilePanelPresenterState, TMP_USER_ID,
    UserInfoVM, UserUpdateParams
} from "../profilePanelModel";
import {arrayEquals, forEach, forEachKVP} from "../../../../framework.core/extras/utils/collectionUtils";
import {userService} from "../../../../serviceComposition";

class ProfilePanelPresenter extends Component<ProfilePanelPresenterProps, ProfilePanelPresenterState> {
    interval!: NodeJS.Timer;

    constructor(props: ProfilePanelPresenterProps, context: any) {
        super(props, context);

        this.state = {
            currentUserEditProperties: [
                {
                    id: 'first_name',
                    placeholder: 'First Name',
                },
                {
                    id: 'last_name',
                    placeholder: 'Last Name',
                },
                {
                    id: 'department',
                    placeholder: 'Department'
                },
                {
                    id: 'role',
                    placeholder: 'Role',
                    readonly: true,
                },
                {
                    id: 'email_address',
                    placeholder: 'Email',
                },
                {
                    id: 'phone_number',
                    placeholder: 'Phone',
                },
                {
                    id: 'account_status',
                    placeholder: 'Account Status',
                    readonly: true
                },
            ],
            editProfiles: {},
            editProperties: [
                {
                    id: 'first_name',
                    placeholder: 'First Name',
                },
                {
                    id: 'last_name',
                    placeholder: 'Last Name',
                },
                {
                    id: 'department',
                    placeholder: 'Department',
                },
                {
                    id: 'role',
                    placeholder: 'Role',
                },
                {
                    id: 'email_address',
                    placeholder: 'Email',
                },
                {
                    id: 'phone_number',
                    placeholder: 'Phone',
                },
                {
                    id: 'account_status',
                    placeholder: 'Account Status',
                },
                {
                    id: 'approved_by',
                    placeholder: 'Approved By',
                    readonly: true,
                }
            ],
            isAddNewUser: false,
            newUserEditProperties: [
                {
                    id: 'dod_id',
                    placeholder: 'DoD ID',
                },
                {
                    id: 'first_name',
                    placeholder: 'First Name',
                },
                {
                    id: 'last_name',
                    placeholder: 'Last Name',
                },
                {
                    id: 'email_address',
                    placeholder: 'Email',
                },
                {
                    id: 'phone_number',
                    placeholder: 'Phone',
                },
                {
                    id: 'department',
                    placeholder: 'Department'
                },
                {
                    id: 'registration_reason',
                    placeholder: 'Please provide organizational affiliation and reason for accessing the CIC Knowledge Management System',
                },
            ],
            newUserErrorMessages: {},
            openProfiles: {},
            showPopup: false,
            tmpUsers: {},
            selectedUserId: '',
        }

        bindInstanceMethods(this);
    }

    componentDidMount() {
        const { currentUser, permissions } = this.props;

        if (currentUser) {
            const { id } = currentUser;

            this._onTmpUsersChanged(id || "", "id", id || "");
        }

        if (permissions.canModify) {
            this.interval = setInterval(() => {
                userService.fetchUsers();
            }, 60000); // refresh every 60 seconds
        }

        userService.fetchUsers();
    }

    componentDidUpdate(prevProps: Readonly<ProfilePanelPresenterProps>, prevState: Readonly<ProfilePanelPresenterState>, snapshot?: any) {
        const { currentUser } = this.props;

        if (currentUser && prevProps.currentUser) {
            if (currentUser.id !== "" && prevProps.currentUser.id !== "") {
                if (currentUser !== prevProps.currentUser) {
                    this._refreshDirtyFlag();

                    const {id} = currentUser;
                    const {id: prevId } = prevProps.currentUser;

                    if (id !== prevId) {
                        this._onTmpUsersChanged(id || "", "id", id || "");
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    _refreshDirtyFlag() {
        const { currentUser } = this.props;
        const { tmpUsers } = this.state;

        let tmpCurrentUser: UserInfoVM = tmpUsers[currentUser.id || ""];

        if (tmpCurrentUser) {
            let nextTmpCurrentUser = {
                ...tmpCurrentUser
            };
            let dirty = false;

            let keysToDelete = [];
            let itemKeys = Object.keys(nextTmpCurrentUser), itemsLength = itemKeys.length;
            for (let index = 0; index < itemsLength; index++) {
                let key = itemKeys[index];

                if (tmpCurrentUser) {
                    if (Array.isArray(tmpCurrentUser[key])) {
                        if (arrayEquals(tmpCurrentUser[key], currentUser[key])) {
                            keysToDelete.push(key)
                        }
                        else {
                            dirty = true;
                        }
                    }
                    else {
                        if (tmpCurrentUser[key] === currentUser[key]) {
                            keysToDelete.push(key);
                        }
                        else {
                            dirty = true;
                        }
                    }
                }
            }

            forEach(keysToDelete, (key: string) => {
                if (key !== 'id') {
                    delete nextTmpCurrentUser[key];
                }
            });

            let tmpUsersCopy: Record<string, UserInfoVM> = {};
            forEachKVP(tmpUsers, (itemKey: string, itemValue: UserInfoVM) => {
                tmpUsersCopy[itemKey] = itemValue;
            });

            tmpUsersCopy[currentUser.id || ""] = nextTmpCurrentUser;

            this.setState({
                ...this.state,
                tmpUsers: tmpUsersCopy,
            })
        }
    }

    _onTmpUsersChanged(id: string, name: string, value: string) {
        const {tmpUsers} = this.state;

        console.log(id + " " + name + " " + value)

        let tmpUsersCopy: Record<string, UserInfoVM> = {};

        if (!tmpUsers[id]) {
            tmpUsersCopy[id] = {
                id,
                [name]: value,
            }
        } else {
            forEachKVP(tmpUsers, (itemKey: string, itemValue: UserInfoVM) => {
                const {id: tmpUserId} = itemValue;
                if (!tmpUserId) return;

                if (tmpUserId === id) {
                    tmpUsersCopy[id] = {
                        ...tmpUsers[id],
                        [name]: value,
                    }
                } else {
                    tmpUsersCopy[tmpUserId] = tmpUsers[tmpUserId];
                }
            })
        }

        this.setState({
            ...this.state,
            tmpUsers: tmpUsersCopy,
        }, () => this._refreshDirtyFlag())
    }

    _onUpdateUser(id: string) {
        const { onUpdateUser } = this.props;
        const { tmpUsers } = this.state;

        let updatedUser: UserUpdateParams = {};

        if (tmpUsers[id]) {
            forEachKVP(tmpUsers[id], (itemKey: keyof UserUpdateParams, itemValue: any) => {
                updatedUser[itemKey] = itemValue;
            })
        }

        if (onUpdateUser) {
            onUpdateUser({...updatedUser});
        }

        this._onManageEditProfiles(id)
    }

    _onRemoveUser(id: string) {
        const { onRemoveUser } = this.props;

        if (onRemoveUser) {
            onRemoveUser(id);
        }

        this._onToggleShowPopup();

        this._refreshState(id);
    }

    _onToggleIsAddNewUser() {
        const { isAddNewUser } = this.state;

        this.setState({
            ...this.state,
            selectedUserId: '',
            isAddNewUser: !isAddNewUser,
        })
    }

    _onAcceptUserRequest(id: string) {
        const { onAcceptUserRequest } = this.props;
        const { tmpUsers } = this.state;

        if (!tmpUsers) return;
        if (tmpUsers[id]) {
            const { role } = tmpUsers[id];
            if (role) {
                if (onAcceptUserRequest) {
                    onAcceptUserRequest(id, role);
                }
            }
        }
    }

    _onDeclineUserRequest(id: string) {
        const { onDeclineUserRequest } = this.props;

        if (onDeclineUserRequest) {
            onDeclineUserRequest(id);
        }

        this._refreshState(id);
    }

    _refreshState(id: string) {
        const { tmpUsers, editProfiles } = this.state;

        let tmpUsersCopy: Record<string, UserInfoVM> = {};

        forEachKVP(tmpUsers, (itemKey: string, itemValue: UserInfoVM) => {
            const { id:tmpUserId } = itemValue;

            if (!tmpUserId) return;

            if (tmpUserId !== id) {
                tmpUsersCopy[tmpUserId] = tmpUsers[tmpUserId];
            }
        });

        let editProfilesCopy: Record<string, boolean> = {};

        forEachKVP(editProfiles, (itemKey: string, itemValue: boolean) => {
            if (itemKey === id) {
                editProfilesCopy[itemKey] = false;
            } else {
                editProfilesCopy[itemKey] = itemValue;
            }
        });

        this.setState({
            ...this.state,
            tmpUsers: tmpUsersCopy,
            editProfiles: editProfilesCopy,
        })
    }

    _onManageOpenProfiles(id: string) {
        const { openProfiles, selectedUserId } = this.state;

        let openProfilesCopy: Record<string, boolean> = {};

        if (openProfiles[id] === undefined) {
            openProfilesCopy[id] = true;
        }

        forEachKVP(openProfiles, (itemKey: string, itemValue: boolean) => {
            if (itemKey === id) {
                openProfilesCopy[itemKey] = !itemValue;
            } else {
                openProfilesCopy[itemKey] = itemValue;
            }
        });

        this.setState({
            ...this.state,
            selectedUserId: id === selectedUserId ? '' : id,
            openProfiles: openProfilesCopy,
        });
    }

    _onManageEditProfiles(id: string) {
        const { editProfiles } = this.state;

        let editProfilesCopy: Record<string, boolean> = {};

        if (editProfiles[id] === undefined) {
            editProfilesCopy[id] = true;
        }

        forEachKVP(editProfiles, (itemKey: string, itemValue: boolean) => {
            if (itemKey === id) {
                editProfilesCopy[itemKey] = !itemValue;
            } else {
                editProfilesCopy[itemKey] = itemValue;
            }
        });

        this.setState({
            ...this.state,
            editProfiles: editProfilesCopy,
        })
    }

    _onAddNewUser() {
        const { onAddNewUser } = this.props;
        const { tmpUsers } = this.state;

        const { dod_id, first_name, last_name, email_address, phone_number } = tmpUsers[TMP_USER_ID];

        let pass = true;

        let focus = false;

        let errorMessages: Record<string, string> = {}

        if (dod_id && !/^\d{10}/im.test(dod_id)) {
            pass = false;
            this._setFocus("dod_id");
            focus = true;
            errorMessages["dod_id"] = "Dod ID must be a 10-digit number";
        }

        if (first_name && !/\w/.test(first_name)) {
            pass = false;
            if (!focus) {
                this._setFocus("first_name");
                focus = true;
            }
            errorMessages["first_name"] = "First Name cannot be empty";
        }

        if (last_name && !/\w/.test(last_name)) {
            pass = false;
            if (!focus) {
                this._setFocus("last_name");
                focus = true;
            }
            errorMessages["last_name"] = "Last Name cannot be empty";
        }

        if (email_address && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/.test(email_address)) {
            pass = false;
            if (!focus) {
                this._setFocus("email_address");
                focus = true;
            }
            errorMessages["email_address"] = "Email must be a valid email address";
        }

        if (phone_number && !/^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/im.test(phone_number)) {
            pass = false;
            if (!focus) {
                this._setFocus("phone_number");
            }
            errorMessages["phone_number"] = "Phone must be a valid phone number";
        }

        if (pass) {

            let newUser: UserUpdateParams = {};

            if (tmpUsers[TMP_USER_ID]) {
                forEachKVP(tmpUsers[TMP_USER_ID], (itemKey: keyof UserUpdateParams, itemValue: any) => {
                    newUser[itemKey] = itemValue;
                })
            }

            if (onAddNewUser) {
                onAddNewUser({...newUser});//TODO fix this
            }

            let tmpUsersCopy: Record<string, UserInfoVM> = {};
            forEachKVP(tmpUsers, (itemKey: string, itemValue: UserInfoVM) => {
                if (itemKey === "-1") {
                    tmpUsersCopy[itemKey] = {};
                } else {
                    tmpUsersCopy[itemKey] = itemValue;
                }
            });
            this.setState({
                ...this.state,
                tmpUsers: tmpUsersCopy,
                isAddNewUser: false,
            });
        } else {
            this._setErrorMessages(errorMessages);
        }
    }

    _setErrorMessages(newUserErrorMessages: Record<string, string>) {
        this.setState({
            ...this.state,
            newUserErrorMessages,
        })
    }

    _setFocus(id: string) {
        document.getElementById(id)?.focus();
    }

    _onToggleShowPopup() {
        const { showPopup } = this.state;

        this.setState({
            ...this.state,
            showPopup: !showPopup
        })
    }

    render() {
        const {
            accountStatuses,
            currentUser,
            departments,
            onClearSearch,
            onSearch,
            onSearchTextChanged,
            permissions,
            roles,
            searchText,
            userRequests,
            users,
        } = this.props;

        const {
            currentUserEditProperties,
            editProfiles,
            editProperties,
            isAddNewUser,
            newUserEditProperties,
            newUserErrorMessages,
            openProfiles,
            selectedUserId,
            showPopup,
            tmpUsers,
        } = this.state;

        return (
            <ProfilePanelView
                accountStatuses={accountStatuses}
                currentUser={currentUser}
                currentUserEditProperties={currentUserEditProperties}
                departments={departments}
                editProfiles={editProfiles}
                editProperties={editProperties}
                isAddNewUser={isAddNewUser}
                newUserEditProperties={newUserEditProperties}
                newUserErrorMessages={newUserErrorMessages}
                onAcceptUserRequest={this._onAcceptUserRequest}
                onAddNewUser={this._onAddNewUser}
                onClearSearch={onClearSearch}
                onDeclineUserRequest={this._onDeclineUserRequest}
                onManageEditProfiles={this._onManageEditProfiles}
                onManageOpenProfiles={this._onManageOpenProfiles}
                onRemoveUser={this._onRemoveUser}
                onSearch={onSearch}
                onSearchTextChanged={onSearchTextChanged}
                onTmpUsersChanged={this._onTmpUsersChanged}
                onToggleIsAddNewUser={this._onToggleIsAddNewUser}
                onToggleShowPopup={this._onToggleShowPopup}
                onUpdateUser={this._onUpdateUser}
                openProfiles={openProfiles}
                permissions={permissions}
                roles={roles}
                searchText={searchText}
                selectedUserId={selectedUserId}
                showPopup={showPopup}
                tmpUsers={tmpUsers}
                userRequests={userRequests}
                users={users}
            />
        );
    }
}

export default ProfilePanelPresenter;
