import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const TextFormatHighlightSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="M 2.8800231,30.222059 C 1.3587156,29.728149 0.06517704,29.30581 0.00549338,29.283527 -0.06640069,29.256684 0.56778169,28.549773 1.8846869,27.188822 l 1.9877089,-2.05419 1.9555247,1.955524 1.9555243,1.955524 -0.9918705,1.043313 C 6.2460458,30.662814 5.765129,31.129553 5.7228704,31.12619 5.6806119,31.122825 4.4013306,30.715968 2.8800231,30.222059 Z M 7.2014438,25.697298 4.3784218,22.873503 5.8845047,21.367419 7.3905876,19.861336 6.9793144,18.524885 c -0.344908,-1.120793 -0.4108659,-1.429438 -0.4087499,-1.912703 0.00433,-0.987943 0.086456,-1.085147 2.978502,-3.525152 0.1285536,-0.108461 0.4937715,0.238985 5.2439005,4.98871 2.808148,2.80791 5.105723,5.126403 5.105723,5.152206 0,0.11997 -2.205772,2.590461 -2.479805,2.777411 -0.718328,0.490054 -1.276998,0.473826 -3.082858,-0.08955 l -1.298393,-0.405058 -1.506583,1.505171 -1.506585,1.505172 z m 8.9721282,-9.006966 c -4.330288,-4.330672 -4.977451,-5.007034 -4.878774,-5.098892 0.06257,-0.05825 2.10184,-1.7993173 4.531706,-3.8690413 2.429866,-2.069725 5.092069,-4.3414896 5.916006,-5.0483667 1.807492,-1.5506925 2.146243,-1.74149983 3.185539,-1.79431913 0.819642,-0.041655 1.465921,0.13562683 2.038665,0.55923113 0.190536,0.1409207 1.286724,1.2016777 2.435973,2.3572375 1.877826,1.888137 2.115268,2.1553439 2.343428,2.6371934 C 31.985523,6.9389776 32,7.0177429 32,7.8147214 c 0,0.787504 -0.01631,0.8792465 -0.239004,1.3445903 -0.189838,0.396682 -1.293016,1.7322563 -5.362738,6.4924483 -2.818055,3.296166 -5.148127,6.001699 -5.177939,6.012294 -0.02981,0.0106 -2.300849,-2.22758 -5.046747,-4.973722 z"/>
    </svg>
);


