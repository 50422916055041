import {Converter} from "../../../common/converters/converter";

export class GetPocketArrayRequestConverter extends Converter<any, any> {
    convert(uiRequestData?: any): any {
        const { user_id, searchText } = uiRequestData;

        const result: Record<string, any> = {};

        result["kp_type"] = ['Pocket'];
        result["user_id"] = user_id;
        result ["search_request"] = searchText;

        return result;
    }
}
