import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const PocketSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 13.705575,30.970688 c -1.24018,-0.5643 -4.54683,-2.06127 -7.34811,-3.32661 l -5.09323,-2.3006 V 12.671738 -1.9319704e-6 h 14.73576 14.73577 V 12.673288 25.346578 l -0.24725,0.11023 c -0.23044,0.10274 -3.72894,1.68497 -11.31636,5.11796 -1.73651,0.78569 -3.16954,1.42705 -3.18451,1.42523 -0.015,-0.002 -1.0419,-0.46501 -2.28207,-1.02931 z m 5.39981,-4.2562 c 1.53391,-0.69434 3.98589,-1.80443 5.44886,-2.46688 l 2.65995,-1.20444 4e-4,-5.86463 4.1e-4,-5.86464 H 16.019775 4.824555 v 5.86933 5.86933 l 2.14608,0.97373 c 1.18035,0.53555 3.69482,1.67447 5.58772,2.53092 l 3.44164,1.55719 0.15824,-0.0687 c 0.087,-0.0378 1.41325,-0.63683 2.94715,-1.33118 z m 8.10007,-21.0674299 -0.0102,-2.10652 -11.18534,-0.01 -11.18533,-0.01 v 2.11648 2.11648 h 11.19557 11.19557 z"/>
    </svg>
);


