import React, {HTMLAttributes} from "react";
import {Nullable} from "../../../framework.core/extras/utils/typeUtils";
import {NoteInfo, PocketInfo} from "../../../app.model";
import {SuggestionItemVM} from "../../theme/widgets/autoComplete/autoCompleteModel";

export type PocketManagerPanelAppStateProps = HTMLAttributes<any> & {
    ownedPockets: Record<string, PocketVM>;
    permissions: PermissionsVM;
    selectedPocket: Nullable<PocketVM>;
    sharedPockets: Record<string, PocketVM>;
    selectedReportId: Nullable<string>;
    selectedResourceId: Nullable<string>;
    selectedExcerptId: Nullable<string>;
    selectedNoteId: Nullable<string>;
    userSuggestionSupplier: (text:string) => Promise<UserItemVM[]>;
}

export type PocketManagerPanelAppDispatchProps = {
    onAddExcerptToReport: (event: React.DragEvent<HTMLDivElement>, id: string, resource_id: string) => void;
    onExcerptSelect: (excerpt_id: string) => void;
    onNoteSelect: (note_id: string) => void;
    onPocketSelect: (pocket_id: string) => void;
    onReportSelect: (report_id: string) => void;
    onResourceSelect: (document_id: string, resource_id: string) => void;
    onReturn: () => void;
    onUpdatePocket: (edits: PocketUpdateParams) => void;
}

export type PocketManagerPanelPresenterProps = PocketManagerPanelAppStateProps & PocketManagerPanelAppDispatchProps;

export type PocketManagerPanelPresenterState = {
    tmpPocket: PocketVM;
    userInputExpanded: boolean;
}

export type PocketManagerPanelViewProps = HTMLAttributes<any> & {
    onAddExcerptToReport: (event: React.DragEvent<HTMLDivElement>, id: string, resource_id: string) => void;
    onAddUser: (name: string, value: string) => void;
    onExcerptSelect: (excerpt_id: string) => void;
    onNoteSelect: (note_id: string) => void;
    onPocketSelect: (pocket_id: string) => void;
    onReportSelect: (report_id: string) => void;
    onResourceSelect: (document_id: string, resource_id: string) => void;
    onReturn: () => void;
    onUpdateTmpPocket: (name: string, value: string) => void;
    onToggleUserInputExpanded: () => void;
    onUpdatePocket: () => void;
    ownedPockets: Record<string, PocketVM>;
    permissions: PermissionsVM;
    selectedPocket: Nullable<PocketVM>;
    sharedPockets: Record<string, PocketVM>;
    tmpPocket: PocketVM;
    userInputExpanded: boolean;
    userSuggestionSupplier: (text:string) => Promise<UserItemVM[]>;
}

export type SelectedPocketViewProps = HTMLAttributes<any> & {
    onAddExcerptToReport: (event: React.DragEvent<HTMLDivElement>, id: string, resource_id: string) => void;
    onAddUser: (name: string, value: string) => void;
    onExcerptSelect: (excerpt_id: string) => void;
    onNoteSelect: (note_id: string) => void;
    onReportSelect: (report_id: string) => void;
    onResourceSelect: (document_id: string, resource_id: string) => void;
    onReturn: () => void;
    onToggleUserInputExpanded: () => void;
    selectedPocket: Nullable<PocketVM>;
    userInputExpanded: boolean;
    userSuggestionSupplier: (text:string) => Promise<UserItemVM[]>;
}

export type PocketContentNode = {
    id: string;
    title: string;
    author: string;
    type: PocketContentNodeType;
    upload_date?: string;
    selected: boolean;
    resource_id?: string;
    source_id?: string;
    children?: Record<string, PocketContentNode>;
}

export enum PocketContentNodeType {
    RESOURCE='RESOURCE',
    REPORT='REPORT',
    EXCERPT='EXCERPT',
    NOTE='NOTE'
}

export type PocketVM = {
    id?: string;
    title?: string;
    upload_date?: string;
    isUpdating?: boolean;
    author?: string;
    shared_authors?: Record<string, string>;
    pocketContent?: Record<string, PocketContentNode>;
    readonly?: boolean;
    editor?: string;
    edit_duration?: string;
    isOwned?: boolean;
    isShared?: boolean;
}

export type NoteVM = {
    id?: string;
    text?: string;
    content?: string;
    excerpt_id?: string;
    resource_id?: string;
    pocket_id?: string;
    selected?: boolean;
}

export type PermissionsVM = {
    canDelete: boolean,
    canDownload: boolean,
    canModify: boolean
}

export type TemplateVM = {
    id?: string;
    title?: string;
    html?: string;
    selected?: boolean;
}

export type PocketUpdateParams = Partial<Record<keyof Omit<PocketInfo, 'type' | 'childNodes' | 'path'>, any>>;

export type UserItemVM = SuggestionItemVM;
