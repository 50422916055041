import {Converter} from "../../common/converters/converter";
import {TemplateInfo} from "../../../app.model";
import {getValueOrDefault} from "../../../framework.core/extras/utils/typeUtils";

export class GetTemplateResponseConverter extends Converter<any, TemplateInfo> {
    convert(fromData: any, reject: any): TemplateInfo {
        let item = fromData;

        if (Array.isArray(fromData)) {
            item = fromData[0];
        }

        const templateInfo = new TemplateInfo(getValueOrDefault(item, 'template_id', getValueOrDefault(item, 'id', '')));

        templateInfo.title = getValueOrDefault(item, 'name', '');
        templateInfo.html = getValueOrDefault(item, 'html_text', '');

        return templateInfo;
    }
}
