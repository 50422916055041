import React, {Component} from 'react';
import {EditPropertyVM, ProfileInfoViewProps} from "../profilePanelModel";
import ComboBox from "../../../theme/widgets/comboBox/comboBox";
import TextEdit from "../../../theme/widgets/textEdit/textEdit";
import Card from "../../../theme/widgets/card/card";
import {LoadingIndicator} from "../../../theme/widgets/loadingIndicator/loadingIndicator";
import {Size} from "../../../theme/widgets/loadingIndicator/loadingIndicatorModel";
import Popup from "../../../theme/widgets/popup/popup";
import {FileSVG} from "../../../theme/svgs/fileSVG";
import Button from "../../../theme/widgets/button/button";

class ProfileInfoView extends Component<ProfileInfoViewProps> {
    render() {
        const {
            accountStatuses,
            departments,
            dirty,
            editProperties,
            onManageEditProfiles,
            onManageOpenProfiles,
            onRemoveUser,
            onTmpUsersChanged,
            onToggleShowPopup,
            onUpdateUser,
            permissions,
            roles,
            selected,
            showPopup,
            tmpUser,
            user,
            userLookup,
        } = this.props;

        const { id, isUpdating, first_name, last_name } = user;

        let display_name = first_name;
        let initials = first_name?.charAt(0);
        if (last_name) {
            display_name += ` ${last_name}`;
            initials += last_name.charAt(0);
        }

        let cn = 'profile-info w-100 position-relative';
        if (selected) {
            cn += ' selected';
        }
        if (dirty) {
            cn += ' dirty';
        }

        const editDivs = editProperties.map((editProperty: EditPropertyVM) => {
            const { id, placeholder, readonly } = editProperty;

            const originalValue = user ? user[id] || '' : '';
            const editValue = tmpUser ? tmpUser[id] || '' : '';

            let renderDiv;
            let cn = 'align-self-center';

            switch (id) {
                case 'department':
                    let departmentId = editValue ? editValue : originalValue;
                    let departmentTitle = '';
                    if (departments && departments[departmentId]) {
                        departmentTitle = departments[departmentId].title;
                    }
                    else {
                        departmentTitle = departmentId;
                    }

                    renderDiv = (
                        <ComboBox
                            key={id}
                            className={cn}
                            disable={!dirty || isUpdating}
                            onSelect={(value: string) => onTmpUsersChanged(user.id || "", id, value)}
                            title={departmentTitle}
                            items={departments}
                            light={true}
                        />
                    );
                    break;
                case 'role':
                    let roleId = editValue ? editValue : originalValue;
                    let roleTitle = '';
                    if (roles && roles[roleId]) {
                        roleTitle = roles[roleId].title;
                    }
                    else {
                        roleTitle = roleId;
                    }

                    renderDiv = (
                        <ComboBox
                            key={id}
                            className={cn}
                            disable={!dirty || isUpdating}
                            onSelect={(value: string) => onTmpUsersChanged(user.id || "", id, value)}
                            title={roleTitle}
                            items={roles}
                            light={true}
                        />
                    );
                    break;
                case 'account_status':
                    let accountStatusId = editValue ? editValue : originalValue;
                    let accountStatusTitle = '';
                    if (accountStatuses && accountStatuses[accountStatusId]) {
                        accountStatusTitle = accountStatuses[accountStatusId].title;
                    } else {
                        accountStatusTitle = accountStatusId;
                    }

                    renderDiv = (
                        <ComboBox
                            key={id}
                            className={cn}
                            disable={!dirty || isUpdating}
                            onSelect={(value: string) => onTmpUsersChanged(user.id || "", id, value)}
                            title={accountStatusTitle}
                            items={accountStatuses}
                            light={true}

                        />
                    );
                    break;
                case 'approved_by':
                    let name = originalValue;
                    let approver = userLookup[name];
                    if (approver) {
                        if (approver.first_name) {
                            name = approver.first_name;
                        }
                        if (approver.last_name) {
                            name += ` ${approver.last_name}`;
                        }
                    }
                    renderDiv =(
                        <TextEdit
                            key={id}
                            className={cn}
                            disable={!readonly ? !dirty || isUpdating : true}
                            placeholder={placeholder}
                            name={id}
                            dirty={!!editValue}
                            value={name}
                            edit={!readonly ? dirty : false}
                            onSubmit={(name: string, value: string) => onTmpUsersChanged(user.id || "", name, value)}/>
                    )
                    break;
                case 'first_name':
                case 'last_name':
                case 'email_address':
                case 'phone_number':
                case 'dod_id':
                default:
                    renderDiv = (
                        <TextEdit
                            key={id}
                            className={cn}
                            disable={!readonly ? !dirty || isUpdating : true}
                            placeholder={placeholder}
                            name={id}
                            dirty={!!editValue}
                            value={editValue ? editValue : originalValue}
                            edit={!readonly ? dirty : false}
                            onSubmit={(name: string, value: string) => onTmpUsersChanged(user.id || "", name, value)}/>
                    );
                    break;
            }

            return renderDiv;
        });

        return (
            <div className={cn}>
                <div
                    onClick={() => onManageOpenProfiles(id || "")}
                    className={'profile-info-header p-3 d-flex align-items-center justify-content-between cursor-pointer w-100'}
                >
                    <div className={'d-flex h-gap-3 align-items-center'}>
                        <div className={'icon rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
                            {initials}
                        </div>
                        <div className={`header-1 ${selected ? 'font-weight-bold' : 'font-weight-semi-bold'}`}>{display_name}</div>
                    </div>
                    {
                        isUpdating &&
                        <LoadingIndicator size={Size.small} className={"loading position-absolute"}/>
                    }
                </div>


                <div className={`profile-info-body p-3 position-absolute ${selected ? 'd-block' : 'd-none'}`}>
                    <Popup
                        text={"Are you sure you want to remove this User?"}
                        proceedText={"Remove"}
                        cancelText={"Cancel"}
                        graphic={FileSVG}
                        padding={"40%"}
                        isVisible={showPopup}
                        onCancel={onToggleShowPopup}
                        onProceed={() => onRemoveUser(id || "")}
                    />
                    <div className={'profile-info-grid w-100 header-2 font-weight-semi-bold align-items-center'}>
                        <div className={'align-self-center justify-self-end'}>First Name:</div>
                        <div className={'align-self-center justify-self-end'}>Last Name:</div>
                        <div className={'align-self-center justify-self-end'}>Department:</div>
                        <div className={'align-self-center justify-self-end'}>Role:</div>
                        <div className={'align-self-center justify-self-end'}>Email:</div>
                        <div className={'align-self-center justify-self-end'}>Phone:</div>
                        <div className={'align-self-center justify-self-end'}>Account Status:</div>
                        <div className={'align-self-center justify-self-end'}>Approved By:</div>
                        {editDivs}
                    </div>
                    {
                        !dirty &&
                        <div className={"d-flex justify-content-end h-gap-2"}>
                            <Button text={"Edit"} light={true} orientation={"horizontal"} onClick={() => onManageEditProfiles(id || "")} selected={false} disabled={isUpdating} className={"px-5"}/>
                        </div>
                    }
                    {
                        dirty &&
                        <div className={"d-flex h-gap-2 justify-content-end"}>
                            {
                                permissions.canDelete &&
                                <Button text={"Remove User"} light={true} onClick={onToggleShowPopup} selected={false} disabled={isUpdating} className={"px-5"}/>
                            }
                            <Button text={"Cancel"} light={true} onClick={() => onManageEditProfiles(id || "")} selected={false} disabled={isUpdating} className={"px-5"}/>
                            <Button text={"Save"} light={true} onClick={() => onUpdateUser(id || "")} selected={false} disabled={isUpdating} className={"px-5"}/>
                        </div>
                    }
                </div>

            </div>
            // <Card className={cn}
            //       header={
            //           <div
            //               onClick={() => onManageOpenProfiles(id || "")}
            //               className={'profile-info-header p-3 d-flex align-items-center justify-content-between'}>
            //               <div className={'d-flex h-gap-3 align-items-center'}>
            //                   <div className={'icon rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
            //                       {initials}
            //                   </div>
            //                   <div className={`header-1 ${selected ? 'font-weight-bold' : 'font-weight-semi-bold'}`}>{display_name}</div>
            //               </div>
            //               {
            //                   isUpdating &&
            //                   <LoadingIndicator size={Size.small} className={"loading position-absolute"}/>
            //               }
            //           </div>
            //       }
            //       body={
            //           <div className={'profile-info-body p-3'}>
            //               <Popup
            //                   text={"Are you sure you want to remove this User?"}
            //                   proceedText={"Remove"}
            //                   cancelText={"Cancel"}
            //                   graphic={FileSVG}
            //                   padding={"40%"}
            //                   isVisible={showPopup}
            //                   onCancel={onToggleShowPopup}
            //                   onProceed={() => onRemoveUser(id || "")}
            //               />
            //               <div className={'profile-info-grid w-100 header-2 font-weight-semi-bold align-items-center'}>
            //                   <div className={'align-self-center justify-self-end'}>First Name:</div>
            //                   <div className={'align-self-center justify-self-end'}>Last Name:</div>
            //                   <div className={'align-self-center justify-self-end'}>Department:</div>
            //                   <div className={'align-self-center justify-self-end'}>Role:</div>
            //                   <div className={'align-self-center justify-self-end'}>Email:</div>
            //                   <div className={'align-self-center justify-self-end'}>Phone:</div>
            //                   <div className={'align-self-center justify-self-end'}>Account Status:</div>
            //                   <div className={'align-self-center justify-self-end'}>Approved By:</div>
            //                   {editDivs}
            //               </div>
            //               {
            //                   !dirty &&
            //                   <div className={"d-flex justify-content-end h-gap-2"}>
            //                       <Button text={"Edit"} light={true} orientation={"horizontal"} onClick={() => onManageEditProfiles(id || "")} selected={false} disabled={isUpdating} className={"px-5"}/>
            //                   </div>
            //               }
            //               {
            //                   dirty &&
            //                   <div className={"d-flex h-gap-2 justify-content-end"}>
            //                       {
            //                           permissions.canDelete &&
            //                           <Button text={"Remove User"} light={true} onClick={onToggleShowPopup} selected={false} disabled={isUpdating} className={"px-5"}/>
            //                       }
            //                       <Button text={"Cancel"} light={true} onClick={() => onManageEditProfiles(id || "")} selected={false} disabled={isUpdating} className={"px-5"}/>
            //                       <Button text={"Save"} light={true} onClick={() => onUpdateUser(id || "")} selected={false} disabled={isUpdating} className={"px-5"}/>
            //                   </div>
            //               }
            //           </div>
            //       }
            //       selected={selected}
            // />
        );
    }
}

export default ProfileInfoView;
