import React from "react";
import './tag.css';
import {DeleteSVG} from "../../svgs/deleteSVG";
import {bindInstanceMethods} from "../../../../framework.core/extras/utils/typeUtils";
import {TagProps, TagState} from "./tagModel";
import {AutoComplete} from "../autoComplete/autoComplete";
import {RecommendedSVG} from "../../svgs/recommendedSVG";
import {ApproveSVG} from "../../svgs/approveSVG";
import {CheckMarkSVG} from "../../svgs/checkMarkSVG";

class Tag extends React.Component<TagProps, TagState> {
    constructor(props: any) {
        super(props);

        this.state = {
            selected: false,
            tmpText: "",
        }

        bindInstanceMethods(this);
    }

    _onDelete() {
        const { onDelete, name, text } = this.props;

        if (onDelete) {
            onDelete(name, text);
        }
    }

    _onSubmit(name: string, newValue: string) {
        const { onSubmit, text:oldValue } = this.props;

        if (onSubmit) {
            onSubmit(name, oldValue, newValue);
        }
    }

    _toggleSelected() {
        const { selected } = this.state;

        this.setState({
            ...this.state,
            selected: !selected,
        })
    };


    render() {
        const { className, text, name, isEdit=false, isGlobal=false, readonly=true, suggestionSupplier, isRecommended=false } = this.props;
        const { tmpText, selected } = this.state;

        let cn = 'tag d-flex rounded-pill cursor-pointer position-relative';

        if (className) {
            cn += ` ${className}`;
        }

        if (isGlobal) {
            cn += ` global`
        }

        if (isRecommended) {
            cn += ` recommended`;
        }

        if (isEdit) {
            cn += ` edit`;
        }

        if (readonly) {
            cn += ` readonly`;
        }

        let editValue = tmpText ? tmpText : '';

        let dirty = !!editValue
        let value = editValue ? editValue : '';

        return (
            <div className={cn}>
                {
                    isRecommended &&
                    <div className={'delete-btn align-self-center px-2 tag-body'}>
                        <RecommendedSVG className={"tiny-image-container"}/>
                    </div>
                }
                <div className={"d-grid tag-body"} onClick={this._toggleSelected}>
                    {
                        isEdit ?
                        <AutoComplete
                            className={"pl-4 mr-4 align-self-center"}
                            placeholder={'Enter New Tag'}
                            name={name}
                            dirty={dirty}
                            value={value}
                            onSubmit={this._onSubmit}
                            edit={true}
                            autoFocus={true}
                            suggestionSupplier={suggestionSupplier}
                        />
                        :
                        <div className={`tag-text display-4 text-nowrap align-self-center pr-4 ${readonly ? 'px-4' : `${isRecommended ? '' : 'pl-4'}`}`}>
                            {text}
                        </div>
                    }
                </div>
                {/*{*/}
                {/*    isRecommended &&*/}
                {/*    <div className={'delete-btn align-self-center pr-2'} onClick={this._onDelete}>*/}
                {/*        <CheckMarkSVG className={"tiny-image-container"}/>*/}
                {/*    </div>*/}
                {/*}*/}
                {/*{*/}
                {/*    (!readonly && (selected || isEdit) && !isRecommended) &&*/}
                {/*    <div className={'delete-btn align-self-center pr-2'} onClick={this._onDelete}>*/}
                {/*        <DeleteSVG className={"tiny-image-container"}/>*/}
                {/*    </div>*/}
                {/*}*/}
                {
                    (!readonly && !isEdit) &&

                    <div className={'position-absolute overlay tag-text d-flex justify-content-center align-items-center display-4 text-nowrap w-100 h-100'} onClick={this._onDelete}>{isRecommended ? 'Apply' : 'Delete'}</div>
                }

            </div>
        );
    }
}

export default Tag;
