import {createVisualConnector, VisualWrapper} from "../../../framework.visual";
import {
    ComponentTypes,
    ContainerTypes,
    ExcerptMapper,
    NoteInfo,
    PocketInfo,
    ResourceMapper,
    SelectionTypes,
    UserInfo
} from "../../../app.model";
import PocketManagerPanelPresenter from "./presenters/pocketManagerPanelPresenter";
import {
    PocketContentNode,
    PocketContentNodeType,
    PocketManagerPanelAppDispatchProps,
    PocketManagerPanelAppStateProps,
    PocketUpdateParams,
    PocketVM,
    UserItemVM
} from "./pocketManagerPanelModel";
import {
    authorizationService,
    displayService,
    documentService,
    pocketService,
    reportService,
    selectionService,
    userService
} from "../../../serviceComposition";
import {createSelector} from "@reduxjs/toolkit";
import {forEach} from "../../../framework.core/extras/utils/collectionUtils";
import {Nullable} from "../../../framework.core/extras/utils/typeUtils";
import {
    PERMISSION_ENTITY, PERMISSION_OPERATOR,
    PocketParamType,
} from "../../../app.core.api";
import React from "react";
import {RegistrationStatusType} from "../../model/registrationStatusType";

class _PocketManagerPanelWrapper extends VisualWrapper {
    constructor() {
        super();

        this.id = ComponentTypes.PocketManagerPanelWrapper;

        this.view = PocketManagerPanelPresenter;

        this.mapStateToProps = (state: any, props: any): PocketManagerPanelAppStateProps => {
            return {
                ownedPockets: this._getOwnedPocket(state),
                permissions: this._getPermissions(state),
                selectedPocket: this._getSelectedPocket(state),
                sharedPockets: this._getSharedPockets(state),
                selectedReportId: this._getSelectedReportId(state),
                selectedResourceId: this._getSelectedDocumentId(state),
                selectedExcerptId: this._getSelectedExcerptId(state),
                selectedNoteId: this._getSelectedNoteId(state),
                userSuggestionSupplier: (text: string) => this._getUserSuggestions(text),
            }
        }

        this.mapDispatchToProps = (dispatch: any): PocketManagerPanelAppDispatchProps => {
            return {
                onAddExcerptToReport: (event: React.DragEvent<HTMLDivElement>, id: string, resource_id: string) => this._onAddExcerptToReport(event, id, resource_id),
                onExcerptSelect: (excerpt_id: string) => this._onExcerptSelect(excerpt_id),
                onNoteSelect: (note_id: string) => this._onNoteSelect(note_id),
                onPocketSelect: (pocket_id: string) => this._onPocketSelect(pocket_id),
                onReportSelect: (report_id: string) => this._onReportSelected(report_id),
                onResourceSelect: (document_id: string, resource_id: string) => this._onDocumentSelected(document_id, resource_id),
                onReturn: () => this._onReturn(),
                onUpdatePocket: (edits: PocketUpdateParams) => this._onUpdatePocket(edits),
            }
        }
    }

    private _getSelectedPocketId = selectionService.makeGetContext(SelectionTypes.POCKET_SELECTION);
    private _getSelectedDocumentId = selectionService.makeGetContext(SelectionTypes.DOCUMENT_SELECTION);
    private _getSelectedExcerptId = selectionService.makeGetContext(SelectionTypes.EXCERPT_SELECTION);
    private _getSelectedNoteId = selectionService.makeGetContext(SelectionTypes.NOTE_SELECTION);
    private _getSelectedReportId = selectionService.makeGetContext(SelectionTypes.REPORT_SELECTION);
    private _getSelectedResourceId = selectionService.makeGetContext(SelectionTypes.RESOURCE_SELECTION);

    private _onAddExcerptToReport(event: React.DragEvent<HTMLDivElement>, excerpt_id: string, resource_id: string) {
        const excerpt = pocketService.getExcerpt(excerpt_id);

        const resource = pocketService.getResource(resource_id);

        if (excerpt) {
            const { text } = excerpt;

            event.dataTransfer.setData("text/plain", "\"" + text + "\"");
            event.dataTransfer.setData("text/excerpt/excerpt_id", excerpt_id);

            if (resource) {
                const { source_author, title, source_publication_date} = resource;

                let resource_information = "";

                let authors = JSON.parse(source_author);

                if (Array.isArray(authors)) {
                    let index = 0;
                    forEach(JSON.parse(source_author), (author: string) => {
                        if (index < source_author.length - 2) {
                            resource_information += author + ", ";
                        } else {
                            resource_information += author + ". ";
                        }

                        index++;
                    });
                } else {
                    resource_information = authors;
                }

                resource_information += title + ". " + source_publication_date.toLocaleString().split("T")[0]

                event.dataTransfer.setData("text/excerpt", resource_information);
            } else {
                event.dataTransfer.setData("text/excerpt", "resource information");
            }
        }
    }

    private _getOwnedPocket = createSelector(
        [
            (s) => pocketService.getOwnedPocketInfos(),
            (s) => userService.getCurrentUserId(),
        ],
        (pocketInfos, currentUserId) => {
            let itemVMs: Record<string, PocketVM> = {};

            forEach(pocketInfos, (pocketInfo: PocketInfo) => {
                const {
                    id,
                    title,
                    upload_date,
                    isUpdating,
                    author_id,
                    shared_author_ids,
                    editor_id,
                    edit_duration
                } = pocketInfo;

                let author = 'Unknown';
                const author_user = userService.getUser(author_id);
                if (author_user) {
                    const { first_name, last_name } = author_user;

                    author = first_name + " " + last_name;
                }

                let shared_authors: Record<string, string> = {};

                forEach(shared_author_ids, (shared_author_id: string) => {
                    let author = 'Unknown';
                    const user = userService.getUser(shared_author_id);
                    if (user) {
                        const { first_name, last_name } = user;

                        author = first_name + " " + last_name;
                    }
                    shared_authors[shared_author_id] = author;
                });

                let editor = '';
                const editor_user = userService.getUser(editor_id);
                if (editor_user) {
                    const { first_name, last_name } = editor_user;

                    editor = first_name + " " + last_name;
                }

                itemVMs[id] = {
                    id,
                    title,
                    upload_date: upload_date ? new Date(upload_date).toLocaleString().split(',')[0] : 'No Upload Date',
                    isUpdating,
                    author,
                    shared_authors,
                    editor,
                    edit_duration,
                    isOwned: currentUserId === author_id,
                    isShared: (author_id ? author_id !== currentUserId : true) && (shared_author_ids.includes(currentUserId)),
                }
            });

            return itemVMs;
        }
    )

    private _getSharedPockets = createSelector(
        [
            (s) => pocketService.getSharedPocketInfos(),
            (s) => userService.getCurrentUserId(),
        ],
        (pocketInfos, currentUserId) => {
            let itemVMs: Record<string, PocketVM> = {};

            forEach(pocketInfos, (pocketInfo: PocketInfo) => {
                const {
                    id,
                    title,
                    upload_date,
                    isUpdating,
                    author_id,
                    shared_author_ids,
                    editor_id,
                    edit_duration
                } = pocketInfo;

                let author = 'Unknown';
                const author_user = userService.getUser(author_id);
                if (author_user) {
                    const { first_name, last_name } = author_user;

                    author = first_name + " " + last_name;
                }

                let shared_authors: Record<string, string> = {};

                forEach(shared_author_ids, (shared_author_id: string) => {
                    let author = 'Unknown';
                    const user = userService.getUser(shared_author_id);
                    if (user) {
                        const { first_name, last_name } = user;

                        author = first_name + " " + last_name;
                    }
                    shared_authors[shared_author_id] = author;
                });

                let editor = '';
                const editor_user = userService.getUser(editor_id);
                if (editor_user) {
                    const { first_name, last_name } = editor_user;

                    editor = first_name + " " + last_name;
                }

                itemVMs[id] = {
                    id,
                    title,
                    upload_date: upload_date ? new Date(upload_date).toLocaleString().split(',')[0] : 'No Upload Date',
                    isUpdating,
                    author,
                    shared_authors,
                    editor,
                    edit_duration,
                    isOwned: currentUserId === author_id,
                    isShared: (author_id ? author_id !== currentUserId : true) && (shared_author_ids.includes(currentUserId)),
                }
            });

            return itemVMs;
        }
    )

    private _getSelectedPocket = createSelector(
        [
            (s) => this._getSelectedPocketId(s),
            (s) => this._getSelectedResourceId(s),
            (s) => this._getSelectedExcerptId(s),
            (s) => this._getSelectedNoteId(s),
            (s) => this._getSelectedReportId(s),
            (s) => pocketService.getPocketMappers(),
            (s) => userService.getActiveUsers(),
            (s) => userService.getCurrentUserId(),
        ],
        (
            selectedPocketId,
            selectedResourceId,
            selectedExcerptId,
            selectedNoteId,
            selectedReportId,
            pocketMappers,
            users,
            currentUserId
        ) => {
            let itemVM: Nullable<PocketVM> = null;

            const pocketMapper = pocketMappers[selectedPocketId];

            if (pocketMapper) {
                const pocket = pocketMapper.pocket;

                const {
                    id: pocket_id,
                    title,
                    upload_date,
                    isUpdating,
                    author_id,
                    shared_author_ids,
                    editor_id,
                    edit_duration
                } = pocket;

                let author = 'Unknown';

                const user = userService.getUser(author_id);
                if (user) {
                    const { first_name, last_name } = user;
                    author = first_name + ' ' + last_name;
                }

                let shared_authors: Record<string, string> = {};

                forEach(shared_author_ids, (shared_author_id: string) => {
                    let author = 'Unknown';
                    const user = users[shared_author_id];
                    if (user) {
                        const { first_name, last_name } = user;

                        author = first_name + " " + last_name;
                    } else {
                        userService.fetchUser(shared_author_id); //selector will automatically recalculate
                        //this may cause issues if user does not exist, so it will loop ad infinitum
                    }
                    shared_authors[shared_author_id] = author;
                });

                let editor = '';
                const editor_user = userService.getUser(editor_id);
                if (editor_user) {
                    const { first_name, last_name } = editor_user;

                    editor = first_name + " " + last_name;
                }

                let readonly = editor_id !== '' && editor_id !== currentUserId;

                let pocketContent: Record<string, PocketContentNode> = {};

                forEach(pocketMapper.notes, (note: NoteInfo) => {
                    if (note) {
                        const { id:note_id, author_id, text } = note;

                        let author_name = '';

                        const author = userService.getUser(author_id);
                        if (author) {
                            author_name = author.first_name + " " + author.last_name;
                        }

                        let noteVM: PocketContentNode = {
                            id: note_id,
                            title: text,
                            author: author_name,
                            type: PocketContentNodeType.NOTE,
                            selected: note_id === selectedNoteId
                        };

                        pocketContent[note_id] = noteVM;
                    }
                });

                forEach(pocketMapper.pocket.report_ids, (report_id: string) => {
                    const report = reportService.getReport(report_id);

                    if (report) {
                        const { id:report_id, title, author_id, upload_date } = report;

                        let author_name = '';

                        const author = userService.getUser(author_id);
                        if (author) {
                            author_name = author.first_name + " " + author.last_name;
                        }

                        let reportVM: PocketContentNode = {
                            id: report_id,
                            title,
                            author: author_name,
                            type: PocketContentNodeType.REPORT,
                            selected: report_id === selectedReportId,
                            upload_date: upload_date ? new Date(upload_date).toLocaleString().split(',')[0] : 'No Upload Date',
                        }

                        pocketContent[report_id] = reportVM;
                    }
                });

                forEach(pocketMapper.resourceMappers, (resourceMapper: ResourceMapper) => {
                    const resource = resourceMapper.resource;
                    if (resource) {
                        const { id: resource_id, title, source_author, source_id } = resource;

                        let resourceChildren: Record<string, PocketContentNode> = {};

                        forEach(resourceMapper.notes, (note: NoteInfo) => {
                            if (note) {
                                const { id:note_id, author_id, text } = note;

                                let author_name = '';

                                const author = userService.getUser(author_id);
                                if (author) {
                                    author_name = author.first_name + " " + author.last_name;
                                }

                                let noteVM: PocketContentNode = {
                                    id: note_id,
                                    title: text,
                                    author: author_name,
                                    type: PocketContentNodeType.NOTE,
                                    selected: note_id === selectedNoteId
                                };

                                resourceChildren[note_id] = noteVM;
                            }
                        });

                        forEach(resourceMapper.excerptMappers, (excerptMapper: ExcerptMapper) => {
                            const excerpt = excerptMapper.excerpt;

                            if (excerpt) {
                                const { id: excerpt_id, text, authorId } = excerpt;

                                let author_name = '';

                                const author = userService.getUser(authorId);
                                if (author) {
                                    author_name = author.first_name + " " + author.last_name;
                                }

                                let excerptChildren: Record<string, PocketContentNode> = {};

                                forEach(excerptMapper.notes, (note: NoteInfo) => {
                                    if (note) {
                                        const { id: note_id, author_id, text } = note;

                                        let author_name = '';

                                        const author = userService.getUser(author_id);
                                        if (author) {
                                            author_name = author.first_name + " " + author.last_name;
                                        }

                                        let noteVM: PocketContentNode = {
                                            id: note_id,
                                            title: text,
                                            author: author_name,
                                            type: PocketContentNodeType.NOTE,
                                            selected: note_id === selectedNoteId
                                        };

                                        excerptChildren[note_id] = noteVM;
                                    }
                                });

                                let excerptVM: PocketContentNode = {
                                    id: excerpt_id,
                                    title: text,
                                    author: author_name,
                                    type: PocketContentNodeType.EXCERPT,
                                    selected: excerpt_id === selectedExcerptId,
                                    resource_id,
                                    children: excerptChildren,
                                }

                                resourceChildren[excerpt_id] = excerptVM;
                            }
                        });

                        let resourceVM: PocketContentNode = {
                            id: resource_id,
                            title,
                            author: source_author,
                            type: PocketContentNodeType.RESOURCE,
                            selected: resource_id === selectedResourceId,
                            source_id,
                            children: resourceChildren,
                        }

                        pocketContent[resource_id] = resourceVM;
                    }
                });

                itemVM = {
                    id: pocket_id,
                    title,
                    upload_date: upload_date ? new Date(upload_date).toLocaleString().split(',')[0] : 'No Upload Date',
                    isUpdating,
                    author,
                    pocketContent,
                    shared_authors,
                    readonly,
                    editor,
                    edit_duration,
                    isOwned: currentUserId === author_id,
                    isShared: (author_id ? author_id !== currentUserId : true) && (shared_author_ids.includes(currentUserId)),
                }
            }

            return itemVM;
        }
    );

    private _onReturn() {
        selectionService.setContext(SelectionTypes.POCKET_SELECTION, '');
        selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, '');
        selectionService.setContext(SelectionTypes.NOTE_SELECTION, '');
        selectionService.setContext(SelectionTypes.EXCERPT_SELECTION, '');
    }

    private _onExcerptSelect(id: string) {
        const selectedId = selectionService.getContext(SelectionTypes.EXCERPT_SELECTION);

        selectionService.setContext(SelectionTypes.EXCERPT_SELECTION, selectedId === id ? '' : id);
        selectionService.setContext(SelectionTypes.NOTE_SELECTION, '');
        selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
        // selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, '');
        // selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');
        // displayService.popNode(ContainerTypes.DocumentPreviewPanel);
        selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, '');
    }

    private _onNoteSelect(id: string) {
        const selectedId = selectionService.getContext(SelectionTypes.NOTE_SELECTION);

        selectionService.setContext(SelectionTypes.NOTE_SELECTION, selectedId === id ? '' : id);
        selectionService.setContext(SelectionTypes.EXCERPT_SELECTION, '');
        selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
        // selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, '');
        // selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');

        // displayService.popNode(ContainerTypes.DocumentPreviewPanel);
        selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, '');
    }

    private _onPocketSelect(id: string) {
        selectionService.setContext(SelectionTypes.POCKET_SELECTION, id);
        pocketService.fetchPocket(id);
    }

    private _onUpdatePocket(edits: PocketUpdateParams) {
        const params: PocketParamType = {
            id: edits.id
        }

        if (edits.title) {
            params.title = edits.title
        }

        if (edits.shared_author_ids) {
            params.shared_author_ids = edits.shared_author_ids;
            params.scope = "Group";
        }

        void pocketService.addOrUpdatePocket(params)
    }

    private _onReportSelected(id: string) {
        const selectedId = selectionService.getContext(SelectionTypes.REPORT_SELECTION);

        if (selectedId === id) {
            selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');
            displayService.popNode(ContainerTypes.DocumentPreviewPanel);
            selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, '');
        } else {
            selectionService.setContext(SelectionTypes.REPORT_SELECTION, id);
            selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
            // selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, '');
            // selectionService.setContext(SelectionTypes.EXCERPT_SELECTION, '');
            selectionService.setContext(SelectionTypes.NOTE_SELECTION, '');
            displayService.pushNode(ComponentTypes.ReportPanelWrapper);
            reportService.fetchReport(id)
        }
    }

    private _onDocumentSelected(id: string, resource_id: string) {
        const selectedId = selectionService.getContext(SelectionTypes.DOCUMENT_SELECTION);

        if (selectedId === id) {
            selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
            selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, '');
            displayService.popNode(ContainerTypes.DocumentPreviewPanel);
            selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "");
        } else {
            selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, id);
            selectionService.setContext(SelectionTypes.RESOURCE_SELECTION, resource_id);
            selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');
            selectionService.setContext(SelectionTypes.EXCERPT_SELECTION, '');
            selectionService.setContext(SelectionTypes.NOTE_SELECTION, '');
            displayService.pushNode(ComponentTypes.DocumentPanelWrapper);
            documentService.fetchDocument(id);
        }
    }

    private _getUserSuggestions(text: string) {
        return new Promise<UserItemVM[]>((resolve, reject) => {
            userService.searchUsers(text)
                .then(result => {
                    const userVMs: UserItemVM[] = [];

                    forEach(result, (user: UserInfo) => {
                        const { account_status } = user;

                        if (account_status === RegistrationStatusType.ACTIVE) {
                            userVMs.push({
                                id: user.id,
                                title: user.first_name + " " + user.last_name,
                            });
                        }
                    });

                    resolve(userVMs);
                })
                .catch(error => {
                    console.log(error);
                    resolve([]);
                });
        });
    }

    private _getPermissions = createSelector(
        [(s) => this._getSelectedPocketId(s), (s) => userService.getCurrentUserId()],
        (selectedPocketId, currentUserId) => {

            let entityOwnerId = '';
            const pocket = pocketService.getPocketInfo(selectedPocketId);
            if (pocket) {
                const { author_id, shared_author_ids } = pocket;

                if (currentUserId === author_id) {
                    entityOwnerId = author_id;
                } else {
                    forEach(shared_author_ids, (shared_author_id: string) => {
                        if (shared_author_id === currentUserId) {
                            entityOwnerId = author_id;//if pocket has been shared with the current user, they also count as the entity owner
                        }
                    });
                }
            }

            return {
                canDelete: authorizationService.hasPermission(PERMISSION_ENTITY.DOCUMENT, PERMISSION_OPERATOR.DELETE, currentUserId, entityOwnerId),
                canDownload: authorizationService.hasPermission(PERMISSION_ENTITY.DOCUMENT, PERMISSION_OPERATOR.DOWNLOAD, currentUserId, entityOwnerId),
                canModify: authorizationService.hasPermission(PERMISSION_ENTITY.DOCUMENT, PERMISSION_OPERATOR.MODIFY, currentUserId, entityOwnerId)
            }
        }
    );
}

export const {
    connectedPresenter: PocketManagerPanelWrapper
} = createVisualConnector(_PocketManagerPanelWrapper);
