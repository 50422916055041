import React, {Component} from "react";
import {
    NoteVM,
    PocketManagerPanelPresenterProps,
    PocketManagerPanelPresenterState, PocketUpdateParams, PocketVM,
} from "../pocketManagerPanelModel";
import {bindInstanceMethods} from "../../../../framework.core/extras/utils/typeUtils";
import PocketManagerPanelView from "../views/pocketManagerPanelView";
import {forEachKVP} from "../../../../framework.core/extras/utils/collectionUtils";
import {pocketService, templateService} from "../../../../serviceComposition";

class PocketManagerPanelPresenter extends Component<PocketManagerPanelPresenterProps, PocketManagerPanelPresenterState> {
    interval!: NodeJS.Timer;

    constructor(props: PocketManagerPanelPresenterProps, context: any) {
        super(props, context);

        bindInstanceMethods(this);

        this.state = {
            tmpPocket: {},
            userInputExpanded: false,
        }
    }

    componentDidMount() {
        // this.interval = setInterval(() => {
        //     pocketService.fetchPockets();
        //     templateService.fetchTemplates();
        // }, 60000); // refresh every 60 seconds
        //
        // pocketService.fetchPockets();
        templateService.fetchTemplates();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    _onToggleUserInputExpanded() {
        const { userInputExpanded } = this.state

        this.setState({
            ...this.state,
            userInputExpanded: !userInputExpanded,
        });
    }

    _onUpdateTmpPocket(name: string, tmpValue: string, options?: string) {
        const { tmpPocket } = this.state;

        if (name === "title") {
            const newTmpPocket: PocketVM = {
                ...tmpPocket,
                title: tmpValue
            }

            this.setState({
                ...this.state,
                tmpPocket: newTmpPocket
            })
        }
    }

    _onUpdatePocket() {
        const { selectedPocket, onUpdatePocket } = this.props;
        const { tmpPocket } = this.state;

        if (selectedPocket) {
            const { id, title:original_title } = selectedPocket;
            const { title:edit_title, shared_authors } = tmpPocket;

            const title = edit_title ? edit_title : original_title;

            let edits: PocketUpdateParams = {
                id,
                title,
            }

            if (shared_authors) {
                let shared_author_ids: string[] = [];

                forEachKVP(shared_authors, (itemKey: string, itemValue: string) => {
                    shared_author_ids.push(itemKey);
                });

                edits = {
                    ...edits,
                    shared_author_ids,
                    scope: 'Group',//set scope to Group
                }
            }

            if (onUpdatePocket) {
                onUpdatePocket(edits);
            }

            this.setState({
                ...this.state,
                tmpPocket: {},
            });
        }
    }

    _onAddUser(name: string, value: string) {
        const { onUpdatePocket, selectedPocket } = this.props;

        if (selectedPocket) {
            const { id, title, shared_authors } = selectedPocket;

            let shared_author_ids: string[] = [];

            if (shared_authors) {
                forEachKVP(shared_authors, (itemKey: string, itemValue: string) => {
                    shared_author_ids.push(itemKey);
                });
            }

            shared_author_ids.push(value);


            let edits: PocketUpdateParams = {
                id,
                title,
                shared_author_ids,
                scope: 'Group',//set scope to Group
            }

            onUpdatePocket(edits);

            this._onToggleUserInputExpanded();
        }
    }

    render() {
        const {
            className,
            onAddExcerptToReport,
            onExcerptSelect,
            onNoteSelect,
            onPocketSelect,
            onReportSelect,
            onResourceSelect,
            onReturn,
            ownedPockets,
            permissions,
            selectedPocket,
            sharedPockets,
            userSuggestionSupplier,
        } = this.props;

        const {
            tmpPocket,
            userInputExpanded
        } = this.state;

        return (
            <PocketManagerPanelView
                className={className}
                onAddExcerptToReport={onAddExcerptToReport}
                onAddUser={this._onAddUser}
                onExcerptSelect={onExcerptSelect}
                onNoteSelect={onNoteSelect}
                onPocketSelect={onPocketSelect}
                onReportSelect={onReportSelect}
                onResourceSelect={onResourceSelect}
                onReturn={onReturn}
                onUpdateTmpPocket={this._onUpdateTmpPocket}
                onToggleUserInputExpanded={this._onToggleUserInputExpanded}
                onUpdatePocket={this._onUpdatePocket}
                ownedPockets={ownedPockets}
                permissions={permissions}
                selectedPocket={selectedPocket}
                sharedPockets={sharedPockets}
                tmpPocket={tmpPocket}
                userInputExpanded={userInputExpanded}
                userSuggestionSupplier={userSuggestionSupplier}
            />
        );
    }
}

export default PocketManagerPanelPresenter;
