import {Component} from "react";
import {bindInstanceMethods} from "../../../../framework.core/extras/utils/typeUtils";
import {
    ObjectType,
    PageWidth,
    SearchResultsPanelPresenterProps,
    SearchResultsPanelPresenterState
} from "../searchResultsModel";
import SearchResultsPanelView from "../views/searchResultsPanelView";
import {forEachKVP} from "../../../../framework.core/extras/utils/collectionUtils";
import {PocketUpdateParams} from "../../pocketManagerPanel/pocketManagerPanelModel";

class SearchResultsPanelPresenter extends Component<SearchResultsPanelPresenterProps, SearchResultsPanelPresenterState> {

    private resizeObserver: ResizeObserver;

    constructor(props: any, context: any) {
        super(props, context);
        bindInstanceMethods(this);

        this.state = {
            pageWidth: PageWidth.FULL,
            userInputExpanded: false,
        }

        this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.contentRect) {
                    const width = entry.contentRect.width;
                    if (width > 1800) {
                        this.setState({
                            ...this.state,
                            pageWidth: PageWidth.FULL,
                        })
                    } else if (width > 900) {
                        this.setState({
                            ...this.state,
                            pageWidth: PageWidth.ONE_HALF,
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            pageWidth: PageWidth.ONE_THIRD,
                        })
                    }
                }
            }
        })
    }

    componentDidMount() {
        let element = document.getElementById('search-results-panel');
        if (element) {
            this.resizeObserver.observe(element);
        }
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    _onCopy() {

    }

    _onEdit(id: string, object_type: ObjectType) {

    }

    _onShare(id: string, object_type: ObjectType) {

    }

    _onAddUser(name: string, value: string) {
        const { onUpdatePocket, selectedPocket } = this.props;

        if (selectedPocket) {
            const { id, title, shared_authors } = selectedPocket;

            let shared_author_ids: string[] = [];

            if (shared_authors) {
                forEachKVP(shared_authors, (itemKey: string, itemValue: string) => {
                    shared_author_ids.push(itemKey);
                });
            }

            shared_author_ids.push(value);


            let edits: PocketUpdateParams = {
                id,
                title,
                shared_author_ids,
                scope: 'Group',//set scope to Group
            }

            onUpdatePocket(edits);

            this._onToggleUserInputExpanded();
        }
    }

    _onToggleUserInputExpanded() {
        const { userInputExpanded } = this.state

        this.setState({
            ...this.state,
            userInputExpanded: !userInputExpanded,
        });
    }

    render() {
        const {
            className,
            searchResults,
            onDocumentSelected,
            resultViews,
            onResultViewSelected,
            userLookup,
            sortTypes,
            selectedSort,
            onSortSelected,
            isLoading,
            hasError,
            errorMessage,
            selectedResultView,
            selectedDocument,
            onDownload,
            onDelete,
            onAddToPocket,
            permissions,
            pockets,
            selectedPocket,
            onAddExcerptToReport,
            onExcerptSelect,
            onNoteSelect,
            onReportSelect,
            onResourceSelect,
            onPocketSelect,
            onReturn,
            userSuggestionSupplier
        } = this.props;

        const {
            pageWidth,
            userInputExpanded
        } = this.state;

        return (
            <SearchResultsPanelView
                className={className}
                searchResults={searchResults}
                onDocumentSelected={onDocumentSelected}
                resultViews={resultViews}
                onResultViewSelected={onResultViewSelected}
                userLookup={userLookup}
                sortTypes={sortTypes}
                selectedSort={selectedSort}
                onSortSelected={onSortSelected}
                isLoading={isLoading}
                hasError={hasError}
                errorMessage={errorMessage}
                selectedResultView={selectedResultView}
                selectedDocument={selectedDocument}
                pageWidth={pageWidth}
                onDownload={onDownload}
                onCopy={this._onCopy}
                onEdit={this._onEdit}
                onShare={this._onShare}
                onDelete={onDelete}
                onAddToPocket={onAddToPocket}
                permissions={permissions}
                pockets={pockets}
                selectedPocket={selectedPocket}
                onAddExcerptToReport={onAddExcerptToReport}
                onExcerptSelect={onExcerptSelect}
                onNoteSelect={onNoteSelect}
                onReportSelect={onReportSelect}
                onResourceSelect={onResourceSelect}
                onReturn={onReturn}
                userSuggestionSupplier={userSuggestionSupplier}
                userInputExpanded={userInputExpanded}
                onAddUser={this._onAddUser}
                onPocketSelect={onPocketSelect}
                onToggleUserInputExpanded={this._onToggleUserInputExpanded}
            />
        );
    }
}

export default SearchResultsPanelPresenter;
