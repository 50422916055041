import {TagInfo, TagType} from "../../../app.model";
import {Converter} from "../../common/converters/converter";

export class GetTagResponseConverter extends Converter<any, TagInfo> {
    convert(fromData: any, reject: any): TagInfo {
        let tagInfo: TagInfo = new TagInfo(fromData);

        tagInfo.type = TagType.PUBLIC;
        tagInfo.title = fromData;

        return tagInfo;
    }
}
