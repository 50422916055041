import React, {Component} from "react";
import {PocketManagerPanelViewProps, PocketVM} from "../pocketManagerPanelModel";
import {PocketSVG} from "../../../theme/svgs/pocketSVG";
import '../pocketManagerPanel.css';
import {forEach} from "../../../../framework.core/extras/utils/collectionUtils";
import {SelectedPocketView} from "./selectedPocketView";
import {getDurationFromSeconds} from "../../../../framework.core/extras/utils/timeUtils";

class PocketManagerPanelView extends Component<PocketManagerPanelViewProps> {
    render() {
        const {
            className,
            onAddExcerptToReport,
            onAddUser,
            onExcerptSelect,
            onNoteSelect,
            onPocketSelect,
            onReportSelect,
            onResourceSelect,
            onReturn,
            onUpdateTmpPocket,
            onToggleUserInputExpanded,
            ownedPockets,
            permissions,
            selectedPocket,
            sharedPockets,
            tmpPocket,
            userInputExpanded,
            userSuggestionSupplier,
            ...rest
        } = this.props;

        let cn = 'pocket-manager-panel d-flex flex-fill flex-column w-100';
        if (className) {
            cn += ` ${className}`;
        }

        let ownedPocketContentDivs: JSX.Element[] = [];
        forEach(ownedPockets, (item: PocketVM) => {
            const { id, title, author, upload_date, isUpdating, editor, edit_duration, isOwned, isShared } = item;

            if (id && id !== '') {
                ownedPocketContentDivs.push(
                    <div key={id} className={'pocket cursor-pointer d-flex flex-column p-3 v-gap-3'} onClick={() => onPocketSelect(id || "")}>
                        <div className={'d-flex h-gap-3 align-items-center'}>
                            <PocketSVG className={'tiny-image-container fill-info'}/>
                            <div className={'display-2 title text-break overflow-hidden'}>{title}</div>
                        </div>
                        <div className={'d-flex h-gap-2 align-items-center'}>
                            {
                                isOwned ?
                                    <div className={'text-info display-4 py-1 px-2 collaboration-info'}>Owned</div>
                                    :
                                    isShared ?
                                        <div className={'d-flex h-gap-2 py-1 px-2 collaboration-info align-items-center'}>
                                            <div className={'rounded-circle icon available'}/>
                                            <div className={'text-info display-4 overflow-hidden'}>From {author}</div>
                                        </div>
                                        : <div/>

                            }
                            {
                                editor &&
                                <div className={'d-flex h-gap-2 py-1 px-2 collaboration-info align-items-center'}>
                                    <div className={'rounded-circle icon in-use'}/>
                                    <div className={'text-info display-4 overflow-hidden'}>In Use by {editor}</div>
                                    {/*<div className={'orange d-flex flex-row h-gap-1 align-items-center'}>*/}
                                    {/*    <div className={'font-weight-semi-bold'}>Time remaining: </div>*/}
                                    {/*    <div>{getDurationFromSeconds(edit_duration)}</div>*/}
                                    {/*</div>*/}
                                </div>

                            }
                        </div>
                        <div className={'header-2 text-break overflow-hidden text-info author'}>
                            Upload: {upload_date}
                        </div>
                        <div className={'header-2 text-break overflow-hidden text-info author'}>
                            Author: {author}
                        </div>
                    </div>
                );
            }

        });

        let sharedPocketContentDivs: JSX.Element[] = [];
        forEach(sharedPockets, (item: PocketVM) => {
            const { id, title, author, upload_date, isUpdating, editor, isOwned } = item;

            if (id && id !== '') {
                sharedPocketContentDivs.push(
                    <div key={id} className={'pocket cursor-pointer d-flex flex-column p-3 v-gap-3'} onClick={() => onPocketSelect(id || "")}>
                        <div className={'d-flex h-gap-3 align-items-center'}>
                            <PocketSVG className={'tiny-image-container fill-info'}/>
                            <div className={'display-2 title text-break overflow-hidden'}>{title}</div>
                        </div>
                        <div className={'d-flex h-gap-2 align-items-center'}>
                            {
                                isOwned ?
                                    <div className={'text-info display-4 py-1 px-2 collaboration-info'}>Owned</div>
                                    :
                                    <div className={'d-flex h-gap-2 py-1 px-2 collaboration-info align-items-center'}>
                                        <div className={'rounded-circle icon available'}/>
                                        <div className={'text-info display-4 overflow-hidden'}>From {author}</div>
                                    </div>

                            }
                            {
                                editor &&
                                <div className={'d-flex h-gap-2 py-1 px-2 collaboration-info align-items-center'}>
                                    <div className={'rounded-circle icon in-use'}/>
                                    <div className={'text-info display-4 overflow-hidden'}>In Use by {editor}</div>
                                </div>

                            }
                        </div>
                        <div className={'header-2 text-break overflow-hidden text-info author'}>
                            Upload: {upload_date}
                        </div>
                        <div className={'header-2 text-break overflow-hidden text-info author'}>
                            Author: {author}
                        </div>
                    </div>
                );
            }
        });

        return (
            <div {...rest} className={cn}>
                {
                    selectedPocket ?
                        <SelectedPocketView
                            onAddExcerptToReport={onAddExcerptToReport}
                            onAddUser={onAddUser}
                            onExcerptSelect={onExcerptSelect}
                            onNoteSelect={onNoteSelect}
                            onReportSelect={onReportSelect}
                            onResourceSelect={onResourceSelect}
                            onToggleUserInputExpanded={onToggleUserInputExpanded}
                            onReturn={onReturn}
                            selectedPocket={selectedPocket}
                            userInputExpanded={userInputExpanded}
                            userSuggestionSupplier={userSuggestionSupplier}
                        />
                        :
                        <div className={'d-flex py-5 px-3 overflow-auto position-relative'}>
                            <div className={'mr-5 pr-5'}>
                                <div className={'section-header text-info display-4 py-2 px-3 v-gap-5 position-sticky'}>Collaborative Pockets</div>
                                {ownedPocketContentDivs}
                            </div>
                            <div className={'shared-pocket-grid ml-5 pl-5'}>
                                <div className={'section-header text-info display-4 py-2 px-3 v-gap-5 position-sticky'}>Invites</div>
                                {sharedPocketContentDivs}
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export default PocketManagerPanelView;
