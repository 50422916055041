import {Editor, Element as SlateElement, Text} from "slate";
import React from "react";
import {ReactEditor} from "slate-react";
import { jsx } from 'slate-hyperscript'

export const isKeyMod = (event: React.KeyboardEvent) => (event.metaKey && !event.ctrlKey) || event.ctrlKey;

export function hasMark(editor: Editor, markKey: string) {
    try {
        const marks = Editor.marks(editor) as Record<string, boolean>
        return marks ? marks[markKey] : false
    } catch (error) {
        console.log(error);
        return false;
    }

}

export function toggleMark(editor: Editor, markKey: string) {
    if (hasMark(editor, markKey)) {
        editor.removeMark(markKey);
    }
    else {
        editor.addMark(markKey, true);
    }

    ReactEditor.focus(editor);
}


export function setMark(editor: Editor, markKey: string, markValue: string) {
    editor.removeMark(markKey)

    editor.addMark(markKey, markValue);

    ReactEditor.focus(editor);
}

export function getMarkValue(editor: Editor, markKey: string, defaultValue: string) {
    let result: string = defaultValue;

    try {
        const marks = Editor.marks(editor) as Record<string, string>
        if (marks != null) {
            const currentValue = marks[markKey];
            if (currentValue != null) {
                result =  currentValue;
            }
        }
    } catch (error) {
        console.log(error);
    }


    return result;
}

export function isMultiElementActive(editor: Editor, elementKey: string, elementValue: any) {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n: any) => {
                const lookup: Record<string, string> = n;

                const result = !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    lookup[elementKey] == elementValue;

                return result;
            }

        })
    )

    return !!match
}

const matchHtmlRegExp = /["'&<>]/

export function escapeHtml(string: string) {
    let str = '' + string;
    let match = matchHtmlRegExp.exec(str);

    if (!match) {
        return str;
    }

    let escape;
    let html = '';
    let index = 0;
    let lastIndex = 0;

    for (index = match.index; index < str.length; index++) {
        switch (str.charCodeAt(index)) {
            case 34: // "
                escape = '&quot;';
                break;
            case 38: // &
                escape = '&amp;';
                break;
            case 39: // '
                escape = '&#39;';
                break;
            case 60: // <
                escape = '&lt;';
                break;
            case 62: // >
                escape = '&gt;';
                break;
            default:
                continue
        }

        if (lastIndex !== index) {
            html += str.substring(lastIndex, index)
        }

        lastIndex = index + 1
        html += escape
    }

    return lastIndex !== index
        ? html + str.substring(lastIndex, index)
        : html
}

export const serialize = (node: any) => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text);
        // @ts-ignore
        if (node.bold) {
            string = `<strong>${string}</strong>`;
        }
        // @ts-ignore
        if (node.fontColor) {
            // @ts-ignore
            string = `<span style={{color: ${node.fontColor}}}>${string}</span>`;
        }
        // @ts-ignore
        if (node.fontFamily) {
            // @ts-ignore
            string = `<span style={{fontFamily: ${node.fontFamily}}}>${string}</span>`;
        }
        // @ts-ignore
        if (node.fontHighlight) {
            // @ts-ignore
            string = `<span style={{backgroundColor: ${node.fontHighlight}}}>${string}</span>`;
        }
        // @ts-ignore
        if (node.fontSize) {
            // @ts-ignore
            string = `<span style={{fontSize: ${parseInt(node.fontSize)}}}>${string}</span>`;
        }
        // @ts-ignore
        if (node.italic) {
            string = `<i>${string}</i>`;
        }
        // @ts-ignore
        if (node.superscript) {
            string = `<sup>${string}</sup>`;
        }
        // @ts-ignore
        if (node.align) {
            // @ts-ignore
            string = `<div style={{textAlign: ${node.align}}}>${string}</div>`;
        }
        // @ts-ignore
        if (node.underline) {
            string = `<u>${string}</u>`;
        }
        return string;
    }

    // if (!node.children) return "";

    if (node.children) {
        const children = node.children.map((n: any) => serialize(n)).join('');

        // @ts-ignore
        if (node.align) {
            return `<div style={{textAlign: ${node.align}}}>${children}</div>`;
        }

        switch (node.type) {
            case 'bulleted':
                return `<ul>${children}</ul>`;
            case 'numbered':
                return `<ol>${children}</ol>`;
            case 'list-item':
                return `<li>${children}</li>`;
            case 'quote':
                return `<blockquote><p>${children}</p></blockquote>`;
            case 'link':
                return `<a href="${escapeHtml(node.url)}">${children}</a>`;
            case 'paragraph':
            default:
                return `<p>${children}</p>`;
        }
    }

}

export const deserialize = (el: any, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
        return jsx('text', markAttributes, el.textContent)
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
        return null;
    }

    const nodeAttributes: any = { ...markAttributes }

    // define attributes for text nodes
    switch (el.nodeName) {
        case 'B':
        case 'strong':
            nodeAttributes.bold = true;
            break;
        case 'I':
        case 'italic':
            nodeAttributes.italic = true;
            break;
        case 'superscript':
            nodeAttributes.superscript = true;
            break;
        case 'U':
        case 'underline':
            nodeAttributes.underline = true;
            break;
        case 'SPAN':
            if (el.attributes[0] && el.attributes[1]) {
                const str = el.attributes[1].nodeName;
                const style = str.substring(0, str.indexOf('}'));
                switch (el.attributes[0].nodeValue) {
                    case '{{fontColor:':
                        nodeAttributes.fontColor = style;
                        break;
                    case '{{fontFamily:':
                        nodeAttributes.fontFamily = style;
                        break;
                    case '{{fontHighlight:':
                        nodeAttributes.fontHighlight = style;
                        break;
                    case '{{fontSize:':
                        nodeAttributes.fontSize = style;
                        break;
                    case '{{textAlign:':
                        nodeAttributes.align = style;
                        break;
                }
            }
            break;
    }

    const children: any = Array.from(el.childNodes)
        .map(node => deserialize(node, nodeAttributes))
        .flat();

    if (children.length === 0) {
        children.push(jsx('text', nodeAttributes, ''));
    }

    switch (el.nodeName) {
        case 'BODY':
            return jsx('fragment', {}, children);
        case 'BR':
            return '\n';
        case 'BLOCKQUOTE':
            return jsx('element', { type: 'quote' }, children);
        case 'DIV':
            if (el.attributes[0] && el.attributes[1]) {
                const str = el.attributes[1].nodeName;
                const style = str.substring(0, str.indexOf('}'));
                switch (el.attributes[0].nodeValue) {
                    case '{{textAlign:':
                        return jsx('element', { type: 'paragraph', align: style }, children);
                }
            }
            break;
        case 'P':
            return jsx('element', { type: 'paragraph' }, children);
        case 'A':
            return jsx(
                'element',
                { type: 'link', url: el.getAttribute('href') },
                children
            );
        case 'UL':
            return jsx('element', { type: 'bulleted' }, children);
        case 'OL':
            return jsx('element', { type: 'numbered' }, children);
        case 'LI':
            return jsx('element', { type: 'list-item' }, children);
        default:
            return children;
    }
}
