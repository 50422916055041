import React from 'react';
import {SVGModel} from '../../../framework.visual';

export const ArrowLeftSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="M 15.949286,24.01924 C 9.8123459,19.63217 4.8031359,16.02346 4.8177059,15.99989 c 0.0146,-0.0236 4.97946,-3.57805 11.0330901,-7.8988503 6.05364,-4.3208 11.07972,-7.91113999 11.16907,-7.97851999 L 27.182326,-2.8514484e-7 V 16.00213 c 0,8.80117 -0.0169,16.00021 -0.0375,15.99787 -0.0206,-0.002 -5.05862,-3.59368 -11.19555,-7.98076 z"/>
    </svg>
);


