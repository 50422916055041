import React, {Component} from 'react';
import './userInput.css';
import {UserInputProps} from "./userInputModel";
import {bindInstanceMethods} from "../../../../framework.core/extras/utils/typeUtils";
import Button from "../button/button";
import {AutoComplete} from "../autoComplete/autoComplete";

class UserInput extends Component<UserInputProps> {
    constructor(props: any) {
        super(props);

        bindInstanceMethods(this);
    }

    _onSubmit(name: string, newValue: string, options?: string) {
        const { onSubmitUser } = this.props;

        if (onSubmitUser) {
            onSubmitUser(name, newValue, options);
        }
    }

    render() {
        const {className, onClick, name, value, suggestionSupplier, expanded, onExpand, ...rest} = this.props;

        let cn = "user-input d-flex position-relative";
        if (className) {
            cn += ` ${className}`;
        }
        if (expanded) {
            cn += ` expanded`;
        }

        let editValue = value ? value : '';

        let dirty = !!editValue

        return (
            <div className={cn} {...rest}>
                <Button className={'rounded-circle p-1 bg-primary display-3 font-weight-semi-bold'} onClick={onExpand}>+</Button>
                {
                    expanded &&
                    <div className={'text-edit-container d-block pe-none '}>
                        <AutoComplete
                            className={'pe-auto pl-2'}
                            placeholder={'Search for User'}
                            name={name}
                            dirty={dirty}
                            value={editValue}
                            onSubmit={this._onSubmit}
                            edit={true}
                            autoFocus={true}
                            suggestionSupplier={suggestionSupplier}
                        />
                    </div>
                }
            </div>
        );
    }
}


export default UserInput;
