import {VisualWrapper} from "../../../framework.visual";
import LandingPanelView from "./landingPanelView";
import {LandingPanelDispatchProps, LandingPanelStateProps, UserInfoVM} from "./landingPanelModel";
import {createVisualConnector} from "../../../framework.visual";
import {createSelector} from "@reduxjs/toolkit";
import {authenticationService, authorizationService} from "../../../serviceComposition";
import {RegistrationStatusType} from "../../model/registrationStatusType";
import {AnimationTypes, ComponentTypes, ContainerTypes} from "../../../app.model";

class LandingPanel extends VisualWrapper {
    constructor() {
        super();

        this.id = ComponentTypes.LandingPanelWrapper;

        this.view = LandingPanelView;

        this.mapStateToProps = (state: any, props: any): LandingPanelStateProps => {
            return {
                registrationStatus: this.getAccountStatus(authenticationService.getState()),
                user: this.getCurrentUserProfile(authenticationService.getState())
            };
        }

        this.mapDispatchToProps = (): LandingPanelDispatchProps => {
            return {

            };
        }

        this.displayOptions = {
            containerId: ContainerTypes.BUMED,
            visible: true,
            appearClass: AnimationTypes.FadeIn,
            enterClass: AnimationTypes.FadeIn,
            exitClass: AnimationTypes.FadeOut,
        }
    }

    getAccountStatus = createSelector(
        [(s) => authenticationService.getAuthenticationStatus(), (s) => authorizationService.isAuthorized()],
        (registerStatus, isAuthorized) => {
            let result = RegistrationStatusType.NONE;

            if (isAuthorized) {
               result = RegistrationStatusType.ACTIVE;
            }
            else {
                switch (registerStatus) {
                    case RegistrationStatusType.NONE:
                        result = RegistrationStatusType.NONE;
                        break;
                    case RegistrationStatusType.CREATED:
                        result = RegistrationStatusType.CREATED;
                        break;
                    case RegistrationStatusType.ACTIVE:
                        result = RegistrationStatusType.ACTIVE;
                        break;
                    case RegistrationStatusType.REJECTED:
                        result = RegistrationStatusType.REJECTED;
                        break;

                }

            }

            return result;
        }
    )

    getCurrentUserProfile = createSelector(
        [() => authenticationService.getUserProfile()],
        (userProfile) => {
            let userVM: UserInfoVM = {
                id: userProfile.id,
                email: userProfile.email,
                first_name: userProfile.firstName,
                last_name: userProfile.lastName
            };

            return userVM;
        }
    )
}

export const {
    connectedPresenter: LandingPanelPresenter
} = createVisualConnector(LandingPanel)


