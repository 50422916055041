import React, {Component} from 'react';
import '../views/systemMenu.css';
import '../../../theme/stylesheets/panel.css';
import {
    DocumentUpdateParams,
    Scope,
    SystemMenuPresenterProps,
    SystemMenuPresenterState,
} from "../systemMenuModel";
import SystemMenuView from "../views/systemMenuView";
import {bindInstanceMethods, Nullable} from "../../../../framework.core/extras/utils/typeUtils";
import {templateService} from "../../../../serviceComposition";
import {NoteVM, PocketUpdateParams, PocketVM} from "../../pocketManagerPanel/pocketManagerPanelModel";

class SystemMenuPresenter extends Component<SystemMenuPresenterProps, SystemMenuPresenterState> {
    constructor(props: any, context: any) {
        super(props, context);

        bindInstanceMethods(this);

        this.state = {
            showAddToPocket: false,
            showCreateReportPopup: false,
            showNotePopup: false,
            showSharePopup: false,
            showTitlePopup: false,
            tmpDocument: {},
            tmpNote: {},
            tmpPocket: {},
            tmpReportTitle: '',
            tmpSharedAuthors: {},
            userInputExpanded: false,
        }
    }

    componentDidMount() {
        templateService.fetchTemplates();
    }

    _onToggleShowAddToPocket() {
        const { showAddToPocket } = this.state;
        const { onSelectMenuItem } = this.props;

        this.setState({
            ...this.state,
            showAddToPocket: !showAddToPocket,
        }, () => {
            if (this.state.showAddToPocket) {
                onSelectMenuItem('System_Menu_File');
            }
        });
    }

    _onToggleShowCreateReportPopup() {
        const { showCreateReportPopup } = this.state;

        this.setState({
            ...this.state,
            showCreateReportPopup: !showCreateReportPopup,
        })
    }

    _onToggleShowSharePopup() {
        const { showSharePopup } = this.state;

        this.setState({
            ...this.state,
            showSharePopup: !showSharePopup,
        });
    }

    _onToggleShowTitlePopup() {
        const { showTitlePopup } = this.state;

        this.setState({
            ...this.state,
            showTitlePopup: !showTitlePopup,
        });
    }

    _onToggleShowNotePopup() {
        const { showNotePopup } = this.state;

        this.setState({
            ...this.state,
            showNotePopup: !showNotePopup,
        });
    }

    _onToggleUserInputExpanded() {
        const { userInputExpanded } = this.state;

        this.setState({
            ...this.state,
            userInputExpanded: !userInputExpanded,
        });
    }

    _onTmpPocketChanged(name: string, tmpValue: string) {
        const { tmpPocket } = this.state;

        if (name === "title") {
            const newTmpPocket: PocketVM = {
                ...tmpPocket,
                title: tmpValue
            }

            this.setState({
                ...this.state,
                tmpPocket: newTmpPocket
            });
        }
    }

    _onTmpDocumentChanged(name: string, value: string) {
        const { tmpDocument } = this.state;

        const newTmpDocument: DocumentUpdateParams = {
            ...tmpDocument,
            [name]: value,
        }

        this.setState({
            ...this.state,
            tmpDocument: newTmpDocument,
        });
    }

    _onTmpReportTitleChanged(name: string, value: string) {
        this.setState({
            ...this.state,
            tmpReportTitle: value,
        });
    }

    _onTmpNoteChanged(name: string, value: string) {
        const { tmpNote } = this.state;

        let newTmpNote = {
            ...tmpNote,
            [name]: value,
        }

        this.setState({
            ...this.state,
            tmpNote: newTmpNote,
        })
    }

    _onAddNote() {
        const { onAddNote, selectedPocket, selectedResourceId, selectedExcerptId, selectedNoteId } = this.props;
        const { tmpNote } = this.state;

        if (selectedPocket) {
            const { id:pocket_id } = selectedPocket;
            const { text } = tmpNote;

            let noteVM: NoteVM = {
                id: "null",
                pocket_id,
                text: text || "New Note",
                content: text || "New Note"
            }

            if (selectedNoteId) {
                noteVM = {
                    ...noteVM,
                    id: selectedNoteId
                }
            } else if (selectedExcerptId) {
                if (selectedResourceId) {
                    noteVM = {
                        ...noteVM,
                        resource_id: selectedResourceId,
                        excerpt_id: selectedExcerptId
                    }
                }
            } else if (selectedResourceId) {
                noteVM = {
                    ...noteVM,
                    resource_id: selectedResourceId,
                }
            }

            onAddNote(noteVM);
        }

        this.setState({
            ...this.state,
            tmpNote: {},
            showNotePopup: false,
        });
    }

    _onCreateReport() {
        const { selectedPocket, onCreateReport } = this.props;
        const { tmpReportTitle } = this.state;

        if (selectedPocket) {
            const { id } = selectedPocket;
            if (id) {
                onCreateReport(id, tmpReportTitle);
            }
        }

        this.setState({
            ...this.state,
            tmpReportTitle: '',
            showCreateReportPopup: false,
        });
    }

    _onDelete() {
        const {
            selectedDocument,
            selectedPocket,
            selectedReportId,
            selectedExcerptId,
            selectedNoteId,
            onDeleteDocument,
            onDeleteExcerpt,
            onDeleteNoteFromExcerpt,
            onDeleteNoteFromPocket,
            onDeleteNoteFromResource,
            onDeletePocket,
            onDeleteReport,
            onDeleteResource,
        } = this.props;

        if (selectedPocket) {
            const { id } = selectedPocket;

            if (id) {
                if (selectedNoteId) {
                    if (selectedExcerptId) {
                        onDeleteNoteFromExcerpt(selectedNoteId, id);
                    } else if (selectedDocument) {
                        onDeleteNoteFromResource(selectedNoteId, id);
                    } else {
                        onDeleteNoteFromPocket(selectedNoteId, id);
                    }
                } else if (selectedExcerptId) {
                    onDeleteExcerpt(selectedExcerptId, id);
                } else if (selectedDocument) {
                    const { id:resource_id } = selectedDocument;

                    if (resource_id) {
                        onDeleteResource(resource_id, id);
                    }
                } else if (selectedReportId) {
                    onDeleteReport(selectedReportId, id);
                } else {
                    onDeletePocket(id);
                }
            }
        } else if (selectedDocument) {
            const { id } = selectedDocument;

            if (id) {
                onDeleteDocument(id);
            }
        }
    }

    _onDownload(original?: boolean) {
        const {
            selectedDocument,
            selectedPocket,
            onDownloadPocket,
            onDownloadDocument
        } = this.props;

        if (selectedDocument) {
            const { id } = selectedDocument;

            if (id) {
                onDownloadDocument(id, original);
            }
        } else if (selectedPocket) {
            const { id } = selectedPocket;

            if (id) {
                onDownloadPocket(id);
            }
        }
    }

    _onCreatePocket() {
        const { onCreatePocket } = this.props;
        const { tmpPocket } = this.state;

        const { title } = tmpPocket;

        let pocket_title = title || "New Pocket";

        onCreatePocket(pocket_title);

        this.setState({
            ...this.state,
            tmpPocket: {},
            showTitlePopup: false,
        });
    }

    _onPublish(scope?: Scope) {
        const { selectedDocument, selectedPocket, onUpdateDocument, onUpdatePocket } = this.props;
        const { tmpDocument, tmpPocket } = this.state;

        //Pocket should come first since resource inside pocket might be selected - but still should update pocket
        if (selectedPocket) {
            const { id, title:original_title, scope:pocket_scope } = selectedPocket;
            const { title:edit_title } = tmpPocket;

            const title = edit_title ? edit_title : original_title;

            let edits: PocketUpdateParams = {
                id,
                title,
            }

            //"Publish Changes" automatically sets scope to public, but if doc has "Group" scope, it shouldn't be overwritten
            if (scope && pocket_scope !== Scope.GROUP) {
                edits['scope'] = scope;
            } else if (pocket_scope === Scope.DRAFT) {
                edits['scope'] = Scope.PUBLIC;
            }

            onUpdatePocket(edits);

            this.setState({
                ...this.state,
                tmpPocket: {},
                showTitlePopup: false,
            });
        } else if (selectedDocument) {
            const { id, scope:document_scope } = selectedDocument;

            let updatedDocument: DocumentUpdateParams = Object.assign({}, tmpDocument);

            updatedDocument['id'] = id;

            //"Publish Changes" automatically sets scope to public, but if doc has "Group" scope, it shouldn't be overwritten
            if (scope && document_scope !== Scope.GROUP) {
                updatedDocument['scope'] = scope;
            } else if (document_scope === Scope.DRAFT) {
                updatedDocument['scope'] = Scope.PUBLIC;
            }

            onUpdateDocument(updatedDocument);

            this.setState({
                ...this.state,
                tmpDocument: {},
                showSharePopup: false,
            });
        }
    }

    _onAddToPocket(id: string, pocketId: string) {
        const { onAddToPocket } = this.props;

        onAddToPocket(id, pocketId);

        this._onToggleShowAddToPocket();
    }

    render() {
        const {
            className,
            currentUser,
            onHoverMenuItem,
            onSelectMenuItem,
            onTemplateSelect,
            permissions,
            pockets,
            selectedDocument,
            selectedExcerptId,
            selectedMenuItemId,
            selectedNoteId,
            selectedPocket,
            selectedReportId,
            selectedTemplateId,
            templates,
            userSuggestionSupplier
        } = this.props;

        const {
            showAddToPocket,
            showCreateReportPopup,
            showNotePopup,
            showSharePopup,
            showTitlePopup,
            tmpNote,
            tmpPocket,
            tmpReportTitle,
            tmpSharedAuthors,
            userInputExpanded,
        } = this.state;

        return (
            <SystemMenuView
                className={className}
                currentUser={currentUser}
                onAddNote={this._onAddNote}
                onAddToPocket={this._onAddToPocket}
                onCreatePocket={this._onCreatePocket}
                onCreateReport={this._onCreateReport}
                onDelete={this._onDelete}
                onDownload={this._onDownload}
                onHoverMenuItem={onHoverMenuItem}
                onPublish={this._onPublish}
                onSelectMenuItem={onSelectMenuItem}
                onTemplateSelect={onTemplateSelect}
                onTmpDocumentChanged={this._onTmpDocumentChanged}
                onTmpNoteChanged={this._onTmpNoteChanged}
                onTmpPocketChanged={this._onTmpPocketChanged}
                onTmpReportNameChanged={this._onTmpReportTitleChanged}
                onToggleShowAddToPocket={this._onToggleShowAddToPocket}
                onToggleShowCreateReportPopup={this._onToggleShowCreateReportPopup}
                onToggleShowNotePopup={this._onToggleShowNotePopup}
                onToggleShowSharePopup={this._onToggleShowSharePopup}
                onToggleShowTitlePopup={this._onToggleShowTitlePopup}
                onToggleUserInputExpanded={this._onToggleUserInputExpanded}
                permissions={permissions}
                pockets={pockets}
                selectedDocument={selectedDocument}
                selectedExcerptId={selectedExcerptId}
                selectedMenuItemId={selectedMenuItemId}
                selectedNoteId={selectedNoteId}
                selectedPocket={selectedPocket}
                selectedReportId={selectedReportId}
                selectedTemplateId={selectedTemplateId}
                showAddToPocket={showAddToPocket}
                showCreateReportPopup={showCreateReportPopup}
                showNotePopup={showNotePopup}
                showSharePopup={showSharePopup}
                showTitlePopup={showTitlePopup}
                templates={templates}
                tmpNote={tmpNote}
                tmpPocket={tmpPocket}
                tmpReportTitle={tmpReportTitle}
                tmpSharedAuthors={tmpSharedAuthors}
                userInputExpanded={userInputExpanded}
                userSuggestionSupplier={userSuggestionSupplier}
            />
        );
    }
}

export default SystemMenuPresenter;
