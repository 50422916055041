import React, {Component} from 'react';
import '../views/settingsPanel.css';
import '../../../theme/stylesheets/panel.css';
import {
    SettingsPanelPresenterProps,
    SettingsPanelPresenterState,
    UserInfoVM, UserUpdateParams
} from "../settingsPanelModel";
import SettingsPanelView from "../views/settingsPanelView";
import {bindInstanceMethods} from "../../../../framework.core/extras/utils/typeUtils";
import {arrayEquals, forEach, forEachKVP} from "../../../../framework.core/extras/utils/collectionUtils";
import {userService} from "../../../../serviceComposition";

class SettingsPanelPresenter extends Component<SettingsPanelPresenterProps, SettingsPanelPresenterState> {
    interval!: NodeJS.Timer;

    constructor(props: SettingsPanelPresenterProps, context: any) {
        super(props, context);

        this.state = {
            editProperties: [
                {
                    id: 'first_name',
                    placeholder: 'First Name',
                },
                {
                    id: 'last_name',
                    placeholder: 'Last Name',
                },
                {
                    id: 'department',
                    placeholder: 'Department',
                },
                {
                    id: 'role',
                    placeholder: 'Role',
                },
                {
                    id: 'email_address',
                    placeholder: 'Email',
                },
                {
                    id: 'phone_number',
                    placeholder: 'Phone',
                },
                {
                    id: 'account_status',
                    placeholder: 'Account Status',
                },
                {
                    id: 'approved_by',
                    placeholder: 'Approved By',
                    readonly: true,
                }
            ],
            edit: false,
            tmpUser: {},
        }

        bindInstanceMethods(this);
    }

    componentDidMount() {
        const { currentUser, permissions } = this.props;

        if (currentUser) {
            const { id } = currentUser;

            this._onTmpUserChanged(id || "", "id", id || "");
        }

        if (permissions.canModify) {
            this.interval = setInterval(() => {
                userService.fetchUsers();
            }, 60000); // refresh every 60 seconds
        }

        userService.fetchUsers();
    }

    componentDidUpdate(prevProps: Readonly<SettingsPanelPresenterProps>, prevState: Readonly<SettingsPanelPresenterState>, snapshot?: any) {
        const { currentUser } = this.props;
        const { tmpUser } = this.state;

        if (currentUser && prevProps.currentUser) {
            if (currentUser.id !== "" && prevProps.currentUser.id !== "") {
                if (currentUser !== prevProps.currentUser) {
                    this._refreshDirtyFlag();

                    const {id} = currentUser;
                    const {id: prevId } = prevProps.currentUser;

                    if (id !== prevId) {
                        this._onTmpUserChanged(id || "", "id", id || "");
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    _refreshDirtyFlag() {
        const { currentUser } = this.props;
        const { tmpUser } = this.state;

        // let tmpCurrentUser: UserInfoVM = tmpUsers[currentUser.id || ""];
        let tmpCurrentUser: UserInfoVM = tmpUser;

        if (tmpCurrentUser) {
            let nextTmpCurrentUser = {
                ...tmpCurrentUser
            };
            let edit = false;

            let keysToDelete = [];
            let itemKeys = Object.keys(nextTmpCurrentUser), itemsLength = itemKeys.length;
            for (let index = 0; index < itemsLength; index++) {
                let key = itemKeys[index];

                if (tmpCurrentUser) {
                    if (Array.isArray(tmpCurrentUser[key])) {
                        if (arrayEquals(tmpCurrentUser[key], currentUser[key])) {
                            keysToDelete.push(key)
                        }
                        else {
                            edit = true;
                        }
                    }
                    else {
                        if (tmpCurrentUser[key] === currentUser[key]) {
                            keysToDelete.push(key);
                        }
                        else {
                            edit = true;
                        }
                    }
                }
            }

            forEach(keysToDelete, (key: string) => {
                if (key !== 'id') {
                    delete nextTmpCurrentUser[key];
                }
            });

            let tmpUserCopy: UserInfoVM = {};
            tmpUserCopy = tmpUser;

            tmpUserCopy = nextTmpCurrentUser;

            this.setState({
                ...this.state,
                tmpUser: tmpUserCopy,
            })
        }
    }

    _onLogout() {
        const { onLogout } = this.props;

        if (onLogout) {
            onLogout();
        }
    }

    _onTmpUserChanged(id: string, name: string, value: string) {
        const {tmpUser} = this.state;

        let tmpUserCopy: UserInfoVM = {};

        if (!tmpUser || Object.keys(tmpUser).length === 0) {
            tmpUserCopy = {
                id,
                [name]: value,
            }
        } else {
            const {id: tmpUserId} = tmpUser;
            if (!tmpUserId) return;

            if (tmpUserId === id) {
                tmpUserCopy = {
                    ...tmpUser,
                    [name]: value,
                }
            } else {
                tmpUserCopy = tmpUser;
            }
        }

        this.setState({
            ...this.state,
            tmpUser: tmpUserCopy,
        }, () => this._refreshDirtyFlag())
    }

    _onToggleEdit() {
        const { edit } = this.state;

        let editCopy: boolean = !edit;

        this.setState({
            ...this.state,
            edit: editCopy,
        })
    }

    _onUpdateUser() {
        const { onUpdateUser } = this.props;
        const { tmpUser } = this.state;

        let updatedUser: UserUpdateParams = {};

        if (tmpUser) {
            forEachKVP(tmpUser, (itemKey: keyof UserUpdateParams, itemValue: any) => {
                updatedUser[itemKey] = itemValue;
            })
        }

        if (onUpdateUser) {
            onUpdateUser({...updatedUser});
        }

        this._onToggleEdit()
    }

    render() {
        const {
            currentUser,
            departments,
            managedUsers,
            permissions,
            roles,
        } = this.props;

        const {
            editProperties,
            edit,
            tmpUser,
        } = this.state;

        return (
            <SettingsPanelView
                departments={departments}
                dirty={edit}
                editProperties={editProperties}
                // key={id}
                onLogout={this._onLogout}
                onToggleEdit={this._onToggleEdit}
                onTmpUserChanged={this._onTmpUserChanged}
                onUpdateUser={this._onUpdateUser}
                permissions={permissions}
                roles={roles}
                edit={edit}
                tmpUser={tmpUser}
                currentUser={currentUser}
                userLookup={managedUsers}
            />
        )
    }
}

export default SettingsPanelPresenter;
