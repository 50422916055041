import UploadPanelView from "./uploadPanelView";
import {VisualWrapper} from "../../../framework.visual";
import {createVisualConnector} from "../../../framework.visual";
import {createSelector} from "@reduxjs/toolkit";
import {forEach} from "../../../framework.core/extras/utils/collectionUtils";
import {
    AnimationTypes,
    ComponentTypes,
    ContainerTypes,
    DocumentInfo,
    ReferenceType,
    SelectionTypes
} from "../../../app.model";
import {
    displayService,
    documentService,
    referenceService,
    selectionService
} from "../../../serviceComposition";
import {PendingDocumentVM} from "./uploadPanelModel";

class UploadPanel extends VisualWrapper {
    constructor() {
        super();

        this.id = ComponentTypes.UploadPanelWrapper;

        this.view = UploadPanelView;

        this.displayOptions = {
            containerId: ContainerTypes.SystemToolPanel,
            visible: false,
            appearClass: AnimationTypes.FadeIn,
            enterClass: AnimationTypes.FadeIn,
            exitClass: AnimationTypes.FadeOut
        };

        this.mapStateToProps = (state: any, props: any) => {
            return {
                pendingFiles: this.getPendingFilesVMs(state)
            }
        }

        this.mapDispatchToProps = (dispatch: any) => {
            return {
                onPendingDocumentAdded: (fileList: PendingDocumentVM[]) => documentService.startUpload(fileList),
                onPendingDocumentRemoved: (id: string) => this._removePendingDocument(id),
                onDocumentSelected: (id: string) => this._onDocumentSelected(id),
                onPendingDocumentApproved: (id: string) => this._approvePendingDocument(id),
                onCancelUpload: (id: string) => this._cancelUpload(id),
                onFetchUploadedDocuments: () => this._fetchUploadedDocuments(),
            };
        }
    }

    _onDocumentSelected(id: string) {
        selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, id);//set selected document
        displayService.pushNode(ComponentTypes.DocumentPanelWrapper);//open document panel
        selectionService.setContext(SelectionTypes.MENU_SELECTION, 'Document_Panel_Info');//open menu tab
        selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "EXPANDED");//expand "more info" section on document preview
    }

    _approvePendingDocument(id: string) {
        documentService.approvePendingFile(id);
    }

    _removePendingDocument(id: string) {
        documentService.removePendingFile(id)

        if (id === selectionService.getContext(SelectionTypes.DOCUMENT_SELECTION)) {
            selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, "");

            displayService.popNode(ContainerTypes.DocumentPreviewPanel);
            selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "");
        }
    }

    _cancelUpload(id: string) {
        documentService.cancelUpload(id);
    }

    _fetchUploadedDocuments() {
        documentService.fetchUploadedDocuments();
    }

    getSelectedDocumentId = selectionService.makeGetContext(SelectionTypes.DOCUMENT_SELECTION);

    getPendingFilesVMs = createSelector(
        [(s) => documentService.getPendingDocuments(), (s) => this.getSelectedDocumentId(s), (s) => referenceService.getAllReferences(ReferenceType.STATUS)],
        (items, selectedId, statusReferenceInfos) => {
            let itemVMs: Record<string, PendingDocumentVM> = {};

            forEach(items, (itemVM: DocumentInfo) => {
                const {
                    id,
                    file_name,
                    isUpdating,
                    isDeleted,
                    status: doc_status,
                } = itemVM;

                let statusReferenceInfo = statusReferenceInfos[doc_status];

                let status = '';

                if (!isDeleted) {
                    if (statusReferenceInfo) {
                        status = statusReferenceInfo.title;
                    }
                    else {
                        switch (doc_status) {
                            case "DRAFT":
                                status = "UPLOADING";
                                break;
                            case "PROCESSING":
                            case "ERROR":
                                status = "PROCESSING";
                                break;
                            case "CREATED":
                            case "PDF_AVAILABLE":
                            case "SEARCHABLE":
                            case "NLP_COMPLETE":
                                status = "COMPLETE";
                                break;
                            case "FAILED":
                                status = "FAILED";
                                break;
                            case "CANCELLED":
                                status = "CANCELLED";
                                break;
                            default:
                                break;
                        }
                    }
                }

                let newId: string = id;
                let newStatus: string = status;
                let newIsDeleted: boolean = isDeleted || false;

                if (id === file_name) {
                    if (itemVMs[file_name]) {
                        newId = itemVMs[file_name].id || id;

                        newStatus = itemVMs[file_name].status || status;

                        newIsDeleted = itemVMs[file_name].deleted || isDeleted || false;
                    }
                }

                itemVMs[itemVM.file_name] = {
                    id: newId,
                    file_name: file_name,
                    isUpdating: isUpdating,
                    status: newStatus,
                    selected: newId == selectedId,
                    deleted: newIsDeleted,
                }

            });

            return itemVMs;
        }
    )
}

export const {
    connectedPresenter: UploadPanelPresenter
} = createVisualConnector(UploadPanel);
