import React, {Component} from 'react';
import './libraryView.css';
import {LibraryViewProps} from "./libraryModel";
import { LibraryBannerWrapper } from '../../../../components/searchBanner/libraryBannerWrapper';
import { PocketManagerPanelWrapper } from '../../../../components/pocketManagerPanel/pocketManagerPanelWrapper';
import {SystemMenuWrapper} from "../../../../components/systemMenu/systemMenuWrapper";

export class LibraryView extends Component<LibraryViewProps> {
    render() {
        const { className, permissions, ...rest } = this.props;

        let cn = 'library-view d-flex flex-column';

        if (className) {
            cn += ` ${className}`;
        }

        return (
            <div id={'library-view'} {...rest} className={cn}>
                {
                    permissions.canSearch ?
                        <LibraryBannerWrapper />
                        :
                        <div className={"d-flex flex-fill align-items-center justify-content-center"}>
                            {
                                !permissions.isAuthorizing &&
                                <div className={'display-1 text-secondary'}>You do not have search permissions</div>
                            }
                        </div>
                }

                <SystemMenuWrapper/>

                <PocketManagerPanelWrapper/>
            </div>
        );
    }
}

