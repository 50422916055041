import React from 'react';
import {SVGModel} from '../../../framework.visual';

export const DocumentSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="m 5.81781,31.922032 c -0.38369,-0.10332 -0.66632,-0.2569 -0.9454,-0.51372 -0.29796,-0.2742 -0.45276,-0.51653 -0.58977,-0.92324 -0.10665,-0.31659 -0.10705,-0.37078 -0.10705,-14.47212 0,-12.7763595 0.008,-14.1821195 0.0835,-14.4397595 0.23761,-0.81258002 0.94794,-1.41442002 1.79892,-1.52419002 0.19305,-0.0249 3.02712,-0.0461 6.29794,-0.047 L 18.3029,2.4792258e-6 23.06365,4.8459025 l 4.76076,4.8459 -0.0138,10.3016295 -0.0138,10.30163 -0.12206,0.30228 c -0.26715,0.6616 -0.85492,1.1689 -1.53302,1.32313 -0.32273,0.0734 -1.44397,0.0821 -10.20532,0.079 -8.30842,-0.003 -9.88575,-0.015 -10.1186,-0.0777 z m 20.25306,-1.44782 c 0.098,-0.0606 0.21907,-0.18475 0.26895,-0.27589 0.0848,-0.15502 0.0916,-0.80401 0.10436,-10.05825 l 0.0137,-9.89253 -3.16667,-0.002 c -1.74168,-9.5e-4 -3.32463,-0.0221 -3.51767,-0.047 -0.82684,-0.10665 -1.50394,-0.6607895 -1.82449,-1.4931495 -0.0732,-0.18998 -0.0876,-0.67898 -0.11044,-3.73941 l -0.0263,-3.52085 -5.86435,-0.0134 -5.86436,-0.0134 -0.17889,0.11951 c -0.0984,0.0657 -0.22024,0.2018 -0.27078,0.30239 -0.0868,0.17273 -0.0919,0.958 -0.0919,14.1611695 0,13.20316 0.005,13.98843 0.0919,14.16117 0.0505,0.10058 0.17224,0.23656 0.27044,0.30216 l 0.17856,0.11929 h 9.90484 9.90483 z M 21.85866,6.2537425 c -1.416,-1.41623 -2.59332,-2.57495 -2.61627,-2.57495 -0.0229,0 -0.0349,1.02275 -0.0265,2.27278 0.0146,2.18345 0.0195,2.28001 0.12318,2.45654 0.0594,0.10106 0.18701,0.23703 0.28366,0.30216 0.17571,0.11841 0.17587,0.11842 2.49308,0.11842 h 2.31737 z"/>
    </svg>
);


