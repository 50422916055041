import React from 'react';
import {SVGModel} from '../../../framework.visual/model/svgModel';

export const TextFormatUnderlineSVG = ({className} : SVGModel) => (
    <svg className={className} viewBox="0 0 32 32">
        <path d="M 1.007264,30.876513 V 29.753027 H 16 30.992736 V 30.876513 32 H 16 1.007264 Z m 12.62954,-2.985525 C 11.102653,27.542052 9.3283394,26.759255 7.952059,25.382974 6.7293157,24.160231 5.9776272,22.630956 5.5679854,20.532688 5.3967156,19.655411 5.3932165,19.514769 5.3414502,11.428571 5.3099778,6.5125222 5.258347,3.0910807 5.2128326,2.905569 4.8936888,1.6047558 4.5523449,1.2516812 3.3060789,0.93328736 3.000568,0.8552368 2.4803875,0.76612107 2.1501211,0.73525308 L 1.5496369,0.67913065 V 0.33956494 0 h 5.8886198 5.8886203 v 0.34866828 0.34866828 h -0.301368 c -0.375924,0 -1.457761,0.19880291 -2.023088,0.37177184 -0.581089,0.1777914 -1.0813207,0.6754479 -1.2638632,1.257359 -0.2412646,0.7691049 -0.2805393,1.9625732 -0.2816845,8.5597316 -0.00115,6.654895 0.05313,8.641377 0.2678477,9.801452 0.422764,2.284108 1.457274,3.827728 3.137265,4.681198 1.184252,0.601625 2.058924,0.774609 3.912832,0.773838 1.663089,-6.98e-4 2.445389,-0.115799 3.484776,-0.51275 2.714557,-1.036711 3.897969,-2.932424 4.221921,-6.763109 0.103489,-1.223746 0.105218,-13.4494047 0.0021,-14.5372714 C 24.330855,2.7191369 24.013972,1.9102133 23.358757,1.4581734 22.961346,1.1839946 22.062784,0.87950179 21.294216,0.75856814 L 20.687651,0.663126 V 0.33156262 0 h 4.765134 4.765133 v 0.33355932 0.33355932 l -0.600485,0.0991109 C 27.96172,1.0395095 27.098031,1.5600271 26.765928,2.4847396 c -0.364495,1.0149075 -0.364008,1.004607 -0.42256,8.9438314 -0.03791,5.140394 -0.08458,7.711644 -0.147667,8.135594 -0.41465,2.786487 -1.15476,4.459274 -2.605371,5.88862 -1.172098,1.154914 -2.7936,1.916604 -4.974346,2.336666 -0.713259,0.13739 -4.202101,0.208535 -4.97918,0.101537 z"/>
    </svg>
);


