export enum SelectionTypes {
    DOCUMENT_SELECTION='DOCUMENT_SELECTION',
    REPORT_SELECTION='REPORT_SELECTION',
    RESOURCE_SELECTION='RESOURCE_SELECTION',
    POCKET_SELECTION='POCKET_SELECTION',
    EXCERPT_SELECTION='EXCERPT_SELECTION',
    NOTE_SELECTION='NOTE_SELECTION',
    TAG_SELECTION='TAG_SELECTION',
    TEMPLATE_SELECTION='TEMPLATE_SELECTION',
    POCKET_NODE_PATH_SELECTION='POCKET_NODE_PATH_SELECTION',
    MENU_SELECTION='MENU_SELECTION',
    USER_SELECTION='USER_SELECTION',
    DOCUMENT_INFO_SELECTION='DOCUMENT_INFO_SELECTION',
}
