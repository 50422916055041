export enum StatusType {
    DRAFT="DRAFT",
    PROCESSING="PROCESSING",
    CREATED="CREATED",
    PDF_AVAILABLE="PDF_AVAILABLE",
    SEARCHABLE="SEARCHABLE",
    NLP_COMPLETE="NLP_COMPLETE",
    ERROR="ERROR",
    FAILED="FAILED",
    CANCELLED="CANCELLED",
}
