import React from 'react';
import './knowledgeManagement.css';
import KnowledgeManagementView from "./knowledgeManagementView";
import {KnowledgeManagementDispatchProps, KnowledgeManagementStateProps} from "./knowledgeManagementModel";
import {VisualWrapper} from "../../../framework.visual";
import {createVisualConnector} from "../../../framework.visual";
import {authenticationService} from "../../../serviceComposition";
import {ComponentTypes, ContainerTypes} from "../../../app.model";

class KnowledgeManagement extends VisualWrapper {
    constructor() {
        super();

        this.id = ComponentTypes.KnowledgeManagementWrapper;

        this.view = KnowledgeManagementView;

        this.displayOptions = {
            containerId: ContainerTypes.Main,
            visible: true,
            appearClass: '',
            enterClass: '',
            exitClass: '',
            timeout: 0
        };

        this.mapDispatchToProps = (dispatch: any): KnowledgeManagementDispatchProps => {
            return {

            }
        }

        this.mapStateToProps = (state: any, props: any): KnowledgeManagementStateProps => {
            return {
                isAuthenticated: authenticationService.isLoggedIn(),
                isAuthenticating: authenticationService.isAuthenticating()
            }
        }
    }
}

export const {
    connectedPresenter: KnowledgeManagementPresenter
} = createVisualConnector(KnowledgeManagement);
