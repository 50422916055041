import {EntityProvider} from "../../../app.core";
import {TemplateInfo,} from "../../../app.model";
import {makeGuid} from "../../../framework.core/extras/utils/uniqueIdUtils";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export class MockTemplateProvider extends EntityProvider<TemplateInfo> {
    baseUrl: string = `${serverUrl}/templates`;
    public static class: string = 'MockTemplateProvider';

    templateInfos: Record<string, TemplateInfo> = {};
    index = 0;

    constructor() {
        super();
        super.appendClassName(MockTemplateProvider.class);

        const id1 = 'template_id_1';
        const template1: TemplateInfo = new TemplateInfo(id1);
        template1.title = 'Template 1';
        template1.html = '<p><div style={{textAlign: center}}><span style={{fontSize: 20}}><span style={{fontFamily: Open Sans}}><strong>Bureau of Medicine and Surgery</strong></span></span></div><p><span style={{fontFamily: Open Sans}}></span></p><div style={{textAlign: center}}><span style={{fontSize: 18}}><span style={{fontFamily: Open Sans}}><strong>7700 Arlington Blvd #5113</strong></span></span></div><div style={{textAlign: center}}><span style={{fontSize: 18}}><span style={{fontFamily: Open Sans}}><strong>Falls Church, VA 22042</strong></span></span></div><p><span style={{fontFamily: Open Sans}}></span></p><p><span style={{fontFamily: Open Sans}}>MEMORANDOM FOR                        </span><i><span style={{fontFamily: Open Sans}}>message recipients</span></i></p><p><span style={{fontFamily: Open Sans}}></span></p><p><span style={{fontFamily: Open Sans}}></span></p><p><span style={{fontFamily: Open Sans}}></span></p><p><span style={{fontFamily: Open Sans}}>SUBJECT                                              </span><i><span style={{fontFamily: Open Sans}}>topic and content details</span></i></p><p><i><span style={{fontFamily: Open Sans}}></span></i></p><p><span style={{fontFamily: Open Sans}}></span></p><p><i><span style={{fontFamily: Open Sans}}>body paragraph</span></i></p><p><span style={{fontFamily: Open Sans}}></span></p><p><span style={{fontFamily: Open Sans}}></span></p><p><span style={{fontFamily: Open Sans}}>Please direct any question or comments to the following points of contact</span></p><p><i><span style={{fontFamily: Open Sans}}>sources of reference</span></i></p><p><span style={{fontFamily: Open Sans}}></span></p><p><span style={{fontFamily: Open Sans}}></span></p><p><span style={{fontFamily: Open Sans}}></span></p><p><span style={{fontFamily: Open Sans}}></span></p><p><span style={{fontFamily: Open Sans}}></span></p></p>';

        const id2 = makeGuid();
        const template2: TemplateInfo = new TemplateInfo(id2);
        template2.title = 'Template 2';
        template2.html = '<p><p><span style={{fontSize: 18}}>A </span></p><p></p></p>';

        const id3 = makeGuid();
        const template3: TemplateInfo = new TemplateInfo(id3);
        template3.title = 'Template 3';
        template3.html = '<p><p><span style={{fontSize: 18}}>A </span></p><p></p></p>';

        const id4= makeGuid();
        const template4: TemplateInfo = new TemplateInfo(id4);
        template4.title = 'Template 4';
        template4.html = '<p><p><span style={{fontSize: 18}}>A </span></p><p></p></p>';

        const id5 = makeGuid();
        const template5: TemplateInfo = new TemplateInfo(id5);
        template5.title = 'Template 5';
        template5.html = '<p><p><span style={{fontSize: 18}}>A </span></p><p></p></p>';

        const id6 = makeGuid();
        const template6: TemplateInfo = new TemplateInfo(id6);
        template6.title = 'Template 6';
        template6.html = '<p><p><span style={{fontSize: 18}}>A </span></p><p></p></p>';

        this.templateInfos[id1] = template1;
        this.templateInfos[id2] = template2;
        this.templateInfos[id3] = template3;
        // this.templateInfos[id4] = template4;
        // this.templateInfos[id5] = template5;
        // this.templateInfos[id6] = template6;
    }

    start() {
        super.start();
    }

    getAll(uiRequestData?: any): Promise<TemplateInfo[]> {
        const me = this;
        return new Promise((resolve, reject) => {
            resolve(Object.values(me.templateInfos));
        });
    }
}




