import React, {Component} from "react";
import {AppState, AppProps} from "./appModel";
import {SearchPresenter} from "./search/searchPresenter";
import {DocumentPanelWrapper} from "../../../components/documentPanel/documentPanelWrapper";
import {UploadPanelPresenter} from "../../../components/uploadPanel/uploadPanelPresenter";
import {ProfilePanelWrapper} from "../../../components/profilePanel/profilePanelWrapper";
import {TagsPanelWrapper} from "../../../components/tagsPanel/tagsPanelWrapper";
import {SystemToolbarPresenter} from "../../../components/systemToolbar/systemToolbarPresenter";
import {pocketService, referenceService, statService, tagService} from "../../../../serviceComposition";
import {ReferenceType} from "../../../../app.model";
import {LandingPanelPresenter} from "../../../components/landingPanel/landingPanelPresenter";
import {LoadingIndicator} from "../../../theme/widgets/loadingIndicator/loadingIndicator";
import {Size} from "../../../theme/widgets/loadingIndicator/loadingIndicatorModel";
import {ReportPanelWrapper} from "../../../components/reportPanel/reportPanelWrapper";
import {bindInstanceMethods} from "../../../../framework.core/extras/utils/typeUtils";
import {forEach} from "../../../../framework.core/extras/utils/collectionUtils";
import { LibraryPresenter } from "./library/libraryPresenter";
import {SettingsPanelWrapper} from "../../../components/settingsPanel/settingsPanelWrapper";
import {DocumentPanelPresenter} from "../../../components/documentPanel/_old/documentPanelPresenter";


export class AppView extends Component<AppProps, AppState> {
    private interval!: NodeJS.Timer;

    constructor(props: AppProps) {
        super(props);

        this.state = {
            documentPreviewPanelWidth: 'w-33',
            isMouseDownSearch: false,
            mousePosition: 0,
            movementDirection: 0,
            showDocumentPanelPreview: false,
        }

        bindInstanceMethods(this);
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.fetchData();
        }, 300000); // 5 minutes
        this.fetchData();

        document.addEventListener('click', (event) => {
            let containsMenu = false;
            document.elementsFromPoint(event.x, event.y).forEach((element) => {
                forEach(element.attributes, (attribute: any) => {

                    if (attribute.name === "id") {
                        if (attribute.value.includes('MENU')) {
                            containsMenu = true;
                        }
                    }
                });
            });

            if (!containsMenu) {
                this.props.onResetMenuSelection();
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<AppProps>, prevState: Readonly<AppState>, snapshot?: any) {
        if (!prevProps.permissions.canSearch && this.props.permissions.canSearch) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    fetchData() {
        if (this.props.permissions.canSearch) {
            statService.fetchStats();
            tagService.fetchTags();
            // forEachKVP(ReferenceType, (key: any, value: ReferenceType) => {
            //     referenceService.fetchReferences(value);
            // });

            referenceService.fetchReferences(ReferenceType.DEPARTMENT);
            referenceService.fetchReferences(ReferenceType.PURPOSE);
            referenceService.fetchReferences(ReferenceType.ROLE);

            pocketService.fetchPockets();
            // referenceService.fetchReferences(ReferenceType.STATUS);
        }
    }

    _onMouseDownSearch(event: React.MouseEvent<HTMLDivElement>) {
        this.setState({
            ...this.state,
            isMouseDownSearch: true,
            mousePosition: event.clientX,
        });
    }

    _onMouseLeaveSearch(event: React.MouseEvent<HTMLDivElement>) {
        const {mousePosition, isMouseDownSearch, documentPreviewPanelWidth } = this.state;

        if (isMouseDownSearch) {
            let movementDirection = 1;
            if (event.clientX < mousePosition) {
                movementDirection = -1;
            }

            let showDocumentPanelPreview = false;
            if (documentPreviewPanelWidth === 'w-33' && movementDirection === -1) {
                showDocumentPanelPreview = true;
            } else if (documentPreviewPanelWidth === 'w-67' && movementDirection === 1) {
                showDocumentPanelPreview = true;
            }

            this.setState({
                ...this.state,
                movementDirection,
                showDocumentPanelPreview,
            });
        }
    }

    _onMouseUp(event: React.MouseEvent<HTMLDivElement>) {
        const {
            documentPreviewPanelWidth,
            movementDirection,
            isMouseDownSearch,
        } = this.state;

        if (isMouseDownSearch) {
            if (documentPreviewPanelWidth === 'w-33' && movementDirection === -1) {
                this.setState({
                    ...this.state,
                    isMouseDownSearch: false,
                    documentPreviewPanelWidth: 'w-67',
                    showDocumentPanelPreview: false,
                })
            } else if (documentPreviewPanelWidth === 'w-67' && movementDirection === 1) {
                this.setState({
                    ...this.state,
                    isMouseDownSearch: false,
                    documentPreviewPanelWidth: 'w-33',
                    showDocumentPanelPreview: false,
                })
            }
        }
    }

    render() {
        const {
            className,
            currentSystemTool,
            isDocumentVisible,
            isPocketDocumentVisible,
            isReportVisible,
            permissions,
            isAuthorized,
            isAuthorizing,
            ...rest
        } = this.props;

        const {
            documentPreviewPanelWidth,
            showDocumentPanelPreview,
        } = this.state;

        let cn = `${className ? className : ''} d-flex w-100 h-100`;

        let searchPanelWidth = 'w-33';
        if (documentPreviewPanelWidth === 'w-33') {
            searchPanelWidth = 'w-67';
        }

        return (
            <div id={'analysis'} {...rest} className={cn} onMouseUp={(e) => this._onMouseUp(e)}>
                {
                    // show loading indicator when fetching user status
                    isAuthorizing ?
                    <LoadingIndicator size={Size.large}/>
                        :
                        !isAuthorized ?
                            // is user status not authorized, then show landing page
                            <LandingPanelPresenter/>
                            :
                            <React.Fragment>
                                <SystemToolbarPresenter style={{zIndex: '1'}}/>

                                <div className={'d-flex flex-column position-relative h-100 w-100'}>
                                    <div className={`d-flex h-100 w-100`}>
                                        <div className={`view-container h-100 p-2 ${currentSystemTool ? `flex-fill slideLeftIn-active position-relative ${searchPanelWidth}` : 'slideLeftOut-active'} `}>
                                            <ProfilePanelWrapper/>
                                            <TagsPanelWrapper/>
                                            <UploadPanelPresenter/>
                                            <LibraryPresenter permissions={permissions} className={"d-flex flex-fill flex-basis-0"}/>
                                            <SearchPresenter permissions={permissions} className={"d-flex flex-fill flex-basis-0"}/>
                                        </div>

                                        <div className={`view-container ${(isDocumentVisible || isReportVisible) ? `flex-fill slideRightIn-active position-relative ${documentPreviewPanelWidth}` : 'slideRightOut-active'}`}>
                                            {
                                                (isDocumentVisible || isReportVisible) &&
                                                <div
                                                    className={"position-absolute h-100"}
                                                    style={{cursor: 'e-resize', width: '1rem', left: 0, top: 0, zIndex: '10'}}
                                                    onMouseDown={this._onMouseDownSearch}
                                                    onMouseLeave={this._onMouseLeaveSearch}
                                                />
                                            }
                                            {/*<DocumentPanelWrapper style={{zIndex: '9'}}/>*/}
                                            <DocumentPanelPresenter className={'flex-fill'} style={{zIndex: '9'}}/>
                                            <ReportPanelWrapper className={'flex-fill'} style={{zIndex: '9'}}/>
                                        </div>

                                        {
                                            showDocumentPanelPreview &&
                                            <div className={`drag-preview position-absolute h-100 pe-none ${searchPanelWidth}`}/>
                                        }
                                    </div>
                                    <SettingsPanelWrapper className={"position-absolute"} style={{zIndex: '99'}}/>
                                </div>
                            </React.Fragment>
                }
            </div>
        );
    }
}
