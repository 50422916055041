import React from 'react';
import {SVGModel} from '../../../framework.visual';

export const PocketInfoSVG = ({className, fill, stroke} : SVGModel) => (
    // <svg className={className} viewBox="0 0 32 32">
    //     <path d="M 11.39722,29.96441 C 9.0146,28.84418 5.77625,27.32544 4.20088,26.58942 L 1.33658,25.25121 1.35088,12.6256 1.36518,-1.6758185e-7 H 16.0143 30.66342 V 12.6256 25.25121 l -7.41006,3.37381 C 19.17783,30.48061 15.81765,31.99935 15.7863,32 c -0.0314,6.4e-4 -2.00642,-0.91537 -4.38904,-2.03559 z m 11.63013,-1.73801 7.18003,-3.26636 0.0145,-7.62051 0.0145,-7.6205002 -14.22182,-0.0145 -14.22183,-0.0145 0.002,7.6380602 0.002,7.63807 6.93799,3.25761 c 3.8159,1.79169 6.97739,3.26017 7.02553,3.26328 0.0482,0.003 3.31855,-1.4642 7.26756,-3.2607 z M 30.20738,4.8450398 V 0.45599983 H 16.01426 1.82114 l -0.0146,4.40339997 -0.0146,4.4034 14.20772,-0.0144 14.20772,-0.0144 z"/>
    // </svg>
    <svg
        className={className}
        viewBox="0 0 32 32"
        version="1.1"
        id="svg786"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs
            id="defs776">
            <filter
                id="Path_29728"
                x="0"
                y="0"
                width="82.984001"
                height="88.919998"
                filterUnits="userSpaceOnUse">
                <feOffset
                    dy="3"
                    id="feOffset760" />
                <feGaussianBlur
                    stdDeviation="3"
                    result="blur"
                    id="feGaussianBlur762" />
                <feFlood
                    floodOpacity="0.278"
                    id="feFlood764" />
                <feComposite
                    operator="in"
                    in2="blur"
                    id="feComposite766"
                    result="result1" />
                <feComposite
                    in="SourceGraphic"
                    in2="result1"
                    id="feComposite768" />
            </filter>
            <linearGradient
                id="linear-gradient"
                x1="8.3124852"
                y1="1.7622468"
                x2="8.3124852"
                y2="16.62497"
                gradientUnits="userSpaceOnUse"
                gradientTransform="scale(3.8078263,0.26261702)">
                <stop
                    offset="0"
                    stopOpacity="0"
                    id="stop771" />
                <stop
                    offset="1"
                    stopColor="#545454"
                    stopOpacity="0.141"
                    id="stop773" />
            </linearGradient>
        </defs>
        <g
            id="Group_54032"
            data-name="Group 54032"
            transform="translate(-55.168,-341.453)">
            <g
                id="jeans-pocket"
                transform="matrix(0.35987405,0,0,0.35987405,59.744878,343.88215)">
                <g
                    transform="translate(-9.75,-6.75)"
                    filter="url(#Path_29728)"
                    id="g779">
                    <path
                        id="Path_29728-2"
                        data-name="Path 29728"
                        d="M 6.391,2.043 H 69.875 V 56.737 L 37.644,71.387 6.391,56.737 Z"
                        transform="translate(3.36,4.71)"
                        fill={`${fill ? fill : "#f0f0f0"}`}
                        stroke={`${stroke ? stroke : "#bababa"}`}
                        strokeWidth="1.5" />
                </g>
                <path
                    id="Path_29729"
                    data-name="Path 29729"
                    d="m 64.391,22.043 h 63.484 V 57.2 L 95.644,71.854 64.391,57.2 Z"
                    transform="translate(-64.391,-2.509)"
                    fill={`${fill ? fill : "#f0f0f0"}`}
                    stroke={`${stroke ? stroke : "#bababa"}`}
                    strokeWidth="1" />
                <rect
                    id="Rectangle_33732"
                    data-name="Rectangle 33732"
                    width="63.305"
                    height="4.3660002"
                    transform="translate(0,15.281)"
                    fill="url(#linear-gradient)"
                    x="0"
                    y="0"
                    style={{fill: "url(#linear-gradient)"}} />
            </g>
            <path
                id="Path_29730"
                data-name="Path 29730"
                d="m 6.391,2.043 h 63.084 v 54.35 L 37.448,70.951 6.391,56.393 Z"
                transform="translate(58.527,346.16)"
                fill="none" />
        </g>
    </svg>
);


