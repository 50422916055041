import SystemToolbarView from "./systemToolbarView";
import {createSelector} from "@reduxjs/toolkit";
import {VisualWrapper} from "../../../framework.visual";
import {createVisualConnector} from "../../../framework.visual";
import {DocPreviewSVG} from "../../theme/svgs/docPreviewSVG";
import {forEach} from "../../../framework.core/extras/utils/collectionUtils";
import {
    authorizationService,
    displayService, documentService, pocketService,
    repoService,
    selectionService
} from "../../../serviceComposition";
import {ComponentTypes, ContainerTypes, SelectionTypes, SystemToolMenuItem} from "../../../app.model";
import {SystemToolVM} from "./systemToolbarModel";
import {PERMISSION_ENTITY, PERMISSION_OPERATOR} from "../../../app.core.api";

class SystemToolbar extends VisualWrapper {
    constructor() {
        super();

        this.id = ComponentTypes.SystemToolbarWrapper;

        this.view = SystemToolbarView;

        this.mapStateToProps = (state: any, props: any) => {
            return {
                documentPreviewTool: this.getDocumentPreviewToolVM(state),
                reportTool: this.getReportToolVM(state),
                tools: this.getToolVMs(state),
            }
        }

        this.mapDispatchToProps = () => {
            return {
                onDocumentPreviewSelected: () => this.onDocumentPreviewSelected(),
                onReportSelected: () => this.onReportSelected(),
                onToolSelected: (id: string) => this.onToolSelected(id),
            };
        }
    }

    getTools = () => {
        return repoService.getAll<SystemToolMenuItem>(SystemToolMenuItem.class);
    }

    onToolSelected(nextId: string) {
        let currentId = displayService.getSelectedNodeId(ContainerTypes.SystemToolPanel);

        switch (nextId) {
            // case ComponentTypes.PocketManagerPanelWrapper:
            //     currentId = displayService.getSelectedNodeId(ContainerTypes.PocketManager);
            //
            //     if (nextId === currentId) {
            //         displayService.popNode(ContainerTypes.PocketManager)
            //     } else {
            //         displayService.pushNode(nextId);
            //     }
            //     break;
            case ComponentTypes.SearchWrapper:
            case currentId:
                selectionService.setContext(SelectionTypes.POCKET_SELECTION, '');
                documentService.fetchSearchResults();
                displayService.pushNode(ComponentTypes.SearchWrapper);
                break;
            default:
                if (nextId === ComponentTypes.LibraryWrapper) {
                    pocketService.fetchPockets();
                    selectionService.setContext(SelectionTypes.POCKET_SELECTION, '');
                }
                displayService.pushNode(nextId);
                break;
        }
    }


    getSelectedNode = () => {
        return displayService.getSelectedNodeId(ContainerTypes.SystemToolPanel);
    }

    getPocketNodeInfo = () => {
        return displayService.getNodeInfo(ComponentTypes.PocketManagerPanelWrapper);
    }

    getDocumentNodeInfo = () => {
        return displayService.getNodeInfo(ComponentTypes.DocumentPanelWrapper);
    }

    getReportNodeInfo = () => {
        return displayService.getNodeInfo(ComponentTypes.ReportPanelWrapper);
    }

    getToolVMs = createSelector(
        [ this.getSelectedNode, this.getPocketNodeInfo, this.getTools, authorizationService.getPermissions], // if this changes, will re-evaluate the combiner and trigger a re-render
        (selectedId, pocketNodeInfo, items) => {
            let itemVMs: Record<string, SystemToolVM> = {};

            forEach(items, (item: SystemToolMenuItem) => {

                let enabled = true;
                switch(item.id) {
                    case ComponentTypes.UploadPanelWrapper:
                        enabled = authorizationService.hasPermission(PERMISSION_ENTITY.DOCUMENT, PERMISSION_OPERATOR.POST);
                        break;
                }

                if (enabled) {
                    switch (item.id) {
                        case ComponentTypes.PocketManagerPanelWrapper:
                            itemVMs[item.id] = {
                                ...item,
                                selected: pocketNodeInfo.visible,
                            };
                            break;
                        default:
                            itemVMs[item.id] = {
                                ...item,
                                selected: selectedId === item.id
                            };
                            break;
                    }
                }
            });

            return Object.values(itemVMs);
        }
    );

    onDocumentPreviewSelected() {
        let currentId = displayService.getSelectedNodeId(ContainerTypes.DocumentPreviewPanel)

        if (currentId === ComponentTypes.DocumentPanelWrapper || currentId === ComponentTypes.ReportPanelWrapper) {
            displayService.popNode(ContainerTypes.DocumentPreviewPanel);
            selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "");
        }
        else {
            displayService.pushNode(ComponentTypes.DocumentPanelWrapper);
        }

        if (selectionService.getContext(SelectionTypes.DOCUMENT_SELECTION) !== '') {
            selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
        }
        if (selectionService.getContext(SelectionTypes.REPORT_SELECTION) !== '') {
            selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');
        }
    }

    onReportSelected() {
        let currentId = displayService.getSelectedNodeId(ContainerTypes.DocumentPreviewPanel)

        if (currentId === ComponentTypes.ReportPanelWrapper) {
            displayService.popNode(ContainerTypes.DocumentPreviewPanel);
            selectionService.setContext(SelectionTypes.DOCUMENT_INFO_SELECTION, "");
        }
        else {
            displayService.pushNode(ComponentTypes.ReportPanelWrapper);
        }

        if (selectionService.getContext(SelectionTypes.REPORT_SELECTION) !== '') {
            selectionService.setContext(SelectionTypes.REPORT_SELECTION, '');
        }
        if (selectionService.getContext(SelectionTypes.DOCUMENT_SELECTION) !== '') {
            selectionService.setContext(SelectionTypes.DOCUMENT_SELECTION, '');
        }
    }

    getDocumentPreviewToolVM = createSelector(
        [this.getDocumentNodeInfo],
        (nodeInfo) => {
            const itemVM = {
                id: ComponentTypes.DocumentPanelWrapper,
                graphic: DocPreviewSVG,
                selected: nodeInfo ? nodeInfo.visible : false,
                title: 'Preview'
            }
            return itemVM;
        }
    );

    getReportToolVM = createSelector(
        [this.getReportNodeInfo],
        (nodeInfo) => {
            const itemVM = {
                id: ComponentTypes.ReportPanelWrapper,
                graphic: DocPreviewSVG,
                selected: nodeInfo ? nodeInfo.visible : false,
                title: 'Report'
            }
            return itemVM;
        }
    );
}

export const {
    connectedPresenter: SystemToolbarPresenter
} = createVisualConnector(SystemToolbar);
