import {Nullable} from "../../framework.core/extras/utils/typeUtils";
import {
    ExcerptParamType,
    IDocumentService,
    IPocketService, IReportService,
    IUserService,
    NoteParamType,
    PocketParamType,
    ResourceParamType,
} from "../../app.core.api";
import {Plugin} from "../../framework.core/extras/plugin";
import {
    ExcerptInfo,
    ExcerptMapper,
    NoteInfo,
    PocketInfo,
    PocketMapper,
    ReportMapper,
    ResourceInfo,
    ResourceMapper, SearchParamInfo
} from "../../app.model";
import {IEntityProvider, ISelectionService} from "../../framework.core.api";
import {forEach, forEachKVP} from "../../framework.core/extras/utils/collectionUtils";
import {IRepoItem} from "../../framework.core/services";
import {createSelector, OutputSelector} from "@reduxjs/toolkit";

type GetAllPocketMapperSelector = OutputSelector<any, Record<string, PocketMapper>,
    (res1: Record<string, PocketInfo>,
     res2: Record<string, ResourceInfo>,
     res3: Record<string, ExcerptInfo>,
     res4: Record<string, NoteInfo>,
     res5: string | undefined)
        => (Record<string, PocketMapper>)>;

export class PocketService extends Plugin implements IPocketService {

    public static readonly class:string = 'DocumentService';

    private userService: Nullable<IUserService> = null;
    private selectionService: Nullable<ISelectionService> = null;
    private documentService: Nullable<IDocumentService> = null;

    private reportService: Nullable<IReportService> = null;

    private pocketProvider: Nullable<IEntityProvider<PocketMapper>> = null;
    private excerptProvider: Nullable<IEntityProvider<ExcerptInfo>> = null;
    private noteProvider: Nullable<IEntityProvider<NoteInfo>> = null;
    private resourceProvider: Nullable<IEntityProvider<ResourceInfo>> = null;

    private readonly getAllPocketMapperSelector: GetAllPocketMapperSelector;
    private readonly getSharedPocketsSelector: OutputSelector<any, Record<string, PocketInfo>, (res1: Record<string, PocketInfo>, res2: string | undefined) => Record<string, PocketInfo>>;
    private readonly getOwnedPocketsSelector: OutputSelector<any, Record<string, PocketInfo>, (res1: Record<string, PocketInfo>, res2: string | undefined) => Record<string, PocketInfo>>;

    constructor() {
        super();
        this.appendClassName(PocketService.class);

        this.getAllPocketMapperSelector = createSelector(
            [
                (s) => this.getAll<PocketInfo>(PocketInfo.class),
                (s) => this.getAll<ResourceInfo>(ResourceInfo.class),
                (s) => this.getAll<ExcerptInfo>(ExcerptInfo.class),
                (s) => this.getAll<NoteInfo>(NoteInfo.class),
                (s) => this.userService?.getCurrentUserId(),
            ],
            (pockets, resources, excerpts, notes, currentUserId) => {
                const pocketMappers: Record<string, PocketMapper> = {};

                forEach(pockets, (pocketInfo: PocketInfo) => {
                    const pocketMapper = new PocketMapper(pocketInfo);

                    forEach(pocketInfo.note_ids, (noteId: string) => {
                        const note: NoteInfo = notes[noteId];

                        if (note == null) {
                            this.warn(`Pocket ${pocketInfo.id} refers to note ${noteId}, but note ${noteId} not found!`)
                        }
                        else {
                            pocketMapper.notes[noteId] = notes[noteId];
                        }
                    });

                    forEach(pocketInfo.resource_ids, (resourceId: string) => {

                        const resource: ResourceInfo = resources[resourceId];

                        if (resource == null) {
                            this.warn(`Pocket ${pocketInfo.id} refers to resource ${resourceId}, but resource ${resourceId} not found!`)
                        }
                        else {

                            const resourceMapper = new ResourceMapper(resource);

                            forEach(resource.note_ids, (noteId: string) => {
                                const note: NoteInfo = notes[noteId];

                                if (note == null) {
                                    this.warn(`Resource ${resourceId} refers to note ${noteId}, but note ${noteId} not found!`)
                                }
                                else {
                                    resourceMapper.notes[noteId] = notes[noteId];
                                }
                            });

                            forEach(resource.excerptIds, (excerptId: string) => {
                                const excerpt: ExcerptInfo = excerpts[excerptId];

                                if (excerpt == null) {
                                    this.warn(`Resource ${resourceId} refers to excerpt ${excerptId}, but excerpt ${excerptId} not found!`)
                                }
                                else {

                                    const excerptMapper = new ExcerptMapper(excerpt);

                                    forEach(excerpt.noteIds, (noteId: string) => {
                                        const note: NoteInfo = notes[noteId];

                                        if (note == null) {
                                            this.warn(`Excerpt ${excerptId} refers to note ${noteId}, but note ${noteId} not found!`)
                                        }
                                        else {
                                            excerptMapper.notes[noteId] = notes[noteId];
                                        }
                                    });

                                    resourceMapper.excerptMappers[excerptId] = excerptMapper;
                                }
                            });

                            pocketMapper.resourceMappers[resourceId] = resourceMapper;
                        }
                    });

                    pocketMappers[pocketInfo.id] = pocketMapper;
                });

                return pocketMappers;
            }
        );

        this.getOwnedPocketsSelector = createSelector(
            [(s) => this.getPocketInfos(), (s) => this.userService?.getCurrentUserId()],
            (items, currentUserId) => {
                let result:Record<string, PocketInfo> = {};

                forEach(items, (item: PocketInfo) => {
                    const { id, author_id } = item;

                    //TODO check with owner_id/original_author_id? instead of author_id
                    if (author_id === currentUserId) {
                        result[id] = item;
                    }
                });

                return result;
            }
        );

        this.getSharedPocketsSelector = createSelector(
            [(s) => this.getPocketInfos(), (s) => this.userService?.getCurrentUserId()],
            (items, currentUserId) => {
                let result:Record<string, PocketInfo> = {};

                forEach(items, (item: PocketInfo) => {
                    const { id, author_id, shared_author_ids } = item;

                    if (author_id !== currentUserId) {
                        forEach(shared_author_ids, (shared_author_id: string) => {
                            if (shared_author_id === currentUserId) {
                                result[id] = item;
                            }
                        });
                    }
                });

                return result;
            }
        );
    }

    getReportMapper(reportId: string): Nullable<ReportMapper> {
        throw new Error("Method not implemented.");
    }

    // private makeGetSingleExcerptMapperSelector(id: string): GetExcerptMapperSelector {
    //     return createSelector(
    //         [
    //             () => this.getRepoItem<ReportDocumentInfo>(ReportDocumentInfo.class, id),
    //             () => this.getAll<ExcerptInfo>(ExcerptInfo.class),
    //             () => this.getAll<NoteInfo>(NoteInfo.class)
    //         ],
    //         (reportDocumentInfo, excerpts, notes) => {
    //
    //             const mappers: Record<string, ExcerptMapper> = {};
    //
    //             let documentInfo: Nullable<ReportDocumentInfo> = reportDocumentInfo;
    //
    //             if (documentInfo == null) {
    //                 return mappers;
    //             }
    //
    //             forEach(documentInfo.excerptIds, (excerptId: string) => {
    //
    //                 let excerpt = this.getRepoItem<ExcerptInfo>(ExcerptInfo.class, excerptId);
    //
    //                 const mapperNotes:Record<string, NoteInfo> = {};
    //
    //                 if (excerpt != null) {
    //                     forEach(excerpt.noteIds, (noteId: string) => {
    //                         let note = notes[noteId];
    //                         if (note != null) {
    //                             mapperNotes[noteId] = note;
    //                         }
    //                     })
    //
    //                     mappers[excerptId] = new ExcerptMapper(excerpt, mapperNotes);
    //                 }
    //             });
    //
    //             return mappers;
    //         }
    //     )
    // }

    private getFilteredRecords<T extends IRepoItem>(ids: string[], lookup: Record<string, T>): Record<string, T> {
        const filteredRecords: Record<string, T> = {};

        forEach(ids, (id: string) => {

            let result: Nullable<T> = null;
            if (lookup[id]) {
                result = lookup[id];
            }

            if (result != null) {
                filteredRecords[id] = lookup[id];
            }
            else {
                this.warn(`When building report for ${id}, metadata with id ${id} was not found`);
            }
        });

        return filteredRecords;
    }

    start() {
        super.start();
    }

    stop() {
        super.stop();
    }

    configure() {
        super.configure();
    }

    setUserService(service: IUserService): void {
        this.userService = service;
    }

    setSelectionService(service: ISelectionService) {
        this.selectionService = service;
    }

    setDocumentService(service: IDocumentService): void {
        this.documentService = service
    }

    setReportService(service: IReportService): void {
        this.reportService = service;
    }

    setPocketProvider(provider: IEntityProvider<PocketMapper>): void {
        this.pocketProvider = provider
    }

    setResourceProvider(provider: IEntityProvider<ResourceInfo>): void {
        this.resourceProvider = provider;
    }

    setExcerptProvider(provider: IEntityProvider<ExcerptInfo>): void {
        this.excerptProvider = provider;
    }

    setNoteProvider(provider: IEntityProvider<NoteInfo>): void {
        this.noteProvider = provider;
    }

    getPocketInfos(): Record<string, PocketInfo> {
        return this.getAll(PocketInfo.class);
    }

    getPocketInfo(id: string): Nullable<PocketInfo> {
        let result: Nullable<PocketInfo> = null;

        if (this.pocketProvider != null) {
            result = this.getRepoItem(PocketInfo.class, id);
        }

        return result;
    }

    getPocketMappers(): Record<string, PocketMapper> {
        return this.getAllPocketMapperSelector(this.getRepoState());
    }

    getOwnedPocketInfos(): Record<string, PocketInfo> {
        return this.getOwnedPocketsSelector(this.getRepoState());
    }

    getSharedPocketInfos(): Record<string, PocketInfo> {
        return this.getSharedPocketsSelector(this.getRepoState());
    }

    getPocketMapper(id: string): Nullable<PocketMapper> {
        const pocketMappers = this.getAllPocketMapperSelector(this.getRepoState());

        let result: Nullable<PocketMapper> = null;

        if (pocketMappers[id] != null) {
            result = pocketMappers[id];
        }

        return result;
    }

    addOrUpdatePocket(params: PocketParamType, updateLocal: boolean = true): Promise<Nullable<PocketMapper>> {
        return new Promise<PocketMapper>((resolve, reject) => {
                let shortClassName = 'PocketMapper'

                if (params.id != null) {
                    const existingItem = this.getPocketMapper(params.id);


                    if (existingItem) {
                        let serverParams = {
                            ...params,
                        }
                        if (!params.title) {
                            serverParams = {
                                ...serverParams,
                                title: existingItem.pocket.title,
                            }
                        }
                        const modifiedPocketMapper: any = {};
                        modifiedPocketMapper.id = existingItem.id;
                        modifiedPocketMapper.pocket = serverParams;
                        modifiedPocketMapper.resourceMappers = existingItem.resourceMappers;

                        let pocket = this.getPocketInfo(params.id);
                        if (pocket) {
                            let modifiedPocket = {
                                ...pocket,
                                isUpdating: true,
                            }

                            this.addOrUpdateRepoItem(modifiedPocket);
                        }

                        // check if params other than id exist on params
                        if (this.pocketProvider != null) {
                            this.pocketProvider.update(params.id, modifiedPocketMapper)
                                .then(result => {
                                    if (result != null) {
                                        if (updateLocal) {
                                            const items = this.flattenPocketMapper(result);
                                            this.addOrUpdateAllRepoItems(items);
                                        }
                                        resolve(result);
                                    }
                                    else {
                                        throw 'Pocket provider provided null return value';
                                    }


                                })
                                .catch(error => {
                                    this.error(`Error while updating ${shortClassName} \n ${error}`);
                                    reject(existingItem);
                                })
                        }
                        else {
                            reject(existingItem);
                        }
                    }
                    else {
                        this.error(`Error while retrieving ${shortClassName}: ${shortClassName} id was supplied but does not exist locally`);
                    }
                }
                else {
                    if (this.pocketProvider == null) {
                        this.error(`${shortClassName} Provider is null!`);
                        reject(null);
                    }
                    else {
                        params.author_id = this.userService?.getCurrentUserId();
                        this.pocketProvider.create(params)
                            .then(result => {
                                if (result != null) {
                                    if (updateLocal) {
                                        const items = this.flattenPocketMapper(result);
                                        this.addOrUpdateAllRepoItems(items);
                                    }
                                    resolve(result);
                                }
                                else {
                                    throw 'Error while creating pocket!';
                                }
                            })
                            .catch(error => {
                                this.error(error + `\nError while creating ${shortClassName} with params ${JSON.stringify(params)}`);
                                reject(null);
                            });
                    }
                }
            }
        );
    }

    removePocket(id: string): void {
        this.pocketProvider?.remove(id)
            .then((pocketMapper: Nullable<PocketMapper>) => {
                if (pocketMapper != null) {
                    const items = this.flattenPocketMapper(pocketMapper);
                    this.removeRepoItems(items);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    fetchPocket(id: string): void {
        this.pocketProvider?.getSingle(id)
            .then((pocketMapper: Nullable<PocketMapper>) => {
                // get the items out of pocketMapper
                if (pocketMapper != null) {
                    const items = this.flattenPocketMapper(pocketMapper);
                    this.addOrUpdateAllRepoItems(items);

                    forEach(pocketMapper.pocket.report_ids, (report_id: string) => {
                        this.reportService?.fetchReport(report_id);
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    fetchPockets(): void {
        const user_id = this.userService?.getCurrentUserId();

        let searchText = "NULL";

        if (this.getSearchText() !== "") {
            searchText = this.getSearchText();
        }

        this.pocketProvider?.getAll({user_id, searchText})
            .then((pocketMappers: PocketMapper[]) => {
                // get the items out of pocketMapper
                const items:IRepoItem[] = [];

                forEach(pocketMappers, (pocketMapper: PocketMapper) => {
                    //have to fetch each individually since not all necessary data is returned by API
                    //not using .then() so UI updates faster and is later fully populated
                    this.fetchPocket(pocketMapper.id);

                    // const flattenedItems = this.flattenPocketMapper(pocketMapper);
                    // items.push(...flattenedItems);

                    // forEach(pocketMapper.pocket.report_ids, (report_id: string) => {
                    //     this.reportService?.fetchReport(report_id);
                    // });
                })

                // this.addOrUpdateAllRepoItems(items);
            })
            .catch(error => {
                console.log(error);
            });
    }

    private flattenPocketMapper(pocketMapper: PocketMapper) {
        const result = [];

        result.push(pocketMapper.pocket);

        forEach(pocketMapper.notes, (note: NoteInfo) => {
            if (note) {
                result.push(note);
            }
        });

        forEach(pocketMapper.resourceMappers, (resourceMapper: ResourceMapper) => {
            result.push(resourceMapper.resource);

            forEach(resourceMapper.notes, (note: NoteInfo) => {
                if (note) {
                    result.push(note);
                }
            });

            forEach(resourceMapper.excerptMappers, (excerptMapper: ExcerptMapper) => {
                result.push(excerptMapper.excerpt);

                forEach(excerptMapper.notes, (note: NoteInfo) => {
                    if (note) {
                        result.push(note);
                    }
                });
            });
        });

        return result;
    }

    addOrUpdateExcerpt(excerptParamType: ExcerptParamType, updateLocal: boolean = true): Promise<Nullable<ExcerptInfo>> {
        return this.addOrUpdateRemoteItem(ExcerptInfo.class, this.excerptProvider, excerptParamType, updateLocal);
    }

    removeExcerpt(id: string) {
        // TODO might want to remove any notes that referenced this excerpt
        return this.deleteRemoteItem(ExcerptInfo.class, id, this.excerptProvider);
    }

    getExcerpt(id: string): Nullable<ExcerptInfo> {
        return this.getRepoItem(ExcerptInfo.class, id);
    }

    addOrUpdateNote(noteParam: NoteParamType, updateLocal: boolean = true): Promise<Nullable<NoteInfo>> {
        return this.addOrUpdateRemoteItem(NoteInfo.class, this.noteProvider, noteParam, updateLocal);
    }

    removeNote(id: string): Promise<Nullable<NoteInfo>> {
        // TODO might want to remove any pockets/resources that referenced this note
        return this.deleteRemoteItem<NoteInfo>(NoteInfo.class, id, this.noteProvider, );
    }

    getNote(id: string): Nullable<NoteInfo> {
        return this.getRepoItem(NoteInfo.class, id);
    }

    addOrUpdateResource(resourceParam: ResourceParamType, updateLocal: boolean = true): Promise<Nullable<ResourceInfo>> {
        return this.addOrUpdateRemoteItem(ResourceInfo.class, this.resourceProvider, resourceParam, updateLocal);
    }

    //TODO deprecate since not used
    removeResource(id: string) {
        // TODO might want to remove any excerpts that referenced this resource
        this.deleteRemoteItem(ResourceInfo.class, id, this.resourceProvider, false)
            .then(resource => {
                if (resource != null) {
                    forEach(this.getPocketMappers(), (pocketMapper: PocketMapper) => {
                        if (pocketMapper.resourceMappers[id] != null) {
                            const pocket = pocketMapper.pocket;
                            const resourceIds:string[] = [];
                            forEach(pocket.resource_ids, (resourceId: string) => {
                                if (resourceId !== id) {
                                    resourceIds.push(resourceId);
                                }
                            })
                            pocket.resource_ids = resourceIds;
                            this.removeRepoItem(resource)
                            void this.addOrUpdatePocket(pocket);

                            return true;
                        }
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    getResource(id: string): Nullable<ResourceInfo> {
        return this.getRepoItem(ResourceInfo.class, id);
    }

    addNoteAndExcerptToPocket(noteParams: NoteParamType, excerptParams: ExcerptParamType, resourceParams: ResourceParamType, pocketParams: PocketParamType): void {
        const { id:note_id } = noteParams;
        const author_id = this.userService?.getCurrentUserId() || "";

        //check if note exists
        if (note_id) {
            //note exists
            let note: Nullable<NoteInfo> = this.getNote(note_id);

            if (note) {
                const { id:excerpt_id } = excerptParams;
                const { content, text } = noteParams;

                if (content) {
                    note.content = content;
                }
                if (text) {
                    note.text = text;
                }

                //check if excerpt exists
                if (excerpt_id) {
                    //excerpt exists
                    let excerpt: Nullable<ExcerptInfo> = this.getExcerpt(excerpt_id);

                    if (excerpt) {
                        const { noteIds } = excerpt;

                        let note_ids: string[] = [];
                        Object.assign(note_ids, noteIds);

                        let excerptContainsNote = false;
                        //check if excerpt already contains note
                        forEach(noteIds, (id: string) => {
                            if (note_id === id) {
                                excerptContainsNote = true;
                            }
                        });

                        if (!excerptContainsNote) {
                            note_ids.push(note_id);
                        }

                        excerpt.noteIds = note_ids;

                        const { id: resource_id } = resourceParams;

                        //check if resource exists
                        if (resource_id) {
                            //resource exists
                            let resource: Nullable<ResourceInfo> = this.getResource(resource_id);

                            if (resource) {
                                const { id:pocket_id } = pocketParams;

                                if (pocket_id) {
                                    let pocketMapper = this.getPocketMapper(pocket_id);

                                    if (pocketMapper) {
                                        pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id].excerpt.noteIds = note_ids;
                                        pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id].notes[note_id] = note;

                                        this.pocketProvider?.update(pocket_id, pocketMapper)
                                            .then(pocketMapper => {
                                                if (pocketMapper) {
                                                    const items:IRepoItem[] = [];

                                                    const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                    items.push(...flattenedItems);

                                                    this.addOrUpdateAllRepoItems(items);
                                                }
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    }
                                }
                            }
                        } else {
                            //resource does not exist or no resource id provided
                            const { source_id } = resourceParams;

                            if (source_id) {
                                const { id:pocket_id } = pocketParams;

                                let resource_id: string = "";

                                if (pocket_id) {
                                    let pocketMapper = this.getPocketMapper(pocket_id);

                                    if (pocketMapper) {
                                        let pocketContainsResource: boolean = false;

                                        forEach(pocketMapper.resourceMappers, (resourceMapper: ResourceMapper) => {
                                            if (resourceMapper.resource.source_id === source_id) {
                                                pocketContainsResource = true;
                                                resource_id = resourceMapper.resource.id;
                                            }
                                        });

                                        if (!pocketContainsResource) {
                                            //resource does not exist
                                            let newResourceParams: ResourceParamType = this._setResourceParamsFromDocument(resourceParams);

                                            newResourceParams= {
                                                ...newResourceParams,
                                                author_id,
                                                excerptIds: [excerpt_id],
                                            }

                                            this.addOrUpdateResource(newResourceParams)
                                                .then(resource => {
                                                    if (resource) {
                                                        const { id:resource_id } = resource;

                                                        if (pocketMapper && excerpt && note) {
                                                            let resourceMapper: ResourceMapper = new ResourceMapper(resource);
                                                            let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                                            excerptMapper.notes[note_id] = note;
                                                            resourceMapper.excerptMappers[excerpt_id] = excerptMapper;

                                                            pocketMapper.resourceMappers[resource_id] = resourceMapper;

                                                            this.pocketProvider?.update(pocket_id, pocketMapper)
                                                                .then(pocketMapper => {
                                                                    if (pocketMapper) {
                                                                        const items:IRepoItem[] = [];

                                                                        const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                                        items.push(...flattenedItems);

                                                                        this.addOrUpdateAllRepoItems(items);
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    console.log(error);
                                                                });
                                                        }
                                                    }
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                        } else {
                                            //resource exists
                                            pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id].excerpt.noteIds = note_ids;
                                            pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id].notes[note_id] = note;

                                            this.pocketProvider?.update(pocket_id, pocketMapper)
                                                .then(pocketMapper => {
                                                    if (pocketMapper) {
                                                        const items:IRepoItem[] = [];

                                                        const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                        items.push(...flattenedItems);

                                                        this.addOrUpdateAllRepoItems(items);
                                                    }
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    //excerpt does not exist
                    let newExcerptParams = {
                        ...excerptParams,
                        authorId: author_id,
                    }

                    this.addOrUpdateExcerpt(newExcerptParams)
                        .then(excerpt => {
                            if (excerpt) {
                                const { id:excerpt_id, noteIds } = excerpt;

                                let note_ids: string[] = [];
                                Object.assign(note_ids, noteIds);

                                let excerptContainsNote = false;
                                //check if excerpt already contains note
                                forEach(noteIds, (id: string) => {
                                    if (note_id === id) {
                                        excerptContainsNote = true;
                                    }
                                });

                                if (!excerptContainsNote) {
                                    note_ids.push(note_id);
                                }

                                excerpt.noteIds = note_ids;

                                const { id: resource_id } = resourceParams;

                                //check if resource exists
                                if (resource_id) {
                                    //resource exists
                                    let resource: Nullable<ResourceInfo> = this.getResource(resource_id);

                                    if (resource) {
                                        const { id:pocket_id } = pocketParams;

                                        if (pocket_id) {
                                            let pocketMapper = this.getPocketMapper(pocket_id);

                                            if (pocketMapper && note) {
                                                let newExcerptIds: string[] = [];

                                                Object.assign(newExcerptIds, resource.excerptIds);
                                                newExcerptIds.push(excerpt_id);

                                                let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                                excerptMapper.notes[note_id] = note;

                                                pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id] = excerptMapper;
                                                pocketMapper.resourceMappers[resource_id].resource.excerptIds = newExcerptIds;

                                                this.pocketProvider?.update(pocket_id, pocketMapper)
                                                    .then(pocketMapper => {
                                                        if (pocketMapper) {
                                                            const items:IRepoItem[] = [];

                                                            const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                            items.push(...flattenedItems);

                                                            this.addOrUpdateAllRepoItems(items);
                                                        }
                                                    })
                                                    .catch(error => {
                                                        console.log(error);
                                                    });
                                            }
                                        }
                                    }
                                } else {
                                    //resource does not exist or no resource id provided
                                    const { source_id } = resourceParams;

                                    if (source_id) {
                                        const { id:pocket_id } = pocketParams;

                                        let resource_id: string = "";

                                        if (pocket_id) {
                                            let pocketMapper = this.getPocketMapper(pocket_id);

                                            if (pocketMapper) {
                                                let pocketContainsResource: boolean = false;

                                                forEach(pocketMapper.resourceMappers, (resourceMapper: ResourceMapper) => {
                                                    if (resourceMapper.resource.source_id === source_id) {
                                                        pocketContainsResource = true;
                                                        resource_id = resourceMapper.resource.id;
                                                    }
                                                });

                                                if (!pocketContainsResource) {
                                                    //resource does not exist
                                                    let newResourceParams: ResourceParamType = this._setResourceParamsFromDocument(resourceParams);

                                                    newResourceParams= {
                                                        ...newResourceParams,
                                                        author_id,
                                                        excerptIds: [excerpt_id],
                                                    }

                                                    this.addOrUpdateResource(newResourceParams)
                                                        .then(resource => {
                                                            if (resource) {
                                                                const { id:resource_id } = resource;

                                                                if (pocketMapper && excerpt && note) {
                                                                    let newExcerptIds: string[] = [];

                                                                    Object.assign(newExcerptIds, resource.excerptIds);
                                                                    newExcerptIds.push(excerpt_id);

                                                                    resource.excerptIds = newExcerptIds;

                                                                    let resourceMapper: ResourceMapper = new ResourceMapper(resource);
                                                                    let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                                                    excerptMapper.notes[note_id] = note;
                                                                    resourceMapper.excerptMappers[excerpt_id] = excerptMapper;

                                                                    pocketMapper.resourceMappers[resource_id] = resourceMapper;

                                                                    this.pocketProvider?.update(pocket_id, pocketMapper)
                                                                        .then(pocketMapper => {
                                                                            if (pocketMapper) {
                                                                                const items:IRepoItem[] = [];

                                                                                const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                                                items.push(...flattenedItems);

                                                                                this.addOrUpdateAllRepoItems(items);
                                                                            }
                                                                        })
                                                                        .catch(error => {
                                                                            console.log(error);
                                                                        });
                                                                }
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.log(error);
                                                        });
                                                } else {
                                                    //resource exists

                                                    if (note) {
                                                        let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                                        excerptMapper.notes[note_id] = note;

                                                        pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id] = excerptMapper;

                                                        this.pocketProvider?.update(pocket_id, pocketMapper)
                                                            .then(pocketMapper => {
                                                                if (pocketMapper) {
                                                                    const items:IRepoItem[] = [];

                                                                    const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                                    items.push(...flattenedItems);

                                                                    this.addOrUpdateAllRepoItems(items);
                                                                }
                                                            })
                                                            .catch(error => {
                                                                console.log(error);
                                                            });
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            }
        } else {
            //note does not exist
            let newNoteParams = {
                ...noteParams,
                author_id,
            }

            this.addOrUpdateNote(newNoteParams)
                .then(note => {
                    if (note) {
                        const { id:note_id } = note;

                        const { id:excerpt_id } = excerptParams;

                        //check if excerpt exists
                        if (excerpt_id) {
                            //excerpt exists
                            let excerpt: Nullable<ExcerptInfo> = this.getExcerpt(excerpt_id);

                            if (excerpt) {
                                const { noteIds } = excerpt;

                                let note_ids: string[] = [];
                                Object.assign(note_ids, noteIds);
                                note_ids.push(note_id);

                                excerpt.noteIds = note_ids;

                                const { id: resource_id } = resourceParams;

                                //check if resource exists
                                if (resource_id) {
                                    //resource exists
                                    let resource: Nullable<ResourceInfo> = this.getResource(resource_id);

                                    if (resource) {
                                        const { id:pocket_id } = pocketParams;

                                        if (pocket_id) {
                                            let pocketMapper = this.getPocketMapper(pocket_id);

                                            if (pocketMapper) {
                                                pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id].excerpt.noteIds = note_ids;
                                                pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id].notes[note_id] = note;

                                                console.log(pocketMapper);

                                                this.pocketProvider?.update(pocket_id, pocketMapper)
                                                    .then(pocketMapper => {
                                                        if (pocketMapper) {
                                                            const items:IRepoItem[] = [];

                                                            const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                            items.push(...flattenedItems);

                                                            this.addOrUpdateAllRepoItems(items);
                                                        }
                                                    })
                                                    .catch(error => {
                                                        console.log(error);
                                                    });
                                            }
                                        }
                                    }
                                } else {
                                    //resource does not exist or no resource id provided
                                    const { source_id } = resourceParams;

                                    if (source_id) {
                                        const { id:pocket_id } = pocketParams;

                                        let resource_id: string = "";

                                        if (pocket_id) {
                                            let pocketMapper = this.getPocketMapper(pocket_id);

                                            console.log(pocketMapper);

                                            if (pocketMapper) {
                                                let pocketContainsResource: boolean = false;

                                                forEach(pocketMapper.resourceMappers, (resourceMapper: ResourceMapper) => {
                                                    if (resourceMapper.resource.source_id === source_id) {
                                                        pocketContainsResource = true;
                                                        resource_id = resourceMapper.resource.id;
                                                    }
                                                });

                                                if (!pocketContainsResource) {
                                                    //resource does not exist
                                                    let newResourceParams: ResourceParamType = this._setResourceParamsFromDocument(resourceParams);

                                                    newResourceParams= {
                                                        ...newResourceParams,
                                                        author_id,
                                                        excerptIds: [excerpt_id],
                                                    }

                                                    this.addOrUpdateResource(newResourceParams)
                                                        .then(resource => {
                                                            if (resource) {
                                                                const { id:resource_id } = resource;

                                                                if (pocketMapper && excerpt && note) {
                                                                    let resourceMapper: ResourceMapper = new ResourceMapper(resource);
                                                                    let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                                                    excerptMapper.notes[note_id] = note;
                                                                    resourceMapper.excerptMappers[excerpt_id] = excerptMapper;

                                                                    pocketMapper.resourceMappers[resource_id] = resourceMapper;

                                                                    console.log(pocketMapper);

                                                                    this.pocketProvider?.update(pocket_id, pocketMapper)
                                                                        .then(pocketMapper => {
                                                                            if (pocketMapper) {
                                                                                const items:IRepoItem[] = [];

                                                                                const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                                                items.push(...flattenedItems);

                                                                                this.addOrUpdateAllRepoItems(items);
                                                                            }
                                                                        })
                                                                        .catch(error => {
                                                                            console.log(error);
                                                                        });
                                                                }
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.log(error);
                                                        });
                                                } else {
                                                    //resource exists

                                                    if (note) {
                                                        pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id].excerpt.noteIds = note_ids;
                                                        pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id].notes[note_id] = note;

                                                        this.pocketProvider?.update(pocket_id, pocketMapper)
                                                            .then(pocketMapper => {
                                                                if (pocketMapper) {
                                                                    const items:IRepoItem[] = [];

                                                                    const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                                    items.push(...flattenedItems);

                                                                    this.addOrUpdateAllRepoItems(items);
                                                                }
                                                            })
                                                            .catch(error => {
                                                                console.log(error);
                                                            });
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            //excerpt does not exist
                            let newExcerptParams = {
                                ...excerptParams,
                                authorId: author_id,
                            }

                            this.addOrUpdateExcerpt(newExcerptParams)
                                .then(excerpt => {
                                    if (excerpt) {
                                        const { id:excerpt_id, noteIds } = excerpt;

                                        let note_ids: string[] = [];
                                        Object.assign(note_ids, noteIds);
                                        note_ids.push(note_id);

                                        excerpt.noteIds = note_ids;

                                        const { id: resource_id } = resourceParams;

                                        //check if resource exists
                                        if (resource_id) {
                                            //resource exists
                                            let resource: Nullable<ResourceInfo> = this.getResource(resource_id);

                                            if (resource) {
                                                const { id:pocket_id } = pocketParams;

                                                if (pocket_id) {
                                                    let pocketMapper = this.getPocketMapper(pocket_id);

                                                    console.log(pocketMapper);

                                                    if (pocketMapper && note) {
                                                        let newExcerptIds: string[] = [];

                                                        Object.assign(newExcerptIds, resource.excerptIds);
                                                        newExcerptIds.push(excerpt_id);

                                                        let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                                        excerptMapper.notes[note_id] = note;

                                                        pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id] = excerptMapper;
                                                        pocketMapper.resourceMappers[resource_id].resource.excerptIds = newExcerptIds;

                                                        console.log(pocketMapper);

                                                        this.pocketProvider?.update(pocket_id, pocketMapper)
                                                            .then(pocketMapper => {
                                                                if (pocketMapper) {
                                                                    const items:IRepoItem[] = [];

                                                                    const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                                    items.push(...flattenedItems);

                                                                    this.addOrUpdateAllRepoItems(items);
                                                                }
                                                            })
                                                            .catch(error => {
                                                                console.log(error);
                                                            });
                                                    }
                                                }
                                            }
                                        } else {
                                            //resource does not exist or no resource id provided
                                            const { source_id } = resourceParams;

                                            if (source_id) {
                                                const { id:pocket_id } = pocketParams;

                                                let resource_id: string = "";

                                                if (pocket_id) {
                                                    let pocketMapper = this.getPocketMapper(pocket_id);

                                                    console.log(pocketMapper);

                                                    if (pocketMapper) {
                                                        let pocketContainsResource: boolean = false;

                                                        forEach(pocketMapper.resourceMappers, (resourceMapper: ResourceMapper) => {
                                                            if (resourceMapper.resource.source_id === source_id) {
                                                                pocketContainsResource = true;
                                                                resource_id = resourceMapper.resource.id;
                                                            }
                                                        });

                                                        if (!pocketContainsResource) {
                                                            //resource does not exist
                                                            let newResourceParams: ResourceParamType = this._setResourceParamsFromDocument(resourceParams);

                                                            newResourceParams= {
                                                                ...newResourceParams,
                                                                author_id,
                                                                excerptIds: [excerpt_id],
                                                            }

                                                            this.addOrUpdateResource(newResourceParams)
                                                                .then(resource => {
                                                                    if (resource) {
                                                                        const { id:resource_id } = resource;

                                                                        if (pocketMapper && excerpt && note) {
                                                                            let resourceMapper: ResourceMapper = new ResourceMapper(resource);
                                                                            let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                                                            excerptMapper.notes[note_id] = note;
                                                                            resourceMapper.excerptMappers[excerpt_id] = excerptMapper;

                                                                            pocketMapper.resourceMappers[resource_id] = resourceMapper;

                                                                            console.log(pocketMapper);

                                                                            this.pocketProvider?.update(pocket_id, pocketMapper)
                                                                                .then(pocketMapper => {
                                                                                    if (pocketMapper) {
                                                                                        const items:IRepoItem[] = [];

                                                                                        const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                                                        items.push(...flattenedItems);

                                                                                        this.addOrUpdateAllRepoItems(items);
                                                                                    }
                                                                                })
                                                                                .catch(error => {
                                                                                    console.log(error);
                                                                                });
                                                                        }
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    console.log(error);
                                                                });
                                                        } else {
                                                            //resource exists
                                                            if (note) {
                                                                let newExcerptIds: string[] = [];

                                                                Object.assign(newExcerptIds, pocketMapper.resourceMappers[resource_id].resource.excerptIds);
                                                                newExcerptIds.push(excerpt_id);

                                                                let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                                                excerptMapper.notes[note_id] = note;

                                                                pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id] = excerptMapper;
                                                                pocketMapper.resourceMappers[resource_id].resource.excerptIds = newExcerptIds;

                                                                this.pocketProvider?.update(pocket_id, pocketMapper)
                                                                    .then(pocketMapper => {
                                                                        if (pocketMapper) {
                                                                            const items:IRepoItem[] = [];

                                                                            const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                                            items.push(...flattenedItems);

                                                                            this.addOrUpdateAllRepoItems(items);
                                                                        }
                                                                    })
                                                                    .catch(error => {
                                                                        console.log(error);
                                                                    });
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    addExcerptToPocket(excerptParams: ExcerptParamType, resourceParams: ResourceParamType, pocketParams: PocketParamType): Promise<void> {//DONE
        return new Promise<void> (() => {
            const { id:excerpt_id } = excerptParams;
            const authorId = this.userService?.getCurrentUserId() || "";

            //check if excerpt exists
            if (excerpt_id) {
                //excerpt exists
                let excerpt: Nullable<ExcerptInfo> = this.getExcerpt(excerpt_id);

                if (excerpt) {
                    const { id:pocket_id } = pocketParams;

                    if (pocket_id) {
                        let pocketMapper = this.getPocketMapper(pocket_id);

                        if (pocketMapper) {
                            let resource: Nullable<ResourceInfo> = null;
                            let newExcerptIds: string[] = [];

                            // check if the source is already included in a resource for this pocket
                            forEach(pocketMapper.resourceMappers, (resourceMapper: ResourceMapper) => {
                                if (resourceMapper.resource.source_id === resourceParams.source_id) {
                                    resource = this.getResource(resourceMapper.resource.id);
                                    if (resource) {
                                        Object.assign(newExcerptIds, resource.excerptIds);
                                        newExcerptIds.push(excerpt_id);
                                    }

                                }
                            });

                            if (resource) {
                                const { id:resource_id } = resource;

                                let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id] = excerptMapper;
                                pocketMapper.resourceMappers[resource_id].resource.excerptIds = newExcerptIds;

                                this.pocketProvider?.update(pocketMapper.id, pocketMapper)
                                    .then(pocketMapper => {
                                        if (pocketMapper) {
                                            const items:IRepoItem[] = [];

                                            const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                            items.push(...flattenedItems);

                                            this.addOrUpdateAllRepoItems(items);
                                        }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });

                            } else {
                                let newResourceParams: ResourceParamType = this._setResourceParamsFromDocument(resourceParams);

                                newResourceParams = {
                                    ...newResourceParams,
                                    excerptIds: [excerpt.id],
                                    author_id: authorId,
                                }

                                this.addOrUpdateResource(newResourceParams)
                                    .then(resource => {
                                        if (resource) {
                                            if (pocketMapper && excerpt) {
                                                let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                                let resourceMapper: ResourceMapper = new ResourceMapper(resource);
                                                resourceMapper.excerptMappers[excerpt.id] = excerptMapper;

                                                pocketMapper.resourceMappers[resource.id] = resourceMapper;

                                                this.pocketProvider?.update(pocketMapper.id, pocketMapper)
                                                    .then(pocketMapper => {
                                                        if (pocketMapper) {
                                                            const items:IRepoItem[] = [];

                                                            const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                            items.push(...flattenedItems);

                                                            this.addOrUpdateAllRepoItems(items);
                                                        }
                                                    })
                                                    .catch(error => {
                                                        console.log(error);
                                                    });
                                            }
                                        }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            }
                        }
                    }
                }
            } else {
                //excerpt does not exist
                let newExcerptParams: ExcerptParamType = {
                    ...excerptParams,
                    authorId,
                }

                this.addOrUpdateExcerpt(newExcerptParams)
                    .then(excerpt => {
                        if (excerpt) {
                            const { id:excerpt_id } = excerpt;
                            const { id:pocket_id } = pocketParams;

                            if (pocket_id) {
                                let pocketMapper = this.getPocketMapper(pocket_id);

                                if (pocketMapper) {
                                    let resource: Nullable<ResourceInfo> = null;
                                    let newExcerptIds: string[] = [];

                                    // check if the source is already included in a resource for this pocket
                                    forEach(pocketMapper.resourceMappers, (resourceMapper: ResourceMapper) => {
                                        if (resourceMapper.resource.source_id === resourceParams.source_id) {
                                            resource = this.getResource(resourceMapper.resource.id);
                                            if (resource) {
                                                Object.assign(newExcerptIds, resource.excerptIds);
                                                newExcerptIds.push(excerpt_id);
                                            }

                                        }
                                    });

                                    if (resource) {
                                        const { id:resource_id } = resource;

                                        let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                        pocketMapper.resourceMappers[resource_id].excerptMappers[excerpt_id] = excerptMapper;
                                        pocketMapper.resourceMappers[resource_id].resource.excerptIds = newExcerptIds;

                                        this.pocketProvider?.update(pocketMapper.id, pocketMapper)
                                            .then(pocketMapper => {
                                                if (pocketMapper) {
                                                    const items:IRepoItem[] = [];

                                                    const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                    items.push(...flattenedItems);

                                                    this.addOrUpdateAllRepoItems(items);
                                                }
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });

                                    } else {
                                        let newResourceParams: ResourceParamType = this._setResourceParamsFromDocument(resourceParams);

                                        newResourceParams = {
                                            ...newResourceParams,
                                            excerptIds: [excerpt.id],
                                            author_id: authorId,
                                        }

                                        this.addOrUpdateResource(newResourceParams)
                                            .then(resource => {
                                                if (resource) {
                                                    if (pocketMapper) {

                                                        let excerptMapper: ExcerptMapper = new ExcerptMapper(excerpt);

                                                        let resourceMapper: ResourceMapper = new ResourceMapper(resource);
                                                        resourceMapper.excerptMappers[excerpt.id] = excerptMapper;

                                                        pocketMapper.resourceMappers[resource.id] = resourceMapper;

                                                        this.pocketProvider?.update(pocketMapper.id, pocketMapper)
                                                            .then(pocketMapper => {
                                                                if (pocketMapper) {
                                                                    const items:IRepoItem[] = [];

                                                                    const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                                    items.push(...flattenedItems);

                                                                    this.addOrUpdateAllRepoItems(items);
                                                                }
                                                            })
                                                            .catch(error => {
                                                                console.log(error);
                                                            });
                                                    }
                                                }
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    }
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        });
    };

    addNoteToPocket(noteParams: NoteParamType, pocketParams: PocketParamType): Promise<void> {//DONE
        return new Promise<void> (() => {
            const { id:note_id } = noteParams;

            //check if note exists
            if (note_id) {
                let note: Nullable<NoteInfo> = this.getNote(note_id);

                if (note) {
                    const { id:pocket_id } = pocketParams;
                    const { content, text } = noteParams;

                    if (content) {
                        note.content = content;
                    }
                    if (text) {
                        note.text = text;
                    }

                    if (pocket_id) {
                        let pocketMapper = this.getPocketMapper(pocket_id);

                        if (pocketMapper) {
                            let note_ids: string[] = [];
                            //copy pocket note ids
                            Object.assign(note_ids, pocketMapper.pocket.note_ids);
                            note_ids.push(note.id);

                            pocketMapper.pocket.note_ids = note_ids;
                            pocketMapper.notes[note_id] = note

                            this.pocketProvider?.update(pocket_id, pocketMapper)
                                .then(pocketMapper => {
                                    if (pocketMapper) {
                                        const items:IRepoItem[] = [];

                                        const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                        items.push(...flattenedItems);

                                        this.addOrUpdateAllRepoItems(items);
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }
            } else {
                const author_id = this.userService?.getCurrentUserId() || "";

                let newNoteParams: NoteParamType = {
                    ...noteParams,
                    author_id,
                }

                //TODO mockProvider only works for objects that were created using the mockProvider
                this.addOrUpdateNote(newNoteParams)
                    .then(note => {
                        if (note) {
                            const { id:note_id } = note;
                            const { id:pocket_id } = pocketParams;

                            if (pocket_id) {
                                let pocketMapper = this.getPocketMapper(pocket_id);

                                if (pocketMapper) {
                                    let note_ids: string[] = [];
                                    //copy pocket note ids
                                    Object.assign(note_ids, pocketMapper.pocket.note_ids);
                                    note_ids.push(note.id);

                                    pocketMapper.pocket.note_ids = note_ids;
                                    pocketMapper.notes[note_id] = note

                                    this.pocketProvider?.update(pocket_id, pocketMapper)
                                        .then(pocketMapper => {
                                            if (pocketMapper) {
                                                const items:IRepoItem[] = [];

                                                const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                items.push(...flattenedItems);

                                                this.addOrUpdateAllRepoItems(items);
                                            }
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        });
    };

    addNoteToResource(noteParams: NoteParamType, resourceParams: ResourceParamType, pocketParams: PocketParamType) {//DONE
        return new Promise<void> (() => {
            const { id:note_id } = noteParams;

            //check if note exists
            if (note_id) {
                let note: Nullable<NoteInfo> = this.getNote(note_id);

                if (note) {
                    const { id:resource_id } = resourceParams;
                    const { content, text } = noteParams;

                    if (content) {
                        note.content = content;
                    }
                    if (text) {
                        note.text = text;
                    }

                    if (resource_id) {
                        let resource: Nullable<ResourceInfo> = this.getResource(resource_id);

                        if (resource) {
                            const { id:pocket_id } = pocketParams;

                            let note_ids: string[] = [];
                            //copy resource note ids
                            Object.assign(note_ids, resource.note_ids);
                            note_ids.push(note_id);

                            if (pocket_id) {
                                const pocketMapper = this.getPocketMapper(pocket_id);

                                if (pocketMapper) {
                                    const resourceMapper = new ResourceMapper(resource);
                                    resourceMapper.notes[note_id] = note;

                                    //set new properties
                                    pocketMapper.resourceMappers[resource_id].notes[note_id] = note;
                                    pocketMapper.resourceMappers[resource_id].resource.note_ids = note_ids;

                                    this.pocketProvider?.update(pocket_id, pocketMapper)
                                        .then(pocketMapper => {
                                            console.log(pocketMapper);
                                            if (pocketMapper) {
                                                const items:IRepoItem[] = [];

                                                const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                items.push(...flattenedItems);

                                                this.addOrUpdateAllRepoItems(items);
                                            }
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                }
                            }
                        }
                    }
                }
            } else {
                const author_id = this.userService?.getCurrentUserId() || "";

                let newNoteParams: NoteParamType = {
                    ...noteParams,
                    author_id,
                }

                //TODO mockProvider only works for objects that were created using the mockProvider
                this.addOrUpdateNote(newNoteParams)
                    .then(note => {
                        if (note) {
                            const { id:note_id } = note;
                            const { id:resource_id } = resourceParams;

                            if (resource_id) {
                                let resource: Nullable<ResourceInfo> = this.getResource(resource_id);

                                if (resource) {
                                    const { id:pocket_id } = pocketParams;

                                    let note_ids: string[] = [];
                                    //copy resource note ids
                                    Object.assign(note_ids, resource.note_ids);
                                    note_ids.push(note_id);

                                    if (pocket_id) {
                                        const pocketMapper = this.getPocketMapper(pocket_id);

                                        if (pocketMapper) {
                                            const resourceMapper = new ResourceMapper(resource);
                                            resourceMapper.notes[note_id] = note;

                                            //set new properties
                                            pocketMapper.resourceMappers[resource_id].notes[note_id] = note;
                                            pocketMapper.resourceMappers[resource_id].resource.note_ids = note_ids;

                                            this.pocketProvider?.update(pocket_id, pocketMapper)
                                                .then(pocketMapper => {
                                                    console.log(pocketMapper);
                                                    if (pocketMapper) {
                                                        const items:IRepoItem[] = [];

                                                        const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                        items.push(...flattenedItems);

                                                        this.addOrUpdateAllRepoItems(items);
                                                    }
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                        }
                                    }
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    addResourceToPocket(resourceParams: ResourceParamType, pocketParams: PocketParamType): Promise<void> {//DONE
        return new Promise<void> (() => {
            const { id:pocket_id } = pocketParams;

            if (pocket_id) {
                let pocketMapper = this.getPocketMapper(pocket_id);

                if (pocketMapper) {
                    const { source_id } = resourceParams;

                    if (source_id) {
                        let pocketContainsResource: boolean = false;

                        forEach(pocketMapper.resourceMappers, (resourceMapper: ResourceMapper) => {
                            if (resourceMapper.resource.source_id === source_id) {
                                pocketContainsResource = true;
                            }
                        });

                        if (!pocketContainsResource) {
                            const author_id = this.userService?.getCurrentUserId() || "";

                            let newResourceParams: ResourceParamType = this._setResourceParamsFromDocument(resourceParams);

                            newResourceParams= {
                                ...newResourceParams,
                                author_id
                            }

                            this.addOrUpdateResource(newResourceParams)
                                .then(resource => {
                                    if (resource) {
                                        const { id:resource_id } = resource;

                                        let resourceMapper: ResourceMapper = new ResourceMapper(resource);

                                        if (pocketMapper) {
                                            pocketMapper.resourceMappers[resource_id] = resourceMapper;

                                            this.pocketProvider?.update(pocket_id, pocketMapper)
                                                .then(pocketMapper => {
                                                    if (pocketMapper) {
                                                        const items:IRepoItem[] = [];

                                                        const flattenedItems = this.flattenPocketMapper(pocketMapper);
                                                        items.push(...flattenedItems);

                                                        this.addOrUpdateAllRepoItems(items);
                                                    }
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                        }
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }

            }
        });
    }

    _setResourceParamsFromDocument(resourceParams: ResourceParamType): ResourceParamType {
        const { source_id } = resourceParams;

        let newResourceParams = {
            ...resourceParams
        }

        if (source_id) {
            if (this.documentService) {
                const document = this.documentService.getDocument(source_id);
                if (document !== null) {
                    const { title, author, publication_date } = document;

                    newResourceParams = {
                        ...newResourceParams,
                        source_title: title,
                        source_author: JSON.stringify(author),
                        source_publication_date: publication_date,
                        source_version: '',
                        title: title,
                    }
                }
            }
        }

        return newResourceParams;
    }

    removeExcerptFromResource(excerpt_id: string, pocket_id: string): Promise<void> {
        return new Promise<void> (() => {
            const pocketMapper = this.getPocketMapper(pocket_id);

            if (pocketMapper) {
                const modifiedPocketMapper: PocketMapper = new PocketMapper(pocketMapper.pocket);

                forEachKVP(pocketMapper.resourceMappers, (itemKey: string, resourceMapper: ResourceMapper) => {

                    const modifiedResourceMapper: ResourceMapper = new ResourceMapper(resourceMapper.resource);
                    modifiedResourceMapper.resource.excerptIds = [];

                    forEachKVP(resourceMapper.excerptMappers, (itemKey: string, excerptMapper: ExcerptMapper) => {

                        if (itemKey !== excerpt_id) {
                            modifiedResourceMapper.excerptMappers[itemKey] = excerptMapper;
                            modifiedResourceMapper.resource.excerptIds.push(itemKey);
                        }
                    });

                    modifiedPocketMapper.resourceMappers[itemKey] = modifiedResourceMapper;


                });

                this.pocketProvider?.update(pocket_id, modifiedPocketMapper)
                    .then(pocketMapper => {
                        if (pocketMapper) {
                            const excerptInfo = this.getExcerpt(excerpt_id);

                            if (excerptInfo) {
                                forEach(excerptInfo.noteIds, (note_id: string) => {
                                    this.removeAllById(NoteInfo.class, note_id);
                                });

                                this.removeAllById(ExcerptInfo.class, excerpt_id);
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        });
    };

    removeNoteFromExcerpt(note_id: string, pocket_id: string): Promise<void> {
        return new Promise<void> (() => {
            const pocketMapper = this.getPocketMapper(pocket_id);

            if (pocketMapper) {
                const modifiedPocketMapper: PocketMapper = new PocketMapper(pocketMapper.pocket);

                forEachKVP(pocketMapper.resourceMappers, (itemKey: string, resourceMapper: ResourceMapper) => {
                    const modifiedResourceMapper: ResourceMapper = new ResourceMapper(resourceMapper.resource);

                    forEachKVP(resourceMapper.excerptMappers, (itemKey: string, excerptMapper: ExcerptMapper) => {
                        const modifiedExcerptMapper: ExcerptMapper = new ExcerptMapper(excerptMapper.excerpt);

                        modifiedExcerptMapper.excerpt.noteIds = [];

                        forEachKVP(excerptMapper.notes, (itemKey: string, noteInfo: NoteInfo) => {

                            if (itemKey !== note_id) {
                                modifiedExcerptMapper.notes[itemKey] = noteInfo;
                                modifiedExcerptMapper.excerpt.noteIds.push(itemKey);
                            } else {
                                delete modifiedExcerptMapper.notes[itemKey];
                            }
                        });

                        modifiedResourceMapper.excerptMappers[itemKey] = modifiedExcerptMapper;
                    });

                    modifiedPocketMapper.resourceMappers[itemKey] = modifiedResourceMapper;
                });

                this.pocketProvider?.update(pocket_id, modifiedPocketMapper)
                    .then(pocketMapper => {
                        if (pocketMapper) {
                            const noteInfo = this.getNote(note_id);

                            if (noteInfo) {
                                this.removeAllById(NoteInfo.class, note_id);
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        })
    }

    removeNoteFromResource(note_id: string, pocket_id: string): Promise<void> {
        return new Promise<void> (() => {
            const pocketMapper = this.getPocketMapper(pocket_id);

            if (pocketMapper) {
                const modifiedPocketMapper: PocketMapper = new PocketMapper(pocketMapper.pocket);

                forEachKVP(pocketMapper.resourceMappers, (itemKey: string, resourceMapper: ResourceMapper) => {
                    const modifiedResourceMapper: ResourceMapper = new ResourceMapper(resourceMapper.resource);

                    modifiedResourceMapper.resource.note_ids = [];

                    forEachKVP(resourceMapper.notes, (itemKey: string, noteInfo: NoteInfo) => {

                        if (itemKey !== note_id) {
                            modifiedResourceMapper.notes[itemKey] = noteInfo;
                            modifiedResourceMapper.resource.note_ids.push(itemKey);
                        } else {
                            delete modifiedResourceMapper.notes[itemKey];
                        }
                    });

                    modifiedPocketMapper.resourceMappers[itemKey] = modifiedResourceMapper;
                });

                this.pocketProvider?.update(pocket_id, modifiedPocketMapper)
                    .then(pocketMapper => {
                        if (pocketMapper) {
                            const noteInfo = this.getNote(note_id);

                            if (noteInfo) {
                                this.removeAllById(NoteInfo.class, note_id);
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        })
    }

    removeNoteFromPocket(note_id: string, pocket_id: string): Promise<void> {
        return new Promise<void> (() => {
            const pocketMapper = this.getPocketMapper(pocket_id);

            if (pocketMapper) {
                const modifiedPocketMapper: PocketMapper = new PocketMapper(pocketMapper.pocket);

                modifiedPocketMapper.pocket.note_ids = [];

                forEachKVP(pocketMapper.notes, (itemKey: string, noteInfo: NoteInfo) => {

                    if (itemKey !== note_id) {
                        modifiedPocketMapper.notes[itemKey] = noteInfo;
                        modifiedPocketMapper.pocket.note_ids.push(itemKey);
                    } else {
                        delete modifiedPocketMapper.notes[itemKey];
                    }
                });

                this.pocketProvider?.update(pocket_id, modifiedPocketMapper)
                    .then(pocketMapper => {
                        if (pocketMapper) {
                            const noteInfo = this.getNote(note_id);

                            if (noteInfo) {
                                this.removeAllById(NoteInfo.class, note_id);
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        });
    };

    removeResourceFromPocket(resource_id: string, pocket_id: string): Promise<void> {
        return new Promise<void>(() => {
            const pocketMapper = this.getPocketMapper(pocket_id);

            if (pocketMapper) {
                const modifiedPocketMapper: PocketMapper = new PocketMapper(pocketMapper.pocket);

                forEachKVP(pocketMapper.resourceMappers, (itemKey: string, resourceMapper: ResourceMapper) => {

                    if (itemKey !== resource_id) {
                        modifiedPocketMapper.resourceMappers[itemKey] = resourceMapper;
                    }
                });

                this.pocketProvider?.update(pocket_id, modifiedPocketMapper)
                    .then(pocketMapper => {
                        if (pocketMapper) {
                            const resourceInfo = this.getResource(resource_id);

                            if (resourceInfo) {
                                forEach(resourceInfo.excerptIds, (excerpt_id: string) => {
                                    const excerptInfo = this.getExcerpt(excerpt_id);

                                    if (excerptInfo) {
                                        forEach(excerptInfo.noteIds, (note_id: string) => {
                                            this.removeAllById(NoteInfo.class, note_id);
                                        });

                                        this.removeAllById(ExcerptInfo.class, excerpt_id);
                                    }
                                });

                                forEach(resourceInfo.note_ids, (note_id: string) => {
                                    this.removeAllById(NoteInfo.class, note_id);
                                });

                                this.removeAllById(ResourceInfo.class, resource_id);
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        });
    }

    getSearchText(): string {
        let result = '';

        let repoItem: any = super.getRepoItem(SearchParamInfo.class, 'pocket_search_request');
        if (repoItem != null) {
            result = repoItem.value;
        }

        return result;
    }

    setSearchText(value: string): void {
        let repoItem = super.getRepoItem(SearchParamInfo.class, 'pocket_search_request');

        if (repoItem != null) {
            let next = Object.assign(new SearchParamInfo('pocket_search_request'), repoItem);
            next.value = value;
            this.addOrUpdateRepoItem(next);
        }
    }

    lockPocket(id: string): Promise<void> {
        return new Promise<void>(() => {
            this.pocketProvider?.patch(id, {lock: true, duration: 30});
        });
    }

    unlockPocket(id: string): Promise<void> {
        return new Promise<void>(() => {
            this.pocketProvider?.patch(id, {lock: false});
        });
    }
}
