import React, {Component} from 'react';
import './systemMenu.css';
import '../../../theme/stylesheets/panel.css';
import {Scope, SystemMenuViewProps} from "../systemMenuModel";
import Button from "../../../theme/widgets/button/button";
import {Justify_Content} from "../../../theme/widgets/button/buttonModel";
import {forEach, forEachKVP} from "../../../../framework.core/extras/utils/collectionUtils";
import {TemplateVM} from "../../pocketManagerPanel/pocketManagerPanelModel";
import {Menu} from "../../../theme/widgets/menu/menu";
import Popup from "../../../theme/widgets/popup/popup";
import TextEdit from "../../../theme/widgets/textEdit/textEdit";
import {PocketSVG} from "../../../theme/svgs/pocketSVG";
import {NoteSVG} from "../../../theme/svgs/noteSVG";
import {PocketVM} from "../../searchResultsPanel/searchResultsModel";
import {DocumentSVG} from "../../../theme/svgs/documentSVG";
import {LoadingIndicator} from "../../../theme/widgets/loadingIndicator/loadingIndicator";
import {Size} from "../../../theme/widgets/loadingIndicator/loadingIndicatorModel";
import UserInput from "../../../theme/widgets/userInput/userInput";
import Portal from "../../../theme/widgets/portal/portal";

class SystemMenuView extends Component<SystemMenuViewProps> {
    render() {
        const {
            className,
            currentUser,
            onAddNote,
            onAddToPocket,
            onCreatePocket,
            onCreateReport,
            onDelete,
            onDownload,
            onHoverMenuItem,
            onPublish,
            onSelectMenuItem,
            onTemplateSelect,
            onTmpDocumentChanged,
            onTmpNoteChanged,
            onTmpPocketChanged,
            onTmpReportNameChanged,
            onToggleShowAddToPocket,
            onToggleShowCreateReportPopup,
            onToggleShowSharePopup,
            onToggleShowTitlePopup,
            onToggleShowNotePopup,
            onToggleUserInputExpanded,
            permissions,
            pockets,
            selectedDocument,
            selectedExcerptId,
            selectedMenuItemId,
            selectedNoteId,
            selectedPocket,
            selectedReportId,
            selectedTemplateId,
            showAddToPocket,
            showCreateReportPopup,
            showNotePopup,
            showSharePopup,
            showTitlePopup,
            templates,
            tmpSharedAuthors,
            tmpNote,
            tmpPocket,
            tmpReportTitle,
            userInputExpanded,
            userSuggestionSupplier,
            ...rest
        } = this.props;

        let cn = "system-menu d-flex";
        if (className) {
            cn += ` ${className}`;
        }

        let isUpdating = false;

        const { title:tmp_pocket_title } = tmpPocket;

        let titleValue = tmp_pocket_title;
        let titleDirty = !!tmp_pocket_title;

        const { text } = tmpNote;

        let noteValue = text;
        let noteDirty = !!text;

        let file_content: JSX.Element[] = [];
        let edit_content: JSX.Element[] = [];

        let pocketItems: JSX.Element[] = [];
        let authorDivs: JSX.Element[] = [];
        let templateDivs: JSX.Element[] = [];

        templateDivs.push(
            <div
                className={`template-item d-flex flex-column bg-primary align-items-center justify-content-center cursor-pointer ${!selectedTemplateId || selectedTemplateId === '' ? 'selected' : ''}`}
                onClick={() => onTemplateSelect('')}
            >
                <div className={'display-4 text-info text-center'}>Blank</div>
            </div>
        );

        if (selectedPocket) {
            // console.log(selectedPocket);
            const { title, shared_authors, readonly } = selectedPocket;

            isUpdating = !!selectedPocket.isUpdating;

            if (!titleValue) {
                titleValue = title;
            }

            forEach(templates, (template: TemplateVM) => {
                const { title, id='', selected } = template;

                templateDivs.push(
                    <div
                        className={`template-item d-flex flex-column bg-primary align-items-center justify-content-center cursor-pointer ${selected ? 'selected' : ''}`}
                        onClick={() => onTemplateSelect(id)}
                    >
                        <div className={'display-4 text-info text-center'}>{title}</div>
                    </div>
                );
            });

            if (currentUser) {
                let initials = currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0);

                authorDivs.push(
                    <div className={'author-icon self rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
                        {initials}
                    </div>
                );
            }

            if (shared_authors) {
                forEachKVP(shared_authors, (itemKey: string, itemValue: string) => {
                    let name = itemValue.split(' ');

                    let initials = name[0]?.charAt(0);
                    if (name[1]) {
                        initials += name[1].charAt(0);
                    }

                    authorDivs.push(
                        <div className={'author-icon rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
                            {initials}
                        </div>
                    );
                });
            }

            forEachKVP(tmpSharedAuthors, (itemKey: string, itemValue: string) => {
                authorDivs.push(
                    <div className={'author-icon rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
                        {itemValue}
                    </div>
                );
            });

            if (!readonly && permissions.canModify) {
                file_content.push(
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={() => onPublish()}
                    >Publish Changes</Button>,
                    // <Button
                    //     className={'rounded-0 header-1 py-2 pl-3'}
                    //     justifyContent={Justify_Content.justify_content_start}
                    //     onClick={onToggleShowSharePopup}
                    // >Share...</Button>,//TODO implement sharing
                );
            }
            // if (permissions.canDownload) {//TODO implement downloading
            //     file_content.push(
            //         <Button
            //             className={'rounded-0 header-1 py-2 pl-3'}
            //             justifyContent={Justify_Content.justify_content_start}
            //             onClick={() => onDownload(true)}
            //         >Download</Button>,
            //     );
            // }

            if (!readonly && permissions.canModify) {
                file_content.push(
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={onToggleShowCreateReportPopup}
                    >Add Report</Button>,
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={onToggleShowNotePopup}
                    >{selectedNoteId ? 'Update' : 'Add'} Note {selectedExcerptId ? 'to Excerpt' : selectedDocument ? 'to Document' : selectedPocket ? selectedNoteId ? '' : 'to Pocket' : ''}</Button>,
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={() => onPublish(Scope.PRIVATE)}
                    >Save Changes</Button>,
                );
            }

            // if (permissions.canDownload) {//TODO implement download
            //     file_content.push(
            //         <Button
            //             className={'rounded-0 header-1 py-2 pl-3'}
            //             justifyContent={Justify_Content.justify_content_start}
            //             onClick={() => onDownload()}
            //         >Print</Button>,
            //     );
            // }

            if (!readonly && permissions.canDelete) {
                file_content.push(
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={onDelete}
                    >{selectedDocument ? 'Remove Resource' : `Delete ${selectedNoteId ? 'Note' : selectedExcerptId ? 'Excerpt' : selectedReportId ? 'Report' : 'Pocket'}`}</Button>,
                );
            }

            if (!readonly && permissions.canModify) {
                edit_content.push(
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={onToggleShowTitlePopup}
                    >Rename</Button>,
                );
            }
        } else if (selectedDocument) {
            console.log(selectedDocument);
            const { id, title, shared_authors } = selectedDocument;

            isUpdating = !!selectedDocument.isUpdating;

            if (!titleValue) {
                titleValue = title;
            }

            if (currentUser) {
                let initials = currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0);

                authorDivs.push(
                    <div className={'author-icon self rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
                        {initials}
                    </div>
                );
            }

            if (shared_authors) {
                forEachKVP(shared_authors, (itemKey: string, itemValue: string) => {
                    let name = itemValue.split(' ');

                    let initials = name[0]?.charAt(0);
                    if (name[1]) {
                        initials += name[1].charAt(0);
                    }

                    authorDivs.push(
                        <div className={'author-icon rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
                            {initials}
                        </div>
                    );
                });
            }

            forEachKVP(tmpSharedAuthors, (itemKey: string, itemValue: string) => {
                authorDivs.push(
                    <div className={'author-icon rounded-circle display-3 font-weight-semi-bold d-flex align-items-center justify-content-center'}>
                        {itemValue}
                    </div>
                );
            });

            if (permissions.canModify) {
                file_content.push(
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={() => onPublish()}
                    >Publish Changes</Button>,
                    // <Button
                    //     className={'rounded-0 header-1 py-2 pl-3'}
                    //     justifyContent={Justify_Content.justify_content_start}
                    //     onClick={onToggleShowSharePopup}
                    // >Share...</Button>,
                );
            }
            if (permissions.canDownload) {
                file_content.push(
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={() => onDownload(true)}
                    >Download</Button>,
                );
            }

            if (permissions.canModify) {
                file_content.push(
                    <Portal
                        isOpen={showAddToPocket}
                        zIndex={9999}
                        enterClass={'fadeIn'}
                        exitClass={'fadeOut'}
                        timeout={250}
                        autoLayout={false}
                        onShouldClose={onToggleShowAddToPocket}
                        portalContent={
                            ({}) =>
                                <div className={`w-100 search-results-portal`}>
                                    <ul className={"w-100 list-items header-3"}>
                                        {pocketItems}
                                        <div className={"d-flex h-gap-1 align-items-center p-3 cursor-pointer"} onClick={() => onAddToPocket(id || "", "")}>
                                            <PocketSVG className={"nano-image-container fill-primary"}/>
                                            <div>Create Pocket</div>

                                        </div>
                                    </ul>
                                </div>
                        }>
                        <Button id={'DOCUMENT_PANEL_MENU_POCKET'} className={'rounded-0 header-1 py-2 pl-3'} justifyContent={Justify_Content.justify_content_start} onClick={onToggleShowAddToPocket}>Pocket</Button>
                    </Portal>,
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={() => onPublish(Scope.PRIVATE)}
                    >Save Changes</Button>,
                );
            }

            if (permissions.canDownload) {
                file_content.push(
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={() => onDownload()}
                    >Print</Button>,
                );
            }

            if (permissions.canDelete) {
                file_content.push(
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={onDelete}
                    >Delete Document</Button>,
                );
            }

            if (permissions.canModify) {
                edit_content.push(
                    <Button
                        className={'rounded-0 header-1 py-2 pl-3'}
                        justifyContent={Justify_Content.justify_content_start}
                        onClick={onToggleShowTitlePopup}
                    >Rename</Button>,
                );
            }

            if (pockets && id) {
                forEachKVP(pockets, (pocketKey: string, pocketValue: PocketVM) => {
                    const { id:pocketId, title:pocketTitle } = pocketValue;

                    if (pocketId) {
                        pocketItems.push(
                            <div
                                className={"d-flex p-3 cursor-pointer"}
                                onClick={() => onAddToPocket(id, pocketId)}
                            >
                                {pocketTitle}
                            </div>
                        );
                    }
                });
            }
        } else {
            file_content.push(
                <Button
                    className={'rounded-0 header-1 py-2 pl-3'}
                    justifyContent={Justify_Content.justify_content_start}
                    onClick={onToggleShowTitlePopup}
                >Create Pocket</Button>,
            );
        }

        return (
            <div className={cn} {...rest}>
                <Popup className={'share-popup'} isVisible={showSharePopup} onCancel={onToggleShowSharePopup}>
                    <div className={'d-flex flex-column align-items-center v-gap-3'}>
                        <DocumentSVG className={'small-image-container'}/>
                        <div className={'text-center display-4'}>Send link to user:</div>
                        <div className={'d-flex h-gap-3 align-items-center'}>
                            {authorDivs}
                            {
                                isUpdating ?
                                    <LoadingIndicator size={Size.nano}/>
                                    :
                                    <UserInput
                                        name={'shared_user_ids'}
                                        onExpand={onToggleUserInputExpanded}
                                        expanded={userInputExpanded}
                                        suggestionSupplier={userSuggestionSupplier}
                                        onSubmitUser={selectedDocument ? onTmpDocumentChanged : selectedPocket ? onTmpPocketChanged : () => {}}
                                    />
                            }

                        </div>
                        <div className={'d-flex h-gap-3'}>
                            <Button light={true} text={'Cancel'} onClick={onToggleShowSharePopup}/>
                            <Button light={true} highlight={true} text={'Send'} onClick={() => onPublish()}/>
                        </div>
                    </div>
                </Popup>
                <Popup
                    className={'report-popup'}
                    isVisible={showCreateReportPopup}
                    onCancel={onToggleShowCreateReportPopup}
                >
                    <div className={'d-flex py-5 flex-column align-items-center v-gap-3'}>
                        <div className={'d-flex h-gap-2 template-item-container px-3'}>
                            {templateDivs}
                        </div>

                        <TextEdit
                            placeholder={'Name Report'}
                            name={'title'}
                            dirty={true}
                            value={tmpReportTitle}
                            // edit={!!selectedPocket}//TODO add permissions
                            autoFocus={true}
                            onSubmit={onTmpReportNameChanged}
                        />

                        <div className={'d-flex h-gap-3'}>
                            <Button light={true} text={'Cancel'} onClick={onToggleShowCreateReportPopup}/>
                            <Button light={true} highlight={true} text={'Create'} onClick={onCreateReport}/>
                        </div>
                    </div>
                </Popup>

                <Popup className={'title-popup'} isVisible={showTitlePopup} onCancel={onToggleShowTitlePopup}>
                    <div className={'d-flex flex-column align-items-center v-gap-3'}>
                        {
                            selectedDocument ?
                                <DocumentSVG className={'small-image-container'}/>
                                :
                                <PocketSVG className={'small-image-container'}/>
                        }

                        <TextEdit
                            placeholder={`Type ${selectedDocument ? 'Document' : 'Pocket'} Name`}
                            name={'title'}
                            dirty={titleDirty}
                            value={titleValue}
                            // edit={!!selectedPocket}//TODO add permissions
                            autoFocus={true}
                            onSubmit={onTmpPocketChanged}
                        />
                        <div className={'d-flex h-gap-3'}>
                            <Button light={true} text={'Cancel'} onClick={onToggleShowTitlePopup}/>
                            <Button
                                light={true}
                                highlight={true}
                                text={selectedDocument || selectedPocket ? 'Update' : 'Create'}
                                onClick={selectedDocument || selectedPocket ? () => onPublish() : onCreatePocket}
                            />
                        </div>
                    </div>
                </Popup>

                <Popup className={'note-popup'} isVisible={showNotePopup} onCancel={onToggleShowNotePopup}>
                    <div className={'d-flex flex-column align-items-center v-gap-3'}>
                        <NoteSVG className={'small-image-container'}/>
                        <TextEdit
                            placeholder={'Type Note'}
                            name={'text'}
                            dirty={noteDirty}
                            value={noteValue}
                            // edit={!!selectedPocket}//TODO add permissions
                            autoFocus={true}
                            onSubmit={onTmpNoteChanged}
                        />
                        <div className={'d-flex h-gap-3'}>
                            <Button light={true} text={'Cancel'} onClick={onToggleShowNotePopup}/>
                            <Button
                                light={true}
                                highlight={true}
                                text={selectedNoteId ? 'Update' : 'Add'}
                                onClick={onAddNote}
                            />
                        </div>
                    </div>
                </Popup>

                <Menu
                    light={true}
                    menuItems={
                        [
                            {
                                id: 'System_Menu',
                                type: 'File',
                                selected: selectedMenuItemId === 'System_Menu_File',
                                content: file_content,
                            },
                            {
                                id: 'System_Menu',
                                type: 'Edit',
                                selected: selectedMenuItemId === 'System_Menu_Edit',
                                content: edit_content,
                            },
                            {
                                id: 'System_Menu',
                                type: 'Tag',
                                selected: selectedMenuItemId === 'System_Menu_Tag',
                                content: [],
                            },
                            {
                                id: 'System_Menu',
                                type: 'View',
                                selected: selectedMenuItemId === 'System_Menu_View',
                                content: [],
                            },
                            {
                                id: 'System_Menu',
                                type: 'Info',
                                selected: selectedMenuItemId === 'System_Menu_Info',
                                content: [],
                            },
                        ]
                    }
                    onSelectMenuItem={onSelectMenuItem}
                    onHoverMenuItem={onHoverMenuItem}
                />
            </div>
        );
    }
}

export default SystemMenuView;
